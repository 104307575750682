const ENV_CONFIG = {
  local: process.env.LOCAL === 'true',
  env: process.env.UI_ENV,
  app: {
    name: 'Kognitos'
  },
  doc: {
    baseUrl: process.env.ENV_DOC_BASE_URL!
  },
  integrations: {
    logRocket: {
      enabled: process.env.ENV_INTEGRATION_LOGROCKET_ENABLED === 'true',
      key: process.env.ENV_INTEGRATION_LOGROCKET_KEY!
    },
    sentry: {
      dsn: process.env.SENTRY_DSN
    },
    amplitude: {
      key: process.env.ENV_INTEGRATION_AMPLITUDE_KEY!
    },
    algolia: {
      key: process.env.ENV_INTEGRATION_ALGOLIA_KEY!,
      indexName: process.env.ENV_INTEGRATION_ALGOLIA_INDEX!,
      appId: process.env.ENV_INTEGRATION_ALGOLIA_APP_ID!
    }
  }
};

export default ENV_CONFIG;
