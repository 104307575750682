import React from 'react';

export enum WalkthroughType {
  EXAMPLE = 'EXAMPLE',
  PROCEDURE = 'PROCEDURE',
  PROCEDURE_RUN = 'PROCEDURE_RUN',
  PLAYGROUND = 'PLAYGROUND'
}

export interface IWalkthroughStep {
  target: string;
  title?: string;
  content: React.ReactNode;
}
