/* eslint-disable no-nested-ternary */
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import {
  AutomationStatus,
  automationStatusSelector,
  runAutomation,
  exampleQuerySelector
} from '@/stores/slices/example';
import React, { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { message, Steps } from 'antd';
import routes from '@/utils/routes';
import Loader, { ILoaderType } from '@/components/Loader';
import Cell from '@/components/example/Cell';
import './Automation.less';
import { LoadingOutlined } from '@ant-design/icons';
import { useAmplitudeTrack } from '@/analytics/hooks';
import AppUtil from '../utils/AppUtil';

const { Step } = Steps;

const steps = [
  'Creating the automation',
  'Running the automation',
  'Redirecting to the automation'
];

export default function Automation() {
  const { name } = useParams();

  useDocumentTitle(AppUtil.preparePageTitle(`${name} | Automation`));
  useAmplitudeTrack('AutomationPage', { name });

  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const status = useAppSelector(automationStatusSelector);
  const [[currentStep, titles], setStepNoAndTitles] = useState([
    0,
    ['Waiting', 'Waiting', 'Waiting']
  ]);
  const { example, isLoading } = useAppSelector(exampleQuerySelector(name!));

  useEffect(() => {
    dispatch(runAutomation(name!))
      .unwrap()
      .then((data) => {
        navigate(
          routes.procedure.getProcedureRunPageUrl(
            data.departmentId,
            data.knowledgeId,
            data.procedureId!,
            data.workerId
          ),
          { replace: true }
        );
      });
  }, [name]);

  useEffect(() => {
    switch (status) {
      case AutomationStatus.Idle:
      case AutomationStatus.Fetching:
        setStepNoAndTitles([0, ['Waiting', 'Waiting', 'Waiting']]);
        break;
      case AutomationStatus.Learning:
        setStepNoAndTitles([0, ['In Progress', 'Waiting', 'Waiting']]);
        break;
      case AutomationStatus.Running:
        setStepNoAndTitles([1, ['Finished', 'In Progress', 'Waiting']]);
        break;
      case AutomationStatus.Redirecting:
        setStepNoAndTitles([2, ['Finished', 'Finished', 'In Progress']]);
        break;
      case AutomationStatus.Error:
        message.error('Error running automation');
        break;
      default:
        break;
    }
  }, [status]);

  if (isLoading) {
    return <Loader type={ILoaderType.FULL_PAGE} />;
  }

  const cell = example?.cells[0];

  return (
    <div className="automation-container" data-cy="automation">
      {/* Showing first cell for heading */}
      {cell && <Cell key={cell.metadata.id} cell={cell} />}
      <Steps
        direction="vertical"
        current={currentStep}
        status={
          status === AutomationStatus.Error
            ? 'error'
            : status === AutomationStatus.Redirecting
            ? 'finish'
            : 'process'
        }
      >
        {steps.map((step, index) => (
          <Step
            title={titles[index]}
            key={step}
            icon={titles[index] === 'In Progress' ? <LoadingOutlined /> : null}
            description={step}
          />
        ))}
      </Steps>
    </div>
  );
}
