/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const createAnswer = /* GraphQL */ `
  mutation CreateAnswer($input: CreateAnswerInput!) {
    createAnswer(input: $input) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      commandId
      questionId
      type
      owner
      readers
      editors
      name
      description
      text
      error
      remember
      secret
    }
  }
`;
export const createAnswers = /* GraphQL */ `
  mutation CreateAnswers($inputs: [CreateAnswerInput!]!) {
    createAnswers(inputs: $inputs) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      commandId
      questionId
      type
      owner
      readers
      editors
      name
      description
      text
      error
      remember
      secret
    }
  }
`;
export const updateAnswer = /* GraphQL */ `
  mutation UpdateAnswer($input: UpdateAnswerInput!) {
    updateAnswer(input: $input) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      commandId
      questionId
      type
      owner
      readers
      editors
      name
      description
      text
      error
      remember
      secret
    }
  }
`;
export const createCommand = /* GraphQL */ `
  mutation CreateCommand($input: CreateCommandInput!, $locked: Boolean) {
    createCommand(input: $input, locked: $locked) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      text
      owner
      readers
      editors
      name
      description
      subText
      subTextProperties
      subTextLanguage
      procedureId
      contextId
      sentenceId
      debug
      ast
      state
      output
      source
      sourceMetadata
      positionInContext
      isMultilineText
      questions {
        nextToken
      }
      answers {
        nextToken
      }
      notifications {
        nextToken
      }
    }
  }
`;
export const updateCommand = /* GraphQL */ `
  mutation UpdateCommand($input: UpdateCommandInput!) {
    updateCommand(input: $input) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      text
      owner
      readers
      editors
      name
      description
      subText
      subTextProperties
      subTextLanguage
      procedureId
      contextId
      sentenceId
      debug
      ast
      state
      output
      source
      sourceMetadata
      positionInContext
      isMultilineText
      questions {
        nextToken
      }
      answers {
        nextToken
      }
      notifications {
        nextToken
      }
    }
  }
`;
export const updateCommandState = /* GraphQL */ `
  mutation UpdateCommandState($id: ID!, $state: String!, $output: String) {
    updateCommandState(id: $id, state: $state, output: $output) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      text
      owner
      readers
      editors
      name
      description
      subText
      subTextProperties
      subTextLanguage
      procedureId
      contextId
      sentenceId
      debug
      ast
      state
      output
      source
      sourceMetadata
      positionInContext
      isMultilineText
      questions {
        nextToken
      }
      answers {
        nextToken
      }
      notifications {
        nextToken
      }
    }
  }
`;
export const createOrganization = /* GraphQL */ `
  mutation CreateOrganization($input: CreateOrganizationInput!) {
    createOrganization(input: $input) {
      id
      name
      owner
      createdAt
      updatedAt
    }
  }
`;
export const deleteOrganization = /* GraphQL */ `
  mutation DeleteOrganization($input: DeleteOrganizationInput!) {
    deleteOrganization(input: $input) {
      id
      name
      owner
      createdAt
      updatedAt
    }
  }
`;
export const createDepartment = /* GraphQL */ `
  mutation CreateDepartment($input: CreateDepartmentInput!) {
    createDepartment(input: $input) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const updateDepartment = /* GraphQL */ `
  mutation UpdateDepartment($input: UpdateDepartmentInput!) {
    updateDepartment(input: $input) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const deleteDepartment = /* GraphQL */ `
  mutation DeleteDepartment($input: DeleteDepartmentInput!) {
    deleteDepartment(input: $input) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const rollbackDepartmentVersion = /* GraphQL */ `
  mutation RollbackDepartmentVersion($input: DepartmentRollbackInput!) {
    rollbackDepartmentVersion(input: $input) {
      id
      departmentId
      previousVersion
      currentVersion
      rollbackVersion
    }
  }
`;
export const restoreDepartment = /* GraphQL */ `
  mutation RestoreDepartment($input: RestoreDepartmentInput!) {
    restoreDepartment(input: $input) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const upsertUserToDepartment = /* GraphQL */ `
  mutation UpsertUserToDepartment(
    $departmentId: ID!
    $userId: ID!
    $role: AccessControlRole!
  ) {
    upsertUserToDepartment(
      departmentId: $departmentId
      userId: $userId
      role: $role
    ) {
      status
    }
  }
`;
export const removeUserFromDepartment = /* GraphQL */ `
  mutation RemoveUserFromDepartment($departmentId: ID!, $userId: ID!) {
    removeUserFromDepartment(departmentId: $departmentId, userId: $userId) {
      status
    }
  }
`;
export const createServiceCommand = /* GraphQL */ `
  mutation CreateServiceCommand($input: CreateServiceCommandInput!) {
    createServiceCommand(input: $input) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      method
      owner
      readers
      editors
      params
      result
      error
    }
  }
`;
export const updateServiceCommand = /* GraphQL */ `
  mutation UpdateServiceCommand($input: UpdateServiceCommandInput!) {
    updateServiceCommand(input: $input) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      method
      owner
      readers
      editors
      params
      result
      error
    }
  }
`;
export const createWakeupCall = /* GraphQL */ `
  mutation CreateWakeupCall($input: CreateWakeupCallInput!) {
    createWakeupCall(input: $input) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      scheduledTime
    }
  }
`;
export const createScheduledWakeupCall = /* GraphQL */ `
  mutation CreateScheduledWakeupCall($input: CreateScheduledWakeupInput!) {
    createScheduledWakeupCall(input: $input) {
      workerId
      scheduledTime
      executionDate
      itemId
    }
  }
`;
export const createWorker = /* GraphQL */ `
  mutation CreateWorker($input: CreateWorkerInput!, $local: Boolean) {
    createWorker(input: $input, local: $local) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
      exceptions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      questions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const updateWorker = /* GraphQL */ `
  mutation UpdateWorker($input: UpdateWorkerInput!) {
    updateWorker(input: $input) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
      exceptions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      questions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const updateWorkerInternalState = /* GraphQL */ `
  mutation UpdateWorkerInternalState(
    $id: ID!
    $state: String!
    $message: String
  ) {
    updateWorkerInternalState(id: $id, state: $state, message: $message) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
      exceptions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      questions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const deleteWorker = /* GraphQL */ `
  mutation DeleteWorker($input: DeleteWorkerInput!) {
    deleteWorker(input: $input) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
      exceptions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      questions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const setWorkerArchived = /* GraphQL */ `
  mutation SetWorkerArchived($input: ArchiveWorkerInput!) {
    setWorkerArchived(input: $input) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
      exceptions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      questions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const createQuestion = /* GraphQL */ `
  mutation CreateQuestion($input: CreateQuestionInput!) {
    createQuestion(input: $input) {
      id
      commandId
      workerId
      contextId
      sentenceId
      contextPath {
        ctxId
        sentenceId
      }
      owner
      readers
      editors
      name
      description
      type
      text
      path
      procedureId
      validationList
      answerId
      isAnswered
      delegationChain {
        method
        recipients
        parameters
      }
      createdAt
      rawException
      parentId
      secret
      stepPath
      answerTypeHint
      sourceMetadata
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      children {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      answer {
        id
        workerId
        createdAt
        forwardedAt
        invokedAt
        receivedAt
        processingAt
        processedAt
        failedAt
        workStatus
        workErrors
        commandId
        questionId
        type
        owner
        readers
        editors
        name
        description
        text
        error
        remember
        secret
      }
    }
  }
`;
export const updateQuestion = /* GraphQL */ `
  mutation UpdateQuestion($input: UpdateQuestionInput!) {
    updateQuestion(input: $input) {
      id
      commandId
      workerId
      contextId
      sentenceId
      contextPath {
        ctxId
        sentenceId
      }
      owner
      readers
      editors
      name
      description
      type
      text
      path
      procedureId
      validationList
      answerId
      isAnswered
      delegationChain {
        method
        recipients
        parameters
      }
      createdAt
      rawException
      parentId
      secret
      stepPath
      answerTypeHint
      sourceMetadata
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      children {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      answer {
        id
        workerId
        createdAt
        forwardedAt
        invokedAt
        receivedAt
        processingAt
        processedAt
        failedAt
        workStatus
        workErrors
        commandId
        questionId
        type
        owner
        readers
        editors
        name
        description
        text
        error
        remember
        secret
      }
    }
  }
`;
export const deleteQuestion = /* GraphQL */ `
  mutation DeleteQuestion($input: DeleteQuestionInput!) {
    deleteQuestion(input: $input) {
      id
      commandId
      workerId
      contextId
      sentenceId
      contextPath {
        ctxId
        sentenceId
      }
      owner
      readers
      editors
      name
      description
      type
      text
      path
      procedureId
      validationList
      answerId
      isAnswered
      delegationChain {
        method
        recipients
        parameters
      }
      createdAt
      rawException
      parentId
      secret
      stepPath
      answerTypeHint
      sourceMetadata
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      children {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      answer {
        id
        workerId
        createdAt
        forwardedAt
        invokedAt
        receivedAt
        processingAt
        processedAt
        failedAt
        workStatus
        workErrors
        commandId
        questionId
        type
        owner
        readers
        editors
        name
        description
        text
        error
        remember
        secret
      }
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      contextId
      commandId
      workerId
      sentenceId
      info
      owner
      readers
      editors
      name
      description
      createdAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      contextId
      commandId
      workerId
      sentenceId
      info
      owner
      readers
      editors
      name
      description
      createdAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
      contextId
      commandId
      workerId
      sentenceId
      info
      owner
      readers
      editors
      name
      description
      createdAt
    }
  }
`;
export const createLearning = /* GraphQL */ `
  mutation CreateLearning($input: CreateLearningInput!) {
    createLearning(input: $input) {
      id
      procedureId
      departmentId
      type
      inputParams
      questionType
      questionPath
      answerType
      answerText
      owner
      readers
      editors
      createdAt
      secret
    }
  }
`;
export const updateLearning = /* GraphQL */ `
  mutation UpdateLearning($input: UpdateLearningInput!) {
    updateLearning(input: $input) {
      id
      procedureId
      departmentId
      type
      inputParams
      questionType
      questionPath
      answerType
      answerText
      owner
      readers
      editors
      createdAt
      secret
    }
  }
`;
export const deleteLearning = /* GraphQL */ `
  mutation DeleteLearning($input: DeleteLearningInput!) {
    deleteLearning(input: $input) {
      id
      procedureId
      departmentId
      type
      inputParams
      questionType
      questionPath
      answerType
      answerText
      owner
      readers
      editors
      createdAt
      secret
    }
  }
`;
export const createProcedure = /* GraphQL */ `
  mutation CreateProcedure($input: CreateProcedureInput!) {
    createProcedure(input: $input) {
      id
      knowledgeId
      departmentId
      text
      name
      owner
      language
      public
      bookReference
      schedules {
        name
        expression
        enabled
      }
      email {
        stage
        email
      }
      title
      version
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const updateProcedure = /* GraphQL */ `
  mutation UpdateProcedure($input: UpdateProcedureInput!) {
    updateProcedure(input: $input) {
      id
      knowledgeId
      departmentId
      text
      name
      owner
      language
      public
      bookReference
      schedules {
        name
        expression
        enabled
      }
      email {
        stage
        email
      }
      title
      version
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const updateProcedureMetadata = /* GraphQL */ `
  mutation UpdateProcedureMetadata($input: UpdateProcedureMetadataInput!) {
    updateProcedureMetadata(input: $input) {
      id
      knowledgeId
      departmentId
      text
      name
      owner
      language
      public
      bookReference
      schedules {
        name
        expression
        enabled
      }
      email {
        stage
        email
      }
      title
      version
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const updateProcedureSchedule = /* GraphQL */ `
  mutation UpdateProcedureSchedule($input: UpdateProcedureScheduleInput!) {
    updateProcedureSchedule(input: $input) {
      id
      knowledgeId
      departmentId
      text
      name
      owner
      language
      public
      bookReference
      schedules {
        name
        expression
        enabled
      }
      email {
        stage
        email
      }
      title
      version
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const shareProcedure = /* GraphQL */ `
  mutation ShareProcedure($input: ShareProcedureInput!) {
    shareProcedure(input: $input) {
      status
    }
  }
`;
export const deleteProcedure = /* GraphQL */ `
  mutation DeleteProcedure($input: DeleteProcedureInput!) {
    deleteProcedure(input: $input) {
      id
      knowledgeId
      departmentId
      text
      name
      owner
      language
      public
      bookReference
      schedules {
        name
        expression
        enabled
      }
      email {
        stage
        email
      }
      title
      version
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const markWorkItemReceived = /* GraphQL */ `
  mutation MarkWorkItemReceived($id: ID!) {
    markWorkItemReceived(id: $id)
  }
`;
export const markWorkItemProcessing = /* GraphQL */ `
  mutation MarkWorkItemProcessing($id: ID!) {
    markWorkItemProcessing(id: $id)
  }
`;
export const markWorkItemProcessed = /* GraphQL */ `
  mutation MarkWorkItemProcessed($id: ID!) {
    markWorkItemProcessed(id: $id)
  }
`;
export const markWorkItemFailed = /* GraphQL */ `
  mutation MarkWorkItemFailed($id: ID!, $errors: [String!]) {
    markWorkItemFailed(id: $id, errors: $errors)
  }
`;
export const invokeProcedure = /* GraphQL */ `
  mutation InvokeProcedure(
    $departmentId: ID!
    $procedureId: ID!
    $knowledgeId: ID
    $owner: String
    $name: String
    $description: String
    $debug: Boolean
    $local: Boolean
    $commandParamsInput: CommandParamsInput
    $version: String
  ) {
    invokeProcedure(
      departmentId: $departmentId
      procedureId: $procedureId
      knowledgeId: $knowledgeId
      owner: $owner
      name: $name
      description: $description
      debug: $debug
      local: $local
      commandParamsInput: $commandParamsInput
      version: $version
    ) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      text
      owner
      readers
      editors
      name
      description
      subText
      subTextProperties
      subTextLanguage
      procedureId
      contextId
      sentenceId
      debug
      ast
      state
      output
      source
      sourceMetadata
      positionInContext
      isMultilineText
      questions {
        nextToken
      }
      answers {
        nextToken
      }
      notifications {
        nextToken
      }
    }
  }
`;
export const duplicateWorker = /* GraphQL */ `
  mutation DuplicateWorker($departmentId: ID!, $workerId: ID!, $owner: String) {
    duplicateWorker(
      departmentId: $departmentId
      workerId: $workerId
      owner: $owner
    ) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
      exceptions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      questions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const workerStatus = /* GraphQL */ `
  mutation WorkerStatus($input: WorkerStatusInput!) {
    workerStatus(input: $input) {
      id
      mood
      operation
      icons
      what
    }
  }
`;
export const createKnowledgeSnapshot = /* GraphQL */ `
  mutation CreateKnowledgeSnapshot($workerId: ID!) {
    createKnowledgeSnapshot(workerId: $workerId) {
      id
      owner
      readers
      editors
      name
      description
      departmentId
      workerId
      parentKnowledgeId
      state
      brainVersion
    }
  }
`;
export const createMutableKnowledge = /* GraphQL */ `
  mutation CreateMutableKnowledge($knowledgeId: ID, $departmentId: ID) {
    createMutableKnowledge(
      knowledgeId: $knowledgeId
      departmentId: $departmentId
    ) {
      id
      owner
      readers
      editors
      name
      description
      departmentId
      workerId
      parentKnowledgeId
      state
      brainVersion
    }
  }
`;
export const updateKnowledge = /* GraphQL */ `
  mutation UpdateKnowledge($input: UpdateKnowledgeInput!) {
    updateKnowledge(input: $input) {
      id
      owner
      readers
      editors
      name
      description
      departmentId
      workerId
      parentKnowledgeId
      state
      brainVersion
    }
  }
`;
export const publishToDepartment = /* GraphQL */ `
  mutation PublishToDepartment($departmentId: ID!, $knowledgeId: ID!) {
    publishToDepartment(
      departmentId: $departmentId
      knowledgeId: $knowledgeId
    ) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const upgradeDepartment = /* GraphQL */ `
  mutation UpgradeDepartment($departmentId: ID!, $brainVersion: String!) {
    upgradeDepartment(
      departmentId: $departmentId
      brainVersion: $brainVersion
    ) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const rollbackDepartment = /* GraphQL */ `
  mutation RollbackDepartment($departmentId: ID!) {
    rollbackDepartment(departmentId: $departmentId) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const requestSandboxes = /* GraphQL */ `
  mutation RequestSandboxes($departmentId: ID!, $brainVersion: String!) {
    requestSandboxes(departmentId: $departmentId, brainVersion: $brainVersion) {
      language
      arn
    }
  }
`;
export const invokeLocalWorker = /* GraphQL */ `
  mutation InvokeLocalWorker($input: WorkerInvocationInput!) {
    invokeLocalWorker(input: $input) {
      departmentId
      event
    }
  }
`;
export const notifyWorkerUpdate = /* GraphQL */ `
  mutation NotifyWorkerUpdate($input: NotifyWorkerUpdateInput!) {
    notifyWorkerUpdate(input: $input) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
      exceptions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      questions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const updateSteps = /* GraphQL */ `
  mutation UpdateSteps($input: StepsInput!) {
    updateSteps(input: $input) {
      workerId
      stepList {
        id
        contextId
        workerId
        number
        commandId
        createdAt
        scratchedAt
        text
        answer
        childContextIdList
        concepts
        questionIds
        requestIds
        positionInContext
        epoch
      }
    }
  }
`;
export const createSandboxProxyRequest = /* GraphQL */ `
  mutation CreateSandboxProxyRequest(
    $departmentId: ID!
    $proxyId: ID!
    $workerId: ID!
    $request: String!
  ) {
    createSandboxProxyRequest(
      departmentId: $departmentId
      proxyId: $proxyId
      workerId: $workerId
      request: $request
    ) {
      departmentId
      workerId
      proxyId
      requestId
      request
      response
    }
  }
`;
export const createSandboxProxyResponse = /* GraphQL */ `
  mutation CreateSandboxProxyResponse(
    $departmentId: ID!
    $proxyId: ID!
    $workerId: ID!
    $requestId: ID!
    $response: String!
  ) {
    createSandboxProxyResponse(
      departmentId: $departmentId
      proxyId: $proxyId
      workerId: $workerId
      requestId: $requestId
      response: $response
    ) {
      departmentId
      workerId
      proxyId
      requestId
      request
      response
    }
  }
`;
export const learnBooks = /* GraphQL */ `
  mutation LearnBooks($ids: [ID!]!, $departmentId: ID!) {
    learnBooks(ids: $ids, departmentId: $departmentId) {
      id
      url
      name
      author
      description
      learnedAt
      icon
      commandId
      version
    }
  }
`;
export const forgetBooks = /* GraphQL */ `
  mutation ForgetBooks($ids: [ID!]!, $departmentId: ID!) {
    forgetBooks(ids: $ids, departmentId: $departmentId) {
      id
      url
      name
      author
      description
      learnedAt
      icon
      commandId
      version
    }
  }
`;
export const createRequest = /* GraphQL */ `
  mutation CreateRequest($input: CreateRequestInput!) {
    createRequest(input: $input) {
      id
      stepPath
      contextId
      sentenceId
      createdAt
      handledAt
      state
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      questions {
        id
        text
        contextId
        sentenceId
        answerTypeHint
        failedAnswerId
        failedAnswerReason
        ... on NativeCodeErrorQuestion {
          errorType
          errorMessage
          errorTraceback
        }
        ... on ParseErrorQuestion {
          errorTraceback
        }
        ... on ReviewConceptQuestion {
          lexicalPath
          conceptId
        }
        ... on ValueNotKnownQuestion {
          lexicalPath
          choices
          helpText
        }
      }
      suggestedAnswers {
        id
        requestId
        questionId
      }
      answers {
        id
        requestId
        questionId
        createdAt
        createdBy
      }
      contextPath {
        ctxId
        sentenceId
      }
    }
  }
`;
export const updateRequestState = /* GraphQL */ `
  mutation UpdateRequestState($input: UpdateRequestStateInput!) {
    updateRequestState(input: $input) {
      id
      stepPath
      contextId
      sentenceId
      createdAt
      handledAt
      state
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      questions {
        id
        text
        contextId
        sentenceId
        answerTypeHint
        failedAnswerId
        failedAnswerReason
        ... on NativeCodeErrorQuestion {
          errorType
          errorMessage
          errorTraceback
        }
        ... on ParseErrorQuestion {
          errorTraceback
        }
        ... on ReviewConceptQuestion {
          lexicalPath
          conceptId
        }
        ... on ValueNotKnownQuestion {
          lexicalPath
          choices
          helpText
        }
      }
      suggestedAnswers {
        id
        requestId
        questionId
      }
      answers {
        id
        requestId
        questionId
        createdAt
        createdBy
      }
      contextPath {
        ctxId
        sentenceId
      }
    }
  }
`;
export const createRequestAnswer = /* GraphQL */ `
  mutation CreateRequestAnswer($input: CreateRequestAnswerInput!) {
    createRequestAnswer(input: $input) {
      id
      requestId
      questionId
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      sourceLearning {
        id
        createdAt
        createdBy
        deletedAt
        deletedBy
        procedureId
        procedureName
      }
      createdAt
      createdBy
    }
  }
`;
export const createBulkRequestAnswer = /* GraphQL */ `
  mutation CreateBulkRequestAnswer($inputs: [CreateRequestAnswerInput!]!) {
    createBulkRequestAnswer(inputs: $inputs) {
      id
      requestId
      questionId
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      sourceLearning {
        id
        createdAt
        createdBy
        deletedAt
        deletedBy
        procedureId
        procedureName
      }
      createdAt
      createdBy
    }
  }
`;
export const deleteRequestAnswer = /* GraphQL */ `
  mutation DeleteRequestAnswer($input: DeleteRequestAnswerInput!) {
    deleteRequestAnswer(input: $input) {
      id
      stepPath
      contextId
      sentenceId
      createdAt
      handledAt
      state
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      questions {
        id
        text
        contextId
        sentenceId
        answerTypeHint
        failedAnswerId
        failedAnswerReason
        ... on NativeCodeErrorQuestion {
          errorType
          errorMessage
          errorTraceback
        }
        ... on ParseErrorQuestion {
          errorTraceback
        }
        ... on ReviewConceptQuestion {
          lexicalPath
          conceptId
        }
        ... on ValueNotKnownQuestion {
          lexicalPath
          choices
          helpText
        }
      }
      suggestedAnswers {
        id
        requestId
        questionId
      }
      answers {
        id
        requestId
        questionId
        createdAt
        createdBy
      }
      contextPath {
        ctxId
        sentenceId
      }
    }
  }
`;
export const createLearnedAnswer = /* GraphQL */ `
  mutation CreateLearnedAnswer($input: CreateLearnedAnswerInput!) {
    createLearnedAnswer(input: $input) {
      id
      questionFilter {
        stepPath
        questionType
        lexicalPath
      }
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      createdAt
      createdBy
      deletedAt
      deletedBy
      procedureId
      procedureName
      procedure {
        id
        knowledgeId
        departmentId
        text
        name
        owner
        language
        public
        bookReference
        title
        version
      }
    }
  }
`;
export const updateLearnedAnswer = /* GraphQL */ `
  mutation UpdateLearnedAnswer($input: UpdateLearnedAnswerInput!) {
    updateLearnedAnswer(input: $input) {
      id
      questionFilter {
        stepPath
        questionType
        lexicalPath
      }
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      createdAt
      createdBy
      deletedAt
      deletedBy
      procedureId
      procedureName
      procedure {
        id
        knowledgeId
        departmentId
        text
        name
        owner
        language
        public
        bookReference
        title
        version
      }
    }
  }
`;
export const deleteLearnedAnswer = /* GraphQL */ `
  mutation DeleteLearnedAnswer($input: DeleteLearnedAnswerInput!) {
    deleteLearnedAnswer(input: $input) {
      id
      questionFilter {
        stepPath
        questionType
        lexicalPath
      }
      action {
        _
        ... on ReplaceStepAction {
          newSteps
        }
        ... on RetryStepAction {
          minimumDelay
          timeout
        }
        ... on UseConceptAction {
          conceptId
        }
        ... on UseLiteralValueAction {
          literalValue
          choices
        }
        ... on UseTechniqueAction {
          technique
        }
      }
      createdAt
      createdBy
      deletedAt
      deletedBy
      procedureId
      procedureName
      procedure {
        id
        knowledgeId
        departmentId
        text
        name
        owner
        language
        public
        bookReference
        title
        version
      }
    }
  }
`;
export const createDepartmentFact = /* GraphQL */ `
  mutation CreateDepartmentFact($input: CreateDepartmentFactInput!) {
    createDepartmentFact(input: $input) {
      id
      departmentId
      name
      values
      version
    }
  }
`;
export const updateDepartmentFact = /* GraphQL */ `
  mutation UpdateDepartmentFact($input: UpdateDepartmentFactInput!) {
    updateDepartmentFact(input: $input) {
      id
      departmentId
      name
      values
      version
    }
  }
`;
export const deleteDepartmentFact = /* GraphQL */ `
  mutation DeleteDepartmentFact($input: DeleteDepartmentFactInput!) {
    deleteDepartmentFact(input: $input) {
      id
      departmentId
      name
      values
      version
    }
  }
`;
export const createSecretValue = /* GraphQL */ `
  mutation CreateSecretValue($input: CreateSecretValueInput!) {
    createSecretValue(input: $input)
  }
`;
export const uploadValue = /* GraphQL */ `
  mutation UploadValue($input: UploadValueInput!) {
    uploadValue(input: $input) {
      value
      url
      fields {
        name
        value
      }
    }
  }
`;
export const createSharedEntity = /* GraphQL */ `
  mutation CreateSharedEntity($input: CreateSharedEntityInput!) {
    createSharedEntity(input: $input) {
      id
      entityId
      departmentId
      shareName
      fullShareId
      departmentVersion
    }
  }
`;
export const updateSharedEntity = /* GraphQL */ `
  mutation UpdateSharedEntity($input: UpdateSharedEntityInput!) {
    updateSharedEntity(input: $input) {
      id
      entityId
      departmentId
      shareName
      fullShareId
      departmentVersion
    }
  }
`;
export const deleteSharedEntity = /* GraphQL */ `
  mutation DeleteSharedEntity($id: ID!) {
    deleteSharedEntity(id: $id) {
      id
      entityId
      departmentId
      shareName
      fullShareId
      departmentVersion
    }
  }
`;
export const importDepartment = /* GraphQL */ `
  mutation ImportDepartment($input: DepartmentImportInput!) {
    importDepartment(input: $input) {
      id
      owner
      organizationId
      readers
      editors
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      upgradeCommandId
      notifyProcedureInvocation
      features
      brainVersion
      worker {
        id
        owner
        readers
        editors
        name
        description
        version
        departmentVersion
        knowledgeId
        departmentId
        procedureId
        entryCommand
        entryCommandId
        originalWorkerId
        createdAt
        state
        stateLastUpdatedAt
        status
        statusLastUpdatedAt
        attachments
        isArchived
      }
      organization {
        id
        name
        owner
        createdAt
        updatedAt
      }
      draftKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      publishedKnowledge {
        id
        owner
        readers
        editors
        name
        description
        departmentId
        workerId
        parentKnowledgeId
        state
        brainVersion
      }
      requests {
        limit
        nextToken
      }
      learnedAnswers {
        nextToken
      }
    }
  }
`;
export const createKChatMessage = /* GraphQL */ `
  mutation CreateKChatMessage($input: CreateChatMessageInput!) {
    createKChatMessage(input: $input) {
      items {
        id
        departmentId
        workerId
        message
        timestamp
        displayName
        role
      }
      nextToken
    }
  }
`;
export const notifyKChatMessageUpdate = /* GraphQL */ `
  mutation NotifyKChatMessageUpdate($input: NotifyChatMessageUpdateInput!) {
    notifyKChatMessageUpdate(input: $input) {
      id
      departmentId
      workerId
      message
      timestamp
      displayName
      role
    }
  }
`;
