/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateCommandByWorkerId = /* GraphQL */ `
  subscription OnCreateCommandByWorkerId($workerId: ID!) {
    onCreateCommandByWorkerId(workerId: $workerId) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      text
      owner
      readers
      editors
      name
      description
      subText
      subTextProperties
      subTextLanguage
      procedureId
      contextId
      sentenceId
      debug
      ast
      state
      output
      source
      sourceMetadata
      positionInContext
      isMultilineText
      questions {
        nextToken
      }
      answers {
        nextToken
      }
      notifications {
        nextToken
      }
    }
  }
`;
export const onUpdateCommandByWorkerId = /* GraphQL */ `
  subscription OnUpdateCommandByWorkerId($workerId: ID!) {
    onUpdateCommandByWorkerId(workerId: $workerId) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      text
      owner
      readers
      editors
      name
      description
      subText
      subTextProperties
      subTextLanguage
      procedureId
      contextId
      sentenceId
      debug
      ast
      state
      output
      source
      sourceMetadata
      positionInContext
      isMultilineText
      questions {
        nextToken
      }
      answers {
        nextToken
      }
      notifications {
        nextToken
      }
    }
  }
`;
export const onCreateNotificationByWorkerId = /* GraphQL */ `
  subscription OnCreateNotificationByWorkerId($workerId: ID!) {
    onCreateNotificationByWorkerId(workerId: $workerId) {
      id
      contextId
      commandId
      workerId
      sentenceId
      info
      owner
      readers
      editors
      name
      description
      createdAt
    }
  }
`;
export const onCreateAnswerByWorkerId = /* GraphQL */ `
  subscription OnCreateAnswerByWorkerId($workerId: ID!) {
    onCreateAnswerByWorkerId(workerId: $workerId) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      commandId
      questionId
      type
      owner
      readers
      editors
      name
      description
      text
      error
      remember
      secret
    }
  }
`;
export const onCreateServiceCommandByWorker = /* GraphQL */ `
  subscription OnCreateServiceCommandByWorker($workerId: ID!) {
    onCreateServiceCommandByWorker(workerId: $workerId) {
      id
      workerId
      createdAt
      forwardedAt
      invokedAt
      receivedAt
      processingAt
      processedAt
      failedAt
      workStatus
      workErrors
      method
      owner
      readers
      editors
      params
      result
      error
    }
  }
`;
export const onWorkerStatus = /* GraphQL */ `
  subscription OnWorkerStatus($id: ID!) {
    onWorkerStatus(id: $id) {
      id
      mood
      operation
      icons
      what
    }
  }
`;
export const onInvokeLocalWorker = /* GraphQL */ `
  subscription OnInvokeLocalWorker($departmentId: ID!) {
    onInvokeLocalWorker(departmentId: $departmentId) {
      departmentId
      event
    }
  }
`;
export const onNotifyWorkerUpdate = /* GraphQL */ `
  subscription OnNotifyWorkerUpdate($id: ID!) {
    onNotifyWorkerUpdate(id: $id) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
      exceptions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      questions {
        id
        commandId
        workerId
        contextId
        sentenceId
        owner
        readers
        editors
        name
        description
        type
        text
        path
        procedureId
        validationList
        answerId
        isAnswered
        createdAt
        rawException
        parentId
        secret
        stepPath
        answerTypeHint
        sourceMetadata
      }
      requests {
        limit
        nextToken
      }
    }
  }
`;
export const onStepsUpdated = /* GraphQL */ `
  subscription OnStepsUpdated($workerId: ID!) {
    onStepsUpdated(workerId: $workerId) {
      workerId
      stepList {
        id
        contextId
        workerId
        number
        commandId
        createdAt
        scratchedAt
        text
        answer
        childContextIdList
        concepts
        questionIds
        requestIds
        positionInContext
        epoch
      }
    }
  }
`;
export const onCreateSandboxProxyRequest = /* GraphQL */ `
  subscription OnCreateSandboxProxyRequest($departmentId: ID!, $proxyId: ID!) {
    onCreateSandboxProxyRequest(
      departmentId: $departmentId
      proxyId: $proxyId
    ) {
      departmentId
      workerId
      proxyId
      requestId
      request
      response
    }
  }
`;
export const onCreateSandboxProxyResponse = /* GraphQL */ `
  subscription OnCreateSandboxProxyResponse(
    $departmentId: ID!
    $workerId: ID!
  ) {
    onCreateSandboxProxyResponse(
      departmentId: $departmentId
      workerId: $workerId
    ) {
      departmentId
      workerId
      proxyId
      requestId
      request
      response
    }
  }
`;
export const onNotifyKChatMessageUpdate = /* GraphQL */ `
  subscription OnNotifyKChatMessageUpdate($workerId: ID!, $departmentId: ID!) {
    onNotifyKChatMessageUpdate(
      workerId: $workerId
      departmentId: $departmentId
    ) {
      id
      departmentId
      workerId
      message
      timestamp
      displayName
      role
    }
  }
`;
