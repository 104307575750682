import React, { useEffect, useState } from 'react';
import { Alert } from 'antd';
// @ts-ignore
// import mammoth from 'mammoth/mammoth.browser';
import Loader, { ILoaderType } from './Loader';
import './DocxViewer.less';

interface IProps {
  fileUrl: string;
}

function DocxViewer(props: IProps) {
  const { fileUrl } = props;

  const [docHTML, _setDocHTML] = useState('');
  const [error, setError] = useState<string | null>(null);
  const [loading, _setLoading] = useState(false);

  useEffect(() => {
    (async () => {
      setError(
        "We're working on adding a docx viewer. Please check back later."
      );

      // TODO: https://linear.app/kognitos/issue/KOG-3272/clone-mammothjs-into-internal-github-organization-for-security-issues
      // setLoading(true);
      // const arrayBuffer = await (await fetch(fileUrl)).arrayBuffer();

      // mammoth
      //   .convertToHtml({ arrayBuffer }, { includeDefaultStyleMap: true })
      //   .then((result: any) => {
      //     setDocHTML(result.value);
      //   })
      //   .catch((e: any) => {
      //     console.log('Failed to convert docx to HTML', e);
      //     setError(e.message);
      //   })
      //   .finally(() => {
      //     setLoading(false);
      //   });
    })();
  }, [fileUrl]);

  return (
    <div>
      {loading && (
        <Loader
          type={ILoaderType.SKELETON}
          skeletonConfig={{
            rows: 6
          }}
        />
      )}
      <div className="docx-viewer">
        {error && <Alert message="Error" description={error} type="error" />}

        {docHTML && (
          <div
            // eslint-disable-next-line react/no-danger
            dangerouslySetInnerHTML={{
              __html: docHTML
            }}
          />
        )}
      </div>
    </div>
  );
}

export default DocxViewer;
