//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// KnowledgeTreeTable is a component that presents a Review UI of the knowledge tree.
// Answers corresponding to tree nodes are presented and can be modified.
// The source of each answer is also presented.

// 3rd part libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';

// Local imports
import ComponentFactory from '@utils/ComponentFactory';
import Loader from '@components/Loader';
import AppUtil from '@utils/AppUtil';
import i18n from '@utils/i18n';
import {
  TreeNodeAction,
  TreeNodeActionsMenu
} from '@details/knowledge/KnowledgeTreeNodeActions';

// Component CSS
import './KnowledgeTreeTable.less';
import Question from '@details/knowledge/Question';
import {
  IContextInfo,
  IKnowledgeTree,
  IKnowledgeTreeNode,
  ITreeNodeAction,
  ITreeNodeActionData
} from '@details/knowledge/KnowledgeInterfaces';
import AppConstants from '@utils/AppConstants';

// Component i18n
const i18nt = (s: string, d: string) => i18n.t('KnowledgeTreeTable', s, d);

// Component Interface
interface IKnowledgeTreeTable {
  /**
   * Tree Data in backend format { id, name, childen }
   */
  treeData: IKnowledgeTree | undefined;
  /**
   * Contexts for this knowledge tree
   */
  contexts: IContextInfo[];
  /**
   * Show context summary column or not
   */
  showContextSummary: boolean;
  /**
   * Error Msgs <treeNodId -> [msgs]
   */
  errorMsgs: Record<string, string[]>;
  /**
   * Selected node ids
   */
  selectedTreeNodeIds: string[];
  /**
   * Current action on a tree node if any
   */
  treeNodeAction: ITreeNodeAction | undefined;
  /**
   * Select node callback
   */
  onSelect: (treeNodeIds: string[]) => void;
  /**
   * On Tree Node mouse over
   */
  onTreeNodeMouseOver: (treeNodeId: string) => void;
  /**
   * On Tree Node mouse out
   */
  onTreeNodeMouseOut: (treeNodeId: string) => void;
  /**
   * On Change value for a tree node
   */
  onTreeNodeValueChange: (treeNodeId: string, value: any) => void;
  /**
   * Tree Node Action selection callback
   */
  onTreeNodeAction: (nodeId: string, actionId: string) => void;

  /**
   * Tree Node Action Performed callback
   */
  onTreeActionConfirm: (actionData: ITreeNodeActionData) => void;

  /**
   * Tree Node Action cancelled
   */
  onTreeActionCancel: () => void;

  /**
   * Tree Node Expansion callback
   */
  onTreeNodeExpand: (bExpanded: boolean, record: IKnowledgeTreeNode) => void;
}

// Component implementation
function KnowledgeTreeTable(props: IKnowledgeTreeTable) {
  const {
    treeData,
    contexts,
    showContextSummary,
    selectedTreeNodeIds,
    treeNodeAction,
    onSelect,
    onTreeNodeMouseOver,
    onTreeNodeMouseOut,
    errorMsgs,
    onTreeNodeValueChange,
    onTreeNodeAction,
    onTreeActionConfirm,
    onTreeActionCancel,
    onTreeNodeExpand
  } = props;
  const [treeTableData, setTreeTableData] = useState<IKnowledgeTreeNode[]>();

  useEffect(() => {
    const tempTree: IKnowledgeTree = JSON.parse(JSON.stringify(treeData));
    // Walk the tree and remove empty children arrays
    AppUtil.TreeWalk(tempTree, (node: IKnowledgeTreeNode) => {
      if (
        node.id !== AppConstants.TREE_ROOT_NODE_ID &&
        node.type !== 'folder'
      ) {
        // @ts-ignore
        // eslint-disable-next-line no-param-reassign
        delete node.children;
      }
    });
    const rootNode: IKnowledgeTreeNode = {
      id: AppConstants.TREE_ROOT_NODE_ID,
      name: tempTree.name,
      parentId: '',
      type: 'folder',
      validationList: [],
      data: [],
      children: tempTree.children
    };

    setTreeTableData([rootNode]);
  }, [treeData]);

  if (!treeTableData) {
    return <Loader />;
  }

  const TABLE_COLS = [
    {
      title: i18nt('fact', 'Fact'),
      dataIndex: 'name',
      render: (text: string, record: IKnowledgeTreeNode) => {
        // Inline UI
        if (treeNodeAction?.nodeId === record.id) {
          return {
            children: (
              <TreeNodeAction
                actionId={treeNodeAction?.actionId || ''}
                nodeTitle={record.name || ''}
                nodeType={record.type}
                onConfirm={onTreeActionConfirm}
                onCancel={onTreeActionCancel}
              />
            ),
            props: {
              colSpan: 3
            }
          };
        }

        return (
          <div className="fact-container" title={text}>
            {text}
          </div>
        );
      }
    },
    {
      title: i18nt('value', 'Value'),
      dataIndex: 'value',
      render: (_text: string, record: IKnowledgeTreeNode) => {
        if (treeNodeAction?.nodeId === record.id) {
          return null;
        }
        // No inputs for group nodes
        if (record.type === 'folder') {
          return null;
        }
        const eMsgs = errorMsgs[record.id] || [];
        return (
          <div className="question-container">
            <Question
              treeNode={record}
              errorMsgs={eMsgs}
              onAnswer={(answer) => {
                onTreeNodeValueChange(record.id, answer);
              }}
              onSave={() => onTreeNodeAction('ANSWER', record.id)}
            />
          </div>
        );
      }
    },
    {
      title: i18nt('context', 'Context'),
      dataIndex: 'context',
      render: (_text: string, record: IKnowledgeTreeNode) => {
        if (treeNodeAction?.nodeId === record.id) {
          return null;
        }
        // No ctx summary for group nodes
        if (record.type === 'folder') {
          return null;
        }
        // Not enough room
        if (!showContextSummary) {
          return null;
        }

        // if we have a bounding box, show it in the summary
        const datum = record.data[0];
        if (datum.contextId && datum.boundingBox?.x) {
          const contextInfo = AppUtil.contextInfo(contexts, datum.contextId);
          if (contextInfo) {
            const sizes = {
              width: 200,
              height: 32
            };
            return (
              <div
                className="context-summary"
                style={{
                  width: `${sizes.width}px`,
                  height: `${sizes.height}px`
                }}
              >
                {ComponentFactory.getContextSummaryViewer(contextInfo, {
                  boundingBox: datum.boundingBox,
                  destination: sizes
                })}
              </div>
            );
          }
        }

        return null;
      }
    },
    {
      title: i18nt('actions', 'Actions'),
      dataIndex: 'id',
      render: (_text: string, record: IKnowledgeTreeNode) => {
        if (treeNodeAction?.nodeId === record.id) {
          return null;
        }
        // No actions for group nodes
        if (record.type === 'folder') {
          return null;
        }

        return (
          <div className="actions-container">
            {record.confidence && (
              <div className={`confidence_${record.confidence}`} />
            )}
            <TreeNodeActionsMenu
              treeNode={record}
              onTreeNodeAction={onTreeNodeAction}
            />
          </div>
        );
      }
    }
  ];

  return (
    <div className="knowledge-tree-table">
      <Table
        rowKey="id"
        onExpand={onTreeNodeExpand}
        defaultExpandedRowKeys={['root']}
        bordered={false}
        pagination={false}
        columns={TABLE_COLS}
        dataSource={treeTableData}
        rowClassName={(record) =>
          selectedTreeNodeIds.indexOf(record.id) >= 0 ? 'row-selected' : ''
        }
        onRow={(record) => ({
          onClick: () => {
            onSelect([record.id]);
          },
          onMouseOver: () => {
            onTreeNodeMouseOver(record.id);
          },
          onMouseOut: () => {
            onTreeNodeMouseOut(record.id);
          }
        })}
      />
    </div>
  );
}

// Component Prop Types

KnowledgeTreeTable.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  treeData: PropTypes.object,
  contexts: PropTypes.array,
  showContextSummary: PropTypes.bool,
  // eslint-disable-next-line react/forbid-prop-types
  errorMsgs: PropTypes.object,
  selectedTreeNodeIds: PropTypes.arrayOf(PropTypes.string),
  onSelect: PropTypes.func.isRequired,
  onTreeNodeValueChange: PropTypes.func.isRequired,
  onTreeNodeAction: PropTypes.func.isRequired,
  onTreeNodeExpand: PropTypes.func.isRequired
};

export default KnowledgeTreeTable;
