import { Fact } from '@/generated/API';
import useFactV2 from '@/hooks/useFactV2';
import { useRunCtxV2 } from '@/provider/runv2';
import AppUtil from '@/utils/AppUtil';
import FormattingUtil from '@/utils/FormattingUtil';
import React from 'react';
import './AnswerConceptsTable.less';
import usePaginationState from '@/hooks/usePaginationState';
import Table from '../table/Table';
import AnswerObjectViewer from '../AnswerObjectViewer';

interface IProps {
  answer?: string;
  tableRowCpt?: any;
}

const getFactValue = (fact: Fact) =>
  AppUtil.safeParseJSON(
    fact.value!.replaceAll('NaN', '""').replaceAll('null', '""'),
    true
  );

function AnswerConceptsTable(props: IProps) {
  const { answer, tableRowCpt } = props;
  const { worker } = useRunCtxV2();
  let cptIds: string[] = [];
  if (answer) {
    const parsedAnswer = FormattingUtil.parseBrainValue(answer!);
    parsedAnswer.concepts.items.forEach((cpt: any) => {
      const ans = cpt ? FormattingUtil.decodeBrainValue(cpt) : {};
      cptIds.push(ans?.concept?.id);
    });
  } else if (tableRowCpt) {
    cptIds = tableRowCpt.ids;
  }

  const paginationState = usePaginationState({
    totalCount: cptIds.length,
    pageSize: 10
  });

  const {
    getFactById,
    ids: currentCpts,
    loading
  } = useFactV2({
    ids: cptIds,
    knowledgeId: worker?.knowledgeId!,
    paginationState: {
      pgNumber: paginationState.pageNumber,
      pgSize: paginationState.pageSize
    }
  });

  const firstFact = getFactById(cptIds[0]);
  const firstVal = firstFact && getFactValue(firstFact);
  const cols = firstVal && Object.keys(firstVal);

  const getData = (ids: string[]) =>
    ids.map((id) => {
      const fact = getFactById(id);
      if (fact) {
        const data = FormattingUtil.parseBrainValue(fact.value || '');
        return data;
      }
      return undefined;
    });

  const getColumns = () => {
    return cols.map((key: any) => ({
      id: `${key}-object-column`,
      dataKey: key,
      title: key,
      renderColumn: (value: any) => {
        if (!value) {
          return '-';
        }
        if (typeof value === 'object') {
          return <AnswerObjectViewer object={value} />;
        }
        return value;
      }
    }));
  };

  return (
    <div className="answer-concepts-table">
      <Table
        loading={loading}
        uniqueRowId="id"
        data={getData(currentCpts)}
        columns={getColumns()}
        pagination={{
          hideTotalString: true,
          total: cptIds.length,
          pageSize: paginationState.pageSize,
          onChange: (page) => {
            paginationState.updatePageNumber(page);
          },
          pageNumber: paginationState.pageNumber
        }}
      />
    </div>
  );
}

export default AnswerConceptsTable;
