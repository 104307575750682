import { Typography } from 'antd';
import React from 'react';
import { showPopup } from '@/stores/slices/appPopup';
import { useAppDispatch } from '../stores/hooks';
import AppUtil from '../utils/AppUtil';
import './AnswerS3FileViewer.less';
import AppConstants from '../utils/AppConstants';

interface IProps {
  answer: string;
}

function AnswerS3FileViewer(props: IProps) {
  const { answer } = props;

  const s3Parts = AppUtil.getS3URIParts(answer)!;

  const dispatch = useAppDispatch();

  const handleViewDetails = () => {
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.VIEW_S3_FILE,
        popupParams: {
          title: s3Parts.filename,
          s3Object: {
            bucket: s3Parts.bucket,
            key: s3Parts.key
          }
        }
      })
    );
  };

  // TODO: Add icons based on file type
  return (
    <div className="answer-s3-file-viewer" data-cy="answer-s3-file-viewer">
      <Typography.Text ellipsis className="text" onClick={handleViewDetails}>
        {s3Parts.filename}
      </Typography.Text>
    </div>
  );
}

export default AnswerS3FileViewer;
