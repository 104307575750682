/* eslint-disable no-underscore-dangle */
/* eslint-disable react/prop-types */
/* eslint-disable no-shadow */
import {
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  DownloadOutlined,
  DownOutlined,
  EditTwoTone,
  LeftOutlined,
  RightOutlined
} from '@ant-design/icons';
import {
  Button,
  ConfigProvider,
  DatePicker,
  Dropdown,
  Empty,
  Input,
  Menu,
  PaginationProps,
  Table as AntTable,
  TableProps,
  Tooltip,
  Badge
} from 'antd';
import classNames from 'classnames';
import React, { useEffect, useImperativeHandle, useState } from 'react';
import i18n from '@utils/i18n';
import dayjs, { Dayjs } from 'dayjs';
import {
  ITableColumn,
  ITableProps,
  ITableRef,
  TableSizeType
} from './interface';
import './Table.less';
import AppConstants from '../../utils/AppConstants';

const i18nt = (s: string, d?: string) => i18n.t('Table', s, d);

const Table = React.forwardRef<ITableRef, ITableProps>((props, ref) => {
  const {
    uniqueRowId,
    columns,
    data,
    loading,
    bordered = true,
    emptyMessage = 'Nothing to show.',
    size = TableSizeType.MEDIUM,
    pagination,
    selection,
    expand,
    actions,
    export: _export,
    onEditSave,
    filter,
    className,
    isRowHighlighted,
    getRowClassName,
    renderExtraRowActions,
    renderExtraActions
  } = props;

  const defaultFilters = filter?.defaultFilters || {};

  const [selectedRows, setSelectedRows] = useState<any[]>([]);
  const [expandedRows, setExpandedRows] = useState<any[]>([]);
  const [filteredInfo, setFilteredInfo] =
    useState<Record<string, any | null>>(defaultFilters);

  const [selectedDate, setSelectedDate] = useState<Dayjs | null>(null);
  const [searchValue, setSearchValue] = useState('');

  const [currentEditableRowId, setCurrentEditableRowId] = useState<
    string | null
  >(null);
  const [rowEditValues, setRowEditValues] = useState<Record<string, any>>({});

  const emptyFilters = Object.values(filteredInfo).every(
    (info) => info === null
  );
  const showExport = !!_export && data.length !== 0;

  const resetState = (filters = true) => {
    setSelectedRows([]);
    setExpandedRows([]);
    setFilteredInfo(defaultFilters);
    setCurrentEditableRowId(null);
    setRowEditValues({});
    if (filters) {
      setSearchValue('');
      setSelectedDate(null);
    }
  };

  useEffect(() => {
    if (expand?.getInitialExpandedRows) {
      setExpandedRows(expand.getInitialExpandedRows(data));
    }

    return () => {
      resetState();
    };
  }, []);

  useImperativeHandle(ref, () => ({
    reset: (...args) => resetState(...args)
  }));

  useEffect(() => {
    if (filter?.onExternalFilterChange) {
      filter.onExternalFilterChange(searchValue, selectedDate);
    }
  }, [selectedDate, searchValue]);

  const editSaveDisabled = Object.keys(rowEditValues || {}).length === 0;

  const handleTableChange = (_pagination: any, filters: any) => {
    setFilteredInfo(filters);
  };

  const handleEditableReset = () => {
    setCurrentEditableRowId(null);
    setRowEditValues({});
  };

  const handleEditSave = () => {
    if (editSaveDisabled) {
      handleEditableReset();
    } else {
      onEditSave?.(currentEditableRowId, rowEditValues, handleEditableReset);
    }
  };

  const onRenderColumn = (
    col: ITableColumn,
    value: any,
    record: any,
    index: number
  ) => {
    const isExpanded = expandedRows.some(
      (row) => row[uniqueRowId] === record[uniqueRowId]
    );

    return col.renderColumn(
      value,
      record,
      index,
      {
        onExpand: (record, allowMultiple = true) => {
          if (isExpanded) {
            if (allowMultiple) {
              setExpandedRows(
                expandedRows.filter(
                  (row) => row[uniqueRowId] !== record[uniqueRowId]
                )
              );
            } else {
              setExpandedRows([]);
            }
          } else {
            // eslint-disable-next-line no-lonely-if
            if (allowMultiple) {
              setExpandedRows([...expandedRows, record]);
            } else {
              setExpandedRows([record]);
            }
          }
        }
      },
      {
        isExpanded
      }
    );
  };

  const SIZE_MAP: Record<TableSizeType, TableProps<any>['size']> = {
    [TableSizeType.SMALL]: 'small',
    [TableSizeType.MEDIUM]: 'middle',
    [TableSizeType.LARGE]: 'large'
  };

  const renderPaginationItem: PaginationProps['itemRender'] = (
    _page,
    type,
    element
  ) => {
    if (type === 'prev') {
      return (
        <LeftOutlined
          onClick={() => pagination?.onChange?.(pagination.pageNumber! - 1)}
        />
      );
    }
    if (type === 'next') {
      return (
        <RightOutlined
          onClick={() => pagination?.onChange?.(pagination.pageNumber! + 1)}
        />
      );
    }
    return element;
  };

  const paginationProps: TableProps<any>['pagination'] = pagination
    ? {
        position: ['topRight'],
        total: emptyFilters ? pagination.total || data.length : undefined,
        current: pagination.pageNumber,
        pageSize: pagination.pageSize,
        defaultCurrent: pagination.defaultPageNumber || 1,
        defaultPageSize:
          pagination.defaultPageSize || AppConstants.TABLE.DEFAULT_PAGE_SIZE,
        disabled: pagination.disabled,
        showQuickJumper: false,
        showSizeChanger: false,
        showTotal: (_total, range) => {
          if (pagination.hideTotalString) {
            return null;
          }
          if (!emptyFilters) {
            return `${range[0]}-${range[1]} of ${pagination.total} items`;
          }
          return `Showing ${range[0]}-${range[1]} items`;
        },
        onChange: pagination.onChange,
        itemRender: renderPaginationItem
      }
    : false;

  const selectionProps: TableProps<any>['rowSelection'] = selection
    ? {
        selectedRowKeys: selectedRows.map((item: any) => item[uniqueRowId]),
        onChange: (_selectedRowKeys, newSelectedRows) => {
          setSelectedRows(newSelectedRows);
          selection.onChange?.(newSelectedRows);
        },
        selections: data.length > 0 && [
          AntTable.SELECTION_ALL,
          AntTable.SELECTION_NONE
        ],
        getCheckboxProps: (record) => ({
          disabled: selection.isDisabled?.(record)
        })
      }
    : undefined;

  const expandProps: TableProps<any>['expandable'] = expand
    ? {
        expandedRowRender: (record) => expand.renderExpandedRow(record),
        showExpandColumn: false,
        expandedRowKeys: expandedRows.map((row) => (row as any)[uniqueRowId])
      }
    : undefined;

  const renderActionMenu = () => (
    <Menu disabled={selectedRows.length === 0}>
      {actions &&
        actions.map((action) => (
          <Menu.Item
            key={action.id}
            onClick={() => action.onClick?.(selectedRows)}
            disabled={action.isDisabled?.(selectedRows)}
            data-cy={action.id}
          >
            {action.label}
          </Menu.Item>
        ))}
    </Menu>
  );

  const renderActions = () => (
    <>
      {filter?.allowSearchFilter && (
        <Input.Search
          placeholder={filter?.searchPlaceholder || 'Search...'}
          onSearch={(v) => {
            setSearchValue(v);
          }}
          allowClear
          className="table-search table-action-left"
          data-cy="table-search"
        />
      )}
      {filter?.allowDateFilter && (
        <Badge dot={!!selectedDate} className="table-action-left">
          <DatePicker
            value={selectedDate}
            onChange={(date) => {
              setFilteredInfo({});
              setSelectedDate(date || null);
            }}
            disabledDate={(current) => dayjs(current).isAfter(dayjs())}
            className="table-date-picker"
          />
        </Badge>
      )}
      {actions && (
        <Dropdown
          overlay={renderActionMenu()}
          trigger={['hover']}
          className="table-button-actions table-action-left"
          disabled={selectedRows.length === 0}
        >
          <Button>
            Actions
            <DownOutlined />
          </Button>
        </Dropdown>
      )}
    </>
  );

  const hasEditColumn = columns.find((col) => !!col.edit);

  const mappedColumns: TableProps<any>['columns'] = columns.map((col) => ({
    title: col.title,
    dataIndex: col.dataKey,
    render: (value: any, record: any, index: number) => {
      if (col.edit && currentEditableRowId === record?.[uniqueRowId]) {
        const onCellChange = (newValue: any) => {
          rowEditValues[col.id] = newValue;
          setRowEditValues({ ...rowEditValues });
        };

        return col.edit.editRender(
          value,
          record,
          rowEditValues[col.id],
          onCellChange
        );
      }

      return onRenderColumn(col, value, record, index);
    },
    width: col.width,
    align: col.align,
    filters: col.fillter?.options || undefined,
    filteredValue: filteredInfo[(col as any)[uniqueRowId]] || null,
    onFilter: col.fillter?.onFilter,
    filterSearch: col.fillter?.allowSearch,
    sorter: col.sort?.onSort as any,
    sortDirections: col.sort?.sortDirections as any
  }));

  if (hasEditColumn) {
    mappedColumns.push({
      title: '',
      dataIndex: 'actions',
      width: '80px',
      align: 'center',
      render: (_txt: string, record: any, index: number) => {
        if (record) {
          if (record._deleted__) {
            return null;
          }

          if (hasEditColumn && currentEditableRowId === record[uniqueRowId]) {
            return (
              <div className="edit-actions">
                <span
                  onClick={handleEditableReset}
                  title="Cancel"
                  data-cy="table-row-edit-cancel"
                >
                  <Tooltip title={i18nt('cancel')}>
                    <CloseCircleTwoTone />
                  </Tooltip>
                </span>
                <span
                  onClick={handleEditSave}
                  title="Ok"
                  data-cy="table-row-edit-save"
                >
                  <Tooltip title={i18nt('save')}>
                    <CheckCircleTwoTone disabled={editSaveDisabled} />
                  </Tooltip>
                </span>
              </div>
            );
          }
          return (
            <div className="edit-actions">
              {hasEditColumn && (
                <span
                  onClick={() => setCurrentEditableRowId(record[uniqueRowId])}
                  data-cy="table-row-edit"
                >
                  <Tooltip title={i18nt('edit')}>
                    <EditTwoTone />
                  </Tooltip>
                </span>
              )}

              {renderExtraRowActions?.(record[uniqueRowId], record, index)}
            </div>
          );
        }
        return (
          <div className="edit-actions">
            {hasEditColumn && (
              <span
                onClick={() => setCurrentEditableRowId(record[uniqueRowId])}
                data-cy="table-row-edit"
              >
                <Tooltip title={i18nt('edit')}>
                  <EditTwoTone />
                </Tooltip>
              </span>
            )}

            {/* {renderExtraRowActions?.(record[uniqueRowId], record)} */}
          </div>
        );
      }
    });
  }

  return (
    <div
      className={classNames('app-table', className, {
        'with-export': showExport,
        loading
      })}
    >
      <div className="actions-cover">
        {renderExtraActions && (
          <div className="table-actions-extra">{renderExtraActions()}</div>
        )}
        {renderActions()}
        {showExport && (
          <Tooltip title="Export" placement="top">
            <Button type="text" className="export-button">
              <DownloadOutlined />
            </Button>
          </Tooltip>
        )}
        {pagination ? <span /> : null}
      </div>

      <ConfigProvider
        renderEmpty={() => (
          <Empty
            description={emptyMessage}
            image={Empty.PRESENTED_IMAGE_SIMPLE}
          />
        )}
      >
        <AntTable
          rowKey={uniqueRowId}
          bordered={bordered}
          loading={loading}
          size={SIZE_MAP[size]}
          rowSelection={selectionProps}
          pagination={paginationProps}
          expandable={expandProps}
          dataSource={data}
          columns={mappedColumns}
          onChange={handleTableChange}
          rowClassName={(row) =>
            classNames(
              {
                'ant-table-row-selected':
                  row?.[uniqueRowId] === currentEditableRowId,
                'table-row-highlighted': isRowHighlighted?.(row)
              },
              getRowClassName?.(row)
            )
          }
        />
      </ConfigProvider>
    </div>
  );
});

Table.displayName = 'Table';

export default Table;
