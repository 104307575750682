//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Timestamp is a component that renders a time stamp
//

// 3rd party libraries
import React from 'react';
import PropTypes from 'prop-types';
import moment from 'moment';
import { Tooltip } from 'antd';

// Component Prop Types
Timestamp.propTypes = {
  /**
   * The timestamp in millis
   * (String, cause graphQL can't handle long ints)
   */
  millis: PropTypes.oneOfType([PropTypes.number, PropTypes.string]).isRequired,
  /**
   * Tip placement
   */
  placement: PropTypes.string.isRequired
};

// Component implementation
function Timestamp(props) {
  // Render the UI  - Relative time shown. Tooltip w/ detailed
  const mnt = moment(Number(props.millis));
  return (
    <span>
      <Tooltip placement={props.placement} title={mnt.toLocaleString()}>
        {mnt.fromNow()}
      </Tooltip>
    </span>
  );
}

export default Timestamp;
