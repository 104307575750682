//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// CurrentPopup is the HOC for rendering popups. It renders the current popup
// using its ID and params which were registered into the app store by the caller
//

// 3rd party libraries
import React from 'react';
import { useSelector } from 'react-redux';
import NewFlow from '@popups/NewFlow';
import NewRun from '@popups/NewRun';
import ContextViewerPopup from '@popups/ContextViewerPopup';
import { hidePopup, popupSelector } from '@/stores/slices/appPopup';
import AppConstants from '@utils/AppConstants';
import { useCustomCompareMemo } from 'use-custom-compare';
import { isEqual } from 'lodash/fp';
import CreateDepartmentPopup from './CreateDepartmentPopup';
import CreateOrganizationPopup from './CreateOrganizationPopup';
import DepartmentCollaboratorsPopup from './DepartmentCollaboratorsPopup';
import ProcedurePromptPopup from './ProcedurePromptPopup';
import ExceptionsBatchAnswerPopup from './ExceptionsBatchAnswerPopup';
import DepartmentWorkerPlaygroundPopup from './DepartmentWorkerPlaygroundPopup';
import ViewQuestionPopup from './ViewQuestionPopup';
import RawFactViewerPopup from './RawFactViewerPopup';
import ObjectViewerPopup from './ObjectViewerPopup';
import S3FileViewerPopup from './S3FileViewerPopup';
import FactRelationsViewerPopup from './FactRelationsViewerPopup';
import { useAppDispatch } from '../stores/hooks';
import ContextDebuggerPopup from './ContextDebuggerPopup';
import MarkdownViewerPopup from './MarkdownViewerPopup';
import MarkdownEditorPopup from './MarkdownEditorPopup';
import ViewRequestPopup from './ViewRequestPopup';
import CreateFactPopup from './CreateFactPopup';
import HTMLViewerPopup from './HTMLViewerPopup';
import ScratchStepPopup from './ScratchStepPopup';
import DepartmentCollaboratorsAddPopup from './DepartmentCollaboratorsAddPopup';
import AnswerCSVPopup from './AnswerCSVPopup';

function CurrentPopup() {
  const dispatch = useAppDispatch();
  const { currentActivePopups } = useSelector(popupSelector);

  const popupComponentMap = {
    [AppConstants.POPUPS.ANSWER_CSV]: AnswerCSVPopup,
    [AppConstants.POPUPS.NEW_FLOW]: NewFlow,
    [AppConstants.POPUPS.NEW_RUN]: NewRun,
    // [AppConstants.POPUPS.EXPORT_GRID]: Export,
    [AppConstants.POPUPS.CONTEXT_VIEWER]: ContextViewerPopup,
    [AppConstants.POPUPS.CREATE_ORGANIZATION]: CreateOrganizationPopup,
    [AppConstants.POPUPS.CREATE_DEPARTMENT]: CreateDepartmentPopup,
    [AppConstants.POPUPS.DEPARTMENT_COLLABORATORS]:
      DepartmentCollaboratorsPopup,
    [AppConstants.POPUPS.DEPARTMENT_COLLABORATORS_ADD]:
      DepartmentCollaboratorsAddPopup,
    [AppConstants.POPUPS.PROCEDURE_PROMPT]: ProcedurePromptPopup,
    [AppConstants.POPUPS.EXCEPTIONS_BATCH_ANSWER]: ExceptionsBatchAnswerPopup,
    // TODO: remove
    [AppConstants.POPUPS.DEPARTMENT_WORKER_PLAYGROUND]:
      DepartmentWorkerPlaygroundPopup,
    [AppConstants.POPUPS.VIEW_QUESTION]: ViewQuestionPopup,
    [AppConstants.POPUPS.VIEW_REQUEST]: ViewRequestPopup,
    [AppConstants.POPUPS.VIEW_OBJECT]: ObjectViewerPopup,
    [AppConstants.POPUPS.VIEW_RAW_FACT]: RawFactViewerPopup,
    [AppConstants.POPUPS.VIEW_S3_FILE]: S3FileViewerPopup,
    [AppConstants.POPUPS.VIEW_FACT_RELATIONS]: FactRelationsViewerPopup,
    [AppConstants.POPUPS.QUESTION_CONTEXT_DEBUGGER]: ContextDebuggerPopup,
    [AppConstants.POPUPS.MARKDOWN_VIEWER]: MarkdownViewerPopup,
    [AppConstants.POPUPS.MARKDOWN_EDITOR]: MarkdownEditorPopup,
    [AppConstants.POPUPS.CREATE_FACT]: CreateFactPopup,
    [AppConstants.POPUPS.HTML_VIEWER]: HTMLViewerPopup,
    [AppConstants.POPUPS.SCRATCH_STEP]: ScratchStepPopup
  };

  const popupMemo = useCustomCompareMemo(
    () => (
      <div>
        {Object.keys(currentActivePopups).map((popupId) => {
          const Komponent = popupComponentMap[popupId] as any;
          const params = currentActivePopups[popupId];
          return (
            <Komponent
              key={popupId}
              {...params}
              onClose={() => {
                dispatch(
                  hidePopup({
                    popupId
                  })
                );
              }}
            />
          );
        })}
      </div>
    ),
    [Object.keys(currentActivePopups)],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  return popupMemo;
}

export default CurrentPopup;
