//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// KnowledgeDetails is a component that shows a tree representation of a subset of a
// knowledge graph. It allows the user to answer question within context
//

// 3rd part libraries
import _isEmpty from 'lodash/fp/isEmpty';
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useLazyQuery } from '@apollo/client';
import Split from 'react-split';

// Local imports
import ComponentFactory from '@utils/ComponentFactory';
import AppConstants from '@utils/AppConstants';
import AppUtil from '@utils/AppUtil';
import Validations from '@utils/Validations';
import Loader from '@components/Loader';

import {
  IKnowledgeEntity,
  IKnowledgeTreeNode,
  IKnowledgeTree,
  IContextInfo,
  IBoundingBox,
  ITreeNodeAction,
  ITreeNodeActionData
} from '@details/knowledge/KnowledgeInterfaces';
import KnowledgeTreeTable from '@details/knowledge/KnowledgeTreeTable';

// Component CSS
import './KnowledgeDetails.less';

// TScript
interface IKnowledgeDetails {
  /**
   * The knowledge entity to show
   */
  entity: IKnowledgeEntity;
}

// Initial left/right sizes (%)
const INITIAL_SIZES = [30, 70];

// Component implementation
function KnowledgeDetails(props: IKnowledgeDetails) {
  const { entity } = props;

  const [loading, setLoading] = useState(false);

  // Tree Node queries
  const GetKnowledgeTreeNodeChildren = useLazyQuery(
    AppConstants.APIS.KNOWLEDGE_TREE.GET_TREE_NODE_CHILDREN(),
    {
      onCompleted: (results) => {
        const r = AppUtil.safeParseJSON(results.getKnowledgeTreeNodeChildren);
        // eslint-disable-next-line no-use-before-define
        onTreeNodeChildrenCB(r.treeNodeId, r.children);
      }
    }
  )[0];

  // Tree Node mutations
  const AnswerKnowledgeTreeNode = useMutation(
    AppConstants.APIS.KNOWLEDGE_TREE.ANSWER_NODE()
  )[0];
  const InsertKnowledgeTreeNode = useMutation(
    AppConstants.APIS.KNOWLEDGE_TREE.INSERT_NODE()
  )[0];
  const UpdateKnowledgeTreeNode = useMutation(
    AppConstants.APIS.KNOWLEDGE_TREE.UPDATE_NODE()
  )[0];
  const DeleteKnowledgeTreeNode = useMutation(
    AppConstants.APIS.KNOWLEDGE_TREE.DELETE_NODE()
  )[0];

  // We can have multiple contexts (pages)
  const [contexts, setContexts] = useState<IContextInfo[]>([]);
  const [currentContextId, setCurrentContextId] = useState('');

  // Error Messages for treenodes - {treeNodeId -> errorMsg}
  const [errorMsgs, setErrorMsgs] = useState<Record<string, string[]>>({});

  // Tree data - the raw tree JSON from the backend
  const [treeData, setTreeData] = useState<IKnowledgeTree>();
  const [selectedTreeNode, setSelectedTreeNode] =
    useState<IKnowledgeTreeNode>();
  const [hoverTreeNodeId, setHoverTreeNodeId] = useState<string>();

  // The current Tree node action
  const [treeNodeAction, setTreeNodeAction] = useState<ITreeNodeAction>();

  // The node to auto edit after an insert
  const [autoEditNodeId, setAutoEditNodeId] = useState<string>();

  // The tree table shows a context (bbox) if its wide enough
  const [showContextSummary, setShowContextSummary] = useState(false);

  // BoundingBoxes within the image
  // { treeNodeId => [boundingBoxes] }
  const [boundingBoxes, setBoundingBoxes] = useState<
    Record<string, IBoundingBox[]>
  >({});

  // Util to update context based on a tree node
  const updateContext = (treeNode: IKnowledgeTreeNode) => {
    const nodeData = treeNode.data;
    if (nodeData.length && nodeData[0].contextId) {
      setCurrentContextId(nodeData[0].contextId);
    }
  };

  // Util to validate current answer. returns true if valid
  const validateTreeNodeAnswer = (treeNode: IKnowledgeTreeNode) =>
    Validations.validate(treeNode.data[0].value, treeNode.validationList);

  // Tree content changes, reset the tree.
  const resetTreeData = () => {
    const nTree: IKnowledgeTree = AppUtil.simpleClone(treeData);
    setTreeData(nTree);
  };

  const resetTreeUI = () => {
    setSelectedTreeNode(undefined);
    setHoverTreeNodeId(undefined);
    setTreeNodeAction(undefined);
    resetTreeData();
  };

  // Send the request to delete a tree node
  const deleteTreeNode = (nodeId: string) => {
    const treeNode = AppUtil.findNodeById(treeData, nodeId);
    if (treeNode) {
      const args = {
        variables: {
          knowledgeTreeId: entity.id,
          // @ts-ignore
          parentNodeId: treeNode.parentId,
          treeNodeId: nodeId
        }
      };
      setLoading(true);
      DeleteKnowledgeTreeNode(args)
        .then((results) => {
          if (results.data.deleteKnowledgeTreeNode) {
            AppUtil.deleteTreeNode(treeData, treeNode);
            resetTreeUI();
          }
        })
        .finally(() => setLoading(false));
    }
  };

  // Send the request to update a tree node
  const updateTreeNode = (
    nodeId: string,
    treeNodeName: string,
    treeNodeType: string
  ) => {
    const treeNode = AppUtil.findNodeById(treeData, nodeId);
    if (treeNode) {
      const args = {
        variables: {
          knowledgeTreeId: entity.id,
          treeNodeId: nodeId,
          treeNodeName,
          treeNodeType
        }
      };
      setLoading(true);
      UpdateKnowledgeTreeNode(args)
        .then((results) => {
          if (results.data.updateKnowledgeTreeNode) {
            // Successful - so update local one
            AppUtil.updateTreeNode(treeNode, treeNodeName, treeNodeType);
            resetTreeUI();
          }
        })
        .finally(() => setLoading(false));
    }
  };

  // Send the request to insert a tree node
  // Inserts "after" the given node
  const insertTreeNode = (nodeId: string) => {
    const treeNode = AppUtil.findNodeById(treeData, nodeId);
    if (treeNode) {
      const args = {
        variables: {
          knowledgeTreeId: entity.id,
          // @ts-ignore
          parentNodeId: treeNode.parentId,
          treeNodeId: nodeId
        }
      };
      setLoading(true);
      InsertKnowledgeTreeNode(args)
        .then((results) => {
          const insertInfo = AppUtil.safeParseJSON(
            results.data.insertKnowledgeTreeNode
          );
          const { newNode } = insertInfo;
          AppUtil.insertTreeNode(treeData, treeNode, newNode);
          resetTreeUI();
          setAutoEditNodeId(newNode.id);
        })
        .finally(() => setLoading(false));
    }
  };

  // Save current tree nodes's answer to backend
  const answerTreeNode = (nodeId: string, boundingBox: IBoundingBox) => {
    const treeNode = AppUtil.findNodeById(treeData, nodeId);
    // Process only leaves
    if (treeNode) {
      const tempNode: IKnowledgeTreeNode = AppUtil.simpleClone(treeNode);
      tempNode.data[0].boundingBox = boundingBox;

      // Now validate the treenode.
      const errs = validateTreeNodeAnswer(tempNode);
      if (errs && errs.length) {
        errorMsgs[nodeId] = errs;
        setErrorMsgs({ ...errorMsgs });
        return;
      }

      // All Good, now submit the change
      const args = {
        variables: {
          knowledgeTreeId: entity.id,
          treeNodeId: nodeId,
          treeNodeData: JSON.stringify(tempNode.data)
        }
      };
      setLoading(true);
      AnswerKnowledgeTreeNode(args)
        .then((results) => {
          if (results.data.answerKnowledgeTreeNode) {
            // Update tree node and the tree
            // @ts-ignore
            treeNode.data = tempNode.data;
            resetTreeData();
          }
        })
        .finally(() => setLoading(false));
    }
  };

  const performTreeNodeAction = (actionData: ITreeNodeActionData) => {
    if (treeNodeAction?.actionId === 'EDIT') {
      updateTreeNode(
        treeNodeAction.nodeId,
        actionData.nodeTitle,
        actionData.nodeType
      );
      if (autoEditNodeId) {
        setAutoEditNodeId(undefined);
      }
    }
    if (treeNodeAction?.actionId === 'DELETE') {
      deleteTreeNode(treeNodeAction.nodeId);
    }
  };

  const cancelTreeNodeAction = () => {
    setTreeNodeAction(undefined);
    if (autoEditNodeId) {
      deleteTreeNode(autoEditNodeId);
      if (autoEditNodeId) {
        setAutoEditNodeId(undefined);
      }
    }
  };

  // The split sizes (set/update)
  const splitSizer = (sizes: number[]) => {
    const leftPanelSize = (document.body.clientWidth * sizes[0]) / 100;
    // if left wide enough, show the context summary
    setShowContextSummary(leftPanelSize > 500);
  };

  useEffect(() => {
    // show context on startup
    splitSizer(INITIAL_SIZES);

    const knowledge = AppUtil.safeParseJSON(entity.knowledge);
    const root = knowledge.tree;

    // Walk the tree and populate the boundingBoxes map
    const bBoxes: Record<string, any[]> = {};
    const visitorFunc = (node: IKnowledgeTreeNode) => {
      if (node.id !== AppConstants.TREE_ROOT_NODE_ID) {
        // Assign a client side parent id
        // Assumption here is that we get only first level of the tree, under root
        // eslint-disable-next-line no-param-reassign
        node.parentId = AppConstants.TREE_ROOT_NODE_ID;

        // Get all the non-empty boxes for each node
        const nodeBoxes = node.data
          .map((d: { boundingBox: any }) => d.boundingBox)
          .filter((nbox: any) => !_isEmpty(nbox));
        if (nodeBoxes && nodeBoxes.length) {
          bBoxes[node.id] = nodeBoxes;
        }
      }
      return true;
    };
    AppUtil.TreeWalk(root, visitorFunc);

    setTreeData(knowledge.tree);
    setContexts(knowledge.contexts); // Immutable
    setBoundingBoxes(bBoxes);

    // Last of all, preselect a node
    if (knowledge.tree.selectedChildIds.length) {
      const preSelectNode = AppUtil.findNodeById(
        root,
        knowledge.tree.selectedChildIds[0]
      );
      if (preSelectNode) {
        updateContext(preSelectNode);
        setSelectedTreeNode(preSelectNode);
      }
    }
  }, [entity.id, entity.knowledge]);

  // When an insert action completes, auto edit the newly inserted node
  useEffect(() => {
    if (autoEditNodeId) {
      // eslint-disable-next-line no-use-before-define
      onTreeNodeAction('EDIT', autoEditNodeId);
    }
  }, [autoEditNodeId]);

  // Direct select of a tree node
  const onTreeNodeSelect = (treeNodeIds: string[]) => {
    if (treeNodeIds.length) {
      const treeNode = AppUtil.findNodeById(treeData, treeNodeIds[0]);
      if (treeNode) {
        updateContext(treeNode);
        setSelectedTreeNode(treeNode);
      }
    }
  };

  const onTreeNodeValueChange = (treeNodeId: string, value: any) => {
    // Update the TreeNode.data.value
    const treeNode = AppUtil.findNodeById(treeData, treeNodeId);
    if (treeNode) {
      // @ts-ignore
      treeNode.data[0].value = value;
      resetTreeData();

      // Clear any associated error msg
      // @ts-ignore
      delete errorMsgs[treeNode.id];
      setErrorMsgs({ ...errorMsgs });
    }
  };

  // Bounding boxes change handler
  // isChanging is an indicator is the bbox is being changing or has finished change
  // We save only when finished change
  const onBoundingBoxesChange = (
    updatedBoxes: IBoundingBox[],
    isChanging: boolean
  ) => {
    if (selectedTreeNode) {
      const n = { ...boundingBoxes };
      n[selectedTreeNode.id] = updatedBoxes;
      setBoundingBoxes(n);

      if (!isChanging) {
        answerTreeNode(selectedTreeNode.id, updatedBoxes[0]);
      }
    }
  };

  // Tree node action handler
  const onTreeNodeAction = (actionId: string, nodeId: string) => {
    const treeNode = AppUtil.findNodeById(treeData, nodeId);
    if (treeNode) {
      if (actionId === 'ANSWER') {
        answerTreeNode(nodeId, (boundingBoxes[nodeId] || [])[0]);
      }
      // Insert a new node in the backend
      else if (actionId === 'INSERT') {
        insertTreeNode(nodeId);
      } else {
        setTreeNodeAction({ actionId, nodeId });
        setSelectedTreeNode(treeNode);
      }
    }
  };

  // Callback to expand/collapse a tree node
  // on expand, we refetch its children
  const onTreeNodeExpand = (
    bExpanded: boolean,
    treeNode: IKnowledgeTreeNode
  ) => {
    if (bExpanded) {
      const variables = {
        knowledgeTreeId: entity.id,
        treeNodeId: treeNode.id
      };
      // See updateTreeNodeChildren() for results processing
      GetKnowledgeTreeNodeChildren({ variables });
    }
  };

  // Received new children for a tree node -- update the tree
  const onTreeNodeChildrenCB = (
    treeNodeId: string,
    children: IKnowledgeTreeNode[]
  ) => {
    const treeNode = AppUtil.findNodeById(treeData, treeNodeId);
    if (treeNode) {
      const incomingChildren = [...children];
      // Assign client side parent Id for incoming children
      // eslint-disable-next-line no-param-reassign
      incomingChildren.forEach((c) => {
        c.parentId = treeNodeId;
      });
      // @ts-ignore
      treeNode.children = incomingChildren;
      resetTreeData();
    }
  };

  // Tree node mouse over. Auto clear after 2 secs
  const onTreeNodeMouseOver = (treeNodeId: string) => {
    setHoverTreeNodeId(treeNodeId);
  };

  const onTreeNodeMouseOut = (_treeNodeId: string) => {
    setHoverTreeNodeId(undefined);
  };

  if (!treeData) {
    return <Loader />;
  }

  const renderLeftPanelView = () => (
    <KnowledgeTreeTable
      treeData={treeData}
      contexts={contexts}
      showContextSummary={showContextSummary}
      errorMsgs={errorMsgs}
      selectedTreeNodeIds={selectedTreeNode ? [selectedTreeNode.id] : []}
      treeNodeAction={treeNodeAction}
      onSelect={onTreeNodeSelect}
      onTreeNodeMouseOver={onTreeNodeMouseOver}
      onTreeNodeMouseOut={onTreeNodeMouseOut}
      onTreeNodeValueChange={onTreeNodeValueChange}
      onTreeNodeAction={onTreeNodeAction}
      onTreeActionConfirm={performTreeNodeAction}
      onTreeActionCancel={cancelTreeNodeAction}
      onTreeNodeExpand={onTreeNodeExpand}
    />
  );

  const contextInfo = AppUtil.contextInfo(contexts, currentContextId);

  const boundingBoxesToDisplay = () => {
    const ret: any[] = [];
    // id -> type
    const treeNodeIdsToHighlight: Record<string, string> = {};
    if (hoverTreeNodeId) {
      treeNodeIdsToHighlight[hoverTreeNodeId] = 'secondary';
    }
    if (selectedTreeNode) {
      treeNodeIdsToHighlight[selectedTreeNode.id] = 'primary';
    }

    Object.keys(treeNodeIdsToHighlight).forEach((tid) => {
      const selNode = AppUtil.findNodeById(treeData, tid);
      if (selNode) {
        AppUtil.TreeWalk(selNode, (node: IKnowledgeTreeNode) => {
          const boxes = boundingBoxes[node.id] || [];
          boxes.forEach((box) => {
            ret.push({ ...box, type: treeNodeIdsToHighlight[tid] });
          });
        });
      }
    });

    return ret;
  };

  return (
    <Split
      className="knowledge-details"
      sizes={INITIAL_SIZES}
      minSize={[300, 400]}
      expandToMin={false}
      gutterSize={10}
      gutterAlign="center"
      snapOffset={30}
      dragInterval={20}
      direction="horizontal"
      cursor="col-resize"
      onDragEnd={splitSizer}
    >
      <div className={`left-panel ${loading ? '-loading' : ''}`}>
        <div className="-loader">
          <Loader message=" " />
        </div>
        {renderLeftPanelView()}
      </div>
      <div className="right-panel">
        <div className="context">
          {contextInfo &&
            ComponentFactory.getContextViewer(contextInfo, {
              readonly: !AppUtil.isLeafTreeNode(selectedTreeNode),
              boundingBoxes: boundingBoxesToDisplay(),
              onChange: onBoundingBoxesChange
            })}
        </div>
      </div>
    </Split>
  );
}

// Component Prop Types
KnowledgeDetails.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  entity: PropTypes.object.isRequired
};

export default KnowledgeDetails;
