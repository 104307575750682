//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// AppHeader is the application header component
//

// 3rd party libraries
import React from 'react';
import { Layout, Menu, Tooltip, Typography, theme, Tag } from 'antd';

// Local imports
import i18n from '@utils/i18n';
import { useLocation, NavLink } from 'react-router-dom';
import './AppHeader.less';
import awsmobile from '@/generated/aws-exports';
import { useSelector } from 'react-redux';
import Container from '../components/Container';
import BrainIcon from '../components/icons/BrainIcon';
import routes from '../utils/routes';
import UserMenu from '../components/UserMenu';
import AppUtil from '../utils/AppUtil';
import { userSelector } from '../stores/slices/user';

const { Header } = Layout;

// Component i18n
const i18nt = (s: string, d?: string) => i18n.t('AppHeader', s, d);

// Component implementation
function AppHeader() {
  const { token } = theme.useToken();
  const { username } = useSelector(userSelector);

  const location = useLocation();
  const { environment } = awsmobile;

  const shouldShowEnvMessage = () => {
    const hide = AppUtil.getFromLocalStorage('HIDE_ENV_MESSAGE');
    if (AppUtil.isMobileUI() || hide === 'true') {
      return false;
    }
    return !AppUtil.isProdEnv();
  };

  if (!username) {
    return null;
  }

  const playgroundUrl = routes.playground.getPlaygroundPageUrl();

  const pages = [
    {
      id: 'procedures',
      label: i18nt(`page_sme_procedures`),
      url: routes.procedure.getProcedureHomePageUrl(),
      isExternal: false
    },
    {
      id: 'department',
      label: i18nt(`page_sme_department`),
      url: routes.department.getDepartmentPageUrl(),
      isExternal: false
    },
    {
      id: 'playground',
      label: i18nt(`page_playground`),
      url: playgroundUrl
    }
  ];

  const currentPage = pages.find((page) => {
    const key = location.pathname.split('/')[1];
    return key === page.id;
  });
  const { id } = currentPage || { id: '' };

  return (
    <Header
      className={AppUtil.mobileClassName('page-header')}
      style={{
        background: AppUtil.isTestEnv() ? token.blue : token['purple-10']
      }}
    >
      <Container>
        <div className="header-content">
          <div className="header-left">
            <NavLink to={routes.global.getHomePageUrl()}>
              <div className="app-logo" data-cy="nav-home">
                <BrainIcon style={{ color: 'white' }} />
              </div>
            </NavLink>
            <div>
              <Menu
                key={id}
                mode="horizontal"
                className="main-menu"
                defaultSelectedKeys={[id]}
                items={pages.map((page) => ({
                  label: page.isExternal ? (
                    <a data-cy={`nav-${page.id}`} href={page.url}>
                      {page.label}
                    </a>
                  ) : (
                    <NavLink data-cy={`nav-${page.id}`} to={page.url}>
                      {page.label}
                    </NavLink>
                  ),
                  key: page.id,
                  className: 'menu-item'
                }))}
                style={{
                  minWidth: 510
                }}
              />
            </div>
          </div>
          <div className="header-right">
            {shouldShowEnvMessage() && (
              <Tag color="purple">
                <Tooltip
                  title={`This app is running in the ${environment} test environment.`}
                >
                  <Typography.Text
                    ellipsis
                    style={{
                      maxWidth: 80
                    }}
                  >
                    {environment}
                  </Typography.Text>
                </Tooltip>
              </Tag>
            )}
            {/* <NotificationsDropdown /> */}
            {/* <DepartmentSelector /> */}
            <UserMenu />
          </div>
        </div>
      </Container>
    </Header>
  );
}

export default AppHeader;
