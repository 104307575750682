import React, { useEffect, useState } from 'react';
import { Upload, Button, Tooltip, Spin } from 'antd';
import {
  InboxOutlined,
  LoadingOutlined,
  UploadOutlined
} from '@ant-design/icons';
import { useApolloClient } from '@apollo/client';
import AppConstants from '@utils/AppConstants';
import i18n from '@utils/i18n';
import slugify from 'slugify';
import { S3PresignedPost } from '../generated/API';
import AppUtil from '../utils/AppUtil';

const i18nt = (s: string, d?: string) => i18n.t('Upload', s, d);

const ACCEPTED_FILE_FORMATS = [
  // PDF
  '.pdf',

  // IMAGE
  '.jpeg',
  '.jpg',
  '.png',

  // Data
  '.txt',
  '.json',
  '.yml',
  '.yaml',
  '.csv',

  // Excel sheets
  '.xlsx',
  '.xls',

  // Docx
  '.docx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document',

  // HTML
  '.html',
  '.htm'
].join(',');

export interface IUploadedResponse {
  s3Url: string;
}

interface IS3FileUploadProps {
  scope: string;
  scopeId: string;
  minimal: boolean;
  multiple: boolean;
  onChange?: (uploadedResponses: IUploadedResponse[]) => void;
  disabled?: boolean;
  showUploadList?: boolean;
}

interface IMetaFile {
  file: File;
  postFields: S3PresignedPost;
}

function S3FileUpload(props: IS3FileUploadProps) {
  const {
    scope,
    scopeId,
    minimal,
    onChange,
    multiple,
    disabled = false,
    showUploadList = false
  } = props;

  const [files, setFiles] = useState<File[]>([]);
  const [loading, setLoading] = useState(false);

  const client = useApolloClient();

  const beforeUpload = async (_file: File, fileList: File[]) => {
    setFiles(
      fileList.map(
        (file) =>
          new File([file], slugify(file.name), {
            type: file.type
          })
      )
    );
    return false;
  };

  const prepareMetaFiles = async (fileList: File[]): Promise<IMetaFile[]> => {
    const fileSignedPostFieldsPromises = fileList.map((file) =>
      client.query({
        query: AppConstants.APIS.S3.UPLOAD_LINK(),
        variables: {
          scope,
          scopeId,
          filename: file.name,
          fields: JSON.stringify({ 'Content-Type': file.type })
        }
      })
    );

    const signedPostFieldsResult = await Promise.allSettled(
      fileSignedPostFieldsPromises
    );

    const metaFiles = signedPostFieldsResult
      .map((r) => {
        if (r.status === 'fulfilled' && !!r.value.data) {
          const postFields: S3PresignedPost = r.value.data?.getUploadToS3Url;
          const s3Parts = AppUtil.getS3URIParts(postFields.s3Url);
          const file = fileList.find((f) => f.name === s3Parts?.filename)!;
          return {
            postFields,
            file
          };
        }
        return null;
      })
      .filter(Boolean) as IMetaFile[];

    return metaFiles;
  };

  const uploadFiles = async (metaFiles: IMetaFile[]) => {
    const uploadPromises = metaFiles.map((metaFile) => {
      const { file, postFields } = metaFile;

      const formData = new FormData();

      const fields =
        typeof postFields.fields === 'string'
          ? JSON.parse(postFields.fields)
          : postFields.fields;

      Object.keys(fields).forEach((key) => {
        formData.append(key, fields[key]);
      });

      formData.append('file', file);

      return fetch(postFields.url, {
        method: 'POST',
        body: formData
      });
    });

    const uploadResult = await Promise.allSettled(uploadPromises);
    const uploadedIndexes = uploadResult
      .map((result, index) => {
        if (result.status === 'fulfilled' && result.value.ok) {
          return index;
        }
        return undefined;
      })
      .filter((index) => index !== undefined);

    const uploadedResponses: IUploadedResponse[] = uploadedIndexes.map(
      (index) => {
        const metaFile = metaFiles.find(
          (_f, fileIndex) => fileIndex === index
        )!;
        return {
          s3Url: metaFile.postFields.s3Url
        };
      }
    );

    return uploadedResponses;
  };

  useEffect(() => {
    (async () => {
      if (files.length > 0) {
        try {
          setLoading(true);
          const metaFiles = await prepareMetaFiles(files);
          const uploadedFilePostFields = await uploadFiles(metaFiles);

          if (onChange) {
            onChange(uploadedFilePostFields);
          }

          setLoading(false);
        } catch (e) {
          console.log(e);
          setLoading(false);
        }
      }
    })();
  }, [files]);

  if (minimal) {
    return (
      <Upload
        accept={ACCEPTED_FILE_FORMATS}
        beforeUpload={beforeUpload}
        showUploadList={false}
        disabled={loading || disabled}
        multiple={multiple}
      >
        <Tooltip title={i18nt('upload', 'Upload File')}>
          <Button
            type="link"
            icon={
              <span>{loading ? <LoadingOutlined /> : <UploadOutlined />}</span>
            }
          />
        </Tooltip>
      </Upload>
    );
  }

  return (
    <Upload.Dragger
      accept={ACCEPTED_FILE_FORMATS}
      beforeUpload={beforeUpload}
      showUploadList={showUploadList}
      disabled={loading || disabled}
      multiple={multiple}
    >
      <Spin spinning={loading}>
        <p className="ant-upload-drag-icon">
          <InboxOutlined />
        </p>
        <p className="ant-upload-text">
          Click or drag files to this area to upload
        </p>
      </Spin>
    </Upload.Dragger>
  );
}

export default S3FileUpload;
