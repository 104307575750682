import { useMutation, useQuery } from '@apollo/react-hooks';
import {
  Button,
  Divider,
  Empty,
  Layout,
  message,
  Modal,
  Popconfirm,
  Space
} from 'antd';
import { isPlainObject, last } from 'lodash/fp';
import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';
import ContextDebuggerNewStep from '../components/context-debugger/ContextDebuggerNewStep';
import Loader, { ILoaderType } from '../components/Loader';
import Request from '../components/request/Request';
import WorkerStatus from '../components/WorkerStatus';
import {
  calcEndPosition,
  computeItems
} from '../details/runs/items/RunContextUtil';
// import RunStep from '../details/runs/items/RunStep';
import QuestionForm from '../details/runs/QuestionForm';
import {
  CreateAnswerMutation,
  CreateAnswerMutationVariables,
  Department,
  GetServiceCommandQuery,
  Question,
  Request as RequestInterface,
  Worker
} from '../generated/API';
import Mutations from '../graphql/Mutations';
import Queries from '../graphql/Queries';
import useFact from '../hooks/useFact';
import usePageVisibility from '../hooks/usePageVisibility';
import { userSelector } from '../stores/slices/user';
import { IGetWorkerDependenciesData } from '../stores/slices/worker';
import AppUtil from '../utils/AppUtil';
import FormattingUtil from '../utils/FormattingUtil';
import { isRequestHandled } from '../utils/request';
import './ContextDebuggerPopup.less';

interface IProps {
  onClose: () => void;
  worker: Worker;
  department: Department;
  question: Question;
  commandId: string | null;
}

function ContextDebuggerPopup(props: IProps) {
  const { onClose, worker, question, department, commandId } = props;

  const [contextId, setContextId] = useState(null);

  const [answerConfirmOpen, setAnswerConfirmOpen] = useState(false);
  const [techniqueAnswerConfirmOpen, setTechniqueAnswerConfirmOpen] =
    useState(false);

  const { data: serviceData } = useQuery<GetServiceCommandQuery>(
    Queries.ServiceCommand(),
    {
      variables: {
        id: commandId
      },
      skip: commandId === null || contextId !== null,
      pollInterval: AppUtil.getQueryPollInterval()
    }
  );

  const { pageHidden } = usePageVisibility();

  const workerDependenciesQuery = useQuery<IGetWorkerDependenciesData>(
    Queries.WorkerDependencies(),
    {
      variables: {
        workerId: worker.id,
        contextId
      },
      pollInterval: AppUtil.getQueryPollInterval(),
      skip: contextId === null || pageHidden,
      fetchPolicy: 'network-only',
      errorPolicy: 'ignore'
    }
  );
  const contextData = workerDependenciesQuery.data?.getContextsForStep;
  const commandsData = workerDependenciesQuery.data?.listCommandsByWorker;
  const workerRequestsData = workerDependenciesQuery.data?.getWorker;

  const workerRequests = (workerRequestsData?.requests as any)?.items || [];
  const requests: RequestInterface[] = workerRequests
    ? workerRequests.filter(
        (request: RequestInterface) => request.contextId === Number(contextId)
      )
    : [];
  const pendingRequests = requests.filter(
    (request) => !isRequestHandled(request)
  );

  const { username } = useSelector(userSelector);

  const { stepList = [] } = (contextData?.items?.[0]! || {}) as any;

  let pendingQuestions: Question[] = [];
  if (commandsData?.items) {
    pendingQuestions = commandsData.items
      .reduce<Question[]>(
        (acc, cur) => acc.concat((cur as any).questions.items),
        []
      )
      .filter(
        (question) => question?.contextId === contextId && !question.isAnswered
      );
  }

  const {
    fetchFactsByIds,
    getFactById,
    loading: factLoading
  } = useFact({
    ids: [],
    knowledgeId: worker.knowledgeId!
  });

  const items = computeItems({
    steps: stepList,
    commands: [],
    newItems: [],
    debugMode: false
  });
  const endPosition = calcEndPosition(stepList);
  const lastStep = last(items);

  let lastStepFactId: string | null = null;
  let lastStepAnswer;
  // TODO: Sumeet fix
  if ((lastStep as any)?.answer) {
    // TODO: Sumeet fix
    const answer = FormattingUtil.parseBrainValue((lastStep as any).answer);
    lastStepAnswer = answer;
    if (answer?.concept?.id) {
      lastStepFactId = answer.concept.id;
    }
  }

  const lastStepFact = getFactById(lastStepFactId);

  useEffect(() => {
    if (lastStepFact) {
      setAnswerConfirmOpen(true);
    }
  }, [lastStepFact]);

  const [createAnswer, createAnswerProps] = useMutation<CreateAnswerMutation>(
    Mutations.CreateAnswer()
  );

  useEffect(() => {
    const commandResult = serviceData?.getServiceCommand?.result
      ? AppUtil.safeParseJSON(serviceData.getServiceCommand.result)
      : {};

    const clonedContextId = commandResult.clone_ctx_id;
    if (clonedContextId) {
      setContextId(clonedContextId);
      console.log('contextId', clonedContextId);
    }
  }, [serviceData?.getServiceCommand]);

  const submitAnswer = (values: Record<string, any>, onSuccess: () => void) => {
    const variables: CreateAnswerMutationVariables = {
      input: AppUtil.prepareAnswerInputVariables({
        question,
        values,
        owner: username
      }) as any
    };

    return createAnswer({
      variables
    }).then((resp) => {
      onSuccess();
      onClose();
      return resp;
    });
  };

  const handleAnswer = (remember: boolean) => {
    if (!lastStepFactId) {
      return;
    }

    const parsedValue = FormattingUtil.parseBrainValue(
      lastStepFact?.value || ''
    );

    let value = parsedValue;
    if (isPlainObject(parsedValue)) {
      const firstKey = Object.keys(parsedValue)[0];
      value = parsedValue[firstKey];
    }

    submitAnswer(
      {
        remember,
        inputType: 'value',
        value,
        secret: false
      },
      () => {
        setAnswerConfirmOpen(false);
        message.success(`'${value}' answer submitted`);
      }
    );
  };

  const handleTeachTechniqueAnswer = (remember: boolean) => {
    const stepTexts = items.map((item) => {
      // TODO: sumeet fix
      const [stepText] = AppUtil.safeParseJSON((item as any).text);
      return stepText;
    });
    const text = stepTexts.join('\n');

    submitAnswer(
      {
        remember,
        inputType: 'technique',
        technique: text,
        secret: false
      },
      () => {
        setTechniqueAnswerConfirmOpen(false);
        message.success(`Answer submitted`);
      }
    );
  };

  const renderSteps = () => {
    if (!items.length) {
      return null;
    }

    return (
      <div className="steps">
        {/* {items.map((item: Step) => (
          <RunStep
            key={nodeId(item)}
            nodeId={nodeId(item)}
            item={item}
            run={worker}
            onAction={() => Promise.resolve(null)}
            onExpandCollapse={() => {}}
            expandedNodes={[]}
            highlightedNodes={[]}
            debug={false}
            stepQuestionsMap={{}}
            stepRequestsMap={{}}
            stepsList={[]}
            source="debugger"
          />
        ))} */}
      </div>
    );
  };

  const renderRequests = () => {
    if (!pendingRequests.length) {
      return null;
    }

    return (
      <div className="questions">
        <div className="run-requests">
          {pendingRequests.map((request) => (
            <Request
              key={request.id}
              request={request}
              worker={worker}
              department={department}
              hideAnsweredQuestion
              questionConfig={{
                answerMode: 'single',
                allowContextDebugging: false
              }}
              dispatchContextPath={false}
            />
          ))}
        </div>
      </div>
    );
  };

  const renderQuestions = () => {
    if (!pendingQuestions.length) {
      return null;
    }

    return (
      <div className="questions">
        <div className="run-questions">
          {pendingQuestions.map((q: any) => {
            let displayQuestions = [q];
            let isParent = false;
            if (['all_answers', 'any_answer'].includes(q.type)) {
              isParent = true;
              displayQuestions = q.children;
            }
            return (
              <div className="question-group" key={q.id}>
                <Layout className="question">
                  {isParent && <Divider>{q.text}</Divider>}

                  <Layout.Content>
                    <QuestionForm
                      key={q.id}
                      knowledgeId={worker.knowledgeId!}
                      questions={displayQuestions}
                      isAnswered={q.isAnswered}
                      hideAnswered
                      allowContextDebugging={false}
                      worker={worker}
                    />
                  </Layout.Content>
                </Layout>
              </div>
            );
          })}
        </div>
      </div>
    );
  };

  const renderContent = () => (
    <div>
      <div className="content">
        {items.length === 0 ? (
          <div className="no-content">
            <Empty />
          </div>
        ) : (
          <div className="items">
            {renderSteps()}
            {renderRequests()}
            {renderQuestions()}
          </div>
        )}
      </div>

      <ContextDebuggerNewStep
        worker={worker}
        department={department}
        contextId={contextId!}
        endStepPosition={endPosition}
      />
    </div>
  );

  return (
    <Modal
      open
      title={<WorkerStatus worker={worker} />}
      onCancel={onClose}
      footer={
        contextId ? (
          <Space>
            <Popconfirm
              title="Remember this answer for this department?"
              open={techniqueAnswerConfirmOpen}
              onConfirm={() => handleTeachTechniqueAnswer(true)}
              onCancel={() => handleTeachTechniqueAnswer(true)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                loading: createAnswerProps.loading
              }}
              cancelButtonProps={{
                loading: createAnswerProps.loading
              }}
            >
              <Button
                key="teach"
                disabled={
                  !lastStepAnswer ||
                  createAnswerProps.loading ||
                  answerConfirmOpen
                }
                loading={factLoading}
                onClick={() => {
                  setTechniqueAnswerConfirmOpen(true);
                }}
              >
                Teach technique
              </Button>
            </Popconfirm>
            <Popconfirm
              title="Remember this answer for this department?"
              open={answerConfirmOpen}
              onConfirm={() => handleAnswer(true)}
              onCancel={() => handleAnswer(false)}
              okText="Yes"
              cancelText="No"
              okButtonProps={{
                loading: createAnswerProps.loading
              }}
              cancelButtonProps={{
                loading: createAnswerProps.loading
              }}
            >
              <Button
                key="answer"
                type="primary"
                loading={factLoading}
                onClick={() => {
                  if (lastStepFactId) {
                    fetchFactsByIds([lastStepFactId]);
                  }
                }}
                disabled={
                  !lastStepFactId ||
                  createAnswerProps.loading ||
                  techniqueAnswerConfirmOpen
                }
              >
                Use answer
              </Button>
            </Popconfirm>
          </Space>
        ) : null
      }
      keyboard={false}
      maskClosable={false}
      className="context-debugger-popup"
    >
      {contextId ? (
        renderContent()
      ) : (
        <Loader
          type={ILoaderType.SKELETON}
          skeletonConfig={{
            rows: 5
          }}
        />
      )}
    </Modal>
  );
}

export default ContextDebuggerPopup;
