//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// DetailsPage is a component that shows an entity's details view
// THis is the general wrapper for a details page.
// Actual content is rendered by entity type
//

// 3rd party libraries
import React from 'react';
import { Alert, Layout } from 'antd';
import { useQuery } from '@apollo/client';

// Local imports
import i18n from '@utils/i18n';
import AppConstants from '@utils/AppConstants';
import ComponentFactory from '@utils/ComponentFactory';
import Loader, { ILoaderType } from '@components/Loader';

// Component CSS
import './DetailsPage.less';
import { useAppDispatch } from '@/stores/hooks';
import { useSelector } from 'react-redux';
import { hideDetails, popupSelector } from '@/stores/slices/appPopup';

// Component i18n
const i18nt = (s, d) => i18n.t('DetailsPage', s, d);

/**
 * @deprecated
 * use DetailsPageV2 instead
 */
function DetailsPage() {
  const { currentPopupId, currentPopupParams, currentDetailsInfo } =
    useSelector(popupSelector);

  const dispatch = useAppDispatch();

  if (!currentDetailsInfo) {
    return null;
  }

  const { entityType, vars } = currentDetailsInfo;

  // The Query hook to load the details object
  // Here we don't want caching. Fetch latest every time
  const { data, loading, error, refetch } = useQuery(
    AppConstants.APIS.GET_ENTITY[entityType](),
    {
      fetchPolicy: 'network-only',
      variables: vars
    }
  );

  // Close this page
  const closeDetails = () => {
    dispatch(hideDetails());
  };

  // Refresh this page
  const refreshDetails = () => {
    refetch();
  };

  // Render the UI
  // Prop ups from within the details page
  const popupComponent = ComponentFactory.getPopup(
    currentPopupId,
    currentPopupParams
  );

  if (loading) {
    return <Loader type={ILoaderType.FULL_PAGE} />;
  }

  const uCaseEType = entityType.charAt(0).toUpperCase() + entityType.slice(1);
  const entity = data[`get${uCaseEType}`];
  // Show error msg or details component
  let detailsComponent;
  if (error) {
    const eMsg = i18nt('error_loading_entity', 'Error loading entity.');
    detailsComponent = <Alert message={eMsg} type="error" />;
  } else {
    const dprops = {
      ...vars,
      onRefresh: refreshDetails,
      onClose: closeDetails
    };
    detailsComponent = ComponentFactory.getDetailsPage(
      entityType,
      entity,
      dprops
    );
  }

  const { Content, Header } = Layout;
  return (
    <Layout className="details-page">
      <Header className="details-header">
        <div className="entity-name">
          {entity && (entity.name || entity.title)}
        </div>
        <div className="entity-description">{entity && entity.description}</div>
        <div className="header-right">
          <div onClick={() => closeDetails()}>
            {ComponentFactory.getIcon(AppConstants.ICONS.CLOSE)}
          </div>
        </div>
      </Header>
      <Content className="details-content">{detailsComponent}</Content>
      {popupComponent}
    </Layout>
  );
}

export default DetailsPage;
