//
// Copyright (C) - Ratif. All rights reserved
//
// ColumnsLayout is a component that does a column layout of its children
//

// 3rd part libraries
import React from 'react';

// Component CSS
import './ColumnsLayout.less';

interface IColumnsLayout {
  children?: React.ReactNode[];
}

// Component implementation
function ColumnsLayout({ children }: IColumnsLayout) {
  if (!children) {
    return null;
  }

  // Render the UI
  return (
    <div className="columns-layout">
      {children.map((c) => (
        <div className="column">{c}</div>
      ))}
    </div>
  );
}

export default ColumnsLayout;
