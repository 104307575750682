//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// A central place to keep all SDK APIs that the UI uses.
//

import { Auth } from '@aws-amplify/auth';
import axios from 'axios';

import AppUtil from '@utils/AppUtil';
import awsmobile from '@/generated/aws-exports';

const SDKApis = {
  Auth: {
    async startSignIn(email, _password, callbackPathname) {
      if (awsmobile.kognitos_login_url !== undefined) {
        // new login flow calling login endpoint

        const response = await axios.post(awsmobile.kognitos_login_url, {
          username: email,
          clientMetadata: {
            origin: window.location.origin,
            pathname: callbackPathname
          }
        });

        if (response.data !== null && response.data.success !== true) {
          throw Error(response.data.message);
        }

        return null;
      }

      // old login flow using user pool sign-up

      const params = {
        username: email,
        password: AppUtil.getRandomString(30),
        attributes: {
          email
        },
        clientMetadata: {
          origin: window.location.origin,
          pathname: callbackPathname
        }
      };

      try {
        await Auth.signUp(params);
        return null;
      } catch (e) {
        if (e.code === 'UsernameExistsException') {
          return null;
        }
        throw e;
      }
    },

    async finishSignIn(email, code) {
      try {
        const user = await Auth.signIn(email, null);
        await Auth.sendCustomChallengeAnswer(user, code);
        return user;
      } catch (e) {
        console.error(e);
        return null;
      }
    },

    async signOut() {
      return Auth.signOut();
    },

    async currentAuthenticatedUser() {
      try {
        const res = await Auth.currentAuthenticatedUser();
        return res;
      } catch (e) {
        console.error(e);
        return null;
      }
    },

    async currentSession() {
      try {
        const res = Auth.currentSession();
        return res;
      } catch (e) {
        console.error(e);
        return null;
      }
    }
  }
};

export default SDKApis;
