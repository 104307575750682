//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// CSVTable is a component that renders a table from a CSV data source.
// The data source is comma separated, and the first line is the header line
//

// 3rd part libraries
import React, { useEffect, useState } from 'react';
import csv from 'csvtojson';
import { Tag, Table, Button, Form, Input, Divider, Tooltip } from 'antd';
import _groupBy from 'lodash/fp/groupBy';
import _pluck from 'lodash/fp/pluck';

// Local imports
import Loader from '@components/Loader';
import { IBoundingBox } from '@details/knowledge/KnowledgeInterfaces';

// Component CSS
import './CSVTable.less';
import classNames from 'classnames';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { isEqual } from 'lodash/fp';

// Util to find a bbox at x/y location
const findBoundingBox = (boundingBoxes: IBoundingBox[], x: number, y: number) =>
  boundingBoxes.find((bbox) => bbox.x === x && bbox.y === y);

const findSummaryBoundingBox = (
  boundingBoxes: IBoundingBox[],
  x: number,
  _y: number
) => boundingBoxes.find((bbox) => bbox.x === x);

// Given data, auto-detect its dataType and convert data to that type
const autoDetectDataType = (strData: string[]) => {
  const grps = _groupBy((x) => x, strData);
  const enumValues = Object.keys(grps);
  if (enumValues.length > 2 && enumValues.length <= 7) {
    return { type: 'enum', enumValues, data: strData };
  }

  return { type: 'string', data: strData };
};

interface ICSVInsertFormProps {
  onFinish: (values: any) => void;
  tableColumnNames: string[];
  loading: boolean;
  showInsertForm: boolean;
  setShowInsertForm: React.Dispatch<React.SetStateAction<boolean>>;
}

function InsertForm(props: ICSVInsertFormProps) {
  const {
    onFinish,
    tableColumnNames,
    loading,
    showInsertForm,
    setShowInsertForm
  } = props;
  const [form] = Form.useForm();
  const [formSubmitEnabled, setFormSubmitEnabled] = useState(false);

  const onCancel = () => {
    form.resetFields();
    setShowInsertForm(false);
  };

  return !showInsertForm ? (
    <Button
      type="link"
      onClick={(e) => {
        e.stopPropagation();
        form.resetFields();
        setShowInsertForm(true);
      }}
    >
      + insert new row
    </Button>
  ) : (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={onFinish}
        initialValues={{}}
        onValuesChange={() => {
          form.validateFields().then((data: Record<string, string>) => {
            if (
              Object.values(data).some((value) => value && value.trim() !== '')
            ) {
              setFormSubmitEnabled(true);
            } else {
              setFormSubmitEnabled(false);
            }
          });
        }}
      >
        {tableColumnNames.map((name) => (
          <Form.Item key={name} label={name} name={name}>
            <Input />
          </Form.Item>
        ))}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formSubmitEnabled || loading}
            loading={loading}
          >
            Submit
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={onCancel}
            disabled={loading}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </>
  );
}

interface ICSVEditFormProps {
  editRecord: Record<string, string>;
  onEditFinish: (data: any) => void;
  tableColumnNames: string[];
  editLoading: boolean;
  onCancel: () => void;
}

function EditForm(props: ICSVEditFormProps) {
  const { editRecord, onEditFinish, tableColumnNames, editLoading, onCancel } =
    props;
  const [form] = Form.useForm();
  const [formSubmitEnabled, setFormSubmitEnabled] = useState(false);

  return (
    <>
      <Form
        form={form}
        name="basic"
        onFinish={(data) => onEditFinish(data)}
        initialValues={editRecord}
        onValuesChange={() => {
          form.validateFields().then((data) => {
            if (!isEqual(data, editRecord)) {
              setFormSubmitEnabled(true);
            } else {
              setFormSubmitEnabled(false);
            }
          });
        }}
      >
        {tableColumnNames.map((name) => (
          <Form.Item key={name} label={name} name={name}>
            <Input />
          </Form.Item>
        ))}

        <Form.Item>
          <Button
            type="primary"
            htmlType="submit"
            disabled={!formSubmitEnabled || editLoading}
            loading={editLoading}
          >
            Submit
          </Button>
          <Button
            style={{ marginLeft: '10px' }}
            onClick={() => {
              form.resetFields();
              onCancel();
            }}
            disabled={editLoading}
          >
            Cancel
          </Button>
        </Form.Item>
      </Form>
      <Divider />
    </>
  );
}

interface ICSVTableProps {
  /**
   * The CSV Data to show as a table
   */
  csvData: string;
  /**
   * BoundingBoxes for display
   */
  boundingBoxes: IBoundingBox[];
  /**
   * Is this a readonly view
   */
  readonly?: boolean;
  /**
   * On Change callback. Required if not readonly
   */
  onChange?: (bboxes: IBoundingBox[]) => void;
  /**
   * Show Only a summary.
   * In this case it will show the table header, and the first bounding box
   */
  summary?: boolean;

  /**
   * Optional className override
   * */
  className?: string;
  onEdit?: (data: string, type?: string) => Promise<unknown>;
}

// Component implementation
function CSVTable(props: ICSVTableProps) {
  const [initialData, setInitialData] = useState(props.csvData);
  const [tableColumnNames, setTableColumnNames] = useState<string[]>([]); // [] of column names
  const [tableData, setTableData] = useState<Record<string, any>>({}); // { columName -> { type: , data: [] }}
  const [tableRows, setTableRows] = useState<any[]>(); // [ { cellObject} ]

  const onEdit = props.onEdit;
  const [form] = Form.useForm();
  const [showInsertForm, setShowInsertForm] = useState(false);
  const [insertLoading, setInsertLoading] = useState(false);

  const [toDeleteRowId, setToDeleteRowId] = useState<number>();
  const [deleteLoading, setDeleteLoading] = useState(false);

  const [toBeEditedRowId, setToBeEditedRowId] = useState<number>();
  const [editRecord, setEditRecord] = useState<Record<string, string>>();
  const [editLoading, setEditLoading] = useState(false);

  // Cell click handler - creates a bounding box of that cell
  const onCellClick = (e: any) => {
    if (!props.readonly && props.onChange) {
      const sCoords = e.currentTarget.getAttribute('data-coords');
      const coords = sCoords.split(',');
      const boundingBoxes = [
        {
          x: Number(coords[0]),
          y: Number(coords[1]),
          width: 1,
          height: 1
        }
      ];
      props.onChange(boundingBoxes);
    }
  };

  // Cell Renderer
  const onRenderCell = (
    colName: string,
    text: string,
    _record: any,
    rowIndex: number
  ) => {
    const x = tableData ? tableColumnNames.indexOf(colName) : -1;
    const y = rowIndex;

    const boundingBox = props.summary
      ? findSummaryBoundingBox(props.boundingBoxes, x, y)
      : findBoundingBox(props.boundingBoxes, x, y);

    let cellData = tableData[colName].data[rowIndex]; // Formated date
    const type = tableData[colName].type;

    let sClassName = boundingBox ? 'selected' : '';
    sClassName += ` data-${type}`;

    if (type === 'enum') {
      // Currently we support 2 enums per table.
      // if we have more - then simply define more color schemes in the .less file
      const enumIdx = tableData[colName].enumValues.indexOf(text);
      const enumColorSchemeIdx = tableData[colName].enumColorSchemeIdx;
      const tagClassName = ` color-scheme-${enumColorSchemeIdx} color-${
        enumIdx + 1
      }`;
      cellData = <Tag className={tagClassName}>{text}</Tag>;
    }
    return (
      <span
        data-coords={`${x},${y}`}
        onClick={onCellClick}
        className={sClassName.trim()}
      >
        {cellData}

        {/* <Input value={text} style={{ minWidth: '100px' }} /> */}
      </span>
    );
  };

  // On load, convert CSV String to JSON data
  useEffect(() => {
    const data = initialData;
    csv({ flatKeys: true })
      .fromString(data)
      .then((results) => {
        // extract cols from first row.
        const colNames = data.split('\n')[0].trim().split(',');

        // If in summary mode - we have only one row, and bounding boxes are required
        const rows = props.summary
          ? [results[props.boundingBoxes[0].y]]
          : results;
        rows.forEach((r, idx) => ({ ...r, key: idx }));

        const columnData: Record<string, any> = {};
        let enumColorSchemeIdx = 0;
        const nbColorSchemes = 2; // Currently we have only 2 color schemes.
        colNames.forEach((colName) => {
          const cData = _pluck((row) => row[colName], rows);
          columnData[colName] = autoDetectDataType(cData);
          if (columnData[colName].type === 'enum') {
            columnData[colName].enumColorSchemeIdx =
              enumColorSchemeIdx % nbColorSchemes;
            enumColorSchemeIdx += 1;
          }
        });

        setTableRows(rows);
        setTableColumnNames(colNames);
        setTableData(columnData);
      });
  }, [initialData, props.boundingBoxes, props.summary]);

  // Ensure we have data
  if (!(Object.keys(tableData).length && tableColumnNames.length)) {
    return <Loader />;
  }

  const cols = tableColumnNames.map((cName, _idx) => ({
    title: cName,
    dataIndex: cName,
    render: (text: string, record: any, index: number) =>
      onRenderCell(cName, text, record, index)
  }));

  // Render the UI
  const className = props.summary
    ? 'csv-table summary'
    : 'csv-table selectable';

  const handleInsert = (values: any) => {
    const newEntry = Object.values(values).join(',');
    const csv = initialData.split('\n');
    const colNames = csv[0];
    const oldData = csv.slice(1).filter((row) => row);
    const newData = [colNames, newEntry, ...oldData].join('\n');
    if (onEdit) {
      setInsertLoading(true);
      onEdit(newData, 'table')
        .then(() => {
          setShowInsertForm(false);
          setToBeEditedRowId(undefined);
          setToDeleteRowId(undefined);
          setInitialData(newData);
        })
        .finally(() => setInsertLoading(false));
    }
  };

  const handleEdit = (data: any) => {
    const headers = initialData.split('\n')[0];
    const csvData = initialData
      .split('\n')
      .slice(1)
      .map((old, idx) =>
        idx === toBeEditedRowId ? Object.values(data).join(',') : old
      );
    const newCsvString = [headers, ...csvData].join('\n');
    if (onEdit) {
      setEditLoading(true);
      onEdit(newCsvString, 'table')
        .then(() => {
          setToBeEditedRowId(undefined);
          setEditRecord(undefined);
          setInitialData(newCsvString);
        })
        .finally(() => setEditLoading(false));
    }
  };

  const handleDelete = () => {
    const csv = initialData.split('\n');
    const data = csv.slice(1);
    const updatedData = data.filter((_, idx) => idx !== toDeleteRowId);
    const newCSV = [csv[0], ...updatedData].join('\n');
    if (onEdit) {
      setDeleteLoading(true);
      onEdit(newCSV, 'table')
        .then(() => {
          setToDeleteRowId(undefined);
          setInitialData(newCSV);
        })
        .finally(() => {
          setDeleteLoading(false);
        });
    }
  };

  const actions = {
    title: 'Actions',
    dataIndex: 'Actions',
    render: (_: string, record: any, index: number) => {
      if (index === toDeleteRowId) {
        return (
          <span>
            Delete?{' '}
            {deleteLoading ? (
              <LoadingOutlined />
            ) : (
              <span>
                <Tooltip title="confirm">
                  <Button
                    disabled={deleteLoading}
                    danger
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<CheckOutlined />}
                    onClick={handleDelete}
                  />
                </Tooltip>
                <Tooltip title="cancel">
                  <Button
                    disabled={deleteLoading}
                    size="small"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => setToDeleteRowId(undefined)}
                  />
                </Tooltip>
              </span>
            )}
          </span>
        );
      }
      return (
        <span
          style={{
            display: 'grid',
            gridGap: '4px',
            gridTemplateColumns: '1fr 1fr'
          }}
        >
          {!editRecord && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                form.resetFields();
                form.resetFields();
                setToBeEditedRowId(index);
                setEditRecord(record);
              }}
            >
              <Tooltip title="Edit">
                <EditOutlined />
              </Tooltip>
            </span>
          )}

          <span
            style={{ cursor: 'pointer' }}
            onClick={() => setToDeleteRowId(index)}
          >
            <Tooltip title="Delete">
              <DeleteOutlined />
            </Tooltip>
          </span>
        </span>
      );
    }
  };

  return (
    <div className={classNames(className, props.className)}>
      {onEdit && !editRecord && (
        <InsertForm
          showInsertForm={showInsertForm}
          setShowInsertForm={setShowInsertForm}
          onFinish={handleInsert}
          tableColumnNames={tableColumnNames}
          loading={insertLoading}
        />
      )}
      {editRecord && (
        <EditForm
          editRecord={editRecord}
          onEditFinish={handleEdit}
          tableColumnNames={tableColumnNames}
          editLoading={editLoading}
          onCancel={() => {
            setToBeEditedRowId(undefined);
            setEditRecord(undefined);
          }}
        />
      )}
      {/* TODO: replace antd table with our own table comp */}
      <Table
        size={(props.summary ? 'small' : 'medium') as any}
        bordered={false}
        pagination={false}
        columns={actions ? [...cols, actions] : cols}
        dataSource={tableRows}
      />
    </div>
  );
}

export default CSVTable;
