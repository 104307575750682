/* eslint-disable no-underscore-dangle */
import React, { useEffect, useRef, useState } from 'react';
import { Tooltip, Typography } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import useForceRerender from '@/hooks/useForceRerenderer';
import Table from './table/Table';
import { ITableColumn, ITableProps, ITableRef } from './table/interface';
import { Fact, Step, Worker } from '../generated/API';
import FormattingUtil from '../utils/FormattingUtil';
import './AutoTable.less';
import useFact from '../hooks/useFact';
import RunFactRenderer from './playground2/RunFactRenderer';
import usePaginationState from '../hooks/usePaginationState';
import AppConstants from '../utils/AppConstants';
import ComponentFactory from '../utils/ComponentFactory';
import AppUtil from '../utils/AppUtil';
import AddFactValue from './facts/AddFactValue';
import useStepFactEdit from '../hooks/useStepFactEdit';
import Loader from './Loader';

interface IProps {
  id: string;
  data: any;
  knowledgeId: string;
  title: string;
  step?: Step;
  worker?: Worker;
}

function AutoTable(props: IProps) {
  const { data: propsData, knowledgeId, id, title, step, worker } = props;

  const factIds: string[] = propsData.map((entry: any) => entry.concept.id);

  const paginationState = usePaginationState({
    totalCount: factIds.length,
    pageSize: 10
  });

  const { refetchDeps } = useForceRerender();

  const { facts, loading, getPageData, getFactById } = useFact({
    ids: factIds,
    knowledgeId,
    pagination: {
      pageSize: paginationState.pageSize
    },
    refetchDeps
  });

  const {
    deleteFact,
    deleteFactWithMultipleValue,
    updateFact,
    updateFactWithMultipleValues
  } = useStepFactEdit({
    step: step!,
    worker: worker!
  });

  const [tableData, setTableData] = useState<any[]>([]);
  const [tableColumns, setTableColumns] = useState<ITableColumn[]>([]);
  const [listId, setListId] = useState('');

  const uniqueRowId = 'id';
  const tableRef = useRef<ITableRef>(null);

  let allowEdit = !!step;
  if ((tableColumns[0]?.id || '').endsWith('-object-column')) {
    allowEdit = false;
  }

  const getValueIndex = (valueId: string) => {
    return tableData
      .filter((data) => !data._deleted__)
      .findIndex((data) => data[uniqueRowId] === valueId);
  };

  const onRowEditSave: ITableProps['onEditSave'] = (
    rowUniqueId,
    rowValues,
    resetEditable
  ) => {
    if (listId) {
      updateFactWithMultipleValues(
        id,
        getValueIndex(rowUniqueId),
        rowValues.value
      ).then(() => {
        resetEditable();
        getPageData(paginationState.pageNumber);
      });
    } else {
      updateFact(id, rowValues.value).then(() => {
        resetEditable();
        getPageData(paginationState.pageNumber);
      });
    }
  };

  const getDefaultColumns = (): ITableColumn<Fact>[] => [
    {
      id: 'value',
      dataKey: 'value',
      title,
      renderColumn: (_value, record) => {
        if (!record && loading) {
          return <Loader noMessage />;
        }
        if ((record as any)?._deleted__) {
          return <Typography.Text delete>Deleted</Typography.Text>;
        }
        return (
          <RunFactRenderer fact={record} knowledgeId={knowledgeId} divider />
        );
      },
      edit: allowEdit
        ? {
            editRender: (_value, record, editValue, onCellChange) => {
              const editorValue =
                editValue === undefined
                  ? FormattingUtil.parseBrainValue(record?.value!) ??
                    record?.names?.[0]
                  : editValue;

              return ComponentFactory.getInputElement(
                'conceptValue',
                AppConstants.INPUT_TYPES.TEXT_AREA,
                editorValue,
                null,
                (_id: any, newValue: string) => onCellChange(newValue),
                {
                  autoSize: true
                }
              );
            }
          }
        : undefined
    }
  ];

  const getObjectColumns = (
    object: Record<string, any>
  ): ITableColumn<any>[] => {
    const cols: ITableColumn[] = Object.keys(object).map((key: any) => ({
      id: `${key}-object-column`,
      dataKey: key,
      title: key,
      renderColumn: (value: any) =>
        FormattingUtil.getFormattedAnswer({
          answer: FormattingUtil.parseBrainValue(value || ''),
          knowledgeId
        }).answer
    }));

    return cols;
  };

  const getDefaultData = () => factIds.map((factId) => getFactById(factId));

  const getTableData = (
    facts: Fact[]
  ): {
    columns: ITableColumn[];
    data: any[];
  } => {
    let columns: ITableColumn[] = getDefaultColumns();
    let data: any[] = getDefaultData();

    const firstFact = facts[0];
    if (firstFact) {
      const parsedValue = FormattingUtil.parseBrainValue(firstFact.value || '');
      const valueType = typeof parsedValue;

      if (
        ['string', 'number', 'boolean'].includes(valueType) ||
        !parsedValue ||
        Array.isArray(parsedValue)
      ) {
        columns = getDefaultColumns();
        data = getDefaultData();
      } else if (valueType === 'object') {
        data = factIds.map((factId) => {
          const fact = getFactById(factId);
          if (fact) {
            return FormattingUtil.parseBrainValue(fact.value || '');
          }
          return undefined;
        });

        columns = getObjectColumns(parsedValue);
      }
    }

    return {
      columns,
      data
    };
  };

  const getListId = () => {
    const currentIds = JSON.stringify(
      propsData.map((i: { concept: { id: any } }) => i.concept.id)
    );
    const stepConcept = AppUtil.safeParseJSON(step?.concepts || '').find(
      (i: { ids: any }) => JSON.stringify(i?.ids || '') === currentIds
    );
    if (stepConcept?.list_id) {
      setListId(stepConcept.list_id);
    }
  };

  useEffect(() => {
    const { columns, data } = getTableData(Object.values(facts));
    setTableColumns(columns);
    setTableData(data);
    getListId();
  }, [facts, refetchDeps]);

  const shouldFetchPageData = (page: number) => {
    const paginatedData = AppUtil.getPaginatedList(
      tableData,
      page,
      paginationState.pageSize
    );
    return paginatedData.every((data) => data === undefined);
  };

  const renderExtraRowActions: ITableProps['renderExtraRowActions'] = (
    uniqId
  ) => (
    <span
      onClick={() => {
        if (!listId) {
          deleteFact(id).then(() => {
            setTableData(
              tableData.map((table) => {
                if (table[uniqueRowId] === uniqId) {
                  return { _deleted__: true };
                }
                return table;
              })
            );
          });
        } else {
          const index = getValueIndex(uniqId);
          deleteFactWithMultipleValue(id, index).then(() => {
            setTableData(
              tableData.map((table) => {
                if (table[uniqueRowId] === uniqId) {
                  return { _deleted__: true };
                }
                return table;
              })
            );
          });
        }
      }}
    >
      <Tooltip title="Delete">
        <DeleteTwoTone />
      </Tooltip>
    </span>
  );

  return (
    <div>
      {allowEdit && listId ? (
        <AddFactValue factId={id} step={step!} worker={worker!} />
      ) : null}

      <Table
        ref={tableRef}
        uniqueRowId={uniqueRowId}
        className="auto-table"
        columns={tableColumns}
        data={tableData}
        loading={loading && refetchDeps < 1}
        pagination={{
          total: factIds.length,
          pageNumber: paginationState.pageNumber,
          pageSize: paginationState.pageSize,
          onChange: (page) => {
            if (shouldFetchPageData(page)) {
              getPageData(page);
            }
            paginationState.updatePageNumber(page);
          }
        }}
        onEditSave={onRowEditSave}
        renderExtraRowActions={allowEdit ? renderExtraRowActions : undefined}
      />
    </div>
  );
}

export default AutoTable;
