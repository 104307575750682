//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Validation capabilities
//

// 3rd party includes
import moment from 'moment';

// Local Includes
import i18n from '@utils/i18n';
import AppConstants from '@utils/AppConstants';

// Component i18n
const i18nt = (s, d) => i18n.t('Validations', s, d);

const Validations = {
  //
  // Validate a value using the given validations
  // @param value - value to validate
  // @param validations - [ { validation, options }... ]
  // @return [] of errorMsgs
  validate: (value, validationList = []) => {
    const ret = [];

    const ValidateFuncMap = {
      [AppConstants.VALIDATIONS.VALID_REGION]: Validations.validRegion,
      [AppConstants.VALIDATIONS.STRING_LENGTH]: Validations.stringLength,
      [AppConstants.VALIDATIONS.MATCHES_REGEX]: Validations.matchesRegex,
      [AppConstants.VALIDATIONS.VALID_DATE]: Validations.validDate,
      [AppConstants.VALIDATIONS.DATE_BEFORE]: Validations.dateBefore,
      [AppConstants.VALIDATIONS.DATE_AFTER]: Validations.dateAfter,
      [AppConstants.VALIDATIONS.VALID_NUMBER]: Validations.validNumber
    };

    validationList.forEach((v) => {
      const vFunc = ValidateFuncMap[v.validation];
      const invalidMsg =
        value !== undefined ? vFunc(value, v.options, v.msg) : null;
      if (invalidMsg) {
        ret.push(invalidMsg);
      }
    });

    return ret;
  },

  // Validate a region.
  // options are minWidth. minHeight
  validRegion: (value, options, msg) => {
    if (value.width < options.minWidth || value.height < options.minHeight) {
      return msg || i18nt('regionTooSmall', 'Region too small');
    }
    return null;
  },

  // Validate a string length.
  // Options are { min, max }
  stringLength: (value, options, msg) => {
    const len = value ? value.length : 0;
    if (len < options.min) {
      return (
        msg ||
        i18nt(
          'valueTooSmall',
          'Value too small. Should be at least %d long'
        ).replace('%d', options.min)
      );
    }
    if (len > options.max) {
      return (
        msg ||
        i18nt(
          'valueTooBig',
          'Value too long. Should be less than %d long'
        ).replace('%d', options.max)
      );
    }
    return null;
  },

  // Does a string match a give regex.
  // Options are { min, max }
  matchesRegex: (value, options, msg) => {
    const r = new RegExp(options.regex);
    if (!r.test(value)) {
      return msg || i18nt('incorrectFormat', 'Value not in expected format');
    }
    return null;
  },

  // Is the value a valid date.
  // Options are format - eg "YY MM DD"
  validDate: (value, options, msg) => {
    const ret = options.format ? moment(value, options.format) : moment(value);
    if (!ret.isValid()) {
      return msg || i18nt('invalidDate', 'Invalid date');
    }
    return null;
  },

  // Is the value a valid date.
  // Options are beforeMillis
  dateBefore: (value, options, msg) => {
    const d = Number(value);
    if (d >= options.beforeMillis) {
      return (
        msg ||
        i18nt('dateBefore', 'Date should be before %s').replace(
          '%s',
          new Date(options.beforeMillis).toLocaleString()
        )
      );
    }
    return null;
  },

  // Is the value a valid date.
  // Options are afterMillis
  dateAfter: (value, options, msg) => {
    const d = Number(value);
    if (d <= options.afterMillis) {
      return (
        msg ||
        i18nt('dateAfter', 'Date should be after %s').replace(
          '%s',
          new Date(options.afterMillis).toLocaleString()
        )
      );
    }
    return null;
  },

  // Is the value a valid date.
  // Options are {min, max}
  validNumber: (value, options, msg) => {
    const d = Number(value);
    if (d < options.min) {
      return (
        msg ||
        i18nt('tooSmall', 'Value should be greater than %d').replace(
          '%d',
          options.min
        )
      );
    }
    if (d > options.max) {
      return (
        msg ||
        i18nt('tooBig', 'Value should be less than %d').replace(
          '%d',
          options.max
        )
      );
    }
    return null;
  }
};

export default Validations;
