/* eslint-disable react/no-unstable-nested-components */
import React, { useState } from 'react';
import { ErrorBoundary as ReactErrorBoundary } from 'react-error-boundary';
import { useLocation } from 'react-router-dom';
import ErrorPage from '../pages/ErrorPage';

interface IProps {
  children: JSX.Element;
}

function ErrorBoundary(props: IProps) {
  const location = useLocation();

  const [errorInfo, setErrorInfo] = useState(null);

  const errorHandler = (_error: any, info: any) => {
    setErrorInfo(info);
  };

  return (
    <ReactErrorBoundary
      FallbackComponent={(error) => (
        <ErrorPage error={error.error} errorInfo={errorInfo} />
      )}
      resetKeys={[location.pathname]}
      onError={errorHandler}
    >
      {props.children}
    </ReactErrorBoundary>
  );
}

export default ErrorBoundary;
