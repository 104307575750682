import React from 'react';
import { showPopup } from '@/stores/slices/appPopup';
import { useAppDispatch } from '../stores/hooks';
import AppConstants from '../utils/AppConstants';
import './AnswerCSVViewer.less';

interface IProps {
  title: string;
  data: string;
  onEdit?: (data: string) => Promise<unknown>;
}

export default function AnswerCSVViewer(props: IProps) {
  const { title, data, onEdit } = props;

  const dispatch = useAppDispatch();

  const show = () => {
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.ANSWER_CSV,
        popupParams: {
          data,
          title,
          onEdit
        }
      })
    );
  };

  return (
    <div className="answer-csv-viewer">
      <span onClick={show} className="view-link">
        {title}
      </span>
    </div>
  );
}
