/* eslint-disable max-len */
//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// VerticalEllipsesIcon is a custom icon for show 3 dots vertically
//

// 3rd part libraries
import React from 'react';

// Component implementation
function VerticalEllipsesIcon() {
  return (
    <svg viewBox="0 0 16 16">
      <path d="M9.5 13a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0zm0-5a1.5 1.5 0 1 1-3 0 1.5 1.5 0 0 1 3 0z" />
    </svg>
  );
}

export default VerticalEllipsesIcon;
