/* eslint-disable no-underscore-dangle */
/* eslint-disable react/destructuring-assignment */
//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// RunStep is a component that is used to display/manage an individual Run item
//

// 3rd party libraries
import React, { useEffect, useMemo, useState } from 'react';
import { Collapse, Space, Tooltip, message } from 'antd';
import {
  CaretDownOutlined,
  CaretRightOutlined,
  EditOutlined,
  InfoCircleFilled
} from '@ant-design/icons';
import InlineFacts from '@components/facts/InlineFacts';
import AppUtil from '@utils/AppUtil';
import './RunStepV2.less';
import { useDispatch, useSelector } from 'react-redux';
import { runSelector } from '@/stores/slices/run';
import { settingsSelector } from '@/stores/slices/settings';
import classnames from 'classnames';
import FormattingUtil from '@/utils/FormattingUtil';
import MarkdownRenderer from '@/components/MarkdownRenderer';
import { showPopup } from '@/stores/slices/appPopup';
import AppConstants from '@/utils/AppConstants';
import RunStepRequests from './RunStepRequests';
import StrikeThroughIcon from '../../../components/icons/StrikethroughIcon';
import { createStepId, stepsQuerySelector } from '../../../stores/slices/steps';
import { Context, Step } from '../../../generated/API';
import RunItemDetailsV2 from './RunItemDetailsV2';
import { useRunCtxV2 } from '../../../provider/runv2';
import { IRunStepItem } from './RunContextV3';
import RunStepInfo from './RunStepInfo';
import { isRequestHandled } from '../../../utils/request';
import usePlaygroundServiceCommands from '../../../hooks/usePlaygroundServiceCommands';
import InlineInfiniteLoader from '../../../components/InlineInfiniteLoader';
import RunStepQuestions from './RunStepQuestions';
import ShortAnswer from './ShortAnswer';
import LongAnswer from './LongAnswer';

interface IRunStepProps {
  nodeId: string;
  item: IRunStepItem;
  context: Context | null;
  handleStepEditAction: (step: Step) => void;
}

// Component implementation
function RunStepV2(props: IRunStepProps) {
  const { nodeId, item, context: _context, handleStepEditAction } = props;

  const step = item.step!;

  const {
    worker,
    stepRequestsMap,
    stepQuestionsMap,
    expandedNodes,
    highlightedNodes,
    updateExpandedNodes,
    showLongAnswerForNode,
    openFactsForNode,
    showFactsForNode
  } = useRunCtxV2();

  const { showAnswer, debugMode } = useSelector(settingsSelector);
  const { contextMap } = useSelector(stepsQuerySelector);
  const { selectedStep } = useSelector(runSelector);
  const { scratchStep } = usePlaygroundServiceCommands();

  const [scratched, setScratched] = useState(!!item.step?.scratchedAt);
  const [showActions, toggleActions] = useState(false);
  const isExpanded = expandedNodes.includes(nodeId);

  const isExpandable = (step?.childContextIdList ?? [])?.length > 0;

  const contextMapKey = createStepId(step);
  const childContext = contextMap[contextMapKey] || null;

  const stepQuestions = stepQuestionsMap[nodeId] || [];
  const stepRequests = stepRequestsMap[nodeId] || [];
  const noOfStepRequests = stepQuestions.length + stepRequests.length;

  // TODO: Uncomment once KOG-2618 is resolved
  // const stepRequests = (step.requestIds || [])
  //   .map((id) => requests.find((r) => r.id === id))
  //   .filter(Boolean) as Request[];
  // const noOfStepRequests = stepRequests.length;

  // useEffect(() => {
  //   const requestIds = step.requestIds || [];
  //   requestIds.forEach((requestId) => {
  //     if (requestId) {
  //       dispatch(
  //         getRequest({
  //           id: requestId
  //         })
  //       );
  //     }
  //   });
  // }, [step]);

  useEffect(() => {
    if (showFactsForNode?.nodeId === nodeId) {
      openFactsForNode({
        nodeId,
        step
      });
    }
  }, [step.concepts, showFactsForNode?.nodeId]);

  const stepClick = (e: React.SyntheticEvent) => {
    e.stopPropagation();
    if (scratched) {
      updateExpandedNodes(
        expandedNodes.filter((id) => id !== nodeId),
        true
      );
      return;
    }

    if (isExpanded) {
      updateExpandedNodes(
        expandedNodes.filter((id) => id !== nodeId),
        true
      );
    } else {
      updateExpandedNodes([nodeId]);
    }
  };

  const text = useMemo(() => {
    let stepText = '';
    [stepText] = AppUtil.safeParseJSON(step.text);
    return stepText;
  }, [step.text]);

  const showEditableAnswer = showAnswer && !!step.answer;

  const highlighted =
    (highlightedNodes.includes(nodeId) ||
      `${selectedStep?.contextId}:${selectedStep?.id}` === nodeId) &&
    expandedNodes.includes(nodeId);

  const hasAnswers =
    !!FormattingUtil.parseBrainValue(step.answer || '') &&
    step.concepts !== '[]';

  const pendingRequests = stepRequests.filter(
    (request) => !isRequestHandled(request)
  );
  const pendingQuestions = stepQuestions.filter(
    (question) => !question.isAnswered
  );

  const hasExceptions =
    pendingRequests.length > 0 || pendingQuestions.length > 0;

  const handleEditClick = (e: any) => {
    e.preventDefault();
    e.stopPropagation();
    handleStepEditAction(step);
  };

  const handleScratch = () => {
    setScratched(true);

    const runAsyncCommands = async () => {
      try {
        await scratchStep(step);
      } catch (e: any) {
        console.error('Error while scratching step', e);
        message.error(e.message || 'Error while scratching step');
      }
    };

    runAsyncCommands();
  };

  // Just for collapse animation
  const [activeKey, setActiveKey] = useState('');

  useEffect(() => {
    let timeout: any = null;
    if (hasExceptions) {
      timeout = setTimeout(() => setActiveKey(nodeId), 500);
    }
    return () => timeout && clearTimeout(timeout);
  }, [hasExceptions]);

  const hasFacts = step.concepts && step.concepts !== '[]';

  const dispatch = useDispatch();

  const actions = scratched ? null : (
    <div className="step-actions">
      <span onClick={handleEditClick}>
        <EditOutlined />
      </span>
      <span
        data-cy={`scratch-${nodeId}`}
        onClick={(e) => {
          e.preventDefault();
          e.stopPropagation();
          dispatch(
            showPopup({
              popupId: AppConstants.POPUPS.SCRATCH_STEP,
              popupParams: {
                onClick: () => handleScratch()
              }
            })
          );
        }}
      >
        <StrikeThroughIcon style={{ height: '14px', width: '14px' }} />
      </span>
      {hasFacts && (
        <Tooltip title="view all facts">
          <span
            onClick={(e) => {
              e.stopPropagation();
              openFactsForNode({ nodeId, step });
            }}
          >
            <InfoCircleFilled
              data-cy={`facts-trigger-icon-${nodeId}`}
              style={{ height: '14px', width: '14px' }}
            />
          </span>
        </Tooltip>
      )}
    </div>
  );

  const renderOpenCloseToggle = () => {
    let icon = <div style={{ width: '12px', height: '22px' }} />;
    if (isExpandable) {
      icon = isExpanded ? <CaretDownOutlined /> : <CaretRightOutlined />;
    }
    return <div className="open-close-toggle">{icon}</div>;
  };

  const textArr =
    AppUtil.safeParseJSON(step.answer) || step.childContextIdList?.length! > 0
      ? [text.split('\n')[0]]
      : text.split('\n');

  const stepDiv = (
    <div
      className={classnames([
        'top',
        { 'step-facts-highlight': showFactsForNode?.nodeId === nodeId }
      ])}
      onClick={stepClick}
    >
      <div
        className="left"
        style={{
          width: `calc(100% - ${noOfStepRequests * 22}px)`
        }}
      >
        <Space>{renderOpenCloseToggle()}</Space>
        <div className="step-text">
          {textArr.map((i) => {
            const whiteSpaces = FormattingUtil.getLeadingWhiteSpaceCount(i);
            return (
              <span
                className={classnames([
                  'step-pre-spaces',
                  { 'my-4': textArr.length > 1 }
                ])}
              >
                <MarkdownRenderer>
                  {Array(whiteSpaces).fill('&nbsp;').join('')}
                </MarkdownRenderer>
                <InlineFacts
                  knowledgeId={worker?.knowledgeId!}
                  text={i.trim()}
                  concepts={FormattingUtil.parseBrainValue(step.concepts || '')}
                />
              </span>
            );
          })}
        </div>
        {showEditableAnswer && (
          <div className="step-answer">
            <ShortAnswer step={step} />
          </div>
        )}
        {showActions && <div className="step-actions-container">{actions}</div>}
      </div>
      <div className="right">
        <RunStepQuestions questions={stepQuestions} worker={worker} />
        <RunStepRequests requests={stepRequests} worker={worker} />
      </div>
    </div>
  );

  const shouldShowAccordion = hasExceptions && !scratched;

  return (
    <InlineInfiniteLoader active={scratched}>
      <div
        className={classnames(
          `run-item type-${step.__typename?.toLowerCase()}`,
          {
            debug: debugMode,
            scratched,
            highlighted,
            'accordion-shown': shouldShowAccordion
          }
        )}
        data-cy={`step-id-${nodeId}`}
        id={`run-item-${nodeId}`}
        onMouseEnter={() => toggleActions(true)}
        onMouseLeave={() => toggleActions(false)}
      >
        {shouldShowAccordion ? (
          <Collapse
            activeKey={hasExceptions ? [activeKey] : ''}
            className={classnames('step-collapse', {
              'transparent-collapse': hasAnswers
            })}
            accordion
            collapsible="header"
            defaultActiveKey={hasExceptions ? [nodeId] : []}
          >
            <Collapse.Panel showArrow={false} header={stepDiv} key={nodeId}>
              <div data-cy={`run-step-info-${nodeId}`}>
                <RunStepInfo
                  step={step}
                  stepRequests={stepRequests}
                  stepQuestions={stepQuestions}
                />
              </div>
            </Collapse.Panel>
          </Collapse>
        ) : (
          <span style={{}}>{stepDiv}</span>
        )}

        {showLongAnswerForNode === nodeId && <LongAnswer step={step} />}

        {isExpanded ? (
          <RunItemDetailsV2 context={childContext} step={step} />
        ) : null}
      </div>
    </InlineInfiniteLoader>
  );
}

export default RunStepV2;
