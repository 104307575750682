import {
  LeftOutlined,
  LoadingOutlined,
  RightOutlined
} from '@ant-design/icons';
import { Select, Space } from 'antd';
import React, { useEffect, useRef, useState } from 'react';
import { Document, Page, pdfjs } from 'react-pdf';

// Component CSS
import './PDFViewer.less';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

enum PDFViewType {
  ONE = 'ONE',
  ALL = 'ALL'
}

export interface IPDFViewerHighlightProps {
  width: number;
  height: number;
  x: number;
  y: number;
}

export interface IPDFViewerProps {
  pdfUrl: string;
  pageWidth?: number;
  pageNumbers?: number[];
  highlight?: IPDFViewerHighlightProps;
  allowViewTypeChange?: boolean;
}

function PDFViewer(props: IPDFViewerProps) {
  const {
    pdfUrl,
    pageWidth,
    pageNumbers: _pageNumbers,
    allowViewTypeChange = true,
    highlight
  } = props;

  const [pageNumbers, setPageNumbers] = useState(_pageNumbers || []);
  const [currentPageNumber, setCurrentPageNumber] = useState(1);

  useEffect(() => {
    setPageNumbers(_pageNumbers || []);
  }, [_pageNumbers]);

  const [pageRectMap, setPageRectMap] = useState<Record<number, DOMRect>>({});
  const [viewType, setViewType] = useState<PDFViewType>(PDFViewType.ALL);

  const pageCanvasRef = useRef<HTMLCanvasElement>(null);
  const highlightElementRef = useRef<HTMLDivElement>(null);

  const noOfPages = pageNumbers.length;

  useEffect(() => {
    if (noOfPages > 10) {
      setViewType(PDFViewType.ONE);
    }
  }, [noOfPages]);

  const showPagination = viewType === PDFViewType.ONE;

  const scrollToHighlighted = () => {
    if (highlightElementRef.current) {
      const offset = 150; // sticky document info header's height + some gap
      document.querySelector('.s3-file-viewer-popup.ant-modal')?.scrollTo({
        top: highlightElementRef.current!.getBoundingClientRect().top - offset
      });
    }
  };

  const onDocumentLoadSuccess = ({
    numPages: _numPages
  }: {
    numPages: number;
  }) => {
    if (noOfPages === 0) {
      setPageNumbers(Array.from(Array(_numPages + 1).keys()).slice(1));
    }
  };

  const changePage = (offset: number) => {
    setCurrentPageNumber((prevPageNumber) => prevPageNumber + offset);
  };

  const handleNextClick = () => {
    if (currentPageNumber >= noOfPages) {
      return;
    }
    changePage(1);
  };

  const handlePreviousClick = () => {
    if (currentPageNumber <= 1) {
      return;
    }
    changePage(-1);
  };

  const updatePageRect = (pageNumber: number) => {
    if (pageCanvasRef.current) {
      const updatedPageRectMap = {
        ...pageRectMap,
        [pageNumber]: pageCanvasRef.current.getBoundingClientRect()
      };
      setPageRectMap(updatedPageRectMap);
      scrollToHighlighted();
    }
  };

  const isLocationFullPage = (location: IPDFViewerHighlightProps): boolean => {
    const fullWidth = location.width === Math.abs(100);
    const fullHeight = location.height === Math.abs(100);
    return fullHeight && fullWidth;
  };

  const renderHighlight = (pageNumber: number) => {
    if (!highlight) {
      return null;
    }

    if (isLocationFullPage(highlight)) {
      return null;
    }

    const pageRect = pageRectMap[pageNumber];

    if (!pageRect) {
      return null;
    }

    let width = 0;
    let height = 0;

    if (pageRect) {
      width = pageRect.width;
      height = pageRect.height;
    }

    const rect = {
      x: (highlight.x / 100.0) * width,
      y: (highlight.y / 100.0) * height,
      width: (highlight.width / 100.0) * width,
      height: (highlight.height / 100.0) * height
    };

    return (
      <div
        className="pdf-highlight"
        // Scroll to the first highlighted location
        ref={pageNumber === 1 ? highlightElementRef : null}
        style={{
          width: rect.width,
          height: rect.height,
          top: rect.y,
          left: rect.x
        }}
      />
    );
  };

  const renderLoader = () => (
    <div className="loader-cnt pdf-loader">
      <LoadingOutlined />
    </div>
  );

  const renderPage = (pageNumber: number) => (
    <div className="page-wrapper">
      <Page
        key={`page_${pageNumber}`}
        pageNumber={pageNumber}
        width={pageWidth}
        className="pdf-page"
        canvasRef={pageCanvasRef}
        onRenderSuccess={() => updatePageRect(pageNumber)}
        loading={renderLoader()}
      />
      {renderHighlight(pageNumber)}
    </div>
  );

  const pageRenderDecider = () => {
    if (!showPagination) {
      return pageNumbers.map((pageNumber) => renderPage(pageNumber));
    }
    return renderPage(currentPageNumber);
  };

  return (
    <div className="pdf-viewer" data-cy="pdf-viewer">
      {allowViewTypeChange && noOfPages > 0 && (
        <Space className="pdf-pagination-info">
          <Select
            style={{
              width: 210
            }}
            value={viewType}
            onChange={setViewType}
            options={[
              {
                value: PDFViewType.ALL,
                label: 'Show all pages at once'
              },
              {
                value: PDFViewType.ONE,
                label: 'Show one page at a time'
              }
            ]}
          />

          {showPagination && (
            <>
              <LeftOutlined
                disabled={currentPageNumber <= 1}
                onClick={handlePreviousClick}
                className="pdf-pagination-previous"
              />
              <div>
                {currentPageNumber} / {noOfPages}
              </div>
              <RightOutlined
                disabled={currentPageNumber >= noOfPages}
                onClick={handleNextClick}
                className="pdf-pagination-next"
              />
            </>
          )}
        </Space>
      )}

      <Document
        className="pdf-viewer-document"
        file={pdfUrl}
        onLoadSuccess={onDocumentLoadSuccess}
        loading={renderLoader()}
        options={{
          standardFontDataUrl: `https://unpkg.com/pdfjs-dist@${pdfjs.version}/standard_fonts`
        }}
      >
        {pageRenderDecider()}
      </Document>
    </div>
  );
}

export default PDFViewer;
