//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// ImageRegions is a component wraps an image with Regions overlayed
//

// 3rd party libraries
import React from 'react';

// Local imports
import RegionSelect from '@components/imgRegions/RegionSelect';
import { IBoundingBox } from '@details/knowledge/KnowledgeInterfaces';

// Component CSS
import './ImageRegions.less';
import AppConstants from '@utils/AppConstants';
import { Empty } from 'antd';
import PDFViewer from './PDFViewer';

interface ContextRegion extends IBoundingBox {
  data: any;
}

const regionStylePrimary = {
  border: 'none',
  opacity: 0.5,
  background: AppConstants.COLORS.SELECTION_COLOR
};

const regionStylePrimaryReadonly = {
  border: 'none',
  opacity: 0.5,
  background: AppConstants.COLORS.SELECTION_COLOR
};

const regionStyleSecondary = {
  border: 'none',
  opacity: 0.25,
  background: AppConstants.COLORS.SELECTION_COLOR,
  filter: 'invert(0.75)'
};

const regionStyleReadonlySecondary = {
  border: 'none',
  opacity: 0.25,
  background: AppConstants.COLORS.SELECTION_COLOR,
  filter: 'invert(0.75)'
};

// Local converter from bbox to context region
const bBox2contextRegion = (
  bbox: IBoundingBox,
  index: number
): ContextRegion | {} => {
  if (bbox) {
    const ret: ContextRegion = {
      ...bbox,
      data: { index }
    };
    return ret;
  }
  return {};
};

// Local converter from context region to bbox
const contextRegion2BBox = (
  contextRegion: ContextRegion
): IBoundingBox | {} => {
  if (contextRegion) {
    const ret: IBoundingBox = {
      x: contextRegion.x,
      y: contextRegion.y,
      width: contextRegion.width,
      height: contextRegion.height
    };
    return ret;
  }
  return {};
};

// Component Prop Types
interface IProps {
  /**
   * URL to the image
   */
  imgURL: string;
  mimeType: string;
  /**
   * BoundingBoxes for display
   */
  boundingBoxes?: IBoundingBox[];
  /**
   * Is this a readonly view
   */
  readonly?: boolean;
  /**
   * On Change callback. Required if not readonly
   */
  onChange?: (bboxes: IBoundingBox[], isChanging: boolean) => void;
}

// Component implementation
function ImageRegions({
  imgURL,
  mimeType,
  boundingBoxes = [],
  readonly = true,
  onChange = () => undefined
}: IProps) {
  // Convert RegionSelect regions to generic bounding boxes that UI keeps track of
  const onRegionChange = (regions: any) => {
    if (!readonly) {
      // When stopped changing, and region is too small -- don't save it
      if (
        !regions[0].isChanging &&
        (regions[0].width < 1 || regions[0].height < 1)
      ) {
        onChange([], false);
        return;
      }
      const bBoxes = regions.map((r: any) => contextRegion2BBox(r));
      onChange(bBoxes, regions[0].isChanging);
    }
  };

  // Convert bbox to regions that are expected by the RegionSelect component
  const regions = boundingBoxes.map((b, idx) => bBox2contextRegion(b, idx));

  const renderDocument = () => {
    if (mimeType === 'application/pdf') {
      return <PDFViewer pdfUrl={imgURL} />;
    }
    if (mimeType.startsWith('image/')) {
      return <img src={imgURL} alt="" />;
    }
    return <Empty />;
  };

  // In readonly mode, the parent div will reject all pointer events, and we hide the
  // resize handles
  return (
    <div className={readonly ? 'image-regions readonly' : 'image-regions'}>
      <RegionSelect
        maxRegions={readonly ? regions.length : 1}
        regions={regions}
        regionStyle={readonly ? regionStylePrimaryReadonly : regionStylePrimary}
        regionStyleSecondary={
          readonly ? regionStyleReadonlySecondary : regionStyleSecondary
        }
        onChange={onRegionChange}
      >
        {renderDocument()}
      </RegionSelect>
    </div>
  );
}

export default ImageRegions;
