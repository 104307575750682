import icons from './icons';

export default [
  {
    title: 'Salesforce Time Machine - Google',
    url: 'salesforce_time_machine', // file name without .json
    icons: [icons.salesforce, icons.google_cloud],
    requirements: [
      '1. Salesforce password, username, instance url and security token.',
      '2. The Google Cloud Storage credentials dictionary. This allows the Kognitos platform to read/write into the google storage bucket.',
      '3. The Google cloud Storage path where the files need to be written. It is a path in a google object storage bucket.'
    ],
    procedure: `
    connect to salesforce
    get salesforce's opportunity table as a csv
    dump the csv to google cloud storage
      the csv path is "opportunity/files/Salesforce-opportunity-{today's date}.csv"
    get salesforce's account table as a csv
    dump the csv to google cloud storage
      the csv path is "account/files/Salesforce-account-{today's date}.csv"
    `,
    description:
      'This is an example for the collection and visualization of important sales metrics over time. The solution is scheduled to pull daily reports (of the table(s) of choice) from Salesforce, note the time and date of the report, upload it to google cloud storage as CSV files. These CSV files are then processed to create a historical trend file that is later ingested into Google BigQuery, the fast analytics platform. Using this, the user is able to create various visualizations in Google Data Studio.'
  },
  {
    title: 'Salesforce Time Machine - AWS',
    url: 'salesforce_time_machine_aws', // file name without .json
    icons: [icons.salesforce, icons.aws_s3],
    requirements: [
      '1. Salesforce password, username, instance url and security token.',
      '2. The AWS Credentials CSV file. This allows the Kognitos platform to read/write into the aws S3 bucket.',
      '3. The AWS S3 path where the files need to be written. It is a path in an AWS S3 bucket.'
    ],
    description:
      'This is an example for the collection and visualization of important sales metrics over time. The solution is scheduled to pull daily reports (of the table(s) of choice) from Salesforce, note the time and date of the report, upload it to aws s3 as CSV files. These CSV files are then processed to create a historical trend file that is later ingested into Google BigQuery, the fast analytics platform. Using this, the user is able to create various visualizations in Google Data Studio.',
    procedure: `
    connect to salesforce
    get salesforce's opportunity table as a csv
    dump the csv to aws s3
      the csv path is "opportunity/files/Salesforce-opportunity-{today's date}.csv"
    get salesforce's account table as a csv
    dump the csv to aws s3
      the csv path is "account/files/Salesforce-account-{today's date}.csv"
    `
  },
  {
    title: 'HubSpot Time Machine - Google',
    url: 'hubspot_time_machine', // file name without .json
    icons: [icons.hubspot, icons.google_cloud],
    requirements: [
      '1. Hubspot access token.',
      '2. The Google Cloud Storage credentials dictionary. This allows the Kognitos platform to read/write into the google storage bucket.',
      '3. The Google cloud Storage path where the files need to be written. It is a path in a google object storage bucket.'
    ],
    procedure: `
    connect to hubspot
    get hubspot's deals as a csv
    dump the csv to google cloud storage
      the csv path is "deals/files/Hubspot-deals-{today's date}.csv"
    `,
    description:
      'This is an example for the collection and visualization of important sales metrics over time. The solution is scheduled to pull daily reports (of the table(s) of choice) from HubSpot, note the time and date of the report, upload it to google cloud storage as CSV files. These CSV files are then processed to create a historical trend file that is later ingested into Google BigQuery, the fast analytics platform. Using this, the user is able to create various visualizations in Google Data Studio.'
  },
  {
    title: 'HubSpot Time Machine - AWS',
    url: 'hubspot_time_machine_aws', // file name without .json
    icons: [icons.hubspot, icons.aws_s3],
    requirements: [
      '1. Hubspot access token.',
      '2. The AWS Credentials CSV file. This allows the Kognitos platform to read/write into the aws S3 bucket.',
      '3. The AWS S3 path where the files need to be written. It is a path in an AWS S3 bucket.'
    ],
    procedure: `
    connect to hubspot
    get hubspot's deals as a csv
    dump the csv to aws s3
      the csv path is "deals/files/Hubspot-deals-{today's date}.csv"
    `,
    description:
      'This is an example for the collection and visualization of important sales metrics over time. The solution is scheduled to pull daily reports (of the table(s) of choice) from HubSpot, note the time and date of the report, upload it to aws s3 as CSV files. These CSV files are then processed to create a historical trend file that is later ingested into Google BigQuery, the fast analytics platform. Using this, the user is able to create various visualizations in Google Data Studio.'
  },
  // {
  //   title: 'Document processing and cross-checking',
  //   url: 'document_processing_with_cross_check',
  //   icons: [icons.document_processing],
  //   requirements: [
  //     '1. Powerbi `tenant id`, `workspace id`, `secret`, `client id` and `dataset id`.\n',
  //     '2. Microsoft teams channel `email id` and `phone number`.\n'
  //   ],
  //   procedure: `
  //     the file is the first attachment out of the attachments,
  //     read the file as an invoice,
  //     obtain the total of the invoice,
  //     obtain the purchase order of the invoice
  //     obtain the value from powerbi where
  //         the po is the purchase order
  //     if the value is the total then,
  //         the result is "MATCH",
  //     else,
  //         the result is "MISMATCH",
  //     the message is "[{the result}] PO: {the purchase order}, Invoice Total: \${the total}, PowerBI Total: \${the value}"
  //     send the message to team's channel where
  //         the attachment is the file,
  //     the output PO is the purchase order,
  //     the output Total is the total,
  //     the output PowerbiTotal is the value,
  //     the output Result is the result'
  //   `,
  //   description:
  //     'Use this solution to reduce the manual work of cross-checking invoice acknowledgement details with details on file. The procedure is built to run whenever a invoice acknowlegment is received. The solution extracts a Purchased Order and total amount from both the invoice PDF and the PowerBI database and then compares to report if the amounts matched or not. On a mismatch, the results are sent to a Microsoft Teams channel with the invoice as attachment.'
  // },
  {
    title: 'Processing Bills of Lading',
    url: 'bol_processing_multiformat',
    icons: [icons.document_processing],
    procedure: `
      if the partner is "CANTOR" then
          get the document's first "SHIPMENT #" as the s trace number
          get the document's "BILL OF LADING NO" as the t trace numbers
          create a row as follows
            the row's BOL_ID is the document identifier
            the row's TRACE_ID is the s trace number
            the row's TRACE_TYPE is "S"
            the row's PARTNER is the partner
            the row's SERIAL is "-"
          create a row of each t trace number as follows
            the row's BOL_ID is the document identifier
            the row's TRACE_ID is the t trace number
            the row's TRACE_TYPE is "T"
            the row's PARTNER is the partner
            the row's SERIAL is "-"

      if the partner is "CAROB" then
          get the document's first "B of L NO." as the b trace number
          get the document's "TRAILER NO." as the t trace numbers
          create a row as follows
            the row's BOL_ID is the document identifier
            the row's TRACE_ID is the b trace number
            the row's TRACE_TYPE is "B"
            the row's PARTNER is the partner
            the row's SERIAL is the document's "DOC ID"
          create a row of each t trace number as follows
            the row's BOL_ID is the document identifier
            the row's TRACE_ID is the t trace number
            the row's TRACE_TYPE is "T"
            the row's PARTNER is the partner
            the row's SERIAL is "-"

      get all of the above rows
      the output rows are the rows
      the output rows
    `,
    description: `
This example shows how to process bills of lading; either individually or via email.
Each bill of lading is handled depending on its type, and the extracted data is finally
collected and shown in an output table. In addition, this example demonstrates Kognitos'
built-in exception handling.

Once the example is learned, try sending one or more of the following samples to the procedure \`process attachments\` via email:
* [Bill 1](https://kognitos-static-assets.s3.us-west-2.amazonaws.com/examples/CANTOR_1337.pdf)
* [Bill 2](https://kognitos-static-assets.s3.us-west-2.amazonaws.com/examples/CANTOR_1338.pdf)
* [Bill 3 (shows exception handling)](https://kognitos-static-assets.s3.us-west-2.amazonaws.com/examples/CAROB_650.pdf)
`
  },
  {
    title: 'Invoice Processing Example',
    url: 'invoice_processing',
    icons: [icons.document_processing],
    procedure: `
      the file
      analyze the file as an invoice
      get the worksheet at "https://docs.google.com/spreadsheets/d/1Umpgukx2XXmYNr-mWK95Fzf8eWCdYAmtss02FFbt-1s/edit#gid=0"
      add the invoice as a row in the worksheet
      save the worksheet
    `,
    description: `
This is an example that processes an uploaded invoice and puts the required fields in a google sheet.  

Sample invoices to try:

* [Invoice with PO #](https://kognitos-static-assets.s3.us-west-2.amazonaws.com/examples/invoice_with_po.png)
* [Invoice without PO #](https://kognitos-static-assets.s3.us-west-2.amazonaws.com/examples/invoice_without_po.png)
`
  }
];
