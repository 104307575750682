import { MinusCircleOutlined, PlusOutlined } from '@ant-design/icons';
import { Button, Divider, Form, Input, Modal, Space } from 'antd';
import React, { useState } from 'react';
import './CreateFactPopup.less';

export interface ICreateFactPopupProps {
  onClose: () => void;
  title: string;
  defaultValues: {
    name?: string;
    values?: string[];
  };
  onFinish: (name: string, values: string[]) => Promise<any>;
}

function CreateFactPopup(props: ICreateFactPopupProps) {
  const { onClose, defaultValues, title, onFinish } = props;

  const [loading, toggleLoading] = useState(false);

  const handleFinish = (fields: any) => {
    toggleLoading(true);
    return onFinish(fields.name, fields.values).then(() => {
      toggleLoading(false);
      onClose();
    });
  };

  return (
    <Modal
      centered
      open
      onCancel={onClose}
      footer={null}
      keyboard={false}
      maskClosable={false}
      className="create-fact-popup"
      title={title}
    >
      <div className="create-fact-form" data-cy="create-fact-form">
        <Form
          onFinish={handleFinish}
          layout="vertical"
          initialValues={{
            name: defaultValues.name,
            values: ['']
          }}
        >
          <Form.Item
            label="Name"
            name="name"
            rules={[{ required: true, message: 'Invalid fact name' }]}
          >
            <Input
              placeholder="Fact name"
              data-cy="fact-name-input"
              autoComplete="off"
              spellCheck={false}
              autoFocus
              disabled={!!defaultValues.name}
            />
          </Form.Item>

          <Form.List name="values">
            {(fields, { add, remove }, { errors }) => (
              <>
                {fields.map((field, index) => (
                  <Form.Item
                    label={index === 0 ? 'Values' : ''}
                    required
                    key={field.key}
                  >
                    <Form.Item
                      {...field}
                      validateTrigger={['onChange', 'onBlur']}
                      rules={[
                        {
                          required: true,
                          message: 'Please input a value or delete this field.'
                        }
                      ]}
                      noStyle
                    >
                      <Input
                        data-cy={`fact-value-input-${index}`}
                        placeholder="Value"
                        style={{ width: fields.length > 1 ? '93%' : '100%' }}
                      />
                    </Form.Item>
                    {fields.length > 1 ? (
                      <MinusCircleOutlined
                        className="dynamic-delete-button"
                        onClick={() => remove(field.name)}
                      />
                    ) : null}
                  </Form.Item>
                ))}
                <Form.Item>
                  <Button
                    type="dashed"
                    data-cy="add-fact-value"
                    onClick={() => add()}
                    icon={<PlusOutlined />}
                  >
                    Add Value
                  </Button>
                  <Form.ErrorList errors={errors} />
                </Form.Item>
              </>
            )}
          </Form.List>

          <Form.Item className="actions">
            <Divider />
            <Space>
              <Button key="back" onClick={onClose}>
                Cancel
              </Button>
              <Button
                key="submit"
                type="primary"
                loading={loading}
                htmlType="submit"
              >
                Submit
              </Button>
            </Space>
          </Form.Item>
        </Form>
      </div>
    </Modal>
  );
}

export default CreateFactPopup;
