import React, { useEffect, useState } from 'react';
import { Drawer, Space, Tooltip } from 'antd';
import { DeleteTwoTone } from '@ant-design/icons';
import AddFactValue from '@/components/facts/AddFactValue';
import useStepFactEdit from '@/hooks/useStepFactEdit';
import useForceRerender from '@/hooks/useForceRerenderer';
import useFact from '../hooks/useFact';
import Table from '../components/table/Table';
import { ITableColumn, ITableProps } from '../components/table/interface';
import RunFactRenderer from '../components/playground2/RunFactRenderer';
import '../components/playground2/FactsTableV2.less';
import usePaginationState from '../hooks/usePaginationState';
import { Fact, Step, Worker } from '../generated/API';
import AppUtil from '../utils/AppUtil';
import AppConstants from '../utils/AppConstants';
import FormattingUtil from '../utils/FormattingUtil';
import ComponentFactory from '../utils/ComponentFactory';

interface IProps {
  title: string;
  ids: string[];
  listId: string;
  knowledgeId: string;
  onClose: () => void;
  step: Step;
  worker: Worker;
}

interface deletedFlag extends Fact {
  _deleted__?: boolean;
}

export default function RunFactsDrawer(props: IProps) {
  const { title, ids, listId, knowledgeId, onClose, step, worker } = props;

  const {
    deleteFact,
    updateFactWithMultipleValues,
    deleteFactWithMultipleValue,
    updateFact
  } = useStepFactEdit({
    step: step!,
    worker: worker!
  });

  const allowUpdate = true;

  const [tableData, setTableData] = useState<Array<deletedFlag | undefined>>(
    []
  );

  const paginationState = usePaginationState({
    totalCount: ids.length,
    pageSize: 10
  });

  const { refetchDeps } = useForceRerender();

  const { getFactById, loading, getPageData, facts } = useFact({
    ids,
    knowledgeId,
    pagination: {
      pageSize: paginationState.pageSize
    },
    refetchDeps
  });

  useEffect(() => {
    Promise.all(ids.map((factId) => getFactById(factId))).then((data) =>
      setTableData(data.filter((data) => data))
    );
  }, [facts]);

  const shouldFetchPageData = (page: number) => {
    const paginatedData = AppUtil.getPaginatedList(
      tableData,
      page,
      paginationState.pageSize
    );
    return paginatedData.every((data) => data === undefined);
  };

  const updateValue = (
    value: string | null /* null means to be deleted */,
    factId: string,
    index: number,
    optListFactId: string | null,
    onSuccess?: () => any
  ) => {
    if (!value) {
      if (!optListFactId) {
        deleteFact(factId).then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
      } else {
        deleteFactWithMultipleValue(optListFactId, index).then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
      }
    } else {
      if (!optListFactId) {
        updateFact(factId, value).then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
      } else {
        updateFactWithMultipleValues(optListFactId, index, value).then(() => {
          if (onSuccess) {
            onSuccess();
          }
        });
      }
    }
  };

  const uniqueRowId = 'id';
  const getValueIndex = (valueId: string) => {
    return (
      tableData
        // eslint-disable-next-line no-underscore-dangle
        .filter((data) => data && !data._deleted__)
        .findIndex((data) => data && data[uniqueRowId] === valueId)
    );
  };

  const onRowEditSave: ITableProps['onEditSave'] = (
    rowUniqueId,
    rowValues,
    resetEditable
  ) => {
    updateValue(
      rowValues.value,
      rowUniqueId,
      getValueIndex(rowUniqueId),
      listId,
      () => resetEditable()
    );
  };

  const columns: ITableColumn<Fact | undefined>[] = [
    {
      id: 'value',
      dataKey: 'value',
      title,
      renderColumn: (_value, record) => {
        if (!record) {
          return null;
        }

        return (
          <div data-cy="run-fact-drawer-value">
            <RunFactRenderer fact={record} knowledgeId={knowledgeId} />
          </div>
        );
      },
      edit: !allowUpdate
        ? undefined
        : {
            editRender: (_value, record, editValue, onCellChange) => {
              const editorValue =
                editValue === undefined
                  ? FormattingUtil.parseBrainValue(record?.value!) ??
                    record?.names?.[0]
                  : editValue;

              return ComponentFactory.getInputElement(
                'conceptValue',
                AppConstants.INPUT_TYPES.TEXT_AREA,
                editorValue,
                null,
                (_id: any, newValue: string) => onCellChange(newValue),
                {
                  autoSize: true
                }
              );
            }
          }
    }
  ];

  const renderExtraRowActions: ITableProps['renderExtraRowActions'] = (
    uniqId,
    _record,
    index
  ) => (
    <span onClick={() => updateValue(null, uniqId, index, listId)}>
      <Tooltip title="Delete">
        <DeleteTwoTone />
      </Tooltip>
    </span>
  );

  return (
    <Drawer title={title} placement="right" onClose={onClose} width="50%" open>
      <span data-cy="run-facts-drawer">
        {listId && (
          <Space>
            <AddFactValue factId={listId} step={step!} worker={worker!} />
          </Space>
        )}

        <Table
          uniqueRowId="id"
          data={tableData}
          columns={columns}
          loading={loading && refetchDeps < 1}
          className="facts-table"
          pagination={{
            total: ids.length,
            pageNumber: paginationState.pageNumber,
            pageSize: paginationState.pageSize,
            onChange: (page) => {
              if (shouldFetchPageData(page)) {
                getPageData(page);
              }
              paginationState.updatePageNumber(page);
            }
          }}
          onEditSave={onRowEditSave}
          renderExtraRowActions={
            allowUpdate ? renderExtraRowActions : undefined
          }
        />
      </span>
    </Drawer>
  );
}
