//
// Copyright (C) - Ratif. All rights reserved
//
// TilesLayout is a component that does a tiles layout of its children
//

// 3rd part libraries
import React from 'react';

// Component CSS
import './TilesLayout.less';

interface ITilesLayout {
  children?: React.ReactNode[];
}

// Component implementation
function TilesLayout({ children }: ITilesLayout) {
  if (!children) {
    return null;
  }

  // Render the UI
  return (
    <div className="tiles-layout">
      {children
        .filter((c) => c !== null)
        .map((c) => (
          <div className="tile">{c}</div>
        ))}
    </div>
  );
}

export default TilesLayout;
