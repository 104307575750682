/* eslint-disable max-classes-per-file */
import React from 'react';
import ReactDOM from 'react-dom';
import {
  EditorView,
  Decoration,
  ViewPlugin,
  WidgetType,
  MatchDecorator
} from '@codemirror/view';
import { Extension } from '@codemirror/state';
import { Provider } from 'react-redux';
import MarkdownLink from '../components/MarkdownLink';
import { store } from '../../../stores/AppStore';
import { removeKogLineBreak } from '../helper';

class PlaceholderWidget extends WidgetType {
  label: any;

  constructor(label: any) {
    super();
    this.label = label;
  }

  eq(other: any) {
    return other.label === this.label;
  }

  toDOM() {
    const wrap = document.createElement('span');
    wrap.className = 'cm-markdown-link';
    ReactDOM.render(
      <Provider store={store}>
        <MarkdownLink
          text={removeKogLineBreak(this.label)}
          onSave={(text) => {
            (window as any).handleEditorMarkdownValueChange(this.label, text);
          }}
        />
      </Provider>,
      wrap
    );
    return wrap;
  }
}

const placeholderMatcher = new MatchDecorator({
  regexp: /"""([^"]*)"""/g,
  decoration: (match) =>
    Decoration.replace({
      widget: new PlaceholderWidget(match[1])
    }),
  maxLength: 1e5
});

export const markdownLinkExtension = () =>
  ViewPlugin.fromClass(
    class {
      placeholders: any;

      constructor(view: any) {
        this.placeholders = placeholderMatcher.createDeco(view);
      }

      update(update: any) {
        this.placeholders = placeholderMatcher.updateDeco(
          update,
          this.placeholders
        );
      }
    },
    {
      decorations: (v) => v.placeholders,
      provide: (plugin) =>
        EditorView.atomicRanges.of(
          (view) => view.plugin(plugin)?.placeholders || Decoration.none
        )
    }
  );

export const markdownLink: Extension = [markdownLinkExtension()];
