import { PlusOutlined } from '@ant-design/icons';
import { Button, Form, Input, message } from 'antd';
import React, { useState } from 'react';
import { Step, Worker } from '../../generated/API';
import useStepFactEdit from '../../hooks/useStepFactEdit';

interface IProps {
  factId: string;
  step: Step;
  worker: Worker;
}

function AddFactValue(props: IProps) {
  const { factId, step, worker } = props;

  const [value, setValue] = useState('');
  const [loading, toggleLoading] = useState(false);

  const { insertFactValue } = useStepFactEdit({
    step,
    worker
  });

  const handleFinish = () => {
    toggleLoading(true);
    insertFactValue(factId, 0, value)
      .then(() => {
        message.success(`'${value}' value added`);
        setValue('');
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  return (
    <Form onFinish={handleFinish}>
      <Form.Item>
        <Input.Group compact>
          <Input
            value={value}
            onChange={(e) => setValue(e.target.value)}
            style={{
              width: 400,
              padding: 6
            }}
            placeholder="Value"
          />
          <Button
            type="primary"
            htmlType="submit"
            icon={<PlusOutlined />}
            loading={loading}
            disabled={!value}
          >
            Add value
          </Button>
        </Input.Group>
      </Form.Item>
    </Form>
  );
}

export default AddFactValue;
