import {
  Answer,
  CreateAnswerMutation,
  CreateAnswerMutationVariables,
  CreateAnswersMutation,
  CreateAnswersMutationVariables,
  GetAnswerQuery,
  GetAnswerQueryVariables
} from '@/generated/API';
import Mutations from '@/graphql/Mutations';
import Queries from '@/graphql/Queries';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
// import { getCommands } from "@/stores/slices/steps";
import { FetchResult } from '@apollo/client';
import {
  createAsyncThunk,
  createEntityAdapter,
  createSlice
} from '@reduxjs/toolkit';

export const getAnswer = createAsyncThunk<
  FetchResult<GetAnswerQuery>,
  GetAnswerQueryVariables,
  AsyncThunkConfig
>('answer/fetch', async (variables, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.Answer(),
    variables
  })
);

export const createAnswer = createAsyncThunk<
  FetchResult<CreateAnswerMutation>,
  CreateAnswerMutationVariables,
  AsyncThunkConfig
>('answer/create', async (variables, thunkAPI) =>
  thunkAPI.extra.appSyncClient.mutate({
    mutation: Mutations.CreateAnswer(),
    variables
  })
);

export const createAnswers = createAsyncThunk<
  FetchResult<CreateAnswersMutation>,
  CreateAnswersMutationVariables,
  AsyncThunkConfig
>('answers/create', async (variables, thunkAPI) =>
  thunkAPI.extra.appSyncClient.mutate({
    mutation: Mutations.CreateAnswers(),
    variables
  })
);

const AnswerAdapter = createEntityAdapter<Answer>();

export const answerSlice = createSlice({
  name: 'answer',
  initialState: AnswerAdapter.getInitialState({
    loading: false,
    error: undefined
  }),
  reducers: {},
  extraReducers: (builder) => {
    // builder.addCase(getCommands.fulfilled, (state, action) => {
    //   if (action.payload.data?.listCommandsByWorker?.items) {
    //     const answers = action.payload.data.listCommandsByWorker.items.reduce((acc, cur) => acc.concat((cur as any).answers.items), []);
    //     AnswerAdapter.upsertMany(state, answers);
    //   }
    // });

    builder.addCase(getAnswer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getAnswer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as any;
    });
    builder.addCase(getAnswer.fulfilled, (state, action) => {
      state.loading = false;
      if (action.payload.data?.getAnswer) {
        AnswerAdapter.addOne(state, action.payload.data?.getAnswer as Answer);
      }
    });
    builder.addCase(createAnswer.pending, (state) => {
      state.loading = false;
    });
    builder.addCase(createAnswer.rejected, (state, action) => {
      state.loading = false;
      state.error = action.error as any;
    });
    builder.addCase(createAnswer.fulfilled, (state, action) => {
      if (action.payload.data?.createAnswer) {
        AnswerAdapter.addOne(
          state,
          action.payload.data?.createAnswer as Answer
        );
      }
    });
    builder.addCase(createAnswers.fulfilled, (state, action) => {
      if (action.payload.data?.createAnswers) {
        AnswerAdapter.addMany(
          state,
          action.payload.data?.createAnswers as Answer[]
        );
      }
    });
  }
});

export const answerSelector = AnswerAdapter.getSelectors<RootState>(
  (state) => state.answer
);
export default answerSlice.reducer;
