import React from 'react';
import { Typography } from 'antd';
import './FactSummary.less';
import { CheckCircleFilled } from '@ant-design/icons';
import useFact from '../../hooks/useFact';
import FormattingUtil from '../../utils/FormattingUtil';
import { useAppDispatch } from '../../stores/hooks';
import AppConstants from '../../utils/AppConstants';
import FactDocumentSummaryList from './FactDocumentSummary';
import { setS3PreviewDetails } from '../../stores/slices/run';
import { showPopup } from '../../stores/slices/appPopup';
import AnswerCSVViewer from '../AnswerCSVViewer';

interface IProps {
  factId: string;
  knowledgeId: string;
  ellipsis?: boolean;
}

function FactSummary(props: IProps) {
  const { factId, knowledgeId, ellipsis = true } = props;

  const { getFactById, loading } = useFact({
    ids: [factId],
    knowledgeId
  });
  const dispatch = useAppDispatch();

  const fact = getFactById(factId);

  const parsedValue = FormattingUtil.parseBrainValue(fact?.value || '');
  const firstName = fact?.names?.[0];
  const value = parsedValue ?? firstName;

  if (loading) {
    return <>...</>;
  }

  const answer = FormattingUtil.getFormattedAnswer({
    answer: value,
    knowledgeId
  });

  const renderValue = () => {
    if (firstName === AppConstants.FACT_TABLE_NAME) {
      return <AnswerCSVViewer title="View table" data={value} />;
    }

    return answer.answer;
  };

  return (
    <span className="fact" kog-dbg-fact-id={factId}>
      <div
        role="link"
        tabIndex={0}
        onDoubleClick={(e) => {
          e.stopPropagation();
          dispatch(
            showPopup({
              popupId: AppConstants.POPUPS.VIEW_RAW_FACT,
              popupParams: {
                factId,
                knowledgeId
              }
            })
          );
        }}
      >
        {typeof answer.answer === 'string' &&
        ['done', 'ok'].includes(answer.answer?.toLowerCase()) ? (
          <span data-cy="answer-ok-icon">
            <CheckCircleFilled />
          </span>
        ) : (
          <Typography.Text ellipsis={ellipsis} className="fact-summary-text">
            {renderValue()}
          </Typography.Text>
        )}
      </div>
      <FactDocumentSummaryList
        fact={fact!}
        showInPopup={false}
        knowledgeId={knowledgeId}
        onClick={(s3Parts, pageNumbers, location) => {
          dispatch(
            setS3PreviewDetails({
              title: s3Parts.filename,
              s3Object: {
                bucket: s3Parts.bucket,
                key: s3Parts.key
              },
              pdfConfig: {
                pageNumbers,
                highlight: {
                  x: location.left * 100,
                  y: location.top * 100,
                  height: (location.bottom - location.top) * 100,
                  width: (location.right - location.left) * 100
                },
                allowViewTypeChange: false
              }
            })
          );
        }}
      />
    </span>
  );
}

export default FactSummary;
