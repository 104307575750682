import { useMutation, useQuery } from '@apollo/client';
import { Divider, message, Avatar, Result, Button } from 'antd';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { PlusOutlined } from '@ant-design/icons';
import {
  ITableAction,
  ITableColumn,
  ITableProps,
  ITableRef
} from '../table/interface';
import Table from '../table/Table';
import {
  AccessControlRole,
  Department,
  DepartmentUser,
  ListDepartmentCollaboratorsQuery,
  RemoveUserFromDepartmentMutation
} from '../../generated/API';
import Mutations from '../../graphql/Mutations';
import Queries from '../../graphql/Queries';
import { userSelector } from '../../stores/slices/user';
import AppConstants from '../../utils/AppConstants';
import AppUtil from '../../utils/AppUtil';
import DepartmentCollaboratorAddForm from './DepartmentCollaboratorAddForm';
import { showPopup } from '../../stores/slices/appPopup';

interface IProps {
  department: Department;
  isSupportModeEnabled: boolean;
  source: 'page' | 'popup';
}

function DepartmentCollaborators(props: IProps) {
  const { department, isSupportModeEnabled, source } = props;

  const { data, loading, refetch, error } =
    useQuery<ListDepartmentCollaboratorsQuery>(
      Queries.ListDepartmentCollaborators(),
      {
        variables: {
          departmentId: department.id
        }
      }
    );

  const tableRef = useRef<ITableRef>(null);

  const refetchCollaborators = () => {
    refetch();
    tableRef.current?.reset();
  };

  const [removeCollaborator] = useMutation<RemoveUserFromDepartmentMutation>(
    Mutations.RemoveDepartmentCollaborator()
  );

  const [operationLoading, setOperationLoading] = useState(false);

  const dispatch = useDispatch();

  const { username } = useSelector(userSelector);

  const collaborators = (data?.listDepartmentCollaborators?.items || []).filter(
    (col) => !col?.userId.includes(AppConstants.MONITORING_EMAIL)
  );

  const adminCollaborators = collaborators.filter(
    (collaborator) => collaborator?.role === AccessControlRole.admin
  );

  const getUserRole = (userId: string): AccessControlRole | undefined =>
    collaborators.find((collaborator) => collaborator?.userId === userId)?.role;

  const canEditCollaborators = (userId: string) => {
    if (userId === AppConstants.MONITORING_EMAIL) {
      return true;
    }
    const userRole = getUserRole(userId);
    return userRole && [AccessControlRole.admin].includes(userRole);
  };

  const isUserAdmin = canEditCollaborators(username!);

  const isDeleteDisabled = (selectedRows: DepartmentUser[]) => {
    // disable delete if no rows are selected
    if (selectedRows.length === 0) {
      return true;
    }

    // disable delete if user is not an admin
    if (!isUserAdmin) {
      return true;
    }

    return false;
  };

  const columns: ITableColumn<DepartmentUser>[] = [
    {
      id: 'email',
      title: 'User',
      dataKey: 'userId',
      renderColumn: (value, _record) => (
        <span data-cy={`dep-email-${value}`}>
          <Avatar
            style={{
              marginRight: 8
            }}
          >
            {value?.charAt(0)}
          </Avatar>
          {value} {username === value ? '(You)' : null}
        </span>
      )
    },
    {
      id: 'role',
      title: 'Role',
      dataKey: 'role',
      renderColumn: (_value, record) => {
        const role =
          // @ts-ignore
          AppConstants.ACCESS_CONTROL_ROLE_CONFIG[
            record.role as AccessControlRole
          ];
        return <div data-cy={`dep-role-${record.userId}`}>{role?.label}</div>;
      },
      fillter: {
        options: Object.values(AppConstants.ACCESS_CONTROL_ROLE_CONFIG).map(
          (option) => ({
            text: option.label,
            value: option.value
          })
        ),
        onFilter: (value, record) => record.role === value
      }
    }
  ];

  const actions: ITableAction<DepartmentUser>[] = [
    {
      id: 'remove',
      label: 'Remove',
      isDisabled: (selectedRows) => isDeleteDisabled(selectedRows),
      onClick: (selectedRows) => {
        setOperationLoading(true);

        const promises = selectedRows.map((row) => {
          return removeCollaborator({
            variables: {
              userId: row.userId,
              departmentId: department.id
            }
          });
        });

        Promise.all(promises)
          .then(() => {
            refetchCollaborators();
            message.success('Removed successfully');
          })
          .finally(() => {
            setOperationLoading(false);
          });
      }
    }
  ];

  const renderExtraActions: ITableProps['renderExtraActions'] = () => {
    if (source === 'page' && isUserAdmin) {
      return (
        <Button
          onClick={() => {
            dispatch(
              showPopup({
                popupId: AppConstants.POPUPS.DEPARTMENT_COLLABORATORS_ADD,
                popupParams: {
                  department,
                  onSuccess: () => refetchCollaborators()
                }
              })
            );
          }}
          type="primary"
        >
          <PlusOutlined /> Add User
        </Button>
      );
    }

    return null;
  };

  const renderAddCollaboratorForm = () => {
    if (source === 'popup') {
      if (isUserAdmin || isSupportModeEnabled) {
        return (
          <DepartmentCollaboratorAddForm
            department={department}
            onSuccess={() => refetchCollaborators()}
          />
        );
      }

      return null;
    }

    return null;
  };

  return (
    <div>
      {renderAddCollaboratorForm()}

      {AppUtil.hasPermissionError(error) ? (
        <Result
          status="warning"
          title={
            <div
              style={{
                // TODO: Use utility classes for these
                fontSize: '16px'
              }}
            >
              {`Sorry, you're not authorized to view the collaborators list.`}
            </div>
          }
        />
      ) : (
        <>
          {source === 'popup' && <Divider>People with access</Divider>}

          <Table
            ref={tableRef}
            uniqueRowId="userId"
            actions={actions}
            columns={columns}
            data={collaborators}
            selection={{
              isDisabled: () => adminCollaborators.length === 1
            }}
            loading={loading || operationLoading}
            pagination={{
              total: collaborators.length,
              defaultPageNumber: 1,
              defaultPageSize: source === 'page' ? 20 : 5
            }}
            className="department-collaborators-table"
            renderExtraActions={renderExtraActions}
          />
        </>
      )}
    </div>
  );
}

export default DepartmentCollaborators;
