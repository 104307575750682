import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  ListQuestionsByProcedureQuery,
  ListQuestionsByProcedureQueryVariables,
  Question
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';

export interface GetProcedureExceptionsParams
  extends ListQuestionsByProcedureQueryVariables {
  page?: number;
}

export const getProcedureExceptions = createAsyncThunk<
  FetchResult<ListQuestionsByProcedureQuery>,
  GetProcedureExceptionsParams,
  AsyncThunkConfig
>(
  'procedureExceptions/fetchAll',
  async ({ limit, nextToken, procedureId, isAnsweredFilter }, thunkAPI) =>
    thunkAPI.extra.appSyncClient.query({
      query: Queries.QuestionsByProcedure(),
      variables: {
        procedureId,
        limit,
        nextToken,
        isAnsweredFilter
      }
    })
);

const ProcedureExceptionsAdapter = createEntityAdapter<Question>({
  selectId: (exception) => exception.id!
});

interface IState {
  loading: boolean;
  error: any;
  nextToken: string | undefined;
  pageDataMap: Record<number, Question[]>;
}

export const procedureExceptionsSlice = createSlice({
  name: 'procedureExceptions',
  initialState: ProcedureExceptionsAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    pageDataMap: {}
  }),
  reducers: {
    updateExceptions: ProcedureExceptionsAdapter.updateMany,
    removeExceptions: ProcedureExceptionsAdapter.removeMany
  },
  extraReducers: (builder) => {
    builder.addCase(getProcedureExceptions.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getProcedureExceptions.fulfilled,
      (state, { payload, meta }) => {
        if (payload?.data?.listQuestionsByProcedure) {
          const { items = [], nextToken } =
            payload.data.listQuestionsByProcedure;

          const exceptions = items as Question[];

          if (!meta.arg.nextToken) {
            ProcedureExceptionsAdapter.setAll(state, exceptions);
          } else {
            ProcedureExceptionsAdapter.addMany(state, exceptions);
          }

          if (meta.arg.page && exceptions.length > 0) {
            state.pageDataMap[meta.arg.page] = exceptions;
          }

          state.nextToken = nextToken || undefined;
        }
        state.loading = false;
      }
    );
    builder.addCase(getProcedureExceptions.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.loading = false;
    });
  }
});

export const { updateExceptions, removeExceptions } =
  procedureExceptionsSlice.actions;

export const procedureExceptionsSelector =
  ProcedureExceptionsAdapter.getSelectors<RootState>(
    (state) => state.procedureExceptions
  );

export const procedureExceptionsQuerySelector = (state: RootState) => ({
  loading: state.procedureExceptions.loading,
  exceptions: procedureExceptionsSelector.selectAll(state),
  error: state.procedureExceptions.error,
  nextToken: state.procedureExceptions.nextToken,
  pageDataMap: state.procedureExceptions.pageDataMap
});

export default procedureExceptionsSlice.reducer;
