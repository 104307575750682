//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Main Entry point for the Kognitos UI
//
import React from 'react';
import ReactDOM from 'react-dom';
import { notification } from 'antd';
import LogRocket from 'logrocket';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import awsmobile from '@/generated/aws-exports';
import {
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  matchRoutes
} from 'react-router-dom';

import App from './App';

// Application CSS
import '@css/index.less';
import ENV_CONFIG from './EnvConstants';

// Global notification configuration
notification.config({
  placement: 'topRight',
  duration: 5,
  maxCount: 1
});

if (!ENV_CONFIG.local) {
  Sentry.init({
    environment: awsmobile.environment,
    dsn: ENV_CONFIG.integrations.sentry.dsn,
    integrations: [
      new BrowserTracing({
        tracingOrigins: [awsmobile.aws_appsync_graphqlEndpoint],
        routingInstrumentation: Sentry.reactRouterV6Instrumentation(
          React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes
        )
      })
    ],
    beforeSend(event: Sentry.Event) {
      const logRocketSession = LogRocket.sessionURL;
      if (logRocketSession !== null) {
        if (!event.extra) event.extra = {};
        event.extra.LogRocket = logRocketSession;
        return event;
      }
      return event;
    },

    // Set tracesSampleRate to 1.0 to capture 100%
    // of transactions for performance monitoring.
    // We recommend adjusting this value in production
    tracesSampleRate: 1.0
  });
}

if (ENV_CONFIG.integrations.logRocket.enabled && !ENV_CONFIG.local) {
  LogRocket.init(ENV_CONFIG.integrations.logRocket.key);
}

if (process.env.MOCK) {
  // eslint-disable-next-line global-require
  const { worker } = require('./mocks/browser');
  worker.start();
}

ReactDOM.render(<App />, document.getElementById('root'));
