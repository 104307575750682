/* eslint-disable no-underscore-dangle */
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Checkbox,
  Form,
  Input,
  Layout,
  Select,
  Space,
  Spin,
  Tooltip
} from 'antd';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import {
  CreateServiceCommandMutation,
  CreateServiceCommandMutationVariables,
  Department,
  GetLearnedAnswerByRequestAnswerIdQuery,
  GetRequestAnswerQuery,
  NativeCodeErrorQuestion,
  Request,
  RequestAnswer,
  RequestQuestion as RequestQuestionInterface,
  ReviewConceptQuestion,
  ValueNotKnownQuestion,
  Worker
} from '../../generated/API';
import Queries from '../../graphql/Queries';
import useRequestQuestionChoices from '../../hooks/useRequestQuestionChoices';
import { useAppDispatch } from '../../stores/hooks';
import { showPopup } from '../../stores/slices/appPopup';
import AppConstants from '../../utils/AppConstants';
import AppUtil from '../../utils/AppUtil';
import FormattingUtil from '../../utils/FormattingUtil';
import {
  getQuestionInputTypeFromAnswer,
  getQuestionInputValueFromAnswer
} from '../../utils/request';
import AutoSizeInput from '../AutoSizeInput';
import ErrorDescription from '../ErrorDescription';
import InlineFacts from '../facts/InlineFacts';
import MarkdownRenderer from '../MarkdownRenderer';
import S3FileUpload, { IUploadedResponse } from '../Upload';
import { IQuestionValues, QuestionInputType } from './interface';
import './Request.less';

export interface IQuestionConfig {
  answerMode: 'single' | 'batch';
  allowContextDebugging?: boolean;
}

interface IRequestQuestionProps {
  request: Request;
  question: RequestQuestionInterface;
  worker: Worker;
  department?: Department;
  config: IQuestionConfig;
  answerId?: string;
  onValuesChange: (values: IQuestionValues) => void;
}

function RequestQuestion(props: IRequestQuestionProps) {
  const {
    request,
    question,
    worker,
    department,
    config,
    answerId,
    onValuesChange
  } = props;

  const { answerMode: source, allowContextDebugging } = config;

  const type = question.__typename as any;

  const questionType = {
    value: type === 'ValueNotKnownQuestion',
    conceptReview: type === 'ReviewConceptQuestion',
    nativeCodeError: type === 'NativeCodeErrorQuestion'
  };

  const valueNotKnownQuestion = question as unknown as ValueNotKnownQuestion;
  const conceptReviewQuestion = question as unknown as ReviewConceptQuestion;

  const {
    text,
    contextId: questionContextId,
    answerTypeHint
  } = question as unknown as RequestQuestionInterface;

  const processed = FormattingUtil.processQuestionV1(
    text || '',
    (valueNotKnownQuestion || conceptReviewQuestion).lexicalPath || null
  );

  const { data: answerData, loading: answerLoading } =
    useQuery<GetRequestAnswerQuery>(Queries.getRequestAnswer(), {
      variables: {
        id: answerId
      },
      skip: !answerId
    });
  const answer = answerData?.getRequestAnswer;

  const { data: learnedAnswerData } =
    useQuery<GetLearnedAnswerByRequestAnswerIdQuery>(
      Queries.getLearnedAnswerByRequestAnswerId(),
      {
        variables: {
          requestAnswerId: answerId
        },
        skip: !answer
      }
    );

  const disabled = !!answerId;

  const questionChoices = useRequestQuestionChoices({
    question,
    knowledgeId: worker.knowledgeId!
  });

  const choices = questionType.value ? valueNotKnownQuestion.choices || [] : [];

  let defaultValue = getQuestionInputValueFromAnswer(answer as RequestAnswer);
  let defaultInputType = getQuestionInputTypeFromAnswer(
    answer as RequestAnswer
  );

  if (questionType.conceptReview) {
    defaultInputType = QuestionInputType.APPROVE_REVIEW;
  }
  if (choices.length > 0) {
    defaultInputType = QuestionInputType.VALUE_CHOICE;
  }

  if (answer) {
    if (
      defaultInputType === QuestionInputType.VALUE_CHOICE &&
      defaultValue !== undefined
    ) {
      defaultValue = questionChoices.choices[Number(defaultValue)];
    }
  }

  const isAnsweredLearned =
    !!learnedAnswerData?.getLearnedAnswerByRequestAnswerId || false;

  const [inputType, setInputType] =
    useState<QuestionInputType>(defaultInputType);
  const [isSecret, toggleSecret] = useState(processed?.isSensitive || false);

  const dispatch = useAppDispatch();

  useEffect(() => {
    setInputType(defaultInputType);
  }, [defaultInputType]);

  useEffect(() => {
    onValuesChange({
      inputType
    });
  }, []);

  const [createDebugCommand, createDebugCommandProps] =
    useMutation<CreateServiceCommandMutation>(
      AppConstants.APIS.SERVICE_COMMANDS.CREATE()
    );

  const showEnhancedTechnique = allowContextDebugging && !disabled;

  const handleEnhancedTeaching = () => {
    const stepNode = AppUtil.getStepNodeFromQuestion(
      request.contextPath,
      questionContextId
    );

    if (!stepNode) {
      return;
    }

    const [contextId, sentenceId] = stepNode.split(':');

    const params = {
      context_id: Number(contextId),
      sentence_id: Number(sentenceId) - 1,
      let_me_know: true
    };

    const variables: CreateServiceCommandMutationVariables = {
      input: {
        owner: worker?.owner!,
        workerId: worker?.id!,
        method: 'clone_context_at_sentence',
        params: JSON.stringify(params)
      }
    };

    createDebugCommand({
      variables
    })
      .then((resp) => {
        dispatch(
          showPopup({
            popupId: AppConstants.POPUPS.QUESTION_CONTEXT_DEBUGGER,
            popupParams: {
              worker,
              department,
              question,
              commandId: resp.data?.createServiceCommand?.id!
            }
          })
        );
      })
      .catch((e) => {
        console.error('error with createDebugCommand', e, variables);
      });
  };

  const renderNativeCodeError = () => {
    const nativeCodeErrorQuestion =
      question as unknown as NativeCodeErrorQuestion;

    return (
      <ErrorDescription
        errorMessage={nativeCodeErrorQuestion.errorMessage || null}
        rawException={nativeCodeErrorQuestion.errorTraceback || null}
        className="error-description"
      />
    );
  };

  const renderInput = () => {
    if (inputType === QuestionInputType.VALUE) {
      return (
        <Form.Item
          name="value"
          valuePropName="value"
          initialValue={defaultValue}
        >
          <AutoSizeInput
            value=""
            onChange={() => null}
            placeholder={processed?.englishPath || 'Answer'}
            disabled={disabled}
            className={classNames('question-input', {
              sensitive: isSecret
            })}
            size={{
              maxRows: 10
            }}
            autoFocus
          />
        </Form.Item>
      );
    }

    // TODO: Remove "source" condition once KOG-779 is resolved
    if (inputType === QuestionInputType.VALUE_UPLOAD && source === 'single') {
      const normFile = (uploadedResponses: IUploadedResponse[]) =>
        uploadedResponses;

      return (
        <Form.Item
          name="upload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="question-upload"
        >
          <S3FileUpload
            scope={AppConstants.S3_FILE_SCOPE.WORKER}
            scopeId={request.worker!.id}
            minimal={false}
            multiple
          />
        </Form.Item>
      );
    }

    if (inputType === QuestionInputType.VALUE_CHOICE) {
      return (
        <Form.Item
          name="choice"
          valuePropName="value"
          initialValue={defaultValue || undefined}
          className="question-choice"
        >
          <Select
            disabled={disabled || questionChoices.loading}
            size="middle"
            placeholder={processed?.englishPath || 'Select'}
            showSearch
            optionFilterProp="children"
            loading={questionChoices.loading}
            filterOption={(input, option) => {
              if (!option?.children?.[0]) {
                return false;
              }
              return option.children[0]
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            optionLabelProp="label"
            autoFocus
          >
            {questionChoices.choices.map((text: any, choiceIndex: number) => (
              <Select.Option
                value={choiceIndex}
                // eslint-disable-next-line react/no-array-index-key
                key={`${choiceIndex}+${text}`}
                label={text}
              >
                {text.toString()}
                {questionChoices.choicesRawValuesMap[choiceIndex] && (
                  <>
                    {' '}
                    <Tooltip title="View details">
                      <InfoCircleTwoTone
                        className="question-choice-view-icon"
                        onClick={() => {
                          dispatch(
                            showPopup({
                              popupId: AppConstants.POPUPS.VIEW_OBJECT,
                              popupParams: {
                                data: questionChoices.choicesRawValuesMap[
                                  choiceIndex
                                ],
                                title: text
                              }
                            })
                          );
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    // TODO: Phase 2
    if (inputType === QuestionInputType.CONCEPT) {
      return null;
    }

    if (inputType === QuestionInputType.TECHNIQUE) {
      return (
        <Form.Item
          name="technique"
          valuePropName="value"
          initialValue={defaultValue}
        >
          <Input.TextArea
            disabled={disabled}
            autoSize={{ minRows: 3, maxRows: 6 }}
            spellCheck={false}
            autoComplete="off"
            placeholder="Technique"
            data-cy="technique-input"
            autoFocus
          />
        </Form.Item>
      );
    }

    if (inputType === QuestionInputType.NO_VALUE) {
      return null;
    }

    if (inputType === QuestionInputType.SKIP) {
      return null;
    }

    if (inputType === QuestionInputType.SKIP_RUN_END) {
      return null;
    }

    if (inputType === QuestionInputType.RETRY) {
      return null;
    }

    // TODO: Update inputType
    if (inputType === QuestionInputType.APPROVE_REVIEW) {
      return null;
    }

    return null;
  };

  return (
    <Spin spinning={answerLoading}>
      <Form
        autoComplete="off"
        onValuesChange={(_, allValues) =>
          onValuesChange({
            ...allValues,
            inputType:
              defaultInputType === QuestionInputType.VALUE_CHOICE
                ? QuestionInputType.VALUE_CHOICE
                : allValues.inputType
          })
        }
        initialValues={{
          remember: isAnsweredLearned,
          secret: isSecret
        }}
        className="question-form"
        key={`${defaultInputType}-${defaultValue}-${isSecret}-${isAnsweredLearned}`}
      >
        <Layout className="question">
          {valueNotKnownQuestion && (
            <div className="question-description">
              <div className="markdown">
                <MarkdownRenderer>
                  {valueNotKnownQuestion.helpText}
                </MarkdownRenderer>
              </div>
            </div>
          )}

          {source === 'single' && (
            <Layout.Header className="question-header">
              <InlineFacts
                text={processed?.text!}
                knowledgeId={worker!.knowledgeId!}
              />
            </Layout.Header>
          )}
          {questionType.nativeCodeError && renderNativeCodeError()}
          <Layout>
            <Space align="center">
              {defaultInputType !== QuestionInputType.VALUE_CHOICE && (
                <Form.Item
                  name="inputType"
                  valuePropName="value"
                  className="question-input-type"
                  initialValue={defaultInputType || undefined}
                >
                  <Select
                    key={inputType}
                    disabled={disabled}
                    size="middle"
                    placeholder="Select a method"
                    bordered
                    onSelect={(value: QuestionInputType) => {
                      setInputType(value);
                    }}
                  >
                    {questionType.conceptReview && (
                      <Select.Option value={QuestionInputType.APPROVE_REVIEW}>
                        Approve
                      </Select.Option>
                    )}
                    {questionType.value && (
                      <Select.Option value={QuestionInputType.VALUE}>
                        Write in answer
                      </Select.Option>
                    )}
                    {/* TODO: Remove "source" condition once KOG-779 is resolved */}
                    {questionType.value && source === 'single' && (
                      <Select.Option value={QuestionInputType.VALUE_UPLOAD}>
                        Upload files
                      </Select.Option>
                    )}
                    {/* TODO: Phase 2 */}
                    {/* {questionType.value && (
                  <Select.Option value={QuestionInputType.CONCEPT}>
                    Concept
                  </Select.Option>
                )} */}
                    {questionType.value && (
                      <Select.Option value={QuestionInputType.TECHNIQUE}>
                        Teach a technique
                      </Select.Option>
                    )}
                    {questionType.value && (
                      <Select.Option value={QuestionInputType.NO_VALUE}>
                        No value
                      </Select.Option>
                    )}
                    {/* <Select.Option value={QuestionInputType.SKIP}>
                      Skip
                    </Select.Option>
                    <Select.Option value={QuestionInputType.SKIP_RUN_END}>
                      Skip to end of run
                    </Select.Option> */}
                    <Select.Option value={QuestionInputType.RETRY}>
                      Retry
                    </Select.Option>
                  </Select>
                </Form.Item>
              )}

              {showEnhancedTechnique ? (
                <Button
                  type="link"
                  onClick={handleEnhancedTeaching}
                  loading={createDebugCommandProps.loading}
                >
                  Teach me!
                </Button>
              ) : null}
            </Space>

            <Layout.Content className="question-inputs">
              {renderInput()}
            </Layout.Content>
          </Layout>
          <Layout.Footer>
            {inputType && !questionType.nativeCodeError ? (
              <>
                <Form.Item name="secret" valuePropName="checked">
                  <Checkbox
                    disabled={disabled}
                    data-cy="secret-checkbox"
                    onChange={() => toggleSecret(!isSecret)}
                  >
                    Secret
                  </Checkbox>
                </Form.Item>
                <Form.Item name="remember" valuePropName="checked">
                  <Checkbox disabled={disabled} data-cy="remember-checkbox">
                    Always use this answer for this department?
                  </Checkbox>
                </Form.Item>
              </>
            ) : null}
            <div className="markdown">
              <MarkdownRenderer>{answerTypeHint}</MarkdownRenderer>
            </div>
          </Layout.Footer>
        </Layout>
      </Form>
    </Spin>
  );
}

export default RequestQuestion;
