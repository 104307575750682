import React, { useState } from 'react';
import { Modal } from 'antd';
import Editor from 'react-markdown-editor-lite';
import 'react-markdown-editor-lite/lib/index.css';
import MarkdownRenderer from '../components/MarkdownRenderer';

interface IProps {
  text: string;
  onSave: (arg1: string) => void;
  onClose: () => void;
}

function MarkdownEditorPopup(props: IProps) {
  const { text, onSave, onClose } = props;

  const [value, setValue] = useState(text);

  return (
    <Modal
      title="Markdown Editor"
      open
      onCancel={onClose}
      width={900}
      keyboard={false}
      maskClosable={false}
      className="markdown-editor"
      onOk={() => {
        onSave(value);
        onClose();
      }}
      okText="Save"
    >
      <Editor
        autoFocus
        style={{ height: '400px' }}
        value={value}
        renderHTML={(text) => <MarkdownRenderer>{text}</MarkdownRenderer>}
        onChange={({ text }) => setValue(text)}
      />
    </Modal>
  );
}

export default MarkdownEditorPopup;
