import { Comment } from '@ant-design/compatible';
import {
  ContainerOutlined,
  CopyOutlined,
  SyncOutlined
} from '@ant-design/icons';
import { useLazyQuery } from '@apollo/client';
import {
  Button,
  Divider,
  message,
  Modal,
  Popover,
  Space,
  Spin,
  Tag,
  Tooltip
} from 'antd';
import classNames from 'classnames';
import React, { useEffect, useRef, useState } from 'react';
import reactStringReplace from 'react-string-replace';
import AutoSizeInput from '../components/AutoSizeInput';
import { GenerateOpenAICompletionsQuery } from '../generated/API';
import Queries from '../graphql/Queries';
import AppConstants from '../utils/AppConstants';
import AppUtil from '../utils/AppUtil';
import './ProcedurePromptPopup.less';

const STORAGE_KEY = 'procedure-prompt';
const INITIAL_PROMPT_STORAGE_KEY = 'INITIAL_PROMPT';

// private
const INITIAL_PROMPT = `Example 1
User:To add an invoice to a google worksheet 
Computer: 
upload the file 
analyze the file as an invoice 
get the worksheet at "<url>" 
add the invoice as a row in the worksheet 
save the worksheet 
User:Change the above. Use the worksheet at "https://docs.google.com/document/d/1Mi2K4TE8KSM3ew_fBqlbc9AuZA-3nEcfPRCUypHpFC0/edit#heading=h.wugmf2hznj8f" 
Computer:
upload the file
analyze the file as an invoice
get the worksheet at "https://docs.google.com/document/d/1Mi2K4TE8KSM3ew_fBqlbc9AuZA-3nEcfPRCUypHpFC0/edit#heading=h.wugmf2hznj8f" 
add the invoice as a row in the worksheet 
save the worksheet 
User:Change the above. Use the file at "s3://file" 
Computer: 
the file is "s3://file" 
analyze the file as an invoice 
get the worksheet at "https://docs.google.com/document/d/1Mi2K4TE8KSM3ew_fBqlbc9AuZA-3nEcfPRCUypHpFC0/edit#heading=h.wugmf2hznj8f" 
add the invoice as a row in the worksheet
save the worksheet

Example 2 
User:I want to add an invoice as a payment into salesforce 
Computer: 
upload the file 
analyze the file as an invoice 
connect to salesforce
add the invoice as a payment in salesforce 
User:Change the above. Use the file at "https://www.dropbox.com/files/sdfsf" 
Computer: 
the file is "https://www.dropbox.com/files/sdfsf" 
analyze the file as an invoice 
connect to salesforce 
add the invoice as a payment in salesforce

Example 3`;

interface IProps {
  onClose: () => void;
  onSuccess?: (output: string) => void;
}

function ProcedurePromptPopup(props: IProps) {
  const { onClose, onSuccess } = props;

  const [commands, setCommands] = useState<string[]>([]);
  const [output, setOutput] = useState(``);
  const [newCommandValue, setNewCommandValue] = useState('');

  const outputRef = useRef<any>(null);

  const [getCompletion, getCompletionProps] =
    useLazyQuery<GenerateOpenAICompletionsQuery>(
      Queries.GenerateOpenAICompletions()
    );

  const getLocalState = () => {
    const localValue = AppUtil.getFromLocalStorage(STORAGE_KEY) || '{}';
    const initialPrompt =
      AppUtil.getFromLocalStorage(INITIAL_PROMPT_STORAGE_KEY) || INITIAL_PROMPT;

    const { commands = [], output = '', prompt = '' } = JSON.parse(localValue);
    return {
      commands,
      output,
      prompt: prompt || initialPrompt
    };
  };

  const updateLocalState = (obj: any) => {
    const localValue = AppUtil.getFromLocalStorage(STORAGE_KEY) || '{}';
    const updatedValue = {
      ...JSON.parse(localValue),
      ...obj
    };
    AppUtil.setInLocalStorage(STORAGE_KEY, JSON.stringify(updatedValue));
  };

  useEffect(() => {
    const localState = getLocalState();
    setCommands(localState.commands);
    setOutput(localState.output);
  }, []);

  const handlePromptReset = () => {
    AppUtil.setInLocalStorage(STORAGE_KEY, JSON.stringify({}));
    setCommands([]);
    setNewCommandValue('');
    setOutput('');
  };

  const getPromptInput = (instruction: string) => {
    const finalInstruction =
      commands.length > 1 ? `Change the above. ${instruction}` : instruction;
    return `${getLocalState().prompt}\nUser: ${finalInstruction}\nComputer:`;
  };

  const handleNewCommandSave = () => {
    if (!newCommandValue.trim().length) {
      return;
    }

    const prompt = getPromptInput(newCommandValue);

    getCompletion({
      variables: {
        prompt
      }
    }).then((resp) => {
      const generatedText = (
        resp.data?.generateOpenAICompletions?.[0] || ''
      ).trim();

      const newCommands = [...commands, newCommandValue];
      setCommands(newCommands);
      setNewCommandValue('');
      setOutput(generatedText);
      updateLocalState({
        output: generatedText,
        commands: newCommands,
        prompt: `${prompt}: ${generatedText}`
      });
    });
  };

  const handleOutputCopy = () => {
    outputRef.current!.focus({
      cursor: 'all'
    });
    navigator.clipboard.writeText(output).then(() => {
      message.info(`Copied to clipboard`);
    });
  };

  const handleSave = () => {
    if (onSuccess) {
      onSuccess(output);
    }
    onClose();
  };

  const renderCommandContent = (command: string) => {
    const commandWithURL = reactStringReplace(
      command,
      AppConstants.PATTERNS.HTTP_URI,
      (match) => (
        <Popover content={match}>
          <Tag data-cy={`tag-url-${match}`}>URL</Tag>
        </Popover>
      )
    );

    return <div data-cy="instruction">{commandWithURL}</div>;
  };

  return (
    <Modal
      open
      title={
        <Space>
          <span>Koncierge</span>
          <Tooltip title="Reset instructions">
            <SyncOutlined
              style={{
                cursor: 'pointer'
              }}
              onClick={handlePromptReset}
              data-cy="reset-instructions"
            />
          </Tooltip>
        </Space>
      }
      onCancel={onClose}
      onOk={handleSave}
      width={1000}
      keyboard={false}
      maskClosable={false}
      okText="Run"
      className="procedure-prompt-popup"
      okButtonProps={{
        disabled: getCompletionProps.loading || !output,
        // @ts-ignore
        'data-cy': 'procedure-prompt-submit'
      }}
    >
      <div className="content">
        <div className="user-commands">
          <Divider>Your wish</Divider>
          <div className="list">
            {commands.map((command) => (
              <Comment key={command} content={renderCommandContent(command)} />
            ))}
          </div>

          <AutoSizeInput
            placeholder={
              output
                ? 'What changes do you need?'
                : 'What would you like to do?'
            }
            disabled={getCompletionProps.loading}
            size={{
              minRows: 2,
              maxRows: 3
            }}
            autoFocus
            value={newCommandValue}
            onChange={(e) => setNewCommandValue(e)}
            className="instruction-input"
          />
          <Button
            type="primary"
            onClick={handleNewCommandSave}
            disabled={getCompletionProps.loading}
            icon={<ContainerOutlined />}
            className="submit-button"
            data-cy="instruction-submit"
          >
            Write it for me!
          </Button>
        </div>
        <div className="computer-output">
          <Divider>Auditable plan of action</Divider>
          <Spin spinning={getCompletionProps.loading}>
            <AutoSizeInput
              ref={outputRef}
              value={output}
              onChange={setOutput}
              placeholder="Computer's answer..."
              size={{
                minRows: 12,
                maxRows: 12
              }}
              className="output"
            />
          </Spin>

          <Space
            className={classNames('actions', {
              hide: !output
            })}
          >
            <Button
              icon={<CopyOutlined />}
              type="text"
              size="small"
              onClick={handleOutputCopy}
            >
              Copy
            </Button>
          </Space>
        </div>
      </div>
    </Modal>
  );
}

export default ProcedurePromptPopup;
