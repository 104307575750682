//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Formatting specific utility functions
//

// 3rd party libraries
import React from 'react';

// Local imports
import AppUtil from '@utils/AppUtil';
import FactSummary from '@/components/facts/FactSummary';
import { Question } from '@/generated/API';
import InlineFacts from '@/components/facts/InlineFacts';
import AnswerObjectViewer from '../components/AnswerObjectViewer';
import AnswerFactsViewer from '../components/AnswerFactsViewer';
import AnswerCSVViewer from '../components/AnswerCSVViewer';
import AnswerS3FileViewer from '../components/AnswerS3FileViewer';
import AnswerMarkdownViewer from '../components/AnswerMarkdownViewer';
import SensitiveText from '../components/SensitiveText';
import AppConstants from './AppConstants';

// eslint-disable-next-line no-shadow
export enum FormattedAnswerType {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  RESULT = 'result',
  CONCEPT = 'concept',
  CONCEPTS = 'concepts',
  LIST = 'list',
  OBJECT = 'object',
  NONE = 'none'
}

export enum FormattedAnswerTypeV2 {
  STRING = 'string',
  NUMBER = 'number',
  BOOLEAN = 'boolean',
  RESULT = 'result',
  SENSITIVE = 'sensitive',
  MARKDOWN = 'markdown',
  S3 = 's3',
  TABLE = 'table',
  TABLES_ROW = 'tables_row',
  NONE = 'none',

  // These are not the final values
  SRC_CONCEPT = 'src_concept',
  LONG = 'long'
}

const FormattingUtil = {
  // srcRegex: /src_([^[{}\]]+)(?:\[([^{}\]]*)])?((?:\\.|[^}])*\})/,
  srcRegex: /src_concept{([^}]*)}/,

  isSrcConcept(string: string) {
    return this.srcRegex.test(string);
  },

  parseSrcConcept(srcCpt: string) {
    try {
      const serialized = this.srcRegex
        .exec(srcCpt)![3]
        .replaceAll('\\{', '{')
        .replaceAll('\\}', '}');
      return JSON.parse(serialized);
    } catch {
      return {};
    }
  },

  parseBrainValue(jsonString: string) {
    let str = jsonString;
    // TODO: Remove once KOG-1952 is resolved
    // eslint-disable-next-line no-restricted-globals
    if (typeof jsonString === 'string' && isNaN(jsonString as any)) {
      str = (jsonString || '').replace(/NaN/gm, null as any);
    }
    const parsed = AppUtil.safeParseJSON(str, true);
    return this.decodeBrainValue(parsed);
  },

  decodeBrainValue(parsed: any) {
    if (parsed === null || parsed === undefined) {
      return null;
    }

    const type = typeof parsed;
    let result = null;

    /* eslint-disable no-underscore-dangle */
    if (type === 'string') {
      result = parsed;
    } else if (type === 'number') {
      result = parsed;
    } else if (type === 'boolean') {
      result = parsed.toString();
    } else if ('__number__' in parsed) {
      result = Number(parsed.__number__);
    } else if ('__literal_string__' in parsed) {
      result = JSON.parse(parsed.__literal_string__);
    } else if ('__result__' in parsed) {
      result = { result: parsed.__result__ };
    } else if ('__concepts__' in parsed) {
      result = { concepts: parsed.__concepts__ };
    } else if ('__concept__' in parsed) {
      result = { concept: parsed.__concept__ };
    } else if ('__list__' in parsed) {
      result = { list: parsed.__list__ };
    } else {
      result = parsed;
    }
    /* eslint-enable no-underscore-dangle */

    return result;
  },

  encodeBrainValue(value: any, stringify = true): string | object {
    if (value === null || value === undefined) {
      throw new Error(`Invalid value provided: ${value}`);
    }

    const type = typeof value;

    let encoded = null;
    /* eslint-disable no-underscore-dangle */
    if (type === 'string') {
      encoded = value;
    } else if (type === 'number') {
      encoded = { __number__: value.toString() };
    } else {
      throw new Error(`Invalid value provided: ${value}`);
    }
    /* eslint-enable no-underscore-dangle */

    if (stringify) {
      return JSON.stringify(encoded);
    }

    return encoded;
  },

  // TODO: take care of list, 'concept info', array with 1 value and object here
  getFormattedAnswerV2({
    answer,
    firstName
  }: {
    answer: any;
    firstName?: string | null;
  }): {
    type: FormattedAnswerTypeV2;
    answer: any;
  } {
    const type = typeof answer;

    const response: {
      type: FormattedAnswerTypeV2;
      answer: any;
    } = {
      type: FormattedAnswerTypeV2.NONE,
      answer: null
    };
    if (typeof answer !== 'object') {
      if (answer === null || answer === undefined) {
        // Do nothing.
      } else if (firstName === AppConstants.FACT_TABLE_NAME) {
        response.type = FormattedAnswerTypeV2.TABLE;
        response.answer = answer;
      } else if (type === 'number') {
        response.type = FormattedAnswerTypeV2.NUMBER;
        response.answer = answer.toString();
      } else if (type === 'boolean') {
        response.type = FormattedAnswerTypeV2.BOOLEAN;
        response.answer = answer.toString();
      } else if (answer.result) {
        response.type = FormattedAnswerTypeV2.RESULT;
        response.answer = answer.result;
      } else if (type === 'string') {
        response.answer = answer;
        if (this.isSensitiveText(answer)) {
          response.type = FormattedAnswerTypeV2.SENSITIVE;
        } else if (AppUtil.hasMarkdownText(answer)) {
          response.type = FormattedAnswerTypeV2.MARKDOWN;
        } else if (AppUtil.getS3URIParts(answer)) {
          response.type = FormattedAnswerTypeV2.S3;
        } else if (answer.includes('src_concept')) {
          response.type = FormattedAnswerTypeV2.SRC_CONCEPT;
        } else if (answer.length > 20) {
          response.type = FormattedAnswerTypeV2.LONG;
        } else {
          response.type = FormattedAnswerTypeV2.STRING;
        }
      }
    } else if (typeof answer === 'object') {
      if (Object.keys(answer).length === 1 && answer.result) {
        response.type = FormattedAnswerTypeV2.RESULT;
        response.answer = answer.result;
      } else {
        response.type = FormattedAnswerTypeV2.TABLES_ROW;
      }
    }
    return response;
  },

  // TODO: Refactor methods and components related to fact answers. Methods should only return contract-based data not UI components. Component rendering logic should be de-coupled from data.
  getFormattedAnswer({
    answer,
    knowledgeId
  }: {
    answer: any;
    knowledgeId: string;
  }): {
    type: FormattedAnswerType;
    answer: any;
  } {
    const type = typeof answer;

    const response: {
      type: FormattedAnswerType;
      answer: any;
    } = {
      type: FormattedAnswerType.NONE,
      answer: null
    };

    if (answer === null || answer === undefined) {
      // Do nothing.
    } else if (type === 'number') {
      response.type = FormattedAnswerType.NUMBER;
      response.answer = answer.toString();
    } else if (type === 'string') {
      response.type = FormattedAnswerType.STRING;
      if (this.isSensitiveText(answer)) {
        response.answer = <SensitiveText isSensitive>{answer}</SensitiveText>;
      } else if (AppUtil.hasMarkdownText(answer)) {
        response.answer = <AnswerMarkdownViewer answer={answer} />;
      } else if (AppUtil.getS3URIParts(answer)) {
        response.answer = <AnswerS3FileViewer answer={answer} />;
      } else {
        response.answer = (
          <InlineFacts text={answer} knowledgeId={knowledgeId} />
        );
      }
    } else if (type === 'boolean') {
      response.type = FormattedAnswerType.BOOLEAN;
      response.answer = answer.toString();
    } else if (answer.result) {
      response.type = FormattedAnswerType.RESULT;
      response.answer = answer.result;
    } else if (answer.concept) {
      response.type = FormattedAnswerType.CONCEPT;
      response.answer = (
        <FactSummary factId={answer.concept.id} knowledgeId={knowledgeId} />
      );
    } else if (answer.concepts) {
      response.type = FormattedAnswerType.CONCEPTS;

      const result = answer.concepts.items.map((item: any) =>
        this.decodeBrainValue(item)
      );

      response.answer = (
        <AnswerFactsViewer
          id={answer.concepts.id}
          title={answer.concepts.name}
          knowledgeId={knowledgeId!}
          data={result}
        />
      );
    } else if (answer.list) {
      response.type = FormattedAnswerType.LIST;
      response.answer = <>List: {JSON.stringify(answer.list)}</>;
    } else if (answer?.['concept info']) {
      if (answer?.['concept info']?.is_a === 'csv') {
        response.answer = (
          <AnswerCSVViewer
            title={answer?.['concept info']?.handle}
            data={AppUtil.safeParseJSON(answer?.['concept info']?.data)}
          />
        );
      }
    } else if (Array.isArray(answer) && answer.length === 1) {
      response.type = FormattedAnswerType.OBJECT;
      response.answer = FormattingUtil.getFormattedAnswer({
        answer: answer[0] || '',
        knowledgeId
      }).answer;
    } else {
      response.type = FormattedAnswerType.OBJECT;
      response.answer = <AnswerObjectViewer object={answer} />;
    }

    return response;
  },

  shouldShowFormattedAnswerInModal(_answerType: FormattedAnswerType): boolean {
    return false;

    // Not needed anymore. At the moment, we first show a link to open the following UI components
    // return [
    //   // FormattedAnswerType.CONCEPT,
    //   FormattedAnswerType.CONCEPTS,
    //   FormattedAnswerType.OBJECT
    // ].includes(answerType);
  },

  // TODO: combine below logic for questions, move to backend
  // ( as Raj says, it's voodoo :) )
  // - This is used by the action bar widget.
  formatQuestionPrompt(question: Question) {
    const { text, type, path } = question;
    const pComponents = AppUtil.safeParseJSON(path);
    const lexical = pComponents?.lexical || [''];
    return `${text || ''} ${type || ''} ${lexical[0] || ''}`;
  },

  isSensitiveText(text: string) {
    const sensitivePattern = /password|security|token|key|secret/i;
    return sensitivePattern.test(text);
  },

  // TODO: Remove
  // - This is used elsewhere.
  processQuestion(question: Question): {
    isSensitive: boolean;
    text: string;
    englishPath: string;
    descriptions: any;
    conceptsToReview: string | null;
  } | null {
    let englishPath: string = '';
    const qpath = JSON.parse(question?.path || '{}');
    let questionText = question.text;
    const descriptions: any = {};
    if (qpath && qpath.lexical) {
      englishPath = qpath.lexical.join("'s ");
      questionText = `${question.text} ${englishPath}`;

      // TODO: improve this.
      let conceptsToReview = null;
      if (question.type!.startsWith('review') && qpath.conceptual.length > 0) {
        const candidate = qpath.conceptual[qpath.conceptual.length - 1];
        if (candidate) {
          const decoded = FormattingUtil.decodeBrainValue(candidate);
          if (decoded.concepts) {
            conceptsToReview = decoded.concepts.items.map((d: any) =>
              FormattingUtil.decodeBrainValue(d)
            );
          } else {
            conceptsToReview = [decoded];
          }
        }
      }

      return {
        isSensitive: FormattingUtil.isSensitiveText(englishPath),
        text: questionText,
        englishPath,
        descriptions,
        conceptsToReview
      };
    }

    return {
      isSensitive: FormattingUtil.isSensitiveText(englishPath),
      text: questionText!,
      englishPath,
      descriptions,
      conceptsToReview: null
    };
  },

  // - This is used elsewhere.
  processQuestionV1(
    text: string,
    lexicalPath: string[] | null
  ): {
    isSensitive: boolean;
    text: string;
    englishPath: string;
    descriptions: any;
    // conceptsToReview: string | null;
  } | null {
    let englishPath: string = '';
    let questionText = text;
    const descriptions: any = {};
    if (lexicalPath) {
      englishPath = lexicalPath.join("'s ");
      questionText = `${text} ${englishPath}`;

      // TODO: improve this.
      // let conceptsToReview = null;
      // if (type!.startsWith('review') && qpath.conceptual.length > 0) {
      //   const candidate = qpath.conceptual[qpath.conceptual.length - 1];
      //   if (candidate) {
      //     const decoded = FormattingUtil.decodeBrainValue(candidate);
      //     if (decoded.concepts) {
      //       conceptsToReview = decoded.concepts.items.map((d: any) =>
      //         FormattingUtil.decodeBrainValue(d)
      //       );
      //     } else {
      //       conceptsToReview = [decoded];
      //     }
      //   }
      // }

      return {
        isSensitive: FormattingUtil.isSensitiveText(englishPath),
        text: questionText,
        englishPath,
        descriptions
        // conceptsToReview
      };
    }

    return {
      isSensitive: FormattingUtil.isSensitiveText(englishPath),
      text: questionText!,
      englishPath,
      descriptions
      // conceptsToReview: null
    };
  },

  getLeadingWhiteSpaceCount(str: string): number {
    const pttrn = /^\s*/;
    const match = str.match(pttrn);
    return match ? match[0].length : 0;
  },

  extractCodeFromMarkdown(str: string) {
    const pattrn = /```[^`]*```/gim;
    const match = str.match(pattrn);
    return match ? match[0].replaceAll('```', '').trim() : '';
  }
};

export default FormattingUtil;
