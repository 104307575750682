import { GetDownloadFromS3UrlQuery } from '@/generated/API';
import AppConstants from '@/utils/AppConstants';
import AppUtil from '@/utils/AppUtil';
import { useApolloClient } from '@apollo/client';
import { message } from 'antd';
import React from 'react';
import axios from 'axios';

export function useS3() {
  const client = useApolloClient();
  const [isLoading, setIsLoading] = React.useState(false);

  const getS3PresignedUrl = async (objectKey: string) => {
    // TODO: Uncomment once KOG-1953 is resolved
    // We show a document inline and then once you click on it, we render a detailed view of the same (in a popup). Ideally we don't want to re-fetch the same document for the second time.
    // const cacheData = getWindowCache('signedUrlCache')(objectKey);
    // if (cacheData) {
    //   return cacheData;
    // }

    try {
      setIsLoading(true);
      const result = await client.query<GetDownloadFromS3UrlQuery>({
        query: AppConstants.APIS.S3.DOWNLOAD_LINK(),
        variables: {
          objectKey
        }
      });
      const r = result?.data?.getDownloadFromS3Url;
      // setWindowCache('signedUrlCache')(objectKey, r);
      return r;
    } catch (e) {
      console.error(e);
    } finally {
      setIsLoading(false);
    }
    return null;
  };

  const getSignedUrlObject = async (signedUrl: string) => {
    // TODO: Uncomment once KOG-1953 is resolved
    // const cacheData = getWindowCache('signedUrlObjectCache')(signedUrl);
    // if (cacheData) {
    //   return cacheData;
    // }

    try {
      const result = axios.get(signedUrl, {
        responseType: 'blob'
      });
      // setWindowCache('signedUrlObjectCache')(signedUrl, result);
      return result;
    } catch (e) {
      console.error(e);
    }
    return null;
  };

  const download = async (url: string) => {
    const { key } = AppUtil.getS3URIParts(url)!;
    const presignedUrl = await getS3PresignedUrl(key);
    if (!presignedUrl) {
      message.error('Failed to get download link');
      return;
    }
    // const resp = await fetch(url);
    // const blob = new Blob([data], { type: 'text/pdf' });
    // const url = window.URL.createObjectURL(blob);
    const link = document.createElement('a');
    link.href = presignedUrl.url;
    const { filename } = AppUtil.getS3URIParts(url)!;
    link.setAttribute('download', filename);
    link.click();
  };

  const openInNewTab = async (url: string) => {
    const { key } = AppUtil.getS3URIParts(url)!;
    const presignedUrl = await getS3PresignedUrl(key);
    if (presignedUrl) {
      window.open(presignedUrl.url, '_blank');
    }
  };

  return {
    isLoading,
    getS3PresignedUrl,
    getSignedUrlObject,
    openInNewTab,
    download
  };
}
