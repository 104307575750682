//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// FlowDetails is a component that renders the details of a flow
//

// 3rd party libraries
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Badge, Menu, Space } from 'antd';
import { useQuery } from '@apollo/client';
import ErrorPage from '@/pages/ErrorPage';

// Local imports
import i18n from '@utils/i18n';
import AppConstants from '@utils/AppConstants';
import Loader, { ILoaderType } from '@components/Loader';
import ComponentFactory from '@utils/ComponentFactory';
import { NavLink, Outlet, useLocation } from 'react-router-dom';
import Timestamp from '@/components/Timestamp';

// Component CSS
import './FlowDetails.less';
import AppUtil from '@/utils/AppUtil';
import { departmentQuerySelector } from '@/stores/slices/department';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import { showPopup } from '@/stores/slices/appPopup';

// Component i18n
const i18nt = (s, d) => i18n.t('FlowDetails', s, d);

// Icons for details tabs
const getIcon = (tabName) => {
  switch (tabName) {
    case 'procedure':
      return ComponentFactory.getIcon(AppConstants.ICONS.TEXT);
    case 'runs':
      return ComponentFactory.getIcon(AppConstants.ICONS.RUN);
    case 'run':
      return <Badge status="default" />;
    case 'learnings':
      return ComponentFactory.getIcon(AppConstants.ICONS.LEARNING);
    default:
      return null;
  }
};

const { SubMenu, Item } = Menu;

function FlowDetails(props) {
  const location = useLocation();
  const [selectedMenu, selectMenu] = useState(() =>
    // /procedure/2gkzpdvg37kf9x8cv8c5mf5way/Q2ZjAk4xfsPfNLSwNs92vW/run/42c58ha4gku4k9ao8syzqbpxjh
    // /procedure/2gkzpdvg37kf9x8cv8c5mf5way/Q2ZjAk4xfsPfNLSwNs92vW/run/all
    // Taking the last 2 slice of the pathname and creating a key to match Menu.Item key to show active menu
    location.pathname.split('/').slice(4).join('_')
  );
  const { procedure } = props;
  const { department } = useAppSelector(departmentQuerySelector);
  const dispatch = useAppDispatch();

  // Load last 5 runs on load
  // the Query hook.
  const { data, loading, error, refetch } = useQuery(
    AppConstants.APIS.WORKERS.LIST_BY_PROCEDURE(),
    {
      variables: { procedureId: procedure.id, limit: 5 }
    }
  );

  // callback to initiate a run
  const initiateRun = () => {
    dispatch(
      showPopup({
        popupId: AppConstants.POPUPS.NEW_RUN,
        popupParams: {
          flowId: procedure.id,
          knowledgeId: procedure.knowledgeId,
          departmentId: department.id,
          onSuccess: () => refetch()
        }
      })
    );
  };

  useEffect(() => {
    if (selectedMenu === 'new_run') {
      initiateRun();
    }
  }, []);

  if (loading) {
    return <Loader type={ILoaderType.FULL_PAGE} />;
  }

  // Show error msg or selected component
  if (error) {
    return <ErrorPage error={error} />;
  }

  // Query results. valid only if no error and not loading
  const runResults = data.listWorkersByProcedure;

  // const totalRuns = results.totalCount; //TODO: Ziv cant you support this ?
  const totalRuns = runResults.items.length;

  const runsTitle = (
    <Space>
      {i18nt('runs', 'runs')}
      <Badge
        style={{ backgroundColor: 'gray' }}
        size="small"
        count={totalRuns}
      />
    </Space>
  );

  const recentRuns = runResults.items;
  const allowNewRuns =
    procedure?.knowledgeId === department?.publishedKnowledgeId;

  return (
    <div className="flow-details">
      <div className="left-panel">
        <Menu
          onClick={(e) => {
            if (e.key === 'new_run') {
              initiateRun();
            } else {
              selectMenu(e.key);
            }
          }}
          style={{ width: '100%' }}
          defaultSelectedKeys={[selectedMenu]}
          defaultOpenKeys={['runs']}
          mode="inline"
        >
          <Item key="procedure" icon={getIcon('procedure')}>
            <NavLink state={location.state} to="procedure">
              {i18nt('procedure', 'procedure')}
            </NavLink>
          </Item>
          <SubMenu key="runs" icon={getIcon('runs')} title={runsTitle}>
            {recentRuns.map((r) => (
              <Item key={`run_${r.id}`}>
                <Badge
                  color={AppConstants.RUN_STATUS_COLOR[r.state] || 'grey'}
                />
                <NavLink
                  key={r.id}
                  state={location.state}
                  data-cy={r.name}
                  to={`run/${r.id}`}
                >
                  {r.name || (
                    <span className="text-capitalize">
                      <Timestamp
                        placement="top"
                        millis={AppUtil.millisFromDateString(r.createdAt)}
                      />
                    </span>
                  )}
                </NavLink>
              </Item>
            ))}

            {totalRuns > 0 ? (
              <Item key="run_all">
                <Badge color="grey" />
                <NavLink state={location.state} to="run/all">
                  {i18nt('show_all', 'Show all')}
                </NavLink>
              </Item>
            ) : null}

            {totalRuns === 0 ? (
              <Item disabled={!allowNewRuns} key="new_run">
                <Badge color="grey" />
                {i18nt('initiate_run', 'Initiate a Run')}
              </Item>
            ) : null}
          </SubMenu>

          <Item key="learnings" icon={getIcon('learnings')}>
            <NavLink state={location.state} to="learnings">
              {i18nt('learnings', 'learnings')}
            </NavLink>
          </Item>
        </Menu>
      </div>
      <div className="right-panel">
        <Outlet context={{ procedure, refetchBasicProcedureRuns: refetch }} />
      </div>
    </div>
  );
}

// Component Prop Types
FlowDetails.propTypes = {
  /**
   * The flow entity
   */
  procedure: PropTypes.object.isRequired
};

export default FlowDetails;
