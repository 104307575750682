/* eslint-disable no-lonely-if */
//
// Copyright (C) - Kognitos, Inc. All rights reserved
//

// 3rd party libraries
import React, { useState, useEffect } from 'react';
import { useMutation, useQuery } from '@apollo/client';
import {
  Button,
  Form,
  Input,
  Select,
  Layout,
  Row,
  Col,
  Alert,
  Checkbox,
  Tooltip,
  Divider,
  Space,
  Tag,
  Popover
} from 'antd';
import classNames from 'classnames';
import AppConstants from '@utils/AppConstants';
import InlineFacts from '@components/facts/InlineFacts';
import FormattingUtil from '@utils/FormattingUtil';
import S3FileUpload, { IUploadedResponse } from '@components/Upload';
import Loader from '@/components/Loader';
import { createAnswer } from '@/stores/slices/answer';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import { ampTrackEvent } from '@/analytics';
import ErrorDescription from '@/components/ErrorDescription';
import AppUtil from '@/utils/AppUtil';
import './QuestionForm.less';
import { last } from 'lodash/fp';
import { showPopup } from '@/stores/slices/appPopup';
import { InfoCircleTwoTone } from '@ant-design/icons';
import { useSelector } from 'react-redux';
import MarkdownRenderer from '@/components/MarkdownRenderer';
import AutoSizeInput from '../../components/AutoSizeInput';
import {
  booksQuerySelector,
  getBooksByDepartment,
  getBooksByKnowledge
} from '../../stores/slices/books';
import { departmentQuerySelector } from '../../stores/slices/department';
import useQuestionChoices from '../../hooks/useQuestionChoices';
import {
  CreateServiceCommandMutation,
  CreateServiceCommandMutationVariables,
  Worker
} from '../../generated/API';
import { userSelector } from '../../stores/slices/user';

const { Option } = Select;
const { TextArea } = Input;
const { Header, Footer, Content } = Layout;

interface IAnswerProps {
  value: string;
  inputType: string;
  secret: boolean;
  learn: boolean;
}

interface IQuestionProps {
  question: any;
  knowledgeId: string;
  disabled: boolean;
  answer?: IAnswerProps;
  onValuesChange: (all: any) => void;
  onTypeChange?: (type: string) => void;
  onEnter?: () => void;
  source?: 'batch' | 'single';
  showRunPoint?: boolean;
  dispatchContextPath?: boolean;
  allowContextDebugging: boolean;
  worker?: Worker;
}

export function Question(props: IQuestionProps) {
  const {
    question,
    knowledgeId,
    disabled,
    answer,
    onValuesChange,
    onTypeChange,
    onEnter,
    source = 'single',
    showRunPoint = false,
    dispatchContextPath = true,
    allowContextDebugging,
    worker
  } = props;

  let questionDefaultValue = '';
  let defaultInputType: undefined | string;
  if (answer && question.isAnswered) {
    questionDefaultValue = FormattingUtil.parseBrainValue(answer.value);
    defaultInputType = answer.inputType;
  }

  if (AppUtil.shouldShowQuestionChoices(question.type)) {
    defaultInputType = 'choice';
  }

  const processed = FormattingUtil.processQuestion(question);

  const [defaultValue, setDefaultValue] = useState(questionDefaultValue || '');
  const [inputType, setInputType] = useState(defaultInputType || '');
  const [isSecret, toggleSecret] = useState(
    answer?.secret || processed?.isSensitive || false
  );

  const { loading: loadingBooks, books } = useAppSelector(booksQuerySelector);
  const { department } = useAppSelector(departmentQuerySelector);
  const dispatch = useAppDispatch();

  const questionChoices = useQuestionChoices({
    question,
    knowledgeId
  });

  useEffect(() => {
    if (answer?.inputType === 'choice' && answer?.value) {
      const newDefaultValue = questionChoices.choices[Number(answer.value)];
      setDefaultValue(newDefaultValue);
    }
  }, [questionChoices.choices, answer]);

  const fetchBooks = () => {
    if (AppUtil.isDepartmentBookSupported(department)) {
      dispatch(
        getBooksByDepartment({
          departmentId: department.id
        })
      );
      return;
    }
    dispatch(
      getBooksByKnowledge({
        knowledgeId: department.draftKnowledgeId!
      })
    );
  };

  useEffect(() => {
    if (inputType === 'book') {
      fetchBooks();
    }

    if (onTypeChange) {
      onTypeChange(inputType);
    }
  }, [inputType]);

  useEffect(() => {
    if (onTypeChange) {
      onTypeChange(inputType);
    }
  }, [inputType]);

  const dispatchHighlightStepEvent = () => {
    const contextPath = question.contextPath.map(
      (path: any) => `${path.ctxId}:${path.sentenceId}`
    );
    const event = new CustomEvent(AppConstants.EVENT_NAMES.STEP_HIGHLIGH_ADD, {
      detail: {
        contextPath,
        questionStep: AppUtil.getStepNodeFromQuestion(
          question.contextPath,
          question.contextId
        )
      }
    });
    window.dispatchEvent(event);
  };

  const dispatchRemoveHighlightStepEvent = () => {
    const event = new CustomEvent(
      AppConstants.EVENT_NAMES.STEP_HIGHLIGH_REMOVE,
      {
        detail: {
          questionStep: AppUtil.getStepNodeFromQuestion(
            question.contextPath,
            question.contextId
          )
        }
      }
    );
    window.dispatchEvent(event);
  };

  useEffect(() => {
    if (dispatchContextPath && question.contextPath?.length > 0) {
      dispatchHighlightStepEvent();
    }

    return () => {
      if (dispatchContextPath && question.contextPath?.length > 0) {
        dispatchRemoveHighlightStepEvent();
      }
    };
  }, [dispatchContextPath]);

  const [createDebugCommand, createDebugCommandProps] =
    useMutation<CreateServiceCommandMutation>(
      AppConstants.APIS.SERVICE_COMMANDS.CREATE()
    );

  const handleEnhancedTeaching = () => {
    const stepNode = AppUtil.getStepNodeFromQuestion(
      question.contextPath,
      question.contextId
    );

    if (!stepNode) {
      return;
    }

    const [contextId, sentenceId] = stepNode.split(':');

    const params = {
      context_id: Number(contextId),
      sentence_id: Number(sentenceId) - 1,
      let_me_know: true
    };

    const variables: CreateServiceCommandMutationVariables = {
      input: {
        owner: worker?.owner,
        workerId: worker?.id!,
        method: 'clone_context_at_sentence',
        params: JSON.stringify(params)
      }
    };

    createDebugCommand({
      variables
    })
      .then((resp) => {
        dispatch(
          showPopup({
            popupId: AppConstants.POPUPS.QUESTION_CONTEXT_DEBUGGER,
            popupParams: {
              worker,
              department,
              question,
              commandId: resp.data?.createServiceCommand?.id!
            }
          })
        );
      })
      .catch((e) => {
        console.error('error with createDebugCommand', e, variables);
      });
  };

  // TODO: make the review screen a popup that is triggered using the correct
  // mechanism.
  // const [showReview, setShowReview] = useState<boolean>(false);
  // let reviewContent = null;
  // if (question.type === 'review relations' && processed?.conceptsToReview) {
  //   const reviewModal = showReview ? (
  //     <Modal
  //       visible
  //       closable={false}
  //       width={1000}
  //       className="review-modal"
  //       footer={null}
  //     >
  //       <RelationsReviewWrapper
  //         knowledgeId={knowledgeId}
  //         concepts={processed.conceptsToReview}
  //         closeCallback={() => setShowReview(false)}
  //       />
  //     </Modal>
  //   ) : null;

  //   const reviewLink = (
  //     <span className="review-trigger">
  //       <Button
  //         size="small"
  //         type="dashed"
  //         shape="round"
  //         onClick={() => setShowReview(true)}
  //       >
  //         Start Review
  //       </Button>
  //     </span>
  //   );

  //   reviewContent = (
  //     <>
  //       {reviewModal}
  //       {reviewLink}
  //     </>
  //   );
  // }

  let descriptionContent = null;

  if (source === 'single' && question.description && !question.rawException) {
    descriptionContent = (
      <div className="question-description">
        <div className="markdown">
          <MarkdownRenderer>{question.description}</MarkdownRenderer>
        </div>
      </div>
    );
  }

  let delegateText = '';
  if (question.delegationChain && question.delegationChain.length > 0) {
    const { recipients } = last<any>(question.delegationChain);
    delegateText = `(delegated to ${recipients})`;
  }

  const showEnhancedTechnique = allowContextDebugging && !disabled;

  const renderInput = () => {
    if (inputType === 'value') {
      return (
        <Form.Item
          name="value"
          valuePropName="value"
          initialValue={defaultValue}
        >
          <AutoSizeInput
            value=""
            onChange={() => null}
            placeholder={processed?.englishPath || 'Answer'}
            disabled={disabled}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && onEnter) {
                onEnter();
              }
            }}
            className={classNames('question-input', {
              sensitive: isSecret
            })}
            size={{
              maxRows: 10
            }}
            autoFocus
          />
        </Form.Item>
      );
    }

    if (inputType === 'choice') {
      // console.log(questionChoices.choices, defaultValue);

      return (
        <Form.Item
          name="choice"
          valuePropName="value"
          initialValue={defaultValue || undefined}
          className="question-choice"
        >
          <Select
            disabled={disabled || questionChoices.loading}
            size="middle"
            placeholder={processed?.englishPath || 'Select'}
            showSearch
            optionFilterProp="children"
            loading={questionChoices.loading}
            filterOption={(input, option) => {
              if (!option?.children?.[0]) {
                return false;
              }
              return option.children[0]
                .toLowerCase()
                .includes(input.toLowerCase());
            }}
            optionLabelProp="label"
            autoFocus
          >
            {questionChoices.choices.map((text: any, choiceIndex: number) => (
              <Option
                value={choiceIndex}
                // eslint-disable-next-line react/no-array-index-key
                key={`${choiceIndex}+${text}`}
                label={text}
              >
                {text.toString()}
                {questionChoices.choicesRawValuesMap[choiceIndex] && (
                  <>
                    {' '}
                    <Tooltip title="View details">
                      <InfoCircleTwoTone
                        className="question-choice-view-icon"
                        onClick={() => {
                          dispatch(
                            showPopup({
                              popupId: AppConstants.POPUPS.VIEW_OBJECT,
                              popupParams: {
                                data: questionChoices.choicesRawValuesMap[
                                  choiceIndex
                                ],
                                title: text
                              }
                            })
                          );
                        }}
                      />
                    </Tooltip>
                  </>
                )}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    if (inputType === 'technique') {
      return (
        <Form.Item
          name="technique"
          valuePropName="value"
          initialValue={defaultValue}
        >
          <TextArea
            disabled={disabled}
            autoSize={{ minRows: 3, maxRows: 6 }}
            onKeyDown={(e) => {
              if (e.key === 'Enter' && onEnter) {
                onEnter();
              }
            }}
            spellCheck={false}
            autoComplete="off"
            placeholder="Technique"
            data-cy="technique-input"
            autoFocus
          />
        </Form.Item>
      );
    }

    if (inputType === 'delegate') {
      return (
        <div className="delegate">
          <Input.Group compact>
            <Form.Item
              label="Delegate to:"
              name="delegate_email"
              valuePropName="value"
            >
              <Input
                disabled={disabled}
                spellCheck={false}
                autoComplete="email"
                placeholder="Email"
                data-cy="delegate-email"
                autoFocus
              />
            </Form.Item>
          </Input.Group>

          <Input.Group compact>
            <Form.Item
              label="Message:"
              name="delegate_message"
              valuePropName="value"
            >
              <TextArea
                disabled={disabled}
                autoSize={{ minRows: 3, maxRows: 6 }}
                spellCheck={false}
                autoComplete="off"
                placeholder="Body"
                data-cy="delegate-body"
              />
            </Form.Item>
          </Input.Group>
        </div>
      );
    }

    // TODO: Remove "source" condition once KOG-779 is resolved
    if (inputType === 'upload' && source === 'single') {
      const normFile = (uploadedResponses: IUploadedResponse[]) =>
        uploadedResponses;

      return (
        <Form.Item
          name="upload"
          valuePropName="fileList"
          getValueFromEvent={normFile}
          className="question-upload"
        >
          <S3FileUpload
            scope={AppConstants.S3_FILE_SCOPE.WORKER}
            scopeId={question.workerId}
            minimal={false}
            multiple
          />
        </Form.Item>
      );
    }

    if (inputType === 'book') {
      return (
        <Form.Item
          name="book_option"
          valuePropName="value"
          initialValue={defaultValue || undefined}
          className="question-book"
        >
          <Select
            disabled={disabled}
            loading={loadingBooks}
            size="middle"
            placeholder="Select"
            showSearch
            optionFilterProp="children"
            filterOption={(input, option) =>
              (option!.children as unknown as string)
                .toLowerCase()
                .includes(input.toLowerCase())
            }
            autoFocus
          >
            {books.map((book) => (
              <Option value={book.id} key={book.id}>
                {book.name}
              </Option>
            ))}
          </Select>
        </Form.Item>
      );
    }

    if (inputType === 'skip') {
      return null;
    }

    if (inputType === 'abort') {
      return null;
    }

    if (inputType === 'retry') {
      return null;
    }

    return null;
  };

  let filesList: string[] = [];

  if (Array.isArray(defaultValue)) {
    filesList = defaultValue.filter((v) =>
      v.match(AppConstants.PATTERNS.S3_URI)
    );
  } else if (
    typeof defaultValue === 'string' &&
    defaultValue.match(AppConstants.PATTERNS.S3_URI)
  ) {
    filesList = [defaultValue];
  }

  return (
    <Form
      autoComplete="off"
      onValuesChange={(_, allValues) =>
        onValuesChange({
          ...allValues,
          inputType:
            defaultInputType === 'choice' ? 'choice' : allValues.inputType
        })
      }
      initialValues={{
        remember: answer?.learn,
        secret: isSecret
      }}
      className="question-form"
      key={defaultValue}
    >
      {filesList.length > 0 && (
        <ul className="view-question-file-list">
          {filesList.map((fileURI) => {
            const { filename, bucket, key } = AppUtil.getS3URIParts(fileURI)!;
            return (
              <li key={fileURI}>
                <Popover content={`${bucket}/${key}`}>
                  <Tag
                    onClick={(e) => {
                      e.stopPropagation();
                      dispatch(
                        showPopup({
                          popupId: AppConstants.POPUPS.VIEW_S3_FILE,
                          popupParams: {
                            title: filename,
                            s3Object: {
                              bucket,
                              key
                            }
                          }
                        })
                      );
                    }}
                  >
                    {filename}
                  </Tag>
                </Popover>
              </li>
            );
          })}
        </ul>
      )}
      <Layout className="question">
        {descriptionContent}

        {source === 'single' && (
          <Header className="question-header">
            <InlineFacts text={processed?.text!} knowledgeId={knowledgeId} />
            {/* {reviewContent} */}
          </Header>
        )}

        {question.rawException ? (
          <ErrorDescription
            errorMessage={question.description}
            rawException={question.rawException}
            className="error-description"
          />
        ) : null}

        <Layout>
          <Space align="center" wrap>
            {defaultInputType !== 'choice' && (
              <Form.Item
                name="inputType"
                valuePropName="value"
                className="question-input-type"
                initialValue={defaultInputType || undefined}
              >
                <Select
                  key={inputType}
                  disabled={disabled}
                  size="middle"
                  placeholder="Select a method"
                  bordered
                  onSelect={(value: string) => {
                    setInputType(value);
                  }}
                >
                  <Option value="value" disabled={question.type === 'which'}>
                    Write in answer
                  </Option>
                  <Option value="choice" disabled={question.type !== 'which'}>
                    Choose from options
                  </Option>
                  <Option value="technique">Teach a technique</Option>
                  <Option value="delegate">Delegate to someone</Option>
                  <Option value="skip">Skip the question</Option>
                  {/* TODO: Remove this condition once KOG-779 is resolved */}
                  {source === 'single' && (
                    <Option value="upload">Upload files</Option>
                  )}
                  <Option value="stop">Stop working on this run</Option>
                  <Option value="retry">Try again from this point</Option>
                  <Option value="book">Learn a book</Option>
                </Select>
              </Form.Item>
            )}

            {showEnhancedTechnique ? (
              <Button
                type="link"
                onClick={handleEnhancedTeaching}
                loading={createDebugCommandProps.loading}
              >
                Teach me!
              </Button>
            ) : null}
          </Space>

          <Content className="question-inputs">{renderInput()}</Content>
        </Layout>
        <Footer>
          {inputType !== '' && (
            <>
              <Form.Item name="secret" valuePropName="checked">
                <Checkbox
                  disabled={disabled}
                  data-cy="secret-checkbox"
                  onChange={() => toggleSecret(!isSecret)}
                >
                  Secret
                </Checkbox>
              </Form.Item>
              <Form.Item name="remember" valuePropName="checked">
                <Checkbox disabled={disabled} data-cy="remember-checkbox">
                  Always use this answer for this department?
                </Checkbox>
              </Form.Item>
              {/* {showRunPoint && (
            <Form.Item name="runFromThisPoint" valuePropName="checked">
              <Checkbox disabled={disabled} data-cy="run-point-checkbox">
                Re-run from this point
              </Checkbox>
            </Form.Item>
          )} */}
              {/* TODO: Enable this checkbox once supported in brain */}
              {showRunPoint && null}
            </>
          )}

          {delegateText && (
            <div className="question-delegate-message">{delegateText}</div>
          )}
        </Footer>
      </Layout>
    </Form>
  );
}

interface IQuestionFormProps {
  /**
   * Questions to show
   */
  questions: any[];

  /**
   * KnowledgeId to use when looking up facts
   */
  knowledgeId: string;

  /**
   * Prop to indicate if parent question is answered
   */
  isAnswered?: boolean;

  onSuccess?: () => void;
  allowUpdate?: boolean;
  hideAnswered: boolean;
  dispatchContextPath?: boolean;
  allowContextDebugging: boolean;
  worker?: Worker;
}

// TODO: Refactor this file
function QuestionForm(props: IQuestionFormProps) {
  const {
    questions,
    knowledgeId,
    isAnswered,
    onSuccess,
    allowUpdate = false,
    hideAnswered = false,
    dispatchContextPath = true,
    allowContextDebugging,
    worker
  } = props;

  const { username } = useSelector(userSelector);
  const dispatch = useAppDispatch();

  const [answeredQuestionIds, setAnsweredQuestionIds] = useState(new Set());
  const [loadingQuestionIds, setLoadingQuestionIds] = useState(new Set());
  const [questionValues, setQuestionValues] = useState<any>({});
  const [questionTypes, setQuestionTypes] = useState<any>({});

  const onFinish = (question: any, values: any) => {
    const variables: any = {
      input: AppUtil.prepareAnswerInputVariables({
        question,
        values,
        owner: username
      })
    };

    setLoadingQuestionIds((prevState) => prevState.add(question.id));

    // useMutation hook cannot be used because there are a variable
    // number of mutations.
    dispatch(createAnswer(variables))
      .unwrap()
      .then(() => {
        loadingQuestionIds.delete(question.id);
        setAnsweredQuestionIds((prevState) => prevState.add(question.id));

        const newQuestionValues = { ...questionValues };
        delete newQuestionValues[question.id];
        setQuestionValues(newQuestionValues);

        const newQuestionTypes = { ...questionTypes };
        delete newQuestionTypes[question.id];
        setQuestionTypes(newQuestionTypes);

        if (onSuccess) {
          onSuccess();
        }
      });
  };

  const onSubmit = () => {
    ampTrackEvent('ClickQuestionSubmit');
    Object.keys(questionValues)
      .filter((id) => AppUtil.areQuestionFormValuesValid(questionValues[id]))
      .forEach((id) => {
        onFinish(
          questions.find((q) => q.id === id),
          questionValues[id]
        );
      });
  };

  const onValuesChange = (question: any, allValues: any) => {
    if (allValues.inputType === 'upload' && !!allValues.upload) {
      onFinish(question, allValues);
    } else {
      setQuestionValues((prevState: any) => ({
        ...prevState,
        [question?.id]: allValues
      }));
    }
  };

  const questionComponents = (questions || []).map((q, index) => {
    let freeze = false;
    let text = '';
    let type = 'value';
    let learn = false;
    let secret = false;

    if (hideAnswered) {
      if (answeredQuestionIds.has(q?.id) || q.isAnswered) {
        return null;
      }
    }

    if (q.isAnswered) {
      freeze = true;
      // todo: move api to redux async action
      const { loading, error, data } = useQuery(
        AppConstants.APIS.GET_ENTITY.answer(),
        {
          variables: { id: q.answerId }
        }
      );
      if (loading) {
        return (
          <div className="question-answer-loader">
            <Loader key={q.id} />
          </div>
        );
      }
      if (error) {
        return (
          <div>
            {' '}
            {error.graphQLErrors.map(({ message }, i) => (
              <Alert
                // eslint-disable-next-line react/no-array-index-key
                key={i}
                message="Error"
                description={message}
                type="error"
                showIcon
              />
            ))}{' '}
          </div>
        );
      }
      const answer = data.getAnswer;
      text = answer.text;
      secret = answer.secret;

      learn = answer.type.includes('_learning');

      switch (answer.type) {
        case 'value_learning':
          type = 'value';
          break;
        case 'path_learning':
        case 'path':
          type = 'technique';
          break;
        case 'choice_learning':
          type = 'choice';
          break;
        default:
          type = answer.type;
          break;
      }
    } else {
      freeze = loadingQuestionIds.has(q.id);
    }

    const isLastQuestion = index + 1 === questions.length;

    return (
      <>
        <Question
          disabled={allowUpdate ? false : freeze}
          showRunPoint={allowUpdate && isAnswered}
          question={q}
          key={q?.id}
          knowledgeId={knowledgeId}
          answer={{
            value: text,
            inputType: type,
            secret,
            learn
          }}
          onValuesChange={(allValues) => {
            onValuesChange(q, allValues);
          }}
          onTypeChange={(questionType) =>
            setQuestionTypes((prevState: any) => ({
              ...prevState,
              [q?.id]: questionType
            }))
          }
          onEnter={() => {
            onSubmit();
          }}
          dispatchContextPath={dispatchContextPath}
          allowContextDebugging={allowContextDebugging}
          worker={worker}
        />
        {!isLastQuestion && (
          <Divider
            style={{
              margin: 0
            }}
          />
        )}
      </>
    );
  });

  let disableSubmit = false;
  if (isAnswered || Object.keys(questionValues).length === 0) {
    disableSubmit = true;
  }

  // let disableSubmit = allowUpdate ? false : isAnswered;
  if (questions?.length === 1) {
    const questionType = (questionTypes as any)[questions[0].id];
    if (questionType === 'upload') {
      disableSubmit = true;
    }
  }

  if (questionComponents.some((q) => q !== null)) {
    return (
      <div
        className={classNames('question-group', {
          multiple: questions?.length > 1
        })}
      >
        {questionComponents}
        <Row justify="start">
          <Col>
            <Button
              loading={loadingQuestionIds.size > 0}
              size="middle"
              type="primary"
              data-cy="question-submit"
              className="question-submit"
              onClick={onSubmit}
              disabled={disableSubmit}
            >
              {allowUpdate ? 'Update' : 'Submit'}
            </Button>
          </Col>
        </Row>
      </div>
    );
  }
  return null;
}

export default QuestionForm;
