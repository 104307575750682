import { Modal } from 'antd';
import React from 'react';
import RunFactRenderer from '../components/playground2/RunFactRenderer';
import { ITableColumn } from '../components/table/interface';
import Table from '../components/table/Table';
import { Fact, Relation } from '../generated/API';
import useFact from '../hooks/useFact';
import './FactRelationsViewerPopup.less';

interface IProps {
  fact: Fact;
  knowledgeId: string;
  onClose: () => void;
}

function FactRelationsViewerPopup(props: IProps) {
  const { onClose, fact, knowledgeId } = props;

  const relations = fact.relations || [];

  const factIds = relations
    .map((relation) => relation?.factId || undefined)
    .filter(Boolean)! as string[];

  const { getFactById, loading } = useFact({
    ids: factIds,
    knowledgeId
  });

  const relationColumns: ITableColumn<Relation>[] = [
    {
      id: 'name',
      dataKey: 'name',
      title: 'Name',
      renderColumn: (value) => value
    },
    {
      id: 'value',
      dataKey: 'value',
      title: 'Value',
      renderColumn: (_value, record) => (
        <RunFactRenderer
          key={record.factId}
          fact={getFactById(record.factId!)}
          knowledgeId={knowledgeId}
          divider
        />
      )
    }
  ];

  return (
    <Modal
      className="fact-relations-viewer-popup"
      title="Related facts"
      centered
      open
      footer={null}
      onCancel={onClose}
      width={800}
    >
      <Table
        uniqueRowId="id"
        columns={relationColumns}
        data={relations}
        loading={loading}
        className="fact-relations-table"
      />
    </Modal>
  );
}

export default FactRelationsViewerPopup;
