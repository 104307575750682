import React from 'react';
import { useSelector } from 'react-redux';
import RunFactsDrawer from '../drawers/RunFactsDrawer';
import AnswerFactsDrawer from '../drawers/AnswerFactsDrawer';
import DocsDrawer from '../drawers/DocsDrawer';
import { useAppDispatch } from '../stores/hooks';
import { drawerSelector, hideDrawer } from '../stores/slices/appDrawer';
import AppConstants from '../utils/AppConstants';

function CurrentDrawer() {
  const dispatch = useAppDispatch();
  const { currentDrawerId, currentDrawerParams } = useSelector(drawerSelector);

  const onClose = () => {
    dispatch(hideDrawer());
  };

  switch (currentDrawerId) {
    case AppConstants.DRAWERS.DOCS:
      return <DocsDrawer onClose={onClose} />;

    case AppConstants.DRAWERS.ANSWER_FACTS:
      return <AnswerFactsDrawer onClose={onClose} {...currentDrawerParams} />;

    case AppConstants.DRAWERS.RUN_FACTS:
      return <RunFactsDrawer onClose={onClose} {...currentDrawerParams} />;

    default:
      return null;
  }
}

export default CurrentDrawer;
