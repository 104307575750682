import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  ListRequestsByProcedureQuery,
  ListRequestsByProcedureQueryVariables,
  Request,
  RequestAnswer,
  RequestQuestion
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';
import { getQuestionAnswer } from '../../utils/request';

export interface IProcedureQuestion {
  question: RequestQuestion;
  requestMeta: {
    id: string;
    workerId: string;
    knowledgeId: string;
  };
  id: string;
  createdAt: string | null;
  answer?: RequestAnswer;
}

export interface GetProcedureRequestsParams
  extends ListRequestsByProcedureQueryVariables {
  page?: number;
}

export const getProcedureRequests = createAsyncThunk<
  FetchResult<ListRequestsByProcedureQuery>,
  GetProcedureRequestsParams,
  AsyncThunkConfig
>(
  'procedureRequests/fetchAll',
  async ({ limit, nextToken, procedureId, departmentId, state }, thunkAPI) =>
    thunkAPI.extra.appSyncClient.query({
      query: Queries.RequestsByProcedure(),
      variables: {
        procedureId,
        departmentId,
        limit,
        nextToken,
        state
      }
    })
);

const ProcedureRequestsAdapter = createEntityAdapter<IProcedureQuestion>({
  selectId: (procedureQuestion) => procedureQuestion.question.id
});

interface IState {
  loading: boolean;
  error: any;
  nextToken: string | undefined;
  pageDataMap: Record<number, IProcedureQuestion[]>;
}

export const procedureRequestsSlice = createSlice({
  name: 'procedureRequests',
  initialState: ProcedureRequestsAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    pageDataMap: {}
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getProcedureRequests.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(
      getProcedureRequests.fulfilled,
      (state, { payload, meta }) => {
        if (payload?.data?.listRequestsByProcedure) {
          const { items = [], nextToken } =
            payload.data.listRequestsByProcedure;

          const requests = items as Request[];
          const procedureQuestions = requests.reduce<IProcedureQuestion[]>(
            (acc, cur) => {
              const procedureQuestions: IProcedureQuestion[] =
                cur.questions!.map((question) => ({
                  question,
                  requestMeta: {
                    id: cur.id,
                    workerId: cur.worker!.id,
                    knowledgeId: cur.worker!.knowledgeId!
                  },
                  id: question.id,
                  createdAt: cur.createdAt!,
                  answer: getQuestionAnswer(question, cur.answers!)
                }));
              return acc.concat(procedureQuestions);
            },
            []
          );

          if (!meta.arg.nextToken) {
            ProcedureRequestsAdapter.setAll(state, procedureQuestions);
          } else {
            ProcedureRequestsAdapter.addMany(state, procedureQuestions);
          }

          if (meta.arg.page && requests.length > 0) {
            state.pageDataMap[meta.arg.page] = procedureQuestions;
          }

          state.nextToken = nextToken || undefined;
        }
        state.loading = false;
      }
    );
    builder.addCase(getProcedureRequests.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.loading = false;
    });
  }
});

export const procedureRequestsSelector =
  ProcedureRequestsAdapter.getSelectors<RootState>(
    (state) => state.procedureRequests
  );

export const ProcedureRequestsQuerySelector = (state: RootState) => ({
  loading: state.procedureRequests.loading,
  requests: procedureRequestsSelector.selectAll(state),
  error: state.procedureRequests.error,
  nextToken: state.procedureRequests.nextToken,
  pageDataMap: state.procedureRequests.pageDataMap
});

export default procedureRequestsSlice.reducer;
