/* eslint-disable no-underscore-dangle */
import {
  Button,
  Col,
  Divider,
  Layout,
  Row,
  Spin,
  Tooltip,
  Typography
} from 'antd';
import classNames from 'classnames';
import React, { useLayoutEffect, useState } from 'react';
import { useMutation } from '@apollo/client';
import {
  Request as RequestInterface,
  Worker,
  RequestQuestion as RequestQuestionInterface,
  CreateRequestAnswerMutation,
  CreateRequestAnswerMutationVariables,
  CreateLearnedAnswerMutation,
  CreateLearnedAnswerMutationVariables,
  Department,
  ReplaceStepAction,
  Procedure,
  ValueNotKnownQuestion
} from '../../generated/API';
import RequestQuestion, { IQuestionConfig } from './RequestQuestion';
import './Request.less';
import { ampTrackEvent } from '../../analytics';
import {
  getQuestionAnswer,
  isQuestionAnswered,
  isRequestHandled,
  prepareAnswerActionInput,
  prepareAnswerInput,
  verifyQuestionValues
} from '../../utils/request';
import { IQuestionValues, QuestionInputType } from './interface';
import Mutations from '../../graphql/Mutations';
import AppConstants from '../../utils/AppConstants';
import AppUtil from '../../utils/AppUtil';
import MarkdownRenderer from '../MarkdownRenderer';

interface IRequestsProps {
  request: RequestInterface;
  worker: Worker;
  department?: Department;
  procedure?: Procedure;
  hideAnsweredQuestion: boolean;
  questionConfig: IQuestionConfig;
  dispatchContextPath: boolean;
  onSuccess?: () => void;
  suggestions?: {
    handleClick: (suggestion: string[]) => void;
  };
}

function Request(props: IRequestsProps) {
  const {
    request,
    worker,
    department,
    procedure,
    hideAnsweredQuestion,
    questionConfig,
    dispatchContextPath,
    onSuccess,
    suggestions
  } = props;
  const { questions: _questions } = request;

  const pendingQuestions = _questions?.filter((question) => {
    return !isQuestionAnswered(question, request.answers!);
  });
  const questions = hideAnsweredQuestion ? pendingQuestions : _questions;

  const [questionValuesMap, setQuestionValuesMap] = useState<
    Record<string, IQuestionValues>
  >({});
  const [loadingQuestionIds, setLoadingQuestionIds] = useState(new Set());

  const [createRequestAnswer] = useMutation<CreateRequestAnswerMutation>(
    Mutations.CreateRequestAnswer()
  );
  const [createLearnedAnswer] = useMutation<CreateLearnedAnswerMutation>(
    Mutations.CreateLearnedAnswer()
  );

  const dispatchHighlightStepEvent = () => {
    const contextPath = request.contextPath!.map(
      (path: any) => `${path.ctxId}:${path.sentenceId}`
    );
    const event = new CustomEvent(AppConstants.EVENT_NAMES.STEP_HIGHLIGH_ADD, {
      detail: {
        contextPath,
        questionStep: AppUtil.getStepNodeFromQuestion(
          request.contextPath,
          request.contextId
        )
      }
    });
    window.dispatchEvent(event);
  };

  const dispatchRemoveHighlightStepEvent = () => {
    const event = new CustomEvent(
      AppConstants.EVENT_NAMES.STEP_HIGHLIGH_REMOVE,
      {
        detail: {
          questionStep: AppUtil.getStepNodeFromQuestion(
            request.contextPath,
            request.contextId
          )
        }
      }
    );
    window.dispatchEvent(event);
  };

  useLayoutEffect(() => {
    setTimeout(() => {
      if (dispatchContextPath && request.contextPath?.length) {
        dispatchHighlightStepEvent();
      }
    }, 1000);

    return () => {
      if (dispatchContextPath && request.contextPath?.length) {
        dispatchRemoveHighlightStepEvent();
      }
    };
  }, [dispatchContextPath]);

  const onFinish = (
    question: RequestQuestionInterface,
    values: IQuestionValues
  ) => {
    setLoadingQuestionIds((prevState) => prevState.add(question.id));
    const variables: CreateRequestAnswerMutationVariables = {
      input: prepareAnswerInput(request, question, values)
    };

    return createRequestAnswer({
      variables
    })
      .then((answerResponse) => {
        const createdAnswer = answerResponse.data?.createRequestAnswer;
        if (createdAnswer) {
          const newQuestionValuesMap = { ...questionValuesMap };
          delete newQuestionValuesMap[question.id];
          setQuestionValuesMap(newQuestionValuesMap);

          if (values.remember && createdAnswer.id && department) {
            const action = prepareAnswerActionInput(values);

            // KOG-2227
            if (values.inputType === QuestionInputType.VALUE_CHOICE) {
              const valueNotKnownQuestion =
                question as unknown as ValueNotKnownQuestion;
              action.useLiteralValueAction!.choices =
                valueNotKnownQuestion.choices;
            }

            const variables: CreateLearnedAnswerMutationVariables = {
              input: {
                departmentId: department.id,
                originalAnswerId: createdAnswer.id,
                procedureId: procedure?.id || null,
                action,
                filter: {
                  questionType: question.__typename,
                  lexicalPath: (question as any).lexicalPath || null,
                  stepPath: request.stepPath
                }
              }
            };
            createLearnedAnswer({
              variables
            });
          }

          if (onSuccess) {
            onSuccess();
          }
        }
      })
      .finally(() => {
        loadingQuestionIds.delete(question.id);
      });
  };

  const onSubmit = (e: React.MouseEvent) => {
    e.stopPropagation();
    ampTrackEvent('ClickQuestionSubmit');
    return Object.keys(questionValuesMap)
      .filter((id) => verifyQuestionValues(questionValuesMap[id]))
      .forEach((id) => {
        onFinish(questions!.find((q) => q.id === id)!, questionValuesMap[id]);
      });
  };

  const onValuesChange = (
    question: RequestQuestionInterface,
    allValues: IQuestionValues
  ) => {
    if (
      allValues.inputType === QuestionInputType.VALUE_UPLOAD &&
      verifyQuestionValues(allValues)
    ) {
      onFinish(question, allValues);
    } else {
      setQuestionValuesMap((prevState) => ({
        ...prevState,
        [question.id]: allValues
      }));
    }
  };

  if (questions?.length === 0 || !worker) {
    return null;
  }

  let disableSubmit = isRequestHandled(request);
  const areValuesValid = Object.values(questionValuesMap).every((e) =>
    verifyQuestionValues(e)
  );
  if (!areValuesValid) {
    disableSubmit = true;
  }
  if (questions?.length === 1) {
    const values = questionValuesMap[questions[0].id];
    if (values) {
      const questionType = values.inputType;
      if (questionType === QuestionInputType.VALUE_UPLOAD) {
        disableSubmit = true;
      }
    }
  }

  const renderSuggestions = () => {
    if (!suggestions) {
      return null;
    }

    const suggestionsList: Array<string[]> = [];

    if (request.suggestedAnswers?.length) {
      request.suggestedAnswers.forEach((suggestedAnswer) => {
        const action = suggestedAnswer.action as any;
        if (action?.__typename === 'ReplaceStepAction') {
          suggestionsList.push((action as ReplaceStepAction).newSteps!);
        }
      });
    }

    if (!suggestionsList.length) {
      return null;
    }

    return (
      <div>
        <Typography.Title level={5}>Suggestions</Typography.Title>
        {suggestionsList.map((suggestion, index) => (
          <Tooltip title="Use this in place of the above procedure">
            <div
              key={index}
              className={classNames([
                'suggestion-text',
                {
                  'suggestion-border-bottom':
                    index !== suggestionsList.length - 1
                }
              ])}
              onClick={(e) => {
                e.stopPropagation();
                suggestions.handleClick(suggestion);
              }}
            >
              <MarkdownRenderer>{suggestion.join('\n')}</MarkdownRenderer>
            </div>
          </Tooltip>
        ))}
      </div>
    );
  };

  const hasSuggestions = (request.suggestedAnswers || []).length > 0;

  return (
    <div className={classNames(['run-requests'])}>
      {hasSuggestions && (
        <div
          className={classNames([
            'suggestions-group',
            { 'width-50': suggestions }
          ])}
        >
          {renderSuggestions()}
        </div>
      )}
      <div
        className={classNames([
          'question-group',
          { 'width-50': hasSuggestions }
        ])}
      >
        <Layout className="question">
          <Layout.Content>
            <div
              className={classNames('question-group', {
                multiple: questions!.length > 1
              })}
            >
              {questions!.map((question, index) => {
                const isLastQuestion = index + 1 === questions!.length;

                return (
                  <Spin
                    spinning={loadingQuestionIds.has(question.id)}
                    key={question.id}
                  >
                    <RequestQuestion
                      request={request}
                      question={question}
                      worker={worker}
                      department={department}
                      config={questionConfig}
                      answerId={
                        getQuestionAnswer(question, request.answers!)?.id ||
                        undefined
                      }
                      onValuesChange={(values) =>
                        onValuesChange(question, values)
                      }
                    />
                    {!isLastQuestion && (
                      <Divider
                        style={{
                          margin: 0
                        }}
                      />
                    )}
                  </Spin>
                );
              })}
              <Row justify="start">
                <Col>
                  <Button
                    loading={loadingQuestionIds.size > 0}
                    size="middle"
                    type="primary"
                    data-cy="question-submit"
                    className="question-submit"
                    onClick={onSubmit}
                    disabled={disableSubmit}
                  >
                    Submit
                  </Button>
                </Col>
              </Row>
            </div>
          </Layout.Content>
        </Layout>
      </div>
    </div>
  );
}

export default Request;
