import { QuestionCircleOutlined } from '@ant-design/icons';
import React, { useEffect, useState } from 'react';
import Joyride, { CallBackProps, STATUS } from 'react-joyride';
import i18n from '@/utils/i18n';
import { Tooltip } from 'antd';
import AppUtil from '../../utils/AppUtil';
import { IWalkthroughStep, WalkthroughType } from './interface';
import WALKTHROUGH_TYPE_STEPS_MAP from './walkthroughSteps';
import './Walkthrough.less';

const i18nt = (s: string, d?: string) => i18n.t('walkthrough', s, d);

interface IProps {
  type: WalkthroughType;
}

interface IState {
  run: boolean;
  stepIndex: number;
  tourActive: boolean;
  steps: IWalkthroughStep[];
}

function Walkthrough(props: IProps) {
  const { type } = props;

  const STORAGE_KEY = `walkthrough:${type}`;

  const steps = WALKTHROUGH_TYPE_STEPS_MAP[type];

  const [state, setState] = useState<IState>({
    run: false,
    stepIndex: 0,
    steps,
    tourActive: false
  });

  const updateState = (key: keyof IState, value: any) => {
    const updatedState = {
      ...state,
      [key]: value
    };
    setState(updatedState);
  };

  const isWalkthroughSeen = () => {
    const localValue = AppUtil.getFromLocalStorage(STORAGE_KEY);
    if (localValue) {
      return localValue === 'true';
    }
    return false;
  };

  const setWalkthroughSeen = () => {
    updateState('run', false);
    AppUtil.setInLocalStorage(STORAGE_KEY, 'true');
  };

  const isDisabled = () => {
    const localValue = AppUtil.getFromLocalStorage('walkthrough:disabled');
    if (localValue) {
      return localValue === 'true';
    }
    return false;
  };

  useEffect(() => {
    if (!isDisabled()) {
      setTimeout(() => {
        if (isWalkthroughSeen()) {
          updateState('run', false);
        } else {
          updateState('run', true);
        }
      }, 2 * 1000);
    }
  }, [type]);

  const handleCallback = (data: CallBackProps) => {
    const { status } = data;
    const finishedStatuses: string[] = [STATUS.FINISHED, STATUS.SKIPPED];

    if (finishedStatuses.includes(status)) {
      setWalkthroughSeen();
    }
  };

  return (
    <div className="walkthrough">
      {!state.run && (
        <Tooltip title={i18nt('show_walkthrough', 'Show Walkthrough')}>
          <QuestionCircleOutlined
            className="trigger kog-icon"
            onClick={() => {
              updateState('run', true);
            }}
            data-cy="walkthrough-trigger"
          />
        </Tooltip>
      )}
      <Joyride
        callback={handleCallback}
        continuous
        run={state.run}
        disableScrolling
        locale={{
          back: 'Back',
          close: 'Close',
          last: 'Close',
          next: 'Next',
          open: 'Open the dialog',
          skip: 'Skip'
        }}
        steps={state.steps.map((step) => ({
          target: step.target,
          content: step.content,
          title: step.title,
          disableBeacon: true,
          hideCloseButton: true
        }))}
        showProgress
        showSkipButton
        styles={{
          options: {
            primaryColor: '#04a7fb'
          },
          tooltipContainer: {
            textAlign: 'left'
          }
        }}
      />
    </div>
  );
}

export default Walkthrough;
