import React from 'react';
import { IWalkthroughStep, WalkthroughType } from './interface';

// title is optional for each step

const WALKTHROUGH_TYPE_STEPS_MAP: Record<WalkthroughType, IWalkthroughStep[]> =
  {
    // individual example page
    [WalkthroughType.EXAMPLE]: [
      {
        content: (
          <div>
            You can use this example or tweak it as needed. For help reach out
            to
            <em> support@kognitos.com</em>. If you would like to participate in
            our
            <em> Koncierge Builders Program</em> please drop in a note at
            <em> builders@kognitos.com</em>. <em>Koncierge 2</em> is the next
            generation AI engine that can do cool stuff like this 👇 (Coming
            soon!)
            <iframe
              src="https://www.youtube.com/embed/0qRpJvCmsAY"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture"
              allowFullScreen
            />
          </div>
        ),
        target: '.input-bak:first-of-type',
        title: 'Welcome to this example!'
      },
      {
        content:
          'This asks the Kognitos Brain to learn the given book. Books provide domain knowledge and ability to understand business terminology in English.',
        target: '.cell_code_knowledge:first-of-type',
        title: "'A book is a gift that you can open again and again'"
      },
      {
        content:
          "The Kognitos Brain reads this procedure and understands it the same way you would. While running it, if it has any questions it will ask you. Don't worry if something looks incomplete.",
        target: '.cell_code_procedure:first-of-type',
        title: 'A procedure in English'
      },
      {
        content:
          'Press this to request the Kognitos Brain to learn all of the above. After learning it will present a link to the top level procedure that you can click and start using right away.',
        target: '.learn-example-btn',
        title: "Let's do it!"
      }
    ],
    // procedure page
    [WalkthroughType.PROCEDURE]: [
      {
        content:
          'The Kognitos Brain understands and runs this diligently. Each fact and decision is recorded for every step that is run. You can modify this procedure as needed.',
        target: '.procedure-editor',
        title: 'The business logic in English'
      },
      // {
      // content: '<procedure-edit-save-content>',
      // target: '.procedure-save-btn',
      // title: '<procedure-edit-save-title>'
      // },
      {
        content: (
          <div>
            <em>Koncierge (beta)</em> helps you tweak the procedure. Just
            highlight an area of the procedure and ask it in fluent English the
            change you want. It will rewrite the procedure for you. This feature
            is currently in <em>beta</em> and we record the interactions so we
            can get better over time.
          </div>
        ),
        target: '.procedure-extra-actions',
        title: 'You want to make some tweaks?'
      },
      {
        content: 'You can see all the previous runs of this procedure here.',
        target: '.procedure-all-runs-link',
        title: 'The history of your business process'
      },
      {
        content:
          'Kognitos Brain learns facts and techniques each time you handle an exception and ask it to "remember".',
        target: '.procedure-learnings-link',
        title: 'On the job learnings'
      },
      {
        content:
          'While the procedure can be run ad hoc with "Run" above and via the Action Bar, you can also schedule it to run periodically.',
        target: '.procedure-schedules-link',
        title: 'Schedule it'
      },
      {
        content:
          'Your colleagues, partners or customers can be invited to invoke this procedure directly via email without any custom setup!',
        target: '.procedure-share-link',
        title: 'Invoke the procedure via email'
      },
      // {
      //   content:
      //     'Share this procedure immediately with everyone in your organization. They can run it via the Kognitos Action Bar.',
      //   target: '.procedure-public-switch',
      //   title: 'Show off your work!'
      // },
      {
        content: 'You can run the procedure now by clicking Run',
        target: '.procedure-run-btn',
        title: 'Run it now'
      },
      {
        content:
          'Normally this is not needed. But, you can run this process one step at a time if needed.',
        target: '.procedure-extra-actions',
        title: 'Run one step at a time'
      }
    ],
    // procedure run page
    [WalkthroughType.PROCEDURE_RUN]: [
      {
        content:
          'Each step is recorded in detail. You can drill into the details of a step if it involved sub-steps. Not only is this a detailed audit, it also allows you to rewind and redo portions of the process if needed.',
        target: '.run-left-top',
        title: 'The details of what was done.'
      },
      // {
      //   content:
      //     'The business information relevant to the step in the process.',
      //   target: '.playground-bottom-vertical',
      //   title: 'Detailed information'
      // },
      {
        content:
          'You can see technical information on how the Kognitos Brain converts the English in the business process into computer-speak internally. This is here because it is fun to watch it 🙂.',
        target: '.worker-status',
        title: 'Live updates from the Kognitos Brain'
      }
      // {
      //  content: '<playground-output-content>',
      //  target: '.run-output',
      //  title: '<playground-output-title>'
      // },
      // {
      //  content: '<playground-settings-content>',
      //  target: '.settings',
      //  title: '<playground-settings-title>'
      // }
    ],
    // playground page
    [WalkthroughType.PLAYGROUND]: [
      // {
      //   content: (
      //     <div>
      //       <b>Beta feature</b>: Use if you are familiar with the docs.
      //     </div>
      //   ),
      //   target: '.run-items-list',
      //   title: 'Here you experiment'
      // },
      // {
      //   content: 'All the information relevant to the steps run.',
      //   target: '.playground-bottom-vertical',
      //   title: 'Detailed information'
      // },
      {
        content:
          'You can see technical information on how the Kognitos Brain converts the English in the business process into computer-speak internally. This is here because it is fun to watch it 🙂.',
        target: '.worker-status',
        title: 'Live updates from the Kognitos Brain'
      },
      // {
      //  content: '<playground-output-content>',
      //  target: '.run-output',
      //  title: '<playground-output-title>'
      // },
      // {
      //  content: '<playground-settings-content>',
      //  target: '.settings',
      //  title: '<playground-settings-title>'
      // },
      {
        content:
          'If you have something that works, click here to convert the contents of the playground and create a repeatable business process with it.',
        target: '.new-procedure-button',
        title: 'Time to deploy!'
      },
      {
        content:
          'Click if you want to start a new playground. If you want to preserve the current playground, just bookmark it in the browser.',
        target: '.playground-new-run',
        title: 'Start a new playground'
      }
    ]
  };

export default WALKTHROUGH_TYPE_STEPS_MAP;
