import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter,
  PayloadAction
} from '@reduxjs/toolkit';
import {
  ContextConnection,
  GetContextsForStepQuery,
  GetContextsForStepQueryVariables,
  ListCommandsByWorkerQuery,
  ListCommandsByWorkerQueryVariables,
  Step
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import { FetchResult } from '@apollo/client';
import AppConstants from '@/utils/AppConstants';
import { isNil } from 'ramda';
import {
  delayExponentialBackoff,
  retryPromiseWithDelay
} from '@/utils/promise';
import { getWorker, getWorkerDependencies } from './worker';

export const getStepsFromContext = (context: ContextConnection): Step[] => {
  if (!context.items?.length) {
    return [];
  }
  return context.items.reduce<Step[]>((acc, cur) => {
    cur?.stepList?.forEach((step) => {
      acc.push(step!);
    });
    return acc;
  }, []);
};

const ensureWorkerId = ({ workerId }: any) => {
  if (isNil(workerId)) {
    console.error('workerId is null');
    return false;
  }
  return true;
};

export const getSteps = createAsyncThunk<
  FetchResult<GetContextsForStepQuery>,
  GetContextsForStepQueryVariables,
  AsyncThunkConfig
>(
  'step/contexts',
  async ({ workerId, contextId, stepId }, thunkAPI) =>
    retryPromiseWithDelay({
      promiseFn: () =>
        thunkAPI.extra.appSyncClient.query({
          query: AppConstants.APIS.WORKERS.STEP_CONTEXTS(),
          variables: {
            workerId,
            contextId,
            stepId
          }
        }),
      errorPredicate: (e: any) => e?.message === 'Execution timed out.',
      retries: 5,
      delayTime: delayExponentialBackoff
    }),
  {
    condition: ensureWorkerId
  }
);

export const getCommands = createAsyncThunk<
  FetchResult<ListCommandsByWorkerQuery>,
  ListCommandsByWorkerQueryVariables,
  AsyncThunkConfig
>(
  'commands/byWorkerId',
  async ({ workerId }, thunkAPI) =>
    thunkAPI.extra.appSyncClient.query({
      query: AppConstants.APIS.COMMANDS.LIST_BY_WORKER(),
      variables: {
        workerId
      }
    }),
  {
    condition: ensureWorkerId
  }
);

export const createStepId = (step: Partial<Step>) =>
  `${step.workerId}-${step.contextId}-${step.id}`;

const StepsAdapter = createEntityAdapter<Step>({
  selectId: createStepId
});

interface IState {
  loading: boolean;
  error: any;
  nextToken: any;
  stepContextMap: Record<string, ContextConnection>;
}

export const stepsSlice = createSlice({
  name: 'steps',
  initialState: StepsAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    stepContextMap: {}
  }),
  reducers: {
    updateStep: StepsAdapter.updateOne,
    removeStepContextFromMap: (state, action: PayloadAction<string>) => {
      delete state.stepContextMap[action.payload];
    },
    removeSteps: StepsAdapter.removeMany,
    resetSteps: (state) => {
      state.stepContextMap = {};
      StepsAdapter.removeAll(state);
    },
    addSteps: (
      state,
      action: PayloadAction<{ steps: Step[]; workerId: string }>
    ) => {
      const { steps } = action.payload;

      if (steps.length) {
        steps.forEach((step) => {
          StepsAdapter.updateOne(state, {
            id: step.id,
            changes: step
          });

          // const contextMapKey = createStepId({
          //   contextId: step.contextId,
          //   workerId
          // });
          // state.stepContextMap[contextMapKey]
        });

        // if (context) {
        //   const contextMapKey = createStepId({
        //     contextId: contextId!,
        //     workerId,
        //     id: stepId || null
        //   });
        //   state.stepContextMap[contextMapKey] = context;
        // }
      }
    }
  },
  extraReducers: (builder) => {
    [getSteps.pending, getWorkerDependencies.pending].forEach((action) => {
      builder.addCase(action, (state) => {
        state.loading = true;
      });
    });

    [getSteps.fulfilled, getWorkerDependencies.fulfilled].forEach((action) => {
      builder.addCase(action, (state, { payload, meta }) => {
        state.loading = false;

        const { contextId, workerId, stepId } = meta.arg as any;

        const context = payload?.data?.getContextsForStep as ContextConnection;

        if (context) {
          const stepList = getStepsFromContext(context);
          if (stepList.length) {
            StepsAdapter.addMany(state, stepList as Step[]);

            if (context) {
              const contextMapKey = createStepId({
                contextId: contextId!,
                workerId,
                id: stepId || null
              });
              state.stepContextMap[contextMapKey] = context;
            }
          }
        }
      });
    });

    [getSteps.rejected, getWorkerDependencies.rejected].forEach((action) => {
      builder.addCase(action, (state, { payload }) => {
        state.error = payload as any;
        state.loading = false;
      });
    });

    builder.addCase(getWorker.fulfilled, (state) => {
      state.stepContextMap = {};
      state.error = undefined;
      StepsAdapter.removeAll(state);
    });
  }
});

export const { updateStep, removeStepContextFromMap, removeSteps, resetSteps } =
  stepsSlice.actions;

export const stepsSelectors = StepsAdapter.getSelectors<RootState>(
  (state) => state.steps
);

export const stepsQuerySelector = (state: RootState) => ({
  loading: state.steps.loading,
  steps: stepsSelectors.selectAll(state),
  error: state.steps.error,
  nextToken: state.steps.nextToken,
  contextMap: state.steps.stepContextMap
});

export default stepsSlice.reducer;
