import React, { useMemo } from 'react';
import { Button, Modal } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import CSVTable from '../components/CSVTable';
import { downloadCSV } from '../utils/csv';
import './AnswerCSVPopup.less';

interface IProps {
  title: string;
  data: string;
  onClose: () => void;
  onEdit?: (data: string) => Promise<unknown>;
}

export default function AnswerCSVPopup(props: IProps) {
  const { title, data, onClose, onEdit } = props;

  const memoCSVTable = useMemo(
    () => <CSVTable onEdit={onEdit} boundingBoxes={[]} csvData={data} />,
    [data]
  );

  return (
    <Modal
      className="answer-csv-modal"
      title={
        <span className="header">
          {title}
          <Button
            type="primary"
            onClick={() => downloadCSV(title, data)}
            icon={<DownloadOutlined />}
          >
            Download
          </Button>
        </span>
      }
      centered
      open
      closable
      onCancel={onClose}
      footer={null}
      maskClosable={false}
    >
      {memoCSVTable}
    </Modal>
  );
}
