/* eslint-disable react/jsx-no-constructed-context-values */
import React, { useEffect, useState } from 'react';
import {
  Context,
  Department,
  GetContextsForStepQueryVariables,
  Procedure,
  Question,
  Request,
  Step,
  Worker
} from '@/generated/API';
import { runSelector } from '@/stores/slices/run';
import { useAppDispatch, useAppSelector, useATQ } from '@/stores/hooks';
import {
  createStepId,
  getSteps,
  stepsQuerySelector,
  stepsSelectors
} from '@/stores/slices/steps';
import {
  getWorker,
  workerSelector,
  getWorkerDependenciesV2,
  updateWorker
} from '@/stores/slices/worker';
import { RootState } from '@/stores/AppStore';
import { isNil, uniq } from 'ramda';
import { useLocation, useParams } from 'react-router-dom';
import { useSelector } from 'react-redux';
import AppUtil from '../utils/AppUtil';
import { requestSelectors } from '../stores/slices/request';
import useStepRequests, { StepRequestsMap } from '../hooks/useStepRequests';
import useStepQuestions, { StepQuestionsMap } from '../hooks/useStepQuestions';
import { questionSelectors } from '../stores/slices/question';
import {
  departmentQuerySelector,
  getDepartment
} from '../stores/slices/department';
import { proceduresQuerySelector } from '../stores/slices/procedure';
import useRunSubscriptions from '../hooks/useRunSubscriptionts';

export interface INodeFactsDetails {
  nodeId: string;
  step: Step;
}

export interface RunContextType {
  stepsList: Array<Step>;
  rootContext: Context | null;
  worker: Worker;
  loading: boolean;
  error: any;
  questions: Array<Question>;
  stepQuestionsMap: StepQuestionsMap;
  requests: Request[];
  stepRequestsMap: StepRequestsMap;
  procedure: Procedure | null;
  expandedNodes: string[];
  updateExpandedNodes: (newNodes: string[], reset?: boolean) => void;
  highlightedNodes: string[];
  updateHighlightedNodes: (newNodes: string[], reset?: boolean) => void;
  openLongAnswerForNode: React.Dispatch<React.SetStateAction<string>>;
  showLongAnswerForNode: string;
  openFactsForNode: React.Dispatch<
    React.SetStateAction<INodeFactsDetails | null>
  >;
  showFactsForNode: INodeFactsDetails | null;
  workerDepartment: Department | undefined;
}

export const RunContextV2 = React.createContext<RunContextType>(null!);

const RunProviderSelector = (state: RootState) => {
  const { workerId } = runSelector(state);
  const worker = workerSelector.selectById(state, workerId!)!;

  const stepsList = stepsSelectors
    .selectAll(state)
    .filter((step) => step.workerId === workerId);

  const questions = questionSelectors
    .selectAll(state)
    .filter((question) => question.workerId === workerId);

  const requests = requestSelectors
    .selectAll(state)
    .filter((request) => request.worker!.id === workerId);

  return {
    workerId,
    worker,
    stepsList,
    questions,
    requests
  };
};

interface IProps {
  children: React.ReactNode;
  contextId: string;
}

export function RunProviderV2(props: IProps) {
  const location = useLocation();
  const { children, contextId } = props;

  const { workerId, worker, stepsList, questions, requests } =
    useAppSelector(RunProviderSelector);

  const { department: selectedDepartment, departments } = useAppSelector(
    departmentQuerySelector
  );
  const { procedures } = useAppSelector(proceduresQuerySelector);
  const department = departments.find((dep) => dep.id === worker?.departmentId); // user may visit a playground with department other than selected, hence we need to find it

  const dispatch = useAppDispatch();

  const [expandedNodes, setExpandedNodes] = useState<string[]>([]);
  const [highlightedNodes, setHighlightedNodes] = useState<string[]>([]);

  // Hack for fetching after some time
  const [refetchNodes, setRefetchNodes] = useState<string[]>([]);

  useEffect(() => {
    setExpandedNodes([]);
    setHighlightedNodes([]);
  }, [workerId]);

  useEffect(() => {
    if (
      worker?.departmentId &&
      worker.departmentId !== selectedDepartment?.id
    ) {
      dispatch(getDepartment({ id: worker.departmentId }));
    }
  }, [worker?.departmentId, selectedDepartment]);

  const { procedureId } = useParams();

  const procedure =
    procedures.find((procedure) => procedure.id === procedureId) || null;

  const workerQuery = useATQ(getWorker, {
    variables: { id: workerId },
    wait: isNil(workerId)
  });

  useRunSubscriptions({
    workerId,
    onStepsUpdate: (paths) => {
      setRefetchNodes(paths);
    },
    onWorkerKnowledgeIdUpdate: (knowledgeId) => {
      dispatch(updateWorker({ id: workerId!, changes: { knowledgeId } }));
    }
  });

  // TODO: Remove once KOG-2618 is resolved
  const workerDependenciesQuery = useATQ(getWorkerDependenciesV2, {
    variables: {
      workerId: worker?.id,
      contextId,
      requestExceptionSupported: AppUtil.isRequestExceptionSupported(department)
    },
    wait:
      isNil(worker?.id) ||
      AppUtil.isPlaygroundV3(location.pathname) ||
      isNil(department),
    polling: AppUtil.getQueryPollInterval() * 2
  });

  useEffect(() => {
    if (worker?.id) {
      dispatch(
        getSteps({
          workerId: worker.id,
          contextId
        })
      );
    }
  }, [worker?.id, contextId]);

  const { contextMap } = useSelector(stepsQuerySelector);

  const contextMapKey = createStepId({
    workerId: worker?.id,
    contextId,
    id: null as any
  });
  const rootContext: Context | null =
    contextMap[contextMapKey]?.items?.[0] || null;

  const loading = workerQuery.loading || workerDependenciesQuery.loading;
  const error = workerQuery.error || workerDependenciesQuery.error;

  const refetchContexts = (nodes: string[]) => {
    const promises = [`${contextId}:`, ...nodes].map((node) => {
      const [ctxId, sentenceId] = node.split(':');

      const variables: GetContextsForStepQueryVariables = {
        workerId: workerId!,
        contextId: ctxId
      };
      if (sentenceId) {
        variables.stepId = sentenceId;
      }
      return dispatch(getSteps(variables));
    });

    Promise.allSettled(promises);
  };

  useEffect(() => {
    let timeout: any = null;
    if (Array.isArray(refetchNodes) && worker?.id) {
      timeout = setTimeout(() => {
        refetchContexts(refetchNodes);
      }, 1000);
    }

    return () => timeout && clearTimeout(timeout);
  }, [refetchNodes, worker?.id]);

  const updateExpandedNodes: RunContextType['updateExpandedNodes'] = (
    newNodes,
    reset
  ) => {
    const nodes = reset ? newNodes : uniq([...expandedNodes, ...newNodes]);
    setExpandedNodes(nodes);
    setRefetchNodes(nodes);
  };

  const { stepRequestsMap } = useStepRequests({
    requests,
    department,
    updateExpandedNodes
  });
  const { stepQuestionsMap } = useStepQuestions({
    questions,
    department,
    updateExpandedNodes
  });

  const updateHighlightedNodes: RunContextType['updateHighlightedNodes'] = (
    newNodes,
    reset
  ) => {
    const nodes = reset ? newNodes : uniq([...highlightedNodes, ...newNodes]);
    setHighlightedNodes(nodes);
  };

  const [showLongAnswerForNode, openLongAnswerForNode] = useState('');
  const [showFactsForNode, openFactsForNode] =
    useState<INodeFactsDetails | null>(null);

  const value: RunContextType = {
    worker,
    rootContext: rootContext?.workerId === workerId ? rootContext : null,
    stepsList,
    requests,
    loading,
    error,
    stepRequestsMap,
    questions,
    stepQuestionsMap,
    procedure,
    expandedNodes,
    updateExpandedNodes,
    highlightedNodes,
    updateHighlightedNodes,
    showLongAnswerForNode,
    openLongAnswerForNode,
    showFactsForNode,
    openFactsForNode,
    workerDepartment: department
  };

  return (
    <RunContextV2.Provider value={value}>{children}</RunContextV2.Provider>
  );
}

export function useRunCtxV2() {
  return React.useContext(RunContextV2);
}
