import { ClusterOutlined, LoadingOutlined } from '@ant-design/icons';
import { Dropdown, Button, Menu } from 'antd';
import React from 'react';
import { Link, useNavigate } from 'react-router-dom';
import { useSelector } from 'react-redux';
import { useAuth } from '../provider/auth';
import AppConstants from '../utils/AppConstants';
import { ConfirmDialog } from '../utils/AppDialogs';
import AppUtil from '../utils/AppUtil';
import ComponentFactory from '../utils/ComponentFactory';
import routes from '../utils/routes';
import { showPopup } from '../stores/slices/appPopup';
import { useAppDispatch } from '../stores/hooks';
import { userSelector } from '../stores/slices/user';

function UserMenu() {
  const { logout } = useAuth();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { username, organization } = useSelector(userSelector);

  const handleLogout = () => {
    const onConfirm = () => {
      logout()
        .catch((err: any) => {
          AppUtil.logError('Error Logging out !', err);
          logout();
        })
        .finally(() => {
          // TODO: Remove this after 2 weeks post prod deployment.
          localStorage.removeItem('persist:root');
          navigate(routes.global.getHomePageUrl());
        });
    };

    ConfirmDialog(
      'Log out',
      'Are you sure?',
      AppConstants.ICONS.QUESTION,
      onConfirm,
      undefined,
      'Yes',
      'No',
      'modal-logout',
      {
        'data-cy': 'logout-yes'
      }
    );
  };

  const menu = (
    <Menu>
      {organization.data === null || organization.loading ? (
        <Menu.Item
          key="create"
          data-cy={
            organization.loading
              ? 'menu-organization-loading'
              : 'menu-organization-create'
          }
          disabled={organization.loading}
          onClick={() => {
            dispatch(
              showPopup({
                popupId: AppConstants.POPUPS.CREATE_ORGANIZATION,
                popupParams: {}
              })
            );
          }}
        >
          {organization.loading ? (
            <>
              Loading Organization <LoadingOutlined />
            </>
          ) : (
            'Create Organization'
          )}
        </Menu.Item>
      ) : (
        <Menu.ItemGroup
          title={
            <>
              Organizations <ClusterOutlined />
            </>
          }
        >
          <Menu.Item
            key={organization.data.id}
            data-cy="menu-organization"
            onClick={() => {
              dispatch(
                showPopup({
                  popupId: AppConstants.POPUPS.CREATE_ORGANIZATION,
                  popupParams: {
                    readonly: true
                  }
                })
              );
            }}
          >
            {organization.data.name}
          </Menu.Item>
        </Menu.ItemGroup>
      )}

      {AppUtil.isKognitosEmail(username) && (
        <Menu.Item key="admin" data-cy="menu-admin">
          <Link to={routes.admin.getAdminPageUrl()}>Admin</Link>
        </Menu.Item>
      )}

      <Menu.Item onClick={handleLogout} key="logout" data-cy="menu-logout">
        Logout
      </Menu.Item>
    </Menu>
  );

  return (
    <span className="user-menu">
      <Dropdown overlay={menu} trigger={['click']}>
        <Button
          size="small"
          type="link"
          data-cy="user-settings"
          className="ant-dropdown-link"
          onClick={(e) => e.preventDefault()}
        >
          <span data-cy="menu-username">{username}</span>
          {ComponentFactory.getIcon(AppConstants.ICONS.DOWN)}
        </Button>
      </Dropdown>
    </span>
  );
}

export default UserMenu;
