import React, { useEffect } from 'react';
import { useSelector } from 'react-redux';
import { useAppDispatch } from '../stores/hooks';
import AppConstants from '../utils/AppConstants';
import './AnswerFactsViewer.less';
import { drawerSelector, showDrawer } from '../stores/slices/appDrawer';

interface IProps {
  id: string;
  title: string;
  knowledgeId: string;
  data: any[];
}

export default function AnswerFactsViewer(props: IProps) {
  const { id, title, knowledgeId, data } = props;

  const { currentDrawerId } = useSelector(drawerSelector);

  const dispatch = useAppDispatch();

  useEffect(() => {
    if (currentDrawerId === AppConstants.DRAWERS.ANSWER_FACTS) {
      dispatch(
        showDrawer({
          drawerId: AppConstants.DRAWERS.ANSWER_FACTS,
          drawerParams: {
            id,
            data,
            title,
            knowledgeId
          }
        })
      );
    }
  }, [JSON.stringify(data)]);

  const show = () => {
    dispatch(
      showDrawer({
        drawerId: AppConstants.DRAWERS.ANSWER_FACTS,
        drawerParams: {
          id,
          data,
          title,
          knowledgeId
        }
      })
    );
  };

  return (
    <div className="answer-facts-viewer">
      <span
        onClick={show}
        data-cy="facts-drawer-view-link"
        className="view-link"
      >
        {data.length} {title}
      </span>
    </div>
  );
}
