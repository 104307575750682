//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Loading messages shown in the UI.
// Original List is from https://gist.github.com/meain/6440b706a97d2dd71574769517e7ed32
//
// Loading messages to show in the UI
//
// General criteria for a message
// - Should be a short one liner
// - Should be funny/witty
// - Shouldn't be offensive to anyone << BE EXTRA VIGILAINT !!
//
// Add/Remove items from this list over time to keep new messages

export default [
  'Generating witty dialog...',
  'Swapping time and space...',
  'Spinning violently around the y-axis...',
  'Tokenizing real life...',
  'Bending the spoon...',
  'We need a new fuse...',
  'Have a good day.',
  'The architects are still drafting',
  'The bits are breeding',
  "We're building the buildings as fast as we can",
  'Would you prefer chicken, steak, or tofu?',
  '(Pay no attention to the man behind the curtain)',
  '...and enjoy the elevator music...',
  'Please wait while the little elves draw your map',
  'Would you like fries with that?',
  'Go ahead -- hold your breath!',
  "...at least you're not on hold...",
  'Hum something loud while others stare',
  "We're testing your patience",
  "Why don't you order a sandwich?",
  'The bits are flowing slowly today',
  "The last time I tried this the monkey didn't survive. Let's hope it works better this time.",
  'I should have had a V8 this morning.',
  'My other loading screen is much faster.',
  "Testing on Timmy... We're going to need another Timmy.",
  'Reconfoobling energymotron...',
  '(Insert quarter to speed this up)',
  'Are we there yet?',
  'Just count to 10 (backwards helps)',
  'Why so serious?',
  "It's not you. It's me.",
  'Counting backwards from Infinity',
  "Don't panic...This is taking a while...",
  'Do not run! We are your friends!',
  "We're making you a cookie.",
  'Spinning the wheel of fortune...',
  'Computing chance of success',
  'I feel like im supposed to be loading something. . .',
  'What do you call 8 Hobbits? A Hobbyte.',
  'Please wait until the sloth starts moving.',
  "Don't break your screen yet!",
  "I swear it's almost done.",
  "Let's take a mindfulness minute...",
  'Unicorns are at the end of this road, I promise.',
  'Listening for the sound of one hand clapping...',
  'Putting the icing on the cake. The cake is not a lie...',
  'Cleaning off the cobwebs...',
  'Granting wishes...',
  "Time flies when you're having fun.",
  'Get some coffee and come back in ten minutes..',
  'Spinning the hamster…',
  'Load it and they will come',
  'Convincing AI not to turn evil..',
  'There is no spoon. Because we are not done loading it',
  'Your left thumb points to the right and your right thumb points to the left.',
  'How did you get here?',
  'Wait, do you smell something burning?',
  'Computing the secret to life, the universe, and everything.',
  'When nothing is going right, go left!!...',
  "I love my job only when I'm on vacation...",
  "I'm not lazy, I'm just relaxed!!",
  'Never steal. The government hates competition....',
  'Why are they called apartments if they are all stuck together?',
  'Life is Short – Talk Fast!!!!',
  'Optimism – is a lack of information.....',
  'Save water and shower together',
  'Whenever I find the key to success, someone changes the lock.',
  "Sometimes I think war is God's way of teaching us geography.",
  "I've got problem for your solution…..",
  "Where there's a will, there's a relative.",
  'User: the word computer professionals use when they mean !!idiot!!',
  'Adults are just kids with money.',
  'I think I am, therefore, I am. I think.',
  'A kiss is like a fight, with mouths.',
  "You don't pay taxes — they take taxes.",
  'I am free of all prejudices. I hate everyone equally.',
  "This is not a joke, it's a commit.",
  'Constructing additional pylons...',
  'We are not liable for any broken screens as a result of waiting.',
  'Hello IT, have you tried turning it off and on again?',
  "They just toss us away like yesterday's jam.",
  'The Elders of the Internet would never stand for it.',
  'Space is invisible mind dust, and stars are but wishes.',
  "Didn't know paint dried so quickly.",
  'Everything sounds the same',
  "I'm going to walk the dog",
  "I didn't choose the engineering life. The engineering life chose me.",
  "If I'm not back in five minutes, just wait longer.",
  "We're going to need a bigger boat.",
  'Web developers do it with <style>',
  'Cracking military-grade encryption...',
  'Simulating traveling salesman...',
  'Entangling superstrings...',
  'Twiddling thumbs...',
  'Laughing at your pictures - I mean, loading...',
  'Looking for sense of humour, please hold on.',
  'Please wait while the intern refills his coffee.',
  'A different error message? Finally, some progress!',
  'Hold on while we wrap up our AI together...sorry',
  'Please hold on as we reheat our coffee',
  'Winter is coming...',
  'Installing dependencies',
  'Distracted by cat gifs',
  'Finding someone to hold my beer',
  'BRB, working on my side project',
  '@todo Insert witty loading message',
  "Let's hope it's worth the wait",
  "Whatever you do, don't look behind you...",
  'Please wait... Consulting the manual...',
  "It is dark. You're likely to be eaten by a ghost.",
  'Loading funny message...',
  'Feel free to spin in your chair',
  'Go ahead, hold your breath and do an ironman plank till loading complete',
  "Help, I'm trapped in a loader!",
  "Chuck Norris doesn't wear a watch. HE decides what time it is.",
  'Mining some bitcoins...',
  'Initializing the initializer...',
  'When was the last time you dusted around here?',
  "Never let a computer know you're in a hurry.",
  'A computer will do what you tell it to do, but that may be much different from what you had in mind.',
  'Shovelling coal into the server',
  'How about this weather, eh?',
  'The severity of your issue is always lower than you expected.',
  'Reading Terms and Conditions for you.',
  'Digested cookies being baked again.',
  'Live long and prosper.',
  'You may call me Steve.',
  'You seem like a nice person...',
  "Coffee at my place, tommorow at 10A.M. - don't be late!",
  'Work, work...',
  'Patience! This is difficult, you know...',
  'Discovering new ways of making you wait...',
  'Your time is very important to us. Please wait while we ignore you...',
  'Time flies like an arrow; fruit flies like a banana',
  'Two men walked into a bar; the third ducked...',
  'Sooooo... Have you seen my vacation photos yet?',
  'TODO: Insert elevator music',
  'Please wait while the minions do their work',
  'Doing the heavy lifting',
  "We're working very Hard .... Really",
  'Waking up the minions',
  'You are number 2843684714 in the queue',
  'Please wait while we serve other customers...',
  'Our premium plan is faster',
  'Do you like my loading animation? I made it myself',
  "No, I'm awake. I was just resting my eyes.",
  'One mississippi, two mississippi...'
];
