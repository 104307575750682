import { LRLanguage, indentNodeProp, delimitedIndent, foldNodeProp, foldInside, HighlightStyle, LanguageSupport } from '@codemirror/language';
import { completeFromList } from '@codemirror/autocomplete';
import { styleTags, tags } from '@lezer/highlight';
import { LRParser } from '@lezer/lr';

// This file was generated by lezer-generator. You probably shouldn't edit it.
const spec_identifier = {__proto__:null,connect:8, to:10, is:18, find:24, the:26, in:28};
const parser = LRParser.deserialize({
  version: 14,
  states: "#lQYQPOOOOQO'#Cd'#CdOOQO'#Cp'#CpOOQO'#Ck'#CkQYQPOOOhQPO'#C_OmQPO'#CcOrQPO'#CgOOQO-E6i-E6iOwQPO,58yO|QPO,58}OcQPO,59ROOQO'#Cb'#CbOOQO1G.e1G.eOOQO1G.i1G.iOOQO'#Cl'#ClO!UQPO1G.mOOQO-E6j-E6jOwQPO7+$XOOQO<<Gs<<Gs",
  stateData: "!^~OcOSPOS~OSTOYQO[VOePO~OTXO~OXYO~O]ZO~Oe[O~OY^OePO~O^bOePO~O",
  goto: "!]ePPPfPPjfpPPfPPP{!RPPP!XTQOSQ]XRcbSUOSQ^YT_Z`QSORWSQ`ZRa`TROS",
  nodeNames: "⚠ LineComment Program Connect connect to BookName Assignment VariableName is String Find find the in",
  maxTerm: 21,
  skippedNodes: [0,1],
  repeatNodeCount: 2,
  tokenData: "#b~RYXYqYZq]^qpqqrs!S!]!^!q!c!}!|#R#S!|#T#o!|$g~!|~vSc~XYqYZq]^qpqq~!VTOr!Srs!fs#O!S#O#P!k#P~!S~!kOY~~!nPO~!S~!vQP~OY!qZ~!q~#RTe~!Q![!|!c!}!|#R#S!|#T#o!|$g~!|",
  tokenizers: [0],
  topRules: {"Program":[0,2]},
  specialized: [{term: 21, get: value => spec_identifier[value] || -1}],
  tokenPrec: 0
});

const KognitosLanguage = LRLanguage.define({
    parser: parser.configure({
        props: [
            indentNodeProp.add({
                Application: delimitedIndent({ closing: ')', align: false })
            }),
            foldNodeProp.add({
                Application: foldInside
            }),
            styleTags({
                Identifier: tags.variableName,
                Boolean: tags.bool,
                String: tags.string,
                LineComment: tags.lineComment
            })
        ]
    }),
    languageData: {
        commentTokens: { line: ';' }
    }
});
const kognitosCompletion = KognitosLanguage.data.of({
    autocomplete: completeFromList([
        { label: 'opportunity', type: 'variable' },
        { label: 'salesforce', type: 'keyword' }
    ])
});
const kogHighlightStyle = HighlightStyle.define([
    // { tag: tags.variableName, color: '#fc6' },
    // { tag: tags.string, color: '#fc6' },
    { tag: tags.comment, color: '#f5d', fontStyle: 'italic' }
]);
function Kognitos() {
    return new LanguageSupport(KognitosLanguage, [kognitosCompletion]);
}

export { Kognitos, KognitosLanguage, kogHighlightStyle, kognitosCompletion };
