export default {
  document_processing:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/invoice-receipt.svg',
  salesforce:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/salesforce.svg',
  gsheet:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/google-sheets.svg',
  pii_processing:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/pii.svg',
  microsoft:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/powerbi.svg',
  hubspot:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/hubspot.svg',
  stripe:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/stripe.svg',
  openapi:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/openapi.svg',
  google_drive:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/google-drive.svg',
  slack:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/slack.svg',
  outlook:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/outlook.svg',
  excel:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/excel.svg',
  netsuite:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/netsuite.svg',
  gmail:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/gmail.svg',
  docusign:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/docusign.svg',
  mongodb:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/mongodb.svg',
  twilio:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/twilio.svg',
  workday:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/workday.svg',
  google_cloud:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/cloud_storage.svg',
  aws_s3:
    'https://kognitos-static-assets.s3.us-west-2.amazonaws.com/librarian/icons/aws_s3.svg'
};
