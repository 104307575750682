import {
  ClusterOutlined,
  ExportOutlined,
  InfoCircleTwoTone
} from '@ant-design/icons';
import { Space, Tooltip, Divider } from 'antd';
import React from 'react';
import { showPopup } from '@/stores/slices/appPopup';
import { Fact } from '../../generated/API';
import { useAppDispatch } from '../../stores/hooks';
import AppConstants from '../../utils/AppConstants';
import FormattingUtil from '../../utils/FormattingUtil';
import './RunFactRenderer.less';
import FactDocumentSummaryList from '../facts/FactDocumentSummary';

interface IFactRendererProps {
  fact: Fact | undefined;
  knowledgeId: string | null;
  debugMode?: boolean;
  divider?: boolean;
}

function RunFactRenderer(props: IFactRendererProps) {
  const { fact, knowledgeId, debugMode = false, divider = false } = props;

  const dispatch = useAppDispatch();

  if (!fact) {
    return null;
  }

  const parsedValue = FormattingUtil.parseBrainValue(fact.value || '');
  const value = parsedValue ?? fact.names?.[0];

  return (
    <div className="run-fact-value" data-cy="fact-value">
      <div className="value">
        <div>
          {
            FormattingUtil.getFormattedAnswer({
              answer: value,
              knowledgeId: knowledgeId!
            }).answer
          }
          <FactDocumentSummaryList
            fact={fact}
            showInPopup
            knowledgeId={knowledgeId}
          />
        </div>
        <Space className="fact-render-actions">
          {fact.relations?.length ? (
            <Tooltip title={value !== null && 'View relations'}>
              <div
                className="fact-related-icon"
                onClick={() => {
                  dispatch(
                    showPopup({
                      popupId: AppConstants.POPUPS.VIEW_FACT_RELATIONS,
                      popupParams: {
                        fact,
                        knowledgeId
                      }
                    })
                  );
                }}
              >
                <ClusterOutlined /> {value === null && 'View relations'}
              </div>
            </Tooltip>
          ) : null}

          {fact?.viewUrl && (
            <Tooltip title="View">
              <ExportOutlined
                data-cy="fact-view-action"
                onClick={(e) => {
                  e.stopPropagation();
                  window.open(fact?.viewUrl!, '_blank');
                }}
                className="fact-viewurl-icon"
              />
            </Tooltip>
          )}

          {debugMode && (
            <Tooltip title="Debug fact">
              <InfoCircleTwoTone
                className="info-icon"
                onClick={() => {
                  dispatch(
                    showPopup({
                      popupId: AppConstants.POPUPS.VIEW_RAW_FACT,
                      popupParams: {
                        factId: fact.id,
                        knowledgeId
                      }
                    })
                  );
                }}
              />
            </Tooltip>
          )}
        </Space>
      </div>

      {divider && <Divider />}

      {value === null && fact.relations?.length === 0 && !debugMode ? (
        <div className="empty" />
      ) : null}
    </div>
  );
}

export default RunFactRenderer;
