// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Component to display actions on a tree node.
//
import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Input, Menu, Select } from 'antd';

import {
  IKnowledgeTreeNode,
  ITreeNodeActionData
} from '@details/knowledge/KnowledgeInterfaces';
import ComponentFactory from '@utils/ComponentFactory';
import AppConstants from '@utils/AppConstants';
import i18n from '@utils/i18n';

// Component CSS
import './KnowledgeTreeNodeActions.less';

// Component i18n
const i18nt = (s: string, d?: string) => i18n.t('common', s, d);

// --------- Dropdown UI for actions on a tree node ---------
export type TreeNodeActionCallback = (actionId: string, nodeId: string) => void;

const TREE_NODE_ACTIONS: Record<string, string> = {
  EDIT: i18nt('edit', 'Edit'),
  INSERT: i18nt('insert', 'Insert'),
  DELETE: i18nt('delete', 'Delete')
};

interface ITreeNodeActionsMenu {
  /**
   * Tree Node to create an actions menu for
   */
  treeNode: IKnowledgeTreeNode;
  /**
   * Action callback
   */
  onTreeNodeAction: TreeNodeActionCallback;
}

export function TreeNodeActionsMenu(props: ITreeNodeActionsMenu) {
  const { treeNode, onTreeNodeAction } = props;

  const mItems: any[] = [];
  Object.keys(TREE_NODE_ACTIONS).forEach((k) => {
    const v = TREE_NODE_ACTIONS[k];
    mItems.push(
      <Menu.Item key={k} onClick={() => onTreeNodeAction(k, treeNode.id)}>
        {v}
      </Menu.Item>
    );
  });

  return (
    <Dropdown
      overlay={<Menu>{mItems}</Menu>}
      placement="bottomRight"
      arrow={false}
    >
      <div className="tree-node-actions">
        {ComponentFactory.getIcon(AppConstants.ICONS.VERTICAL_ELLIPSES)}
      </div>
    </Dropdown>
  );
}

// Component Prop Types
TreeNodeActionsMenu.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  treeNode: PropTypes.object.isRequired,
  onTreeNodeAction: PropTypes.func.isRequired
};

// --------- UI for an action on a tree node ---------

interface ITreeNodeAction {
  /**
   * The action for which to render a UI
   */
  actionId: string;
  /**
   * Node label/name
   */
  nodeTitle: string;
  /**
   * Node type ITreeNodeData:type ('number' | 'string' | 'date')
   */
  nodeType: string | undefined;
  /**
   * Action Confirm callback.
   */
  onConfirm: (actionData: ITreeNodeActionData) => void;
  /**
   * Action Cancel callback.
   */
  onCancel: () => void;
}

export function TreeNodeAction(props: ITreeNodeAction) {
  const { Option } = Select;
  const { actionId, nodeTitle, nodeType, onConfirm, onCancel } = props;
  let actionUI;

  const [title, setTitle] = useState<string>();
  const [type, setType] = useState<string>();

  useEffect(() => {
    setTitle(nodeTitle);
    setType(nodeType);
  }, [nodeTitle, nodeType]);

  const actionIcons = ComponentFactory.getOkCancelIcons();
  const okCancelPanel = (
    <div className="-actions">
      <span onClick={onCancel} title={i18nt('cancel', 'Cancel')}>
        {actionIcons.cancel}
      </span>
      <span
        title={i18nt('ok', 'Ok')}
        onClick={() => {
          onConfirm({
            nodeTitle: title || '',
            nodeType: type || ''
          });
        }}
      >
        {actionIcons.ok}
      </span>
    </div>
  );

  switch (actionId) {
    case 'EDIT': {
      actionUI = (
        <div className="tn-action edit">
          <Input
            size="small"
            type="text"
            value={title}
            onChange={(e) => setTitle(e.target.value)}
            placeholder="Label"
          />
          <Select value={type} onChange={(v: any) => setType(v)}>
            {['string', 'number', 'date'].map((t) => (
              <Option key={t} value={t}>
                {i18nt(`node_type_${t}`, t)}
              </Option>
            ))}
          </Select>
          {okCancelPanel}
        </div>
      );
      break;
    }
    case 'DELETE': {
      actionUI = (
        <div className="tn-action delete">
          <div className="-prompt">
            {i18nt('deleteNodeConfirm', 'Delete %s').replace('%s', nodeTitle)}
          </div>
          {okCancelPanel}
        </div>
      );
      break;
    }
    default:
      actionUI = null;
  }

  return actionUI;
}
