import { Button } from 'antd';
import React, { useState } from 'react';
import { Department, Worker } from '../../generated/API';
import { useAppDispatch } from '../../stores/hooks';
import { sendCommandsSeq } from '../../stores/slices/worker';
import AppConstants from '../../utils/AppConstants';
import AppUtil from '../../utils/AppUtil';
import Editor from '../editor';
import { ParseFeedback, ParseResult } from '../ParseFeedback';
import S3FileUpload from '../Upload';

interface IProps {
  department: Department;
  worker: Worker;
  contextId: string;
  endStepPosition: number;
}

// TODO: Create a common component and re-use for context debugger and playground
function ContextDebuggerNewStep(props: IProps) {
  const { department, worker, contextId, endStepPosition } = props;

  const [value, setValue] = useState('');
  const [isLoading, setLoading] = useState(false);
  const [parseResult, setParseResult] = useState<ParseResult>(ParseResult.FAIL);
  // const { transcript, resetTranscript, browserSupportsSpeechRecognition } =
  //   useSpeechRecognition();
  // const [isRecording, setIsRecording] = useState(false);

  const dispatch = useAppDispatch();

  // Ref: https://linear.app/kognitos/issue/KOG-2190/remove-microphone-icon
  // Audio input hook
  // useEffect(() => {
  //   if (transcript.length > 0) {
  //     setValue(transcript);
  //   }
  // }, [transcript]);

  // const startRecording = () => {
  //   if (browserSupportsSpeechRecognition) {
  //     setIsRecording(true);
  //     SpeechRecognition.startListening();
  //   }
  // };

  // const stopRecording = () => {
  //   if (browserSupportsSpeechRecognition) {
  //     setIsRecording(false);
  //     SpeechRecognition.stopListening();
  //     resetTranscript();
  //   }
  // };

  // const onToggleRecording = () => {
  //   if (browserSupportsSpeechRecognition) {
  //     if (isRecording) {
  //       stopRecording();
  //     } else {
  //       startRecording();
  //     }
  //   } else {
  //     message.info(`This browser does not support speech recording.`);
  //   }
  // };

  const submitMultilineCmd = async (text: string) => {
    if (parseResult !== ParseResult.PASS || value.trim() === '') {
      return;
    }

    setLoading(true);
    try {
      await dispatch(
        sendCommandsSeq({
          text: AppUtil.formatProcedureText(text),
          workerId: worker.id,
          contextId,
          currentStepPosition: endStepPosition,
          debug: true
        })
      );
    } catch (e) {
      console.error(e);
    } finally {
      setValue('');
      setLoading(false);
      // stopRecording();
    }
  };

  const toolbar = (
    <div className="toolbar">
      {value && (
        <ParseFeedback
          statement={value}
          scopeCode=""
          language="english"
          departmentId={department.id}
          parseCallback={setParseResult}
        />
      )}
      {/* {browserSupportsSpeechRecognition && (
        <Button
          type="link"
          icon={
            isRecording ? (
              <AudioFilled style={{ color: 'red' }} />
            ) : (
              <AudioOutlined />
            )
          }
          className="new-run-audio"
          onClick={onToggleRecording}
        />
      )} */}
      <div>
        <S3FileUpload
          minimal
          scope={AppConstants.S3_FILE_SCOPE.WORKER}
          disabled={isLoading}
          scopeId={worker.id!}
          multiple
          onChange={(response) => {
            setValue((oldValue: string) =>
              AppUtil.prepareFilesUploadCommand(response, oldValue)
            );
            setParseResult(ParseResult.PASS);
          }}
        />
      </div>
      <Button
        disabled={value.length <= 0 || parseResult !== ParseResult.PASS}
        loading={isLoading}
        type="primary"
        className="suffix"
        onClick={() => submitMultilineCmd(value)}
      >
        Run
      </Button>
    </div>
  );

  return (
    <div>
      <div className="actions">
        <div className="new-run-item">
          <div className="run-input">
            <div className="left">
              <div className="multiline-container">
                <Editor
                  className="run-input"
                  value={value}
                  onChange={setValue}
                  markdown={{
                    onSave(updatedValue) {
                      setValue(updatedValue);
                    }
                  }}
                />
                {toolbar}
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ContextDebuggerNewStep;
