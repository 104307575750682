/* eslint-disable react/jsx-props-no-spreading */
//
//  Copyright (C) - Kognitos, Inc. All rights reserved
//

import React from 'react';
import moment from 'moment';
import {
  Button,
  Checkbox,
  DatePicker,
  Input,
  Select,
  TimePicker,
  Upload
} from 'antd';
import {
  ArrowDownOutlined,
  ArrowUpOutlined,
  AudioOutlined,
  BarChartOutlined,
  BarsOutlined,
  BellFilled,
  BlockOutlined,
  BookOutlined,
  BranchesOutlined,
  BulbOutlined,
  CaretDownFilled,
  CaretLeftFilled,
  CaretRightFilled,
  CaretRightOutlined,
  CheckCircleTwoTone,
  CloseCircleTwoTone,
  CloseOutlined,
  DeleteOutlined,
  DownOutlined,
  EditOutlined,
  EnterOutlined,
  ExclamationCircleOutlined,
  ExclamationCircleTwoTone,
  FileTextOutlined,
  FilterOutlined,
  HeartFilled,
  HistoryOutlined,
  InfoCircleOutlined,
  LeftOutlined,
  LinkOutlined,
  LoadingOutlined,
  MailOutlined,
  MenuOutlined,
  PlaySquareOutlined,
  PlusCircleOutlined,
  QuestionCircleOutlined,
  RedoOutlined,
  RightOutlined,
  SearchOutlined,
  SettingOutlined,
  StepForwardOutlined,
  TableOutlined,
  UploadOutlined
} from '@ant-design/icons';

// Local Includes
import i18n from '@utils/i18n';
import AppConstants from '@utils/AppConstants';
import VerticalEllipsesIcon from '@components/VerticalEllipsesIcon';
import ImageRegions from '@components/ImageRegions';
import ImageArea from '@components/ImageArea';
import CSVTable from '@components/CSVTable';

import ColumnsLayout from '@components/layouts/ColumnsLayout';
import TilesLayout from '@components/layouts/TilesLayout';

// Details pages
import FlowDetails from '@details/FlowDetails';
import KnowledgeDetails from '@details/knowledge/KnowledgeDetails';
import { IKnowledgeEntity } from '@details/knowledge/KnowledgeInterfaces';
import NameIcon from '../components/icons/NameIcon';
import NameWithLogoIcon from '../components/icons/NameWithLogoIcon';
import BrainLogo from '../components/icons/BrainLogo';

type Key = string | number;

// Component i18n - Uses common section
const i18nt = (s: string, d: string) => i18n.t('Common', s, d);

const ComponentFactory = {
  // Get details page by entityType
  getDetailsPage: (entityType: string, entity: object, props = {}) => {
    switch (entityType) {
      case AppConstants.ENTITY_TYPES.PROCEDURE:
        return <FlowDetails procedure={entity} {...props} />;
      case AppConstants.ENTITY_TYPES.KNOWLEDGE_TREE:
        return (
          <KnowledgeDetails entity={entity as IKnowledgeEntity} {...props} />
        );

      default:
        return null;
    }
  },

  // Get popups by Id
  getPopup: (_popupId: any) => null,

  // Get icon by status
  getStatusIcon: (status: string) => {
    switch (status) {
      case 'success':
      case 'completed':
        return <CheckCircleTwoTone twoToneColor="#52c41a" />;
      case 'error':
        return <CloseCircleTwoTone twoToneColor="#ff4d4f" />;
      case 'info':
      case 'inprogress':
        return <LoadingOutlined twoToneColor="#1890ff" />;
      case 'warning':
        return <ExclamationCircleTwoTone twoToneColor="#faad14" />;
      default:
        return null;
    }
  },

  getOkCancelIcons: () => ({
    ok: <CheckCircleTwoTone twoToneColor="#52c41a" />,
    cancel: <CloseCircleTwoTone twoToneColor="#ff4d4f" />
  }),

  // get icon by id (See AppConstants.ICONS for ids
  getIcon: (iconId: string) => {
    switch (iconId) {
      case AppConstants.ICONS.AUDIO:
        return <AudioOutlined />;
      case AppConstants.ICONS.BRAIN_LOGO:
        return <BrainLogo />;
      case AppConstants.ICONS.CANCEL:
        return <CloseCircleTwoTone />;
      case AppConstants.ICONS.OK:
        return <CheckCircleTwoTone />;
      case AppConstants.ICONS.BAR_CHART:
        return <BarChartOutlined />;
      case AppConstants.ICONS.CLOSE:
        return <CloseOutlined />;
      case AppConstants.ICONS.DELETE:
        return <DeleteOutlined />;
      case AppConstants.ICONS.DOWN:
        return <DownOutlined />;
      case AppConstants.ICONS.DOWN_ARROW:
        return <ArrowDownOutlined />;
      case AppConstants.ICONS.EDIT:
        return <EditOutlined />;
      case AppConstants.ICONS.ENTER:
        return <EnterOutlined />;
      case AppConstants.ICONS.FACT:
        return <InfoCircleOutlined />;
      case AppConstants.ICONS.FORWARD:
        return <StepForwardOutlined />;
      case AppConstants.ICONS.FILTER:
        return <FilterOutlined />;
      case AppConstants.ICONS.HEART:
        return <HeartFilled />;
      case AppConstants.ICONS.HISTORY:
        return <HistoryOutlined />;
      case AppConstants.ICONS.LINK:
        return <LinkOutlined />;
      case AppConstants.ICONS.LOADING:
        return <LoadingOutlined />;
      case AppConstants.ICONS.LEFT_ARROW:
        return <LeftOutlined />;
      case AppConstants.ICONS.DOWN_CARET:
        return <CaretDownFilled />;
      case AppConstants.ICONS.LEFT_CARET:
        return <CaretLeftFilled />;
      case AppConstants.ICONS.RIGHT_ARROW:
        return <RightOutlined />;
      case AppConstants.ICONS.RIGHT_CARET:
        return <CaretRightFilled />;
      case AppConstants.ICONS.LEARNING:
        return <BulbOutlined />;
      case AppConstants.ICONS.IMAGE:
        return <BlockOutlined />;
      case AppConstants.ICONS.NAME:
        return <NameIcon />;
      case AppConstants.ICONS.NAME_WITH_LOGO:
        return <NameWithLogoIcon />;
      case AppConstants.ICONS.NOTIFICATION:
        return <BellFilled />;
      case AppConstants.ICONS.MENU:
        return <MenuOutlined />;
      case AppConstants.ICONS.PLAY:
        return <CaretRightOutlined />;
      case AppConstants.ICONS.PLUS:
        return <PlusCircleOutlined />;
      case AppConstants.ICONS.RUN:
        return <PlaySquareOutlined />;
      case AppConstants.ICONS.EMAIL:
        return <MailOutlined />;
      case AppConstants.ICONS.QUESTION:
        return <QuestionCircleOutlined />;
      case AppConstants.ICONS.RESTART:
        return <RedoOutlined />;
      case AppConstants.ICONS.SEARCH:
        return <SearchOutlined />;
      case AppConstants.ICONS.SETTINGS:
        return <SettingOutlined />;
      case AppConstants.ICONS.TEXT:
        return <FileTextOutlined />;
      case AppConstants.ICONS.TABLE:
        return <TableOutlined />;
      case AppConstants.ICONS.UP_ARROW:
        return <ArrowUpOutlined />;
      case AppConstants.ICONS.VERTICAL_ELLIPSES:
        return <VerticalEllipsesIcon />;
      case AppConstants.ICONS.WARNING_EXCLAMATION:
        return <ExclamationCircleOutlined />;
      case AppConstants.ICONS.BOOK:
        return <BookOutlined />;
      case AppConstants.ICONS.VISUALIZATION:
        return <BranchesOutlined />;
      case AppConstants.ICONS.WORKFLOW:
        return <BarsOutlined />;
      case AppConstants.ICONS.UPLOAD:
        return <UploadOutlined />;
      // When adding a new icon -- please keep this list sorted.
      default:
        return null;
    }
  },

  getInputElement(
    id: Key,
    type: string,
    value: any,
    options: any,
    onChangeCB: any,
    additionalProps: any
  ) {
    switch (type) {
      case AppConstants.INPUT_TYPES.TEXT:
      case AppConstants.INPUT_TYPES.STRING:
      case AppConstants.INPUT_TYPES.NUMBER:
        return (
          <Input
            id={id}
            key={id}
            value={value}
            onChange={(e) => onChangeCB(id, e.target.value)}
            {...additionalProps}
          />
        );

      case AppConstants.INPUT_TYPES.TEXT_AREA:
        return (
          <Input.TextArea
            id={id}
            key={id}
            value={value}
            onChange={(e: any) => onChangeCB(id, e.target.value)}
            {...additionalProps}
          />
        );

      case AppConstants.INPUT_TYPES.PASSWORD:
        return (
          <Input.Password
            id={id}
            key={id}
            value={value}
            onChange={(e) => onChangeCB(id, e.target.value)}
            {...additionalProps}
          />
        );

      case AppConstants.INPUT_TYPES.BOOLEAN_CHECKBOX:
        return (
          <Checkbox
            id={id}
            key={id}
            checked={value}
            onChange={(e) => onChangeCB(id, e.target.checked)}
            {...additionalProps}
          >
            {additionalProps.label}
          </Checkbox>
        );
      case AppConstants.INPUT_TYPES.DATE:
        // Value passed in and out are in millis. Datepicker expects moment
        return (
          <DatePicker
            id={id}
            key={id}
            value={value && moment(value)}
            onChange={(_d: any, dStr) => {
              onChangeCB(id, new Date(dStr).getTime());
            }}
            {...additionalProps}
          />
        );

      case AppConstants.INPUT_TYPES.TIME:
        // Value passed in and out are in millis. Timepicker expects moment
        return (
          <TimePicker
            use12Hours
            format="h:mm A"
            id={id}
            key={id}
            value={value}
            onChange={(tDayJs: any, tStr: string) => {
              onChangeCB(id, tStr, tDayJs);
            }}
            {...additionalProps}
          />
        );

      case AppConstants.INPUT_TYPES.SINGLE_SELECT:
        return (
          <Select
            id={id}
            key={id}
            value={value}
            onChange={(v) => {
              onChangeCB(id, v);
            }}
            {...additionalProps}
          >
            {options.map((o: { value: Key; label: string }) => (
              <Select.Option key={o.value} value={o.value}>
                {o.label}
              </Select.Option>
            ))}
          </Select>
        );

      case AppConstants.INPUT_TYPES.MULTIPLE_SELECT:
        return (
          <Select
            id={id}
            key={id}
            mode="multiple"
            allowClear
            value={value}
            onChange={(v) => {
              onChangeCB(id, v);
            }}
            {...additionalProps}
          >
            {options.map((o: { value: Key; label: string }) => (
              <Select.Option key={o.value} value={o.value}>
                {o.label}
              </Select.Option>
            ))}
          </Select>
        );

      case AppConstants.INPUT_TYPES.FILE_UPLOAD:
        return (
          <Upload id={id} key={id} onChange={onChangeCB} {...additionalProps}>
            <Button
              size="small"
              icon={ComponentFactory.getIcon(AppConstants.ICONS.UPLOAD)}
            >
              {i18nt('clickToUpload', 'Click to upload')}
            </Button>
          </Upload>
        );
      default:
        return null;
    }
  },

  // Based on a context's mime type, get the corresponding viewer.
  getContextViewer(
    contextInfo: { data: any; mimeType: string; url: string },
    props = {}
  ) {
    if (
      contextInfo.mimeType.startsWith('image/') ||
      contextInfo.mimeType === 'application/pdf'
    ) {
      return (
        <ImageRegions
          {...props}
          imgURL={contextInfo.url}
          mimeType={contextInfo.mimeType}
        />
      );
    }
    if (contextInfo.mimeType === 'text/csv') {
      return (
        <CSVTable {...props} csvData={contextInfo.data} boundingBoxes={[]} />
      );
    }

    return null;
  },

  // Based on a context's mime type, get the corresponding summary viewer.
  getContextSummaryViewer(
    contextInfo: { data: any; mimeType: string; url: string },
    props: any = {}
  ) {
    if (contextInfo.mimeType.startsWith('image/')) {
      return <ImageArea {...props} imgURL={contextInfo.url} readonly />;
    }
    if (contextInfo.mimeType === 'text/csv') {
      return (
        <CSVTable
          boundingBoxes={[props.boundingBox]}
          csvData={contextInfo.data}
          readonly
          summary
        />
      );
    }

    return null;
  },

  getLayoutComponent(componentName?: string) {
    switch (componentName) {
      case 'columns':
        return ColumnsLayout;
      case 'tiles':
        return TilesLayout;
      default:
        return undefined;
    }
  }
};

export default ComponentFactory;
