import { Dayjs } from 'dayjs';
import { ReactNode } from 'react';

// Component props should not include types from any third-party library. This will help us in avoiding lockins and for easy migration.

/* eslint-disable no-shadow */
export enum TableSizeType {
  SMALL = 'SMALL',
  MEDIUM = 'MEDIUM',
  LARGE = 'LARGE'
}

export interface ITableFilterOption {
  text: string;
  value: string | boolean;
}

export interface ITableColumnMethods<T = any> {
  onExpand: (record: T, allowMultiple?: boolean) => void;
}

export interface ITableColumnState {
  isExpanded: boolean;
}

export interface ITableColumn<T = any> {
  id: string;
  title?: string;
  dataKey?: string;
  renderColumn: (
    value: any,
    record: T,
    index: number,
    methods: ITableColumnMethods,
    state: ITableColumnState
  ) => ReactNode;
  width?: number;
  align?: 'center' | 'left' | 'right';
  fillter?: {
    options: ITableFilterOption[];
    onFilter: (value: any, record: T) => boolean;
    allowSearch?: boolean;
  };
  sort?: {
    onSort: (a: T, b: T) => number;
    sortDirections: string[];
  };
  edit?: {
    editRender: (
      value: any,
      record: T,
      editValue: any,
      onCellChange: (val: string) => void
    ) => ReactNode;
  };
}

export interface ITableAction<T = any> {
  id: string;
  label: string;
  onClick?: (selectedRows: T[]) => void;
  isDisabled?: (selectedRows: T[]) => boolean;
}

export interface ITablePagination {
  total?: number;
  // to control pagination (controlled)
  pageNumber?: number;
  pageSize?: number;
  // to use default
  defaultPageNumber?: number;
  defaultPageSize?: number;
  disabled?: boolean;
  onChange?: (pageNumber: number) => void;
  hideTotalString?: boolean;
}

export interface ITableSelection<T = any> {
  onChange?: (selectedRows: T[]) => void;
  isDisabled?: (record: T) => boolean;
}

export interface ITableExpand<T = any> {
  renderExpandedRow: (record: T) => ReactNode;
  getInitialExpandedRows?: (records: T[]) => T[];
}

export interface ITableProps {
  uniqueRowId: string;
  columns: ITableColumn<any>[];
  data: any[];
  loading: boolean;

  // optional
  bordered?: boolean; // default true
  emptyMessage?: string; // default ''
  size?: TableSizeType; // default middle
  pagination?: ITablePagination;
  selection?: ITableSelection;
  expand?: ITableExpand;
  actions?: ITableAction[];
  export?: {
    onExport: () => void;
  };
  filter?: {
    defaultFilters?: Record<string, any>;
    allowSearchFilter?: boolean;
    allowDateFilter?: boolean;
    onExternalFilterChange?: (
      searchValue: string,
      dateValue: Dayjs | null
    ) => void;
    searchPlaceholder?: string;
  };
  onEditSave?: (
    rowUniqueId: any,
    editValues: Record<string, any>,
    resetEditable: () => void
  ) => void;
  className?: string;
  isRowHighlighted?: (row: any) => boolean;
  getRowClassName?: (row: any) => string;
  renderExtraRowActions?: (
    uniqId: string,
    record: any,
    index: number
  ) => ReactNode;
  renderExtraActions?: () => ReactNode;
}

export interface ITableRef {
  reset: (filters?: boolean) => void;
}
