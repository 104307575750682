//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// ContextViewerPopup is a component that shows a context and overlays any boundingboxes
// in a popup
//

// 3rd part libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';

// Local imports
import i18n from '@utils/i18n';
import ComponentFactory from '@utils/ComponentFactory';

// Component CSS
import './ContextViewerPopup.less';

// Component i18n
const i18nt = (s, d) => i18n.t('ContextViewerPopup', s, d);

// Component Prop Types
ContextViewerPopup.propTypes = {
  /**
   * Context to display { data, mimetype, url }
   */
  contextInfo: PropTypes.object.isRequired,

  /**
   * BoundingBox of the area within the image to highlight
   */
  boundingBox: PropTypes.shape({
    x: PropTypes.number,
    y: PropTypes.number,
    width: PropTypes.number,
    height: PropTypes.number
  }),

  /**
   * Close Callback
   */
  onClose: PropTypes.func.isRequired
};

// Component implementation
function ContextViewerPopup(props) {
  // Is the popup visible
  const [visible, setVisible] = useState(true);

  const onCancel = () => {
    setVisible(false);
    props.onClose();
  };

  const sClassName = props.contextInfo.mimeType.replace('/', '-');

  // Render the UI
  return (
    <Modal
      className="context-viewer-popup"
      title={i18nt('source', 'Source')}
      centered
      open={visible}
      onCancel={onCancel}
      footer={null}
    >
      <div className={sClassName}>
        {ComponentFactory.getContextViewer(props.contextInfo, {
          readonly: true,
          boundingBoxes: [props.boundingBox]
        })}
      </div>
    </Modal>
  );
}

export default ContextViewerPopup;
