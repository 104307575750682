import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '@/stores/AppStore';

export interface ISettings {
  playMode: boolean;
  debugMode: boolean;
  showAnswer: boolean;
}

const initialState: ISettings = {
  playMode: false,
  debugMode: false,
  showAnswer: true
};

export const settingSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    togglePlay: (state, action: PayloadAction<boolean>) => {
      state.playMode =
        action.payload !== undefined ? action.payload : !state.playMode;
    },
    toggleDebug: (state) => {
      state.debugMode = !state.debugMode;
    },
    toggleShowAnswer: (state) => {
      state.showAnswer = !state.showAnswer;
    }
  }
});

export const { toggleDebug, togglePlay, toggleShowAnswer } =
  settingSlice.actions;

export const settingsSelector = (state: RootState) => state.settings;

export default settingSlice.reducer;
