import { Tabs } from 'antd';
import React, { useEffect, useState } from 'react';
import { read, utils } from 'xlsx';
import Table from './table/Table';
import './ExcelTable.less';

interface IProps {
  fileUrl: string;
}

function ExcelTable(props: IProps) {
  const { fileUrl } = props;

  const [loading, setLoading] = useState(false);
  const [sheetDataMap, setSheetDataMap] = useState<Record<string, any[]>>({});

  useEffect(() => {
    (async () => {
      setLoading(true);
      const arrayBuffer = await (await fetch(fileUrl)).arrayBuffer();
      const workbook = read(arrayBuffer);

      const newDataMap = workbook.SheetNames.reduce<Record<string, any[]>>(
        (acc, cur) => {
          acc[cur] = utils.sheet_to_json(workbook.Sheets[cur]);
          return acc;
        },
        {}
      );
      setSheetDataMap(newDataMap);

      setLoading(false);
    })();
  }, [fileUrl]);

  const getColumns = (data: any[]) =>
    Object.keys(data[0]).map((key: any) => ({
      id: key,
      dataKey: key,
      title: key.includes('__EMPTY') ? '' : key, // Show '' in case of empty excel column title
      renderColumn: (value: any) => value
    }));

  return (
    <div className="excel-table">
      <Tabs
        defaultActiveKey="0"
        tabPosition="top"
        items={Object.keys(sheetDataMap).map((sheetName) => {
          const data = sheetDataMap[sheetName];
          return {
            label: sheetName,
            key: sheetName,
            children: (
              <Table
                data={data}
                loading={loading}
                columns={data.length > 0 ? getColumns(data) : []}
                uniqueRowId="id"
                pagination={{}}
              />
            )
          };
        })}
      />
    </div>
  );
}

export default ExcelTable;
