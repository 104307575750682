/* eslint-disable no-underscore-dangle */
//
// Copyright (C) - Kognitos. All rights reserved
//
// RunContext is a component that displays and manages run items at a level in the runs tree
//

// 3rd party libraries
import React, { useEffect, useState } from 'react';
import { Tag, message } from 'antd';
import { useSelector } from 'react-redux';
import Loader from '@components/Loader';
import RunStepV2 from '@details/runs/items/RunStepV2';
import NewRunItemV3 from '@/details/runs/items/NewRunItemV3';
import { settingsSelector } from '@/stores/slices/settings';
import { computeItemsV2, nodeId } from '@/details/runs/items/RunContextUtil';
import './RunContext.less';
import AppUtil from '@/utils/AppUtil';
import { useRunCtxV2 } from '../../../provider/runv2';
import { Context, Step } from '../../../generated/API';
import usePlaygroundServiceCommands from '../../../hooks/usePlaygroundServiceCommands';

export enum RunStepItemType {
  READ = 'READ',
  EDIT = 'EDIT',
  NEW = 'NEW'
}

export interface INewStepItemConfig {
  closeable?: boolean;
  onClose?: () => void;
  defaultValue?: string;
  placeholder?: string;
  onSubmit?: (text: string) => Promise<void>;
}

export interface IRunStepItem {
  type: RunStepItemType;
  step?: Step;
  newStepConfig?: INewStepItemConfig; // for ui config
}

interface IRunContextProps {
  context: Context | null;
  isRoot: boolean;
  defaultValueForNewBlock?: string;
}

function RunContextV3(props: IRunContextProps) {
  const { isRoot, context, defaultValueForNewBlock } = props;

  const { playMode, debugMode } = useSelector(settingsSelector);
  const { loading } = useRunCtxV2();
  const { scratchStep, sendSteps } = usePlaygroundServiceCommands();

  const newStepItem: IRunStepItem = {
    type: RunStepItemType.NEW,
    newStepConfig: {
      placeholder: 'type a command...'
    }
  };

  const getDefaultNewItems = () => {
    if (!isRoot) {
      return [];
    }
    if (playMode) {
      return [newStepItem];
    }
    return [];
  };

  const rootListClass = isRoot ? 'root-list' : '';
  const debugClass = debugMode ? 'run-items-list-debug' : '';

  const [items, setItems] = useState<IRunStepItem[]>();

  useEffect(() => {
    const data = computeItemsV2({
      steps: context ? (context.stepList! as Step[]) : null,
      newItems: getDefaultNewItems()
    });
    setItems(() => data);
  }, [context?.stepList]);

  const handleEditClose = (step: Step) => {
    const newItems = items?.map((item) =>
      nodeId(item.step!) === nodeId(step)
        ? {
            type: RunStepItemType.READ,
            step: item.step
          }
        : item
    );
    setItems(newItems);
  };

  const handleEditSubmit = async (text: string, step: Step) => {
    const runAsyncCommands = async () => {
      try {
        await scratchStep(step);
        await sendSteps(text, step.contextId);
      } catch (e) {
        console.error('editCommand error', e);
        message.error((e as any)?.message || 'Error while editing commands');
      }
    };
    runAsyncCommands();
  };

  const handleStepEditAction = (step: Step) => {
    if (!items) {
      return;
    }

    const updatedItems = items.map((item) =>
      nodeId(item.step!) === nodeId(step)
        ? {
            ...item,
            type: RunStepItemType.EDIT,
            newStepConfig: {
              ...item.step,
              defaultValue: step.text
                ? AppUtil.safeParseJSON(step.text).join('\n')
                : '',
              closeable: true,
              onClose: () => handleEditClose(item.step!),
              onSubmit: (text: string) => handleEditSubmit(text, item.step!)
            }
          }
        : item
    );
    setItems(updatedItems);
  };

  if (loading) {
    return <Loader />;
  }

  const renderItem = (item: IRunStepItem, index: number) => {
    switch (item.type) {
      case RunStepItemType.READ: {
        if (!debugMode && item.step!.scratchedAt) {
          return null;
        }
        return (
          <RunStepV2
            key={nodeId(item.step!)}
            nodeId={nodeId(item.step!)}
            item={item}
            context={context}
            handleStepEditAction={handleStepEditAction}
          />
        );
      }

      case RunStepItemType.NEW: {
        const defaultText = defaultValueForNewBlock;
        return (
          <NewRunItemV3
            key={`${item.type}-${index}`}
            newStepItemConfig={{
              ...item.newStepConfig!,
              defaultValue: defaultText
            }}
          />
        );
      }

      case RunStepItemType.EDIT: {
        return (
          <NewRunItemV3
            key={`${item.type}-${index}`}
            newStepItemConfig={item.newStepConfig!}
          />
        );
      }
      default: {
        return null;
      }
    }
  };

  return (
    <div className={`run-items-list ${debugClass} ${rootListClass}`}>
      {debugMode && context && (
        <Tag color="cyan" data-cy={`context-${context.id}`}>
          Context {context.id}
        </Tag>
      )}
      {items && items.map(renderItem)}
    </div>
  );
}

export default RunContextV3;
