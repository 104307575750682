//
// Copyright (C) - Kognitos, Inc. All rights reserved
// GraphQL mutations. Client Side templates
//
import { gql } from '@apollo/client';
import {
  createAnswer,
  createAnswers,
  createCommand,
  createKChatMessage,
  createLearnedAnswer,
  createOrganization,
  createProcedure,
  createRequestAnswer,
  createServiceCommand,
  createSharedEntity,
  createWorker,
  deleteDepartment,
  deleteLearnedAnswer,
  deleteLearning,
  duplicateWorker,
  forgetBooks,
  importDepartment,
  learnBooks,
  publishToDepartment,
  removeUserFromDepartment,
  rollbackDepartmentVersion,
  setWorkerArchived,
  shareProcedure,
  updateDepartment,
  updateLearnedAnswer,
  updateProcedure,
  updateProcedureSchedule,
  upsertUserToDepartment
} from '../generated/graphql/mutations';

const Mutations = {
  // Create a worker
  CreateWorker() {
    return gql(createWorker);
  },

  DuplicateWorker() {
    return gql(duplicateWorker);
  },

  // Create an answer
  CreateAnswer() {
    return gql(createAnswer);
  },

  CreateRequestAnswer() {
    return gql(createRequestAnswer);
  },

  CreateLearnedAnswer() {
    return gql(createLearnedAnswer);
  },

  UpdateLearnedAnswer() {
    return gql(updateLearnedAnswer);
  },

  DeleteLearnedAnswer() {
    return gql(deleteLearnedAnswer);
  },

  CreateAnswers() {
    return gql(createAnswers);
  },

  // Create a procedure
  CreateProcedure() {
    return gql(createProcedure);
  },

  CreateSharedEntity() {
    return gql(createSharedEntity);
  },

  // Set Archive state of a worker
  SetWorkerArchived() {
    return gql(setWorkerArchived);
  },

  // Share a procedure
  ShareProcedure() {
    return gql(shareProcedure);
  },

  // Create a new command
  CreateCommand() {
    return gql(createCommand);
  },

  // Create a service command
  CreateServiceCommand() {
    return gql(createServiceCommand);
  },

  // Delete a learning
  DeleteLearning() {
    return gql(deleteLearning);
  },

  // Create organization
  CreateOrganization() {
    return gql(createOrganization);
  },

  // Create a new department
  CreateDepartment() {
    const query = `
    mutation CreateDepartment($input: CreateDepartmentInput!) {
      createDepartment(input: $input) {
        id
        owner
        organizationId
        readers
        editors
        name
        draftKnowledgeId
        publishedKnowledgeId
        workerId
        local
        state
        errors
        createdAt
        isDeleted
        upgradeCommandId
        notifyProcedureInvocation
        features
        brainVersion
        organization {
          id
          name
        }
      }
    }
  `;
    return gql(query);
  },

  // Delete department
  DeleteDepartment() {
    return gql(deleteDepartment);
  },

  // Update department
  UpdateDepartment() {
    return gql(updateDepartment);
  },

  // Rollback Dept to a previous version
  RollbackDepartmentVersion() {
    return gql(rollbackDepartmentVersion);
  },

  // Publish a departments draft knowledge
  PublishDepartment() {
    return gql(publishToDepartment);
  },

  // Add a user to department as a collaborator
  AddDepartmentCollaborator() {
    return gql(upsertUserToDepartment);
  },

  // Remove a user from department as a collaborator
  RemoveDepartmentCollaborator() {
    return gql(removeUserFromDepartment);
  },

  // Add a new Run Item.
  // params.runId - ID of run to add to
  // params.parentId - ID of parent under which to add to
  // params.atIndex - Index at which to insert
  // params.lineText - LineText of the new run item
  // @return - newly created run item (currently on id, linetext)
  AddRunItem() {
    return gql`
      mutation AddRunItem(
        $runId: String!
        $parentId: String!
        $atIndex: Int!
        $lineText: String!
      ) {
        AddRunItem(
          runId: $runId
          parentId: $parentId
          atIndex: $atIndex
          lineText: $lineText
        ) {
          id
          lineText
        }
      }
    `;
  },

  // Save a knowledge tree node's answer.
  // params.knowledgeTreeId - ID of knowledge tree to update
  // params.treeNodeId - ID of node to to answer
  // params.treeNodeData - JSON string of treenode's data array
  AnswerKnowledgeTreeNode() {
    return gql`
      mutation answerKnowledgeTreeNode(
        $knowledgeTreeId: String!
        $treeNodeId: String!
        $treeNodeData: String!
      ) {
        answerKnowledgeTreeNode(
          knowledgeTreeId: $knowledgeTreeId
          treeNodeId: $treeNodeId
          treeNodeData: $treeNodeData
        )
      }
    `;
  },

  // Insert a knowledge tree node into a tree.
  // params.knowledgeTreeId - ID of knowledge tree to update
  // params.treeNodeId - ID of node to insert after
  InsertKnowledgeTreeNode() {
    return gql`
      mutation insertKnowledgeTreeNode(
        $knowledgeTreeId: String!
        $parentNodeId: String!
        $treeNodeId: String!
      ) {
        insertKnowledgeTreeNode(
          knowledgeTreeId: $knowledgeTreeId
          parentNodeId: $parentNodeId
          treeNodeId: $treeNodeId
        )
      }
    `;
  },

  // Update a knowledge tree node
  // params.knowledgeTreeId - ID of knowledge tree to update
  // treeNodeId - id of node to update
  // treeNodeName, treeNodeType -- Node's info to update to
  UpdateKnowledgeTreeNode() {
    return gql`
      mutation updateKnowledgeTreeNode(
        $knowledgeTreeId: String!
        $treeNodeId: String!
        $treeNodeName: String!
        $treeNodeType: String!
      ) {
        updateKnowledgeTreeNode(
          knowledgeTreeId: $knowledgeTreeId
          treeNodeId: $treeNodeId
          treeNodeName: $treeNodeName
          treeNodeType: $treeNodeType
        )
      }
    `;
  },

  // Delete a knowledge tree node
  // params.knowledgeTreeId - ID of knowledge tree to update
  // treeNodeId - id of node to delete
  DeleteKnowledgeTreeNode() {
    return gql`
      mutation deleteKnowledgeTreeNode(
        $knowledgeTreeId: String!
        $parentNodeId: String!
        $treeNodeId: String!
      ) {
        deleteKnowledgeTreeNode(
          knowledgeTreeId: $knowledgeTreeId
          parentNodeId: $parentNodeId
          treeNodeId: $treeNodeId
        )
      }
    `;
  },

  // Update a knowledge tree.
  // params.knowledgeTreeId - ID of knowledge tree to update
  // params.data - JSON String
  //   {questionId -> { answers: [ 'a1, 'a2'...], locations: { tNodeId -> [{box1}, {box2..}] }}
  UpdateKnowledgeTree() {
    return gql`
      mutation updateKnowledgeTree($knowledgeTreeId: String!, $data: String!) {
        updateKnowledgeTree(knowledgeTreeId: $knowledgeTreeId, data: $data)
      }
    `;
  },

  CreateRealtimeChannel() {
    return gql`
      mutation createRealtimeChannel($workerId: ID!, $connectionId: ID!) {
        createRealtimeChannel(
          workerId: $workerId
          connectionId: $connectionId
        ) {
          workerId
          connectionId
        }
      }
    `;
  },

  // Register an additional column
  // params - gridId, columnId, columnName
  RegisterAdditionalColumn() {
    return gql`
      mutation registerAdditionalColumn(
        $gridId: String!
        $columnId: String!
        $columnName: String!
      ) {
        registerAdditionalColumn(
          gridId: $gridId
          columnId: $columnId
          columnName: $columnName
        )
      }
    `;
  },

  // Deregister an additional column
  // params - gridId, columnId
  DeregisterAdditionalColumn() {
    return gql`
      mutation deregisterAdditionalColumn(
        $gridId: String!
        $columnId: String!
      ) {
        deregisterAdditionalColumn(gridId: $gridId, columnId: $columnId)
      }
    `;
  },

  LearnBooks() {
    return gql(learnBooks);
  },

  ForgetBooks() {
    return gql(forgetBooks);
  },

  UpdateProcedure() {
    return gql(updateProcedure);
  },

  UpdateProcedureSchedule() {
    return gql(updateProcedureSchedule);
  },

  CreateKChatMessage() {
    return gql(createKChatMessage);
  },

  ImportDepartment() {
    return gql(importDepartment);
  }
};

export default Mutations;
