import React from 'react';
import { Drawer } from 'antd';
import ENV_CONFIG from '../EnvConstants';

interface IProps {
  onClose: () => void;
}

export default function DocsDrawer(props: IProps) {
  const { onClose } = props;

  return (
    <Drawer
      title="Docs"
      placement="right"
      headerStyle={{
        height: '64px'
        // backgroundColor: '#140d57',
        // borderBottom: '1px solid #140d57'
      }}
      bodyStyle={{ padding: 0, overflowY: 'hidden' }}
      width={600}
      onClose={onClose}
      mask={false}
      open
      rootClassName="docs-drawer"
    >
      <iframe
        title="docs"
        id="docs-iframe"
        style={{
          width: '100%',
          height: '100%',
          border: 'none'
        }}
        src={ENV_CONFIG.doc.baseUrl}
      />
    </Drawer>
  );
}
