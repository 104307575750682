import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { showPopup } from '@/stores/slices/appPopup';
import { range } from 'lodash/fp';
import useFactV2 from '@/hooks/useFactV2';
import { Fact } from '../../generated/API';
import { useS3 } from '../../hooks/useS3';
import { useAppDispatch } from '../../stores/hooks';
import AppUtil from '../../utils/AppUtil';
import PDFArea from '../PDFArea';
import AppConstants from '../../utils/AppConstants';
import './FactDocumentSummary.less';
import FormattingUtil from '../../utils/FormattingUtil';

interface IFactDocumentSummaryListProps {
  fact: Fact;
  showInPopup: boolean;
  onClick?: (s3Parts: any, pageNumbers: number[], location: any) => void;
  knowledgeId: string | null;
}

interface IFactDocumentSummaryProps extends IFactDocumentSummaryListProps {
  locationConfig: { pages: number[]; location: any };
}

enum DocumentType {
  PDF = 'PDF'
}

function FactDocumentSummary(props: IFactDocumentSummaryProps) {
  const { fact, showInPopup, onClick, knowledgeId, locationConfig } = props;
  const { location, pages } = locationConfig;

  const dispatch = useAppDispatch();
  const [documentUrl, setDocumentUrl] = useState<string | null>(null);
  const s3 = useS3();
  const [documentType, setDocumentType] = useState<DocumentType | null>(null);

  const { getFactById } = useFactV2({
    ids: fact?.parentFactId ? [fact.parentFactId] : [],
    knowledgeId
  });

  const parentFact = fact?.parentFactId ? getFactById(fact.parentFactId) : null;

  const getBoundingBox = (location: any) => ({
    x: location.left * 100,
    y: location.top * 100,
    height: (location.bottom - location.top) * 100,
    width: (location.right - location.left) * 100
  });

  useEffect(() => {
    if (parentFact?.value) {
      const parsedValue = FormattingUtil.parseBrainValue(parentFact.value);

      if (parsedValue !== null) {
        const s3Parts = AppUtil.getS3URIParts(parsedValue)!;
        if (s3Parts) {
          s3.getS3PresignedUrl(s3Parts.key).then((resp) => {
            if (resp) {
              if (resp.s3Url.toLowerCase().endsWith('.pdf')) {
                setDocumentType(DocumentType.PDF);
              }
              setDocumentUrl(resp.url);
            }
          });
        }
      }
    }
  }, [parentFact?.value]);

  const handleViewDetails = () => {
    const s3Parts = AppUtil.getS3URIParts(
      FormattingUtil.parseBrainValue(parentFact?.value || '')
    )!;

    if (!s3Parts) {
      return;
    }

    if (showInPopup) {
      dispatch(
        showPopup({
          popupId: AppConstants.POPUPS.VIEW_S3_FILE,
          popupParams: {
            title: s3Parts.filename,
            s3Object: {
              bucket: s3Parts.bucket,
              key: s3Parts.key
            },
            pdfConfig: {
              pageNumbers: pages,
              highlight: getBoundingBox(location),
              allowViewTypeChange: false
            }
          }
        })
      );
    }

    if (onClick) {
      onClick(s3Parts, pages, location);
    }
  };

  const renderDocumentSummary = useCallback(() => {
    if (documentType === DocumentType.PDF) {
      return (
        <PDFArea
          pdfUrl={documentUrl!}
          boundingBox={getBoundingBox(location)}
          pageNumber={pages[0]}
          onClick={(e: any) => {
            e.stopPropagation();
            handleViewDetails();
          }}
          destination={{
            height: 25,
            width: 100
          }}
        />
      );
    }

    return null;
  }, [documentUrl, documentType]);

  return (
    <div className="document-summary">
      {documentUrl && renderDocumentSummary()}
    </div>
  );
}

function FactDocumentSummaryList(props: IFactDocumentSummaryListProps) {
  const { fact, knowledgeId, showInPopup, onClick } = props;

  const memoList = useMemo(() => {
    if (!fact?.locations?.[0]) {
      return null;
    }

    return fact.locations!.map((factLocation: string) => {
      const [pageConfig, locationConfig] = AppUtil.safeParseJSON(factLocation);

      if (!pageConfig || !locationConfig) {
        return null;
      }

      const { page } = pageConfig;

      // `page` in the updated location schema has an object. The following logic is for backward compatibility.
      const locationPages =
        typeof page === 'object'
          ? [...range(page.start)(page.end), page.end]
          : [page];

      const { location } = locationConfig;

      return (
        <FactDocumentSummary
          key={factLocation}
          fact={fact}
          locationConfig={{
            pages: locationPages.map((p) => p + 1),
            location
          }}
          knowledgeId={knowledgeId}
          showInPopup={showInPopup}
          onClick={onClick}
        />
      );
    });
  }, [fact?.locations]);

  return <div className="fact-document-summary-list">{memoList}</div>;
}

export default FactDocumentSummaryList;
