import { useApolloClient, useLazyQuery } from '@apollo/client';
import { useState } from 'react';
import { GET_FACTS } from '@/graphql/queries/fact';
import AppUtil from '@/utils/AppUtil';
import { useCustomCompareLayoutEffect } from 'use-custom-compare';
import { isEqual } from 'lodash/fp';
import { Fact, GetFactsQuery } from '../generated/API';
import Queries from '../graphql/Queries';

interface IProps {
  ids: string[];
  knowledgeId: string | null;
  paginationState?: {
    pgNumber: number;
    pgSize: number;
  };
}

function useFact(props: IProps) {
  const { ids, knowledgeId, paginationState } = props;
  const [fetchIds, setFetchIds] = useState<string[]>(ids);
  const [loading, setLoading] = useState(false);
  const client = useApolloClient();

  useCustomCompareLayoutEffect(
    () => {
      let currentIds = ids;
      if (paginationState) {
        const { pgNumber, pgSize } = paginationState;
        currentIds = AppUtil.getPaginatedList(ids, pgNumber, pgSize);
      }
      setFetchIds(currentIds);
    },
    [ids, paginationState],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  const [fetchFacts] = useLazyQuery<GetFactsQuery>(Queries.Facts(), {
    fetchPolicy: 'cache-and-network',
    nextFetchPolicy: 'cache-and-network',
    onCompleted: (resp) => {
      if (resp?.getFacts) {
        const responseFacts = (resp.getFacts! as Fact[]) || [];
        const factsMap = responseFacts.reduce<Record<string, Fact>>(
          (acc, cur) => {
            if (cur && cur.id) {
              acc[cur.id] = cur;
            }
            return acc;
          },
          {}
        );
        const facts = client.readQuery({ query: GET_FACTS });
        client.writeQuery({
          query: GET_FACTS,
          data: {
            getLocalFacts: {
              data: { ...facts?.getLocalFacts?.data, ...factsMap }
            }
          }
        });
        const refIds = responseFacts
          .map((fact) => fact?.referenceTo?.[0]!)
          .filter(Boolean);
        if (refIds.length > 0) {
          setFetchIds(refIds);
        } else {
          setLoading(false);
        }
      }
    }
  });

  const refetchFacts = (fetchIds: string[]) => {
    if (fetchIds.length > 0) {
      setLoading(true);
      fetchFacts({
        variables: {
          factIds: fetchIds,
          knowledgeId
        }
      });
    }
  };

  useCustomCompareLayoutEffect(
    () => {
      refetchFacts(fetchIds);
    },
    [fetchIds],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  const getFactById = (factId: string | null): Fact | undefined => {
    if (!factId) {
      return undefined;
    }

    let factToShow: Fact | undefined;

    const facts =
      client.readQuery({ query: GET_FACTS })?.getLocalFacts?.data || {};

    const fact = facts[factId];

    if (fact) {
      if (fact.referenceTo?.[0]) {
        factToShow = getFactById(fact.referenceTo[0]);
      } else {
        factToShow = fact;
      }
    }

    return factToShow;
  };

  const getIds = () => {
    if (paginationState) {
      const { pgNumber, pgSize } = paginationState;
      return AppUtil.getPaginatedList(ids, pgNumber, pgSize);
    }
    return ids;
  };

  return { getFactById, loading, ids: getIds(), refetchFacts };
}

export default useFact;
