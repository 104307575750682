// type VisitorType = 'sme' | 'eu';

import { Procedure } from '../generated/API';

// Auth
const getLoginPageUrl = () => '/login';

const getVerifyLoginPageUrl = (challenge: string) => `verify-user/${challenge}`;

// Global
const getHomePageUrl = () => '/';

// Playground
const getPlaygroundHistoryPageUrl = () => '/playground/history';

const getPlaygroundPageUrl = (workerId?: string) =>
  `/playground${workerId ? `/${workerId}` : ''}`;

const getPlayground2PageUrl = (workerId?: string) =>
  `/playground2${workerId ? `/${workerId}` : ''}`;

// Question
const getQuestionPageUrl = (
  departmentId: string,
  knowledgeId: string,
  questionId: string
) => `/question/${departmentId}/${knowledgeId}/${questionId}`;

// Procedure
const getProcedureHomePageUrl = () => `/procedures`;

const getProcedurePageUrl = (
  departmentId: Procedure['departmentId'],
  knowledgeId: Procedure['knowledgeId'], // TODO: DEP-BOOK: REMOVE
  procedureId: string
) => {
  return `/procedure/${departmentId}/${knowledgeId}/${procedureId}`;
};

const getProcedureRunAllPageUrl = (
  departmentId: Procedure['departmentId'],
  knowledgeId: Procedure['knowledgeId'],
  procedureId: string
) => `${getProcedurePageUrl(departmentId, knowledgeId, procedureId)}/run/all`;

const getProcedureRunPageUrl = (
  departmentId: Procedure['departmentId'],
  knowledgeId: Procedure['knowledgeId'],
  procedureId: string,
  workerId: string
) =>
  `${getProcedurePageUrl(
    departmentId,
    knowledgeId,
    procedureId
  )}/run/${workerId}`;

const getProcedureLearningsPageUrl = (
  departmentId: Procedure['departmentId'],
  knowledgeId: Procedure['knowledgeId'],
  procedureId: string
) => `${getProcedurePageUrl(departmentId, knowledgeId, procedureId)}/learnings`;

const getProcedureSchedulesPageUrl = (
  departmentId: Procedure['departmentId'],
  knowledgeId: Procedure['knowledgeId'],
  procedureId: string
) => `${getProcedurePageUrl(departmentId, knowledgeId, procedureId)}/schedules`;

const getProcedureSharePageUrl = (
  departmentId: Procedure['departmentId'],
  knowledgeId: Procedure['knowledgeId'],
  procedureId: string
) => `${getProcedurePageUrl(departmentId, knowledgeId, procedureId)}/share`;

// Department
const getDepartmentsPageUrl = () => `/departments`;
const getDepartmentPageUrl = () => `/department`;
const getDepartmentLearningsPageUrl = () => `/department/learnings`;
const getDepartmentHistoryPageUrl = () => `/department/history`;
const getDepartmentCollaboratorsPageUrl = () => `/department/collaborators`;

// Fact
const getFactDebugPageUrl = (knowledgeId: string, factId: string) =>
  `/fact-debug/${knowledgeId}/${factId}`;

const getFactTreePageUrl = () => `/page/fact-tree`;

// Admin
const getAdminPageUrl = () => `/internal/admin`;

// Example
const getExamplesPageUrl = () => '/examples';
const getExamplePageUrl = (ex: string) => `/example/${ex}`;

const authRoutes = {
  getLoginPageUrl,
  getVerifyLoginPageUrl
};

const globalRoutes = {
  getHomePageUrl
};

const playgroundRoutes = {
  getPlaygroundHistoryPageUrl,
  getPlaygroundPageUrl,
  getPlayground2PageUrl
};

const questionRoutes = {
  getQuestionPageUrl
};

const procedureRoutes = {
  getProcedureHomePageUrl,
  getProcedurePageUrl,
  getProcedureRunAllPageUrl,
  getProcedureRunPageUrl,
  getProcedureLearningsPageUrl,
  getProcedureSchedulesPageUrl,
  getProcedureSharePageUrl
};

const departmentRoutes = {
  getDepartmentsPageUrl,
  getDepartmentPageUrl,
  getDepartmentLearningsPageUrl,
  getDepartmentHistoryPageUrl,
  getDepartmentCollaboratorsPageUrl
};

const factRoutes = {
  getFactDebugPageUrl,
  getFactTreePageUrl
};

const adminRoutes = {
  getAdminPageUrl
};

const solutionRoutes = {
  getExamplesPageUrl,
  getExamplePageUrl
};

const routes = {
  auth: authRoutes,
  global: globalRoutes,
  playground: playgroundRoutes,
  question: questionRoutes,
  procedure: procedureRoutes,
  department: departmentRoutes,
  fact: factRoutes,
  admin: adminRoutes,
  example: solutionRoutes
};

export default routes;
