import React from 'react';
import { Provider } from 'react-redux';
import { Route, BrowserRouter, Routes, useLocation } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import { store } from '@app/stores/AppStore';
import { AuthProvider } from '@app/provider/auth';
import AuthApp from '@/AuthRoute';
import AppUtil from '@utils/AppUtil';
import {
  Login,
  PlaygroundV2,
  QuestionPage,
  FactDebugPage,
  AdminPage,
  DepartmentsPage,
  ProcedureShare,
  FactTreeViewer,
  InvalidRoute,
  ProceduresPage,
  // ActionBar,
  DepartmentLayout,
  DepartmentBooks,
  DepartmentLearnings,
  DepartmentHistory,
  DepartmentCollaborators,
  AllRunsPage,
  RunViewLayout,
  ProcedurePage,
  RunView,
  SchedulesPage,
  VerifyLogin,
  DashboardPage,
  RequestPage,
  PlaygroundV3,
  ProcedureLearningsPage
} from '@/pages';
import './authConfig';
import './App.less';
import Example, { ExampleList } from '@/pages/Example';
import Automation from '@/pages/Automation';
import { ConfigProvider } from 'antd';
import { RefreshProvider, useRefresh } from './provider/refresh';
import WithSuspense from './components/WithSuspense';
import Apollo from './provider/apollo';
import { RunProviderV2 } from './provider/runv2';

const SentryRoutes = Sentry.withSentryReactRouterV6Routing(Routes);

// function LazyRoute({ path, component }: { path: string; component: string }) {
//   return <Route path={path} element={lazy(() => import(component))} />;
// }

function AppRoutes() {
  const location = useLocation();
  const lState = location.state as { background?: Location };
  return (
    <div>
      <SentryRoutes location={lState?.background || location}>
        {/* we could refactor like this for one-one mapping */}
        {/* <LazyRoute path="/signup" component="@pages/login" /> */}
        <Route path="/login" element={<WithSuspense element={<Login />} />} />
        <Route
          path="/verify-user/:challenge"
          element={<WithSuspense element={<VerifyLogin />} />}
        />
        {/* Auth routes should be below, non-auth routes should be above */}
        <Route path="/" element={<AuthApp />}>
          {/* <Route index element={<ActionBar />} /> */}
          <Route index element={<DashboardPage />} />
          <Route path="/procedures" element={<ProceduresPage />} />

          {/* TODO: DEP-BOOK: REMOVE knowledgeId once dep-book is enabled by default */}
          <Route
            path="/procedure/:departmentId/:knowledgeId/:procedureId"
            element={<RunViewLayout />}
          >
            <Route index element={<ProcedurePage />} />
            <Route
              path="run/:workerId"
              element={
                <RunProviderV2 contextId="1">
                  <RunView />
                </RunProviderV2>
              }
            />
            <Route path="learnings" element={<ProcedureLearningsPage />} />
            <Route path="schedules" element={<SchedulesPage />} />
            <Route path="share" element={<ProcedureShare />} />
            <Route path="runs" element={<AllRunsPage paneKey="1" />} />
            <Route path="exceptions" element={<AllRunsPage paneKey="2" />} />
            <Route path="outputs" element={<AllRunsPage paneKey="3" />} />
            <Route path="run/all" element={<AllRunsPage paneKey="1" />} />
          </Route>

          {/* TODO: This is kept for backward-compatibility. Remove after 2 weeks post prod deployment. */}
          <Route
            path="/procedure/:knowledgeId/:procedureId"
            element={<RunViewLayout />}
          >
            <Route index element={<ProcedurePage />} />
            <Route
              path="run/:workerId"
              element={
                <RunProviderV2 contextId="1">
                  <RunView />
                </RunProviderV2>
              }
            />
            <Route path="runs" element={<AllRunsPage paneKey="1" />} />
            <Route path="schedules" element={<SchedulesPage />} />
            <Route path="share" element={<ProcedureShare />} />
            <Route path="exceptions" element={<AllRunsPage paneKey="2" />} />
            <Route path="outputs" element={<AllRunsPage paneKey="3" />} />
            <Route path="run/all" element={<AllRunsPage paneKey="1" />} />
          </Route>

          <Route path="/departments" element={<DepartmentsPage />} />
          <Route path="/department" element={<DepartmentLayout />}>
            <Route index element={<DepartmentBooks />} />
            <Route path="learnings" element={<DepartmentLearnings />} />
            <Route path="history" element={<DepartmentHistory />} />
            <Route path="collaborators" element={<DepartmentCollaborators />} />
          </Route>

          <Route
            path="/playground"
            element={
              <RunProviderV2 contextId="0">
                <PlaygroundV2 />
              </RunProviderV2>
            }
          >
            <Route path=":workerId" element={null} />
          </Route>

          <Route
            path="/playground2"
            element={
              <RunProviderV2 contextId="0">
                <PlaygroundV3 />
              </RunProviderV2>
            }
          >
            <Route path=":workerId" element={null} />
          </Route>

          <Route path="/examples" element={<ExampleList />} />
          <Route path="/example/:name" element={<Example />} />
          <Route path="/automation/:name" element={<Automation />} />
          <Route
            path="/question/:departmentId/:knowledgeId/:questionId"
            element={<QuestionPage />}
          />

          <Route
            path="/request/:departmentId/:knowledgeId/:requestId"
            element={<RequestPage />}
          />
          <Route
            path="/fact-debug/:knowledgeId/:factId"
            element={<FactDebugPage />}
          />

          {/* TODO: Remove after 2 weeks post prod deployment. */}
          <Route path="/admin" element={<AdminPage />} />
          <Route path="/internal/admin" element={<AdminPage />} />

          <Route path="/page/fact-tree" element={<FactTreeViewer />} />

          <Route path="*" element={<InvalidRoute />} />
        </Route>

        <Route path="*" element={<InvalidRoute />} />
      </SentryRoutes>
    </div>
  );
}

// Routes for mobile only pages. Currently only one
function MobileRoutes() {
  const location = useLocation();
  const lState = location.state as { background?: Location };
  return (
    <div>
      <SentryRoutes location={lState?.background || location}>
        <Route path="/login" element={<WithSuspense element={<Login />} />} />
        <Route
          path="/verify-user/:challenge"
          element={<WithSuspense element={<VerifyLogin />} />}
        />
        {/* Auth routes should be below, non-auth routes should be above */}
        <Route path="/" element={<AuthApp />}>
          {/* <Route index element={<NotificationsPage />} /> */}
          <Route index element={<DashboardPage />} />
          <Route path="*" element={<InvalidRoute />} />
        </Route>

        <Route path="*" element={<InvalidRoute />} />
      </SentryRoutes>
    </div>
  );
}

function RefreshableAppRoutes() {
  const { refreshCount } = useRefresh();
  return (
    <React.Fragment key={refreshCount}>
      {AppUtil.isMobileUI() ? <MobileRoutes /> : <AppRoutes />}
    </React.Fragment>
  );
}

// Component implementation
function App() {
  return (
    <ConfigProvider
      theme={{
        token: {
          borderRadius: 5,
          fontFamily: 'Nunito',
          colorPrimary: '#661eff',
          'purple-10': '#140e56'
        }
      }}
    >
      <Provider store={store}>
        <BrowserRouter basename="">
          <Apollo>
            <AuthProvider>
              <RefreshProvider>
                <RefreshableAppRoutes />
              </RefreshProvider>
            </AuthProvider>
          </Apollo>
        </BrowserRouter>
      </Provider>
    </ConfigProvider>
  );
}

export default App;
