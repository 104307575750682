//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Question is a component that presents a question to the user
//

// 3rd part libraries
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';

// Local imports
import i18n from '@utils/i18n';
import ComponentFactory from '@utils/ComponentFactory';
import AppConstants from '@utils/AppConstants';
import { IKnowledgeTreeNode } from '@details/knowledge/KnowledgeInterfaces';

// Component CSS
import './Question.less';

// Component i18n
const i18nt = (s: string, d: string) => i18n.t('Question', s, d);

// Props for this component
interface IQuestionProps {
  /**
   * Tree Node for which to present a question
   */
  treeNode: IKnowledgeTreeNode;
  /**
   * Errors associates with this node
   */
  errorMsgs: string[];
  /**
   * Answer callback
   */
  onAnswer: (answer: any) => void;
  /**
   * Save current answer callback
   */
  onSave: () => void;
}

// Component implementation
function Question(props: IQuestionProps) {
  const { treeNode, errorMsgs, onAnswer, onSave } = props;

  const [focused, setFocused] = useState(false);

  const onChange = (_id: string, value: any) => {
    onAnswer(value);
    // Auto save data changes
    if (treeNode.type === AppConstants.QUESTION_TYPES.DATE) {
      onSave();
    }
  };

  const questionValue =
    treeNode.type === AppConstants.QUESTION_TYPES.MULTIPLE_SELECT
      ? treeNode.data.map((d) => d.value)
      : treeNode.data[0].value;

  const enterTypes = [
    AppConstants.QUESTION_TYPES.STRING,
    AppConstants.QUESTION_TYPES.NUMBER
  ];
  const sTipKey =
    enterTypes.indexOf(treeNode.type) >= 0
      ? 'question_instructions_enter'
      : 'question_instructions_select';

  // Choice questions have options - Currently not supported
  // const qOptions = question.options.map((o) => ({ value: o, label: o }));
  const qOptions: any = [];

  // If we have an error - the error is shown in the tip
  const hasError = errorMsgs.length > 0;
  // Hints are hidden for non-focused elements
  let hintClass = 'hint'; // `hint ${focused ? "" : "hint-not-focused"}`;
  let tipColor = AppConstants.COLORS.BLACK;
  let tipHTML;
  if (hasError) {
    hintClass = 'hint hint-error';
    tipColor = AppConstants.COLORS.COLOR_ERROR;
    tipHTML = <div>{errorMsgs[0]}</div>;
  } else {
    tipHTML = (
      <div>
        <div>{i18nt(sTipKey, '')}</div>
        {treeNode.confidence && (
          <div>
            {i18nt('question_confidence', '').replace(
              '%s',
              treeNode.confidence
            )}
          </div>
        )}
      </div>
    );
  }

  return (
    <div className="question-container">
      <div className="question">
        <div
          className={`question-answers
          ${hasError ? 'has-errors' : ''}
          ${questionValue ? 'has-value' : ''}`}
        >
          {ComponentFactory.getInputElement(
            treeNode.id,
            treeNode.type,
            questionValue,
            qOptions,
            // OnChange
            onChange,
            // AdditionalProps: Blur and Enter Key auto save
            {
              onFocus: () => setFocused(true),
              onBlur: () => {
                setFocused(false);
                onSave();
              },
              onKeyDown: (e: KeyboardEvent) => {
                if (e.code === 'Enter') {
                  onSave();
                }
              },
              // Bordered if focused or doesn't have a value
              bordered: hasError || focused || !questionValue
            }
          )}
          <div className={hintClass}>
            <Tooltip placement="bottom" color={tipColor} title={tipHTML}>
              {ComponentFactory.getIcon(
                hasError
                  ? AppConstants.ICONS.WARNING_EXCLAMATION
                  : AppConstants.ICONS.QUESTION
              )}
            </Tooltip>
          </div>
        </div>
      </div>
    </div>
  );
}

// Component Prop Types
Question.propTypes = {
  // eslint-disable-next-line react/forbid-prop-types
  treeNode: PropTypes.object.isRequired,
  errorMsgs: PropTypes.arrayOf(PropTypes.string),
  onAnswer: PropTypes.func.isRequired
};

export default Question;
