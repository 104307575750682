import React, { useState } from 'react';
import { Alert, Button } from 'antd';
import classNames from 'classnames';
import Editor from '@/components/editor';
import './ErrorDescription.less';
import MarkdownRenderer from './MarkdownRenderer';

interface IErrorDescriptionProps {
  errorMessage: string | null;
  rawException: string | null;
  className?: string;
}

function ErrorDescription(props: IErrorDescriptionProps) {
  const { errorMessage, rawException, className } = props;

  const [showDetails, toggleDetails] = useState(!errorMessage);

  const body = (
    <div>
      <MarkdownRenderer>{errorMessage}</MarkdownRenderer>
      {showDetails && rawException && (
        <Editor
          language="english"
          value={rawException!}
          className="error-raw"
          onChange={() => {}}
          readOnly
        />
      )}
    </div>
  );

  return (
    <Alert
      message="Error"
      description={body}
      type="error"
      action={
        rawException ? (
          <Button
            size="small"
            danger
            onClick={() => toggleDetails(!showDetails)}
          >
            Detail
          </Button>
        ) : null
      }
      className={classNames('error-description', className)}
    />
  );
}

export default ErrorDescription;
