import React from 'react';
import { Space, Button } from 'antd';

import AppUtil from '@utils/AppUtil';
import AppConstants from '@utils/AppConstants';
import { useAppDispatch } from '../stores/hooks';
import { showDrawer } from '../stores/slices/appDrawer';

import './DocsViewer.less';

export default function DocsViewer() {
  const dispatch = useAppDispatch();

  const show = () => {
    dispatch(
      showDrawer({
        drawerId: AppConstants.DRAWERS.DOCS,
        drawerParams: {}
      })
    );
  };

  return (
    <div className={AppUtil.mobileClassName('docs-viewer')}>
      <Space>
        <Button className="doc-button" onClick={show}>
          Docs
        </Button>
      </Space>
    </div>
  );
}
