import { Button, message, Modal } from 'antd';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';
import { Question as QuestionUI } from '../details/runs/QuestionForm';
import {
  Answer,
  CreateAnswerInput,
  CreateAnswersMutationVariables,
  Question
} from '../generated/API';
import { useAppDispatch } from '../stores/hooks';
import { createAnswers } from '../stores/slices/answer';
import { userSelector } from '../stores/slices/user';
import AppUtil from '../utils/AppUtil';
import FormattingUtil from '../utils/FormattingUtil';
import './ExceptionsBatchAnswerPopup.less';

interface IProps {
  onClose: () => void;
  onSuccess?: (data: Answer[]) => void;
  questions: Question[];
}

function ExceptionsBatchAnswerPopup(props: IProps) {
  const { onSuccess, onClose, questions } = props;

  const { username } = useSelector(userSelector);
  const dispatch = useAppDispatch();

  const [questionValues, setQuestionValues] = useState<any>({});
  const [loading, toggleLoading] = useState(false);

  const onFinish = (values: any) => {
    const variables: CreateAnswersMutationVariables = {
      inputs: questions.map(
        (question) =>
          AppUtil.prepareAnswerInputVariables({
            question,
            values,
            owner: username
          }) as CreateAnswerInput
      )
    };

    toggleLoading(true);

    dispatch(createAnswers(variables))
      .unwrap()
      .then((resp) => {
        if (resp.data?.createAnswers) {
          onSuccess?.(resp.data?.createAnswers as Answer[]);
        }
        message.success(
          `Answered ${questions.length} ${AppUtil.getPlural(
            'exception',
            questions.length
          )} ✅`
        );
        onClose();
      })
      .finally(() => {
        toggleLoading(false);
      });
  };

  const onValuesChange = (allValues: any) => {
    if (allValues.inputType === 'upload' && !!allValues.upload) {
      onFinish(allValues);
    } else {
      setQuestionValues({
        ...questionValues,
        ...allValues
      });
    }
  };

  const disableSubmit = questionValues.inputType === 'upload';

  let title = `Answer ${AppUtil.getPlural('exception', questions.length)} (${
    questions.length
  })`;

  const sameQuestions = AppUtil.checkSameQuestions(questions);
  if (sameQuestions) {
    const processed = FormattingUtil.processQuestion(questions[0]);
    const questionText = processed?.text;
    if (questionText) {
      title = questionText;
    }
  }

  return (
    <Modal
      className="exceptions-batch-answer-modal"
      title={title}
      centered
      open
      footer={null}
      onCancel={onClose}
      keyboard={false}
      maskClosable={false}
    >
      <div className="question-group">
        <QuestionUI
          question={{}}
          disabled={loading}
          knowledgeId=""
          onValuesChange={onValuesChange}
          source="batch"
          allowContextDebugging={false}
        />
        <div className="batch-answer-button">
          <Button
            loading={loading}
            size="middle"
            type="primary"
            onClick={() => onFinish(questionValues)}
            disabled={disableSubmit}
          >
            Submit
          </Button>
        </div>
      </div>
    </Modal>
  );
}

export default ExceptionsBatchAnswerPopup;
