import * as amplitude from '@amplitude/analytics-browser';
import Amplitude from '@redux-beacon/amplitude';
import { createMiddleware, createMetaReducer } from 'redux-beacon';
import amplitudeEvents from '@/analytics/amplitude-event-definitions';
import ENV_CONFIG from '@/EnvConstants';

amplitude.init(ENV_CONFIG.integrations.amplitude.key, undefined, {
  optOut: ENV_CONFIG.local,
  attribution: {
    trackPageViews: true
  }
});

const target = Amplitude({ instance: amplitude });

export const amplitudeMiddleware = createMiddleware(amplitudeEvents, target);
export const amplitudeMetaReducer = createMetaReducer(amplitudeEvents, target);
