import { Notification } from '@/generated/API';
import { RootState } from '@/stores/AppStore';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';
import { getWorkerDependencies } from './worker';

export const NotificationAdapter = createEntityAdapter<Notification>();

export const notificationSlice = createSlice({
  name: 'notification',
  initialState: NotificationAdapter.getInitialState({
    loading: false,
    error: undefined,
    nextToken: undefined
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getWorkerDependencies.fulfilled, (state, action) => {
      if (action.payload.data?.listCommandsByWorker?.items) {
        const notification =
          action.payload.data.listCommandsByWorker.items.reduce(
            (acc, cur) => acc.concat((cur as any).notifications.items),
            []
          );
        NotificationAdapter.upsertMany(state, notification);
      }
    });
  }
});

export const notificationSelectors =
  NotificationAdapter.getSelectors<RootState>((state) => state.notification);

export default notificationSlice.reducer;
