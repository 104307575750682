import { useApolloClient } from '@apollo/client';
import { useEffect } from 'react';
import { uniq } from 'ramda';
import Subscriptions from '../graphql/Subscriptions';

interface IUseRunSubscriptionsProps {
  workerId: string | null;
  onStepsUpdate: (paths: string[]) => void;
  onWorkerKnowledgeIdUpdate: (knowledgeId: string) => void;
}

function useRunSubscriptions(props: IUseRunSubscriptionsProps) {
  const { workerId, onStepsUpdate, onWorkerKnowledgeIdUpdate } = props;

  const client = useApolloClient();

  let workerKnowledgeIdUpdateSubscription: any = null;
  let stepsUpdateSubscription: any = null;

  const handleUpdatesError = () => {
    // notification.warning({
    //   message: (
    //     <Space>
    //       Updates stopped due to an error, please refresh to start receiving
    //       them.
    //     </Space>
    //   ),
    //   description: (
    //     <Button onClick={() => refresh()}>Refresh</Button>
    //   ),
    //   icon: <ExclamationCircleFilled />,
    //   closeIcon: <span />,
    //   duration: 0
    // });

    window.location.reload();
  };

  useEffect(() => {
    if (workerId) {
      const observer = client.subscribe({
        query: Subscriptions.onWorkerKnowledgeIdUpdate(),
        variables: {
          id: workerId
        },
        fetchPolicy: 'network-only'
      });

      workerKnowledgeIdUpdateSubscription = observer.subscribe({
        next({ data }) {
          const knowledgeId = data?.onNotifyWorkerUpdate?.knowledgeId;
          if (knowledgeId) {
            onWorkerKnowledgeIdUpdate(knowledgeId);
          }
        },
        error(e) {
          console.log(
            'onWorkerKnowledgeIdUpdate subcription error',
            JSON.stringify(e)
          );
          handleUpdatesError();
        }
      });
    }

    return () => {
      if (workerKnowledgeIdUpdateSubscription) {
        workerKnowledgeIdUpdateSubscription.unsubscribe();
      }
    };
  }, [workerId]);

  useEffect(() => {
    if (workerId) {
      const observer = client.subscribe({
        query: Subscriptions.OnStepsUpdated(),
        variables: {
          workerId
        },
        fetchPolicy: 'network-only'
      });

      stepsUpdateSubscription = observer.subscribe({
        next({ data }) {
          const steps: any[] = data?.onStepsUpdated?.stepList;
          if (steps) {
            let paths: any[] = [];
            steps.forEach((step) => {
              paths = uniq(
                paths.concat(
                  (step as any).sentencePath.map(
                    (path: any) => `${path.ctxId}:${path.sentenceId}`
                  )
                )
              );
            });
            onStepsUpdate(paths);
          }
        },
        error(e) {
          console.log('OnStepsUpdated subcription error', JSON.stringify(e));
          handleUpdatesError();
        }
      });
    }

    return () => {
      if (stepsUpdateSubscription) {
        stepsUpdateSubscription.unsubscribe();
      }
    };
  }, [workerId]);
}

export default useRunSubscriptions;
