//
// Copyright (C) - Kognotos, Inc. All rights reserved
//
// Shows debug details for a run
//

// 3rd party libraries
import React from 'react';
import { Popover } from 'antd';

// Local imports
import AppConstants from '@utils/AppConstants';
import { Worker } from '@/generated/API';
import moment from 'moment';
import AppUtil from '../utils/AppUtil';

interface IPropsRunDebugDetails {
  /**
   * The run (worker).
   */
  worker: Worker;
  workerId: string;

  /**
   * Embedded child elements to show inside popover.
   */
  children: any;
  showCreatedAt?: boolean;
}

function RunDebugDetails({
  worker,
  workerId,
  children,
  showCreatedAt
}: IPropsRunDebugDetails) {
  const logLink = AppConstants.TEMPLATES.WORKER_LOGS({ workerId });

  const mmt = moment(
    Number(AppUtil.millisFromDateString(worker?.createdAt ?? Date().toString()))
  );

  return (
    <Popover
      key={`details-${worker?.id}`}
      placement="right"
      className="popover-trigger"
      title={worker?.name || 'Playground'}
      trigger="hover"
      content={
        <span>
          <b>Id:</b> {worker?.id} (
          <a href={logLink} target="_blank" rel="noreferrer">
            logs
          </a>
          )
          <p />
          <b>KnowledgeId:</b> {worker?.knowledgeId}
          <p />
          {showCreatedAt ? (
            <span>
              <b>Created At:</b> {mmt.toLocaleString()}
            </span>
          ) : null}
        </span>
      }
    >
      {children}
    </Popover>
  );
}

export default RunDebugDetails;
