//
// Copyright (C) - Kognitos, Inc. All rights reserved
// ApolloGraphQL queries - Client side templates
//

import {
  questions,
  getAnswer,
  getFact,
  getProcedure,
  getQuestion,
  listLearningsByProcedure,
  listProceduresByKnowledge,
  parseAlangText,
  getUploadToS3Url,
  getDownloadFromS3Url,
  listWorkerOutputsByProcedure,
  generateOpenAICompletions,
  listDepartmentCollaborators,
  getDepartmentHistory,
  getOwnerOrganization,
  getServiceCommand,
  autoCompleteCommand,
  getStepPathForRun,
  getFactsAtStepPath,
  getRequestAnswer,
  listLearningsByDepartment,
  getLearnedAnswerByRequestAnswerId,
  listProceduresByDepartment,
  listKChatMessagesByWorker,
  listSharedEntityByProcedure,
  exportCodeForContext,
  exportDepartment
} from '@/generated/graphql/queries';
import { gql } from '@apollo/client';
import { listDepartmentLearnedAnswers } from './learning';
import { getRequest, listProcedureRequestsQuery } from './request';
import {
  getWorker,
  getWorkerDependencies,
  getWorkerDependenciesV2,
  getWorkerKnowledgeId,
  getWorkerRequestsQuery
} from './workerQuery';
import { listBooksByDepartment, listBooksByKnowledge } from './book';
import {
  getDepartmentQuery,
  listAllDepartmentsQuery,
  listUserDepartmentsQuery
} from './queries/department';

export const COMMAND_FIELDS = gql`
  fragment CommandFields on Command {
    id
    owner
    readers
    editors
    name
    description
    workerId
    text
    subText
    subTextLanguage
    procedureId
    createdAt
    ast
    state
    output
    source
    sourceMetadata
    answers {
      items {
        questionId
      }
    }
    debug
    receivedAt
    questions {
      items {
        id
        createdAt
        commandId
        workerId
        text
        answerId
        isAnswered
        path
        type
        validationList
        description
        delegationChain {
          method
          recipients
        }
        rawException
        contextPath {
          ctxId
          sentenceId
        }
        contextId
        children {
          id
          createdAt
          commandId
          workerId
          text
          answerId
          isAnswered
          path
          type
          validationList
          delegationChain {
            method
            recipients
          }
          rawException
          contextPath {
            ctxId
            sentenceId
          }
          contextId
          parentId
          description
        }
      }
    }
    notifications {
      items {
        id
        contextId
        commandId
        workerId
        sentenceId
        info
        owner
        readers
        editors
        name
        description
        createdAt
      }
    }
  }
`;

const Queries = {
  Command() {
    return gql`
      ${COMMAND_FIELDS}
      query GetCommand($id: ID!) {
        getCommand(id: $id) {
          ...CommandFields
        }
      }
    `;
  },

  IsCommandReceived() {
    return gql`
      query GetCommand($id: ID!) {
        getCommand(id: $id) {
          id
          receivedAt
        }
      }
    `;
  },

  CommandsByWorker() {
    return gql`
      ${COMMAND_FIELDS}
      query ListCommandsByWorker(
        $workerId: ID
        $limit: Int
        $nextToken: String
      ) {
        listCommandsByWorker(
          workerId: $workerId
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            ...CommandFields
          }
          nextToken
        }
      }
    `;
  },

  Fact() {
    return gql(getFact);
  },

  Facts() {
    return gql`
      query GetFacts($knowledgeId: ID!, $factIds: [ID!]!) {
        getFacts(knowledgeId: $knowledgeId, factIds: $factIds) {
          id
          knowledgeId
          knowledge {
            id
            owner
            readers
            editors
            name
            description
            departmentId
            workerId
            parentKnowledgeId
            state
          }
          names
          parentFact {
            id
            knowledgeId
            names
            parentFactId
            locations
            value
            owner
            readers
            editors
            referenceTo
            viewUrl
          }
          parentFactId
          locations
          relations {
            factId
            name
            direction
            fact {
              names
              value
              locations
              parentFact {
                id
                names
                value
              }
            }
          }
          value
          descriptions {
            name
            factId
          }
          owner
          readers
          editors
          referenceTo
          viewUrl
        }
      }
    `;
  },

  ExportCodeForContext() {
    return gql(exportCodeForContext);
  },

  ContextsForStep() {
    return gql`
      query GetContextsForStep($workerId: ID!, $contextId: ID, $stepId: ID) {
        getContextsForStep(
          workerId: $workerId
          contextId: $contextId
          stepId: $stepId
        ) {
          items {
            id
            createdAt
            depth
            stepList {
              __typename
              id
              number
              createdAt
              scratchedAt
              text
              answer
              childContextIdList
              contextId
              positionInContext
              workerId
              concepts
              questionIds
              commandId
              requestIds
            }
            commands {
              __typename
              contextId
              positionInContext
              id
              state
              text
              createdAt
              forwardedAt
              invokedAt
              receivedAt
              processingAt
              processedAt
              failedAt
              workErrors
              workStatus
            }
            workerId
          }
        }
      }
    `;
  },

  Department() {
    return gql(getDepartmentQuery);
  },

  ListUserDepartments() {
    return gql(listUserDepartmentsQuery);
  },

  ListDepartmentLearnedAnswers() {
    return gql(listDepartmentLearnedAnswers);
  },

  ListAllDepartments() {
    return gql(listAllDepartmentsQuery);
  },

  ListDepartmentCollaborators() {
    return gql(listDepartmentCollaborators);
  },

  // Get department history for
  GetDepartmentHistory() {
    return gql(getDepartmentHistory);
  },

  // List departments w/ worker info
  Departments() {
    return gql`
      query ListDepartments($limit: Int, $nextToken: String) {
        listDepartments(limit: $limit, nextToken: $nextToken) {
          items {
            id
            owner
            readers
            editors
            name
            description
            publishedKnowledgeId
            workerId
            worker {
              id
              knowledgeId
            }
          }
          nextToken
        }
      }
    `;
  },

  // Organization
  OwnerOrganization() {
    return gql(getOwnerOrganization);
  },

  // Get procedure
  Procedure() {
    return gql(getProcedure);
  },

  ProceduresByDepartment() {
    return gql(listProceduresByDepartment);
  },

  // List procedures
  ProceduresByKnowledge() {
    return gql(listProceduresByKnowledge);
  },

  ListSharedEntities() {
    return gql(listSharedEntityByProcedure);
  },

  Question() {
    return gql(getQuestion);
  },

  Questions() {
    return gql(questions);
  },

  getRequestAnswer() {
    return gql(getRequestAnswer);
  },

  getLearnedAnswerByRequestAnswerId() {
    return gql(getLearnedAnswerByRequestAnswerId);
  },

  QuestionsByProcedure() {
    return gql`
      query ListQuestionsByProcedure(
        $procedureId: ID!
        $limit: Int = 100
        $nextToken: String
        $isAnsweredFilter: Boolean
      ) {
        listQuestionsByProcedure(
          procedureId: $procedureId
          limit: $limit
          nextToken: $nextToken
          isAnsweredFilter: $isAnsweredFilter
        ) {
          items {
            id
            commandId
            workerId
            contextId
            sentenceId
            owner
            readers
            editors
            name
            description
            type
            text
            path
            procedureId
            validationList
            answerId
            isAnswered
            delegationChain {
              method
              recipients
            }
            worker {
              id
              knowledgeId
            }
            createdAt
            rawException
            parentId
            answer {
              createdAt
              owner
              text
              type
            }
          }
          nextToken
        }
      }
    `;
  },

  BasicQuestionsByProcedure() {
    return gql`
      query ListQuestionsByProcedure(
        $procedureId: ID!
        $limit: Int = 100
        $nextToken: String
      ) {
        listQuestionsByProcedure(
          procedureId: $procedureId
          limit: $limit
          nextToken: $nextToken
        ) {
          items {
            id
          }
        }
      }
    `;
  },

  Answer() {
    return gql(getAnswer);
  },

  // Get worker
  Worker() {
    return gql(getWorker);
  },

  WorkerKnowledgeId() {
    return gql(getWorkerKnowledgeId);
  },

  WorkerDependencies() {
    return gql(getWorkerDependencies);
  },

  WorkerDependenciesV2() {
    return gql(getWorkerDependenciesV2);
  },

  WorkerRequests() {
    return gql(getWorkerRequestsQuery);
  },

  Request() {
    return gql(getRequest);
  },

  RequestsByProcedure() {
    return gql(listProcedureRequestsQuery);
  },

  // List workers by procedure
  ListBasicWorkersByProcedure() {
    return gql`
      query ListWorkersByProcedure(
        $procedureId: ID
        $limit: Int = 1000
        $nextToken: String
        $stateFilter: String
      ) {
        listWorkersByProcedure(
          procedureId: $procedureId
          limit: $limit
          nextToken: $nextToken
          stateFilter: $stateFilter
        ) {
          items {
            id
            name
            createdAt
            originalWorkerId
            state
          }
          nextToken
        }
      }
    `;
  },

  // List workers by procedure
  ListWorkersByProcedure() {
    return gql`
      query ListWorkersByProcedure(
        $procedureId: ID
        $limit: Int = 1000
        $nextToken: String
        $stateFilter: String
        $fromCreationDate: String
        $toCreationDate: String
      ) {
        listWorkersByProcedure(
          procedureId: $procedureId
          limit: $limit
          nextToken: $nextToken
          stateFilter: $stateFilter
          fromCreationDate: $fromCreationDate
          toCreationDate: $toCreationDate
        ) {
          items {
            id
            owner
            readers
            editors
            name
            description
            state
            version
            knowledgeId
            departmentId
            procedureId
            entryCommand
            entryCommandId
            createdAt
            originalWorkerId
            exceptions {
              id
              commandId
              workerId
              contextId
              sentenceId
              owner
              readers
              editors
              name
              description
              type
              text
              path
              procedureId
              validationList
              answerId
              isAnswered
              createdAt
              rawException
              parentId
              secret
            }
            state
            stateLastUpdatedAt
            status
            statusLastUpdatedAt
            attachments
            requests {
              limit
              nextToken
              items {
                id
                state
                questions {
                  id
                }
              }
            }
          }
          nextToken
        }
      }
    `;
  },

  // List learnings by procedure
  LearningsByProcedure() {
    return gql(listLearningsByProcedure);
  },

  LearningsByDepartment() {
    return gql(listLearningsByDepartment);
  },

  // Get a knowledge tree by id
  KnowledgeTree() {
    return gql`
      query getKnowledgeTree($id: ID!) {
        getKnowledgeTree(id: $id) {
          id
          title
          knowledge
        }
      }
    `;
  },

  // Given a knowledge tree and its tree node, get its children
  GetKnowledgeTreeNodeChildren() {
    return gql`
      query getKnowledgeTreeNodeChildren(
        $knowledgeTreeId: String!
        $treeNodeId: String!
      ) {
        getKnowledgeTreeNodeChildren(
          knowledgeTreeId: $knowledgeTreeId
          treeNodeId: $treeNodeId
        )
      }
    `;
  },

  // Get additional columns by gridId
  AdditionalColumns() {
    return gql`
      query getAdditionalColumns($gridId: ID!) {
        getAdditionalColumns(gridId: $gridId)
      }
    `;
  },

  ListBooksByKnowledge() {
    return gql(listBooksByKnowledge);
  },

  ListBooksByDepartment() {
    return gql(listBooksByDepartment);
  },

  ParseAlangText() {
    return gql(parseAlangText);
  },

  S3UploadLink() {
    return gql(getUploadToS3Url);
  },

  S3DownloadLink() {
    return gql(getDownloadFromS3Url);
  },

  ListWorkerOutputsByProcedure() {
    return gql(listWorkerOutputsByProcedure);
  },

  GenerateOpenAICompletions() {
    return gql(generateOpenAICompletions);
  },

  GetAutoComplete() {
    return gql(autoCompleteCommand);
  },

  ServiceCommand() {
    return gql(getServiceCommand);
  },

  GetStepPathForRun() {
    return gql(getStepPathForRun);
  },

  GetFactsAtStepPath() {
    return gql(getFactsAtStepPath);
  },

  ListKChatMessagesByWorkerQuery() {
    return gql(listKChatMessagesByWorker);
  },

  ExportDepartment() {
    return gql(exportDepartment);
  }
};

export default Queries;
