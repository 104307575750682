import { PlaySquareOutlined } from '@ant-design/icons';
import { useMutation, useQuery } from '@apollo/client';
import { Button, Modal } from 'antd';
import React, { useState } from 'react';
import AutoSizeInput from '../components/AutoSizeInput';
import FactSummary from '../components/facts/FactSummary';
import Loader from '../components/Loader';
import {
  Command,
  CreateCommandMutation,
  CreateCommandMutationVariables,
  Department,
  ListCommandsByWorkerQuery
} from '../generated/API';
import usePageVisibility from '../hooks/usePageVisibility';
import AppConstants from '../utils/AppConstants';
import AppUtil from '../utils/AppUtil';
import FormattingUtil from '../utils/FormattingUtil';
import './DepartmentWorkerPlaygroundPopup.less';

interface IProps {
  onClose: () => void;
  department: Department;
}

function DepartmentWorkerPlaygroundPopup(props: IProps) {
  const { onClose, department } = props;

  const [commandValue, setCommandValue] = useState('');

  const { pageHidden } = usePageVisibility();

  const [createCommand, createCommandResults] =
    useMutation<CreateCommandMutation>(AppConstants.APIS.COMMANDS.CREATE());

  const { data, loading } = useQuery<ListCommandsByWorkerQuery>(
    AppConstants.APIS.COMMANDS.LIST_BY_WORKER(),
    {
      variables: {
        workerId: department.workerId
      },
      pollInterval: AppUtil.getQueryPollInterval(),
      skip: pageHidden
    }
  );

  const commands = (data?.listCommandsByWorker?.items || []) as Command[];

  const handleNewCommandSave = () => {
    const variables: CreateCommandMutationVariables = {
      input: {
        name: 'debug',
        description: 'debug',
        workerId: department.workerId!,
        text: commandValue,
        subText: '',
        subTextLanguage: 'english'
      }
    };

    createCommand({ variables })
      .then(() => {
        setCommandValue('');
        // return resp;
      })
      .catch(console.error);
  };

  const renderCommand = (command: Command, index: number) => {
    const parsedOutput = FormattingUtil.parseBrainValue(command.output || '{}');
    return (
      <div className="command" data-cy={`command-${index}`}>
        <span className="command-value">{command.text}</span>
        <span className="answer">
          {parsedOutput.id && (
            <FactSummary
              factId={parsedOutput.id}
              knowledgeId={department.draftKnowledgeId!}
            />
          )}
        </span>
      </div>
    );
  };

  return (
    <Modal
      open
      onCancel={onClose}
      width={600}
      title={
        <div>
          Department Worker{' '}
          <span className="workerId">({department.workerId})</span>
        </div>
      }
      footer={[
        <Button key="back" onClick={onClose}>
          Close
        </Button>
      ]}
      maskClosable={false}
      keyboard={false}
      className="department-worker-playground-popup"
    >
      {loading ? (
        <div className="commands-loader-cnt">
          <Loader />
        </div>
      ) : (
        <div className="commands">
          {commands
            .sort((a: any, b: any) => {
              const c1 = new Date(a.createdAt).getTime();
              const c2 = new Date(b.createdAt).getTime();
              return c1 - c2;
            })
            .map((command, index) => (
              <React.Fragment key={command.id}>
                {renderCommand(command, index)}
              </React.Fragment>
            ))}
        </div>
      )}

      <div className="actions">
        <AutoSizeInput
          placeholder="Enter a step"
          disabled={createCommandResults.loading}
          size={{
            minRows: 1,
            maxRows: 5
          }}
          autoFocus
          value={commandValue}
          onChange={setCommandValue}
          className="command-input"
        />
        <Button
          type="primary"
          onClick={handleNewCommandSave}
          loading={createCommandResults.loading}
          icon={<PlaySquareOutlined />}
          className="submit-button"
        >
          Run
        </Button>
      </div>
    </Modal>
  );
}

export default DepartmentWorkerPlaygroundPopup;
