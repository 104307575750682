import React, { useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Divider,
  Form,
  Input,
  message,
  Modal,
  Space
} from 'antd';
import { useMutation } from '@apollo/client';
import { ampTrackEvent } from '@/analytics';
import { useSelector } from 'react-redux';
import Mutations from '../graphql/Mutations';
import {
  CreateDepartmentInput,
  CreateDepartmentMutation,
  CreateOrganizationMutation,
  Department,
  DepartmentFeature
} from '../generated/API';
import './CreateDepartmentPopup.less';
import { useAppDispatch } from '../stores/hooks';
import { addDepartment, selectDepartment } from '../stores/slices/department';
import AppUtil from '../utils/AppUtil';
import { updateOrganization, userSelector } from '../stores/slices/user';
import {
  retryPromiseWithDelay,
  delayExponentialBackoff
} from '../utils/promise';

interface IProps {
  onSuccess?: (department: Department) => void;
  onClose?: () => void;
}

function NotAuthorized() {
  return (
    <>
      <p>It seems you don&apos;t have access to create an organization</p>
      <p>
        Our app is currently in early-access and you have been added to the
        waitlist
      </p>
      <p>
        If you have already been invited, please contact{' '}
        <a href="mailto:support@kognitos.com?subject=Requesting Access">
          support@kognitos.com
        </a>
      </p>
      <p>Thank you for your interest in Kognitos.</p>
    </>
  );
}

export function CreateDepartment(props: IProps) {
  const { onSuccess, onClose } = props;

  const {
    username,
    organization: { data: organization }
  } = useSelector(userSelector);
  const [create, { loading }] = useMutation<CreateDepartmentMutation>(
    Mutations.CreateDepartment()
  );
  const [createOrganization, createOrgProps] =
    useMutation<CreateOrganizationMutation>(Mutations.CreateOrganization());

  const dispatch = useAppDispatch();

  const [orgValue, setOrgValue] = useState(organization?.name || '');

  const handleOrganizationCreate = () => {
    if (orgValue.trim().length !== 0) {
      retryPromiseWithDelay({
        promiseFn: () =>
          createOrganization({
            variables: {
              input: {
                name: orgValue,
                owner: username
              }
            }
          }),
        errorPredicate: (e: any) =>
          (e?.message || '').toLowerCase().includes('status code 429'),
        retries: 5,
        delayTime: delayExponentialBackoff
      })
        .then((resp) => {
          if (resp.data?.createOrganization) {
            const newOrganization = resp.data.createOrganization;
            dispatch(updateOrganization(newOrganization));
            ampTrackEvent('Create Organization Success', {
              name: orgValue,
              email: username
            });
            message.success(
              `'${newOrganization.name}' organization created successfully`
            );
          }
        })
        .catch((e) => {
          if (e.message.includes('not authorized')) {
            ampTrackEvent('Create Organization Fail', {
              name: orgValue,
              email: username
            });
          } else {
            console.error('org create error', e);
            message.error(
              e.message || 'Failed to create a new organization',
              5
            );
          }
        });
    }
  };

  const onFinish = (values: any) => {
    const features: CreateDepartmentInput['features'] = [
      DepartmentFeature.EXCEPTION_REQUEST,
      DepartmentFeature.DEPARTMENT_BOOK // this is enabled by default, so it doesn't technically need to be set here
    ];

    const input: CreateDepartmentInput = {
      name: values.name,
      owner: values.owner,
      local: values.local,
      features
    };

    retryPromiseWithDelay({
      promiseFn: () =>
        create({
          variables: {
            input
          }
        }),
      errorPredicate: (e: any) =>
        (e?.message || '').toLowerCase().includes('organisation not found') ||
        (e?.message || '').toLowerCase().includes('status code 429'),
      retries: 5,
      delayTime: delayExponentialBackoff
    })
      .then((resp) => {
        if (resp.data?.createDepartment) {
          const newDepartment = resp.data.createDepartment as Department;
          dispatch(addDepartment(newDepartment));
          dispatch(selectDepartment(newDepartment));
          message.success(
            `'${newDepartment.name}' department created successfully`
          );
          if (onSuccess) {
            onSuccess(newDepartment);
          }
        }
        if (resp.errors) {
          console.error('Create department error', resp.errors);
        }
        onClose?.();
      })
      .catch((e) => {
        console.error('Create department error', e);
        message.error(e.message || 'Failed to create a new department');
      });
  };

  return (
    <div className="create-department-form" data-cy="create-department-form">
      <h3 className="heading">Create Department</h3>
      {createOrgProps.error?.message.includes('not authorized') && (
        <Alert
          message="Oops!"
          description={<NotAuthorized />}
          type="error"
          className="alert"
        />
      )}
      {!organization && (
        <Alert
          message="Organization Not Found."
          description="Each department is associated with an organization. Please create one before proceeding further."
          type="info"
          className="alert"
        />
      )}
      <Form onFinish={onFinish} layout="vertical">
        <Form.Item
          label="Organization"
          name="organization"
          initialValue={organization?.name}
        >
          {!organization ? (
            <Input
              placeholder="Organization name"
              data-cy="org-name"
              autoComplete="off"
              spellCheck={false}
              disabled={createOrgProps.loading}
              autoFocus={!organization}
              value={orgValue}
              onChange={(e) => setOrgValue(e.target.value)}
              onPressEnter={handleOrganizationCreate}
              suffix={
                <Button
                  type="link"
                  size="small"
                  loading={createOrgProps.loading}
                  onClick={handleOrganizationCreate}
                >
                  Create
                </Button>
              }
            />
          ) : (
            <Input data-cy="org-name" disabled />
          )}
        </Form.Item>
        <Form.Item
          label="Name"
          name="name"
          rules={[{ required: true, message: 'Invalid department name' }]}
        >
          <Input
            placeholder="Department name"
            data-cy="dep-name"
            autoComplete="off"
            spellCheck={false}
            autoFocus={!!organization}
            disabled={!organization}
          />
        </Form.Item>

        <Form.Item label="Owner" name="owner" initialValue={username}>
          <Input disabled />
        </Form.Item>

        {AppUtil.allowDepLocalUpdate() && (
          <Form.Item name="local" valuePropName="checked">
            <Checkbox disabled={!organization}>Local</Checkbox>
          </Form.Item>
        )}

        <Form.Item className="actions">
          <Divider />
          <Space>
            {onClose && (
              <Button key="back" onClick={onClose}>
                Cancel
              </Button>
            )}
            <Button
              key="submit"
              type="primary"
              loading={loading}
              disabled={!organization}
              htmlType="submit"
            >
              Submit
            </Button>
          </Space>
        </Form.Item>
      </Form>
    </div>
  );
}

function CreateDepartmentPopup(props: IProps) {
  const { onSuccess, onClose } = props;
  return (
    <Modal
      centered
      open
      onCancel={onClose}
      footer={null}
      keyboard={false}
      maskClosable={false}
      className="create-department-popup"
    >
      <CreateDepartment onClose={onClose} onSuccess={onSuccess} />
    </Modal>
  );
}

export default CreateDepartmentPopup;
