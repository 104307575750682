import { max } from 'lodash/fp';
import { uniq } from 'ramda';
import { Step } from '../../../generated/API';
import AppUtil from '../../../utils/AppUtil';
import FormattingUtil from '../../../utils/FormattingUtil';
import { IRunStepItem, RunStepItemType } from './RunContextV3';

export const nodeId = (step: Step) => `${step?.contextId}:${step?.id}`;

export const calcEndPosition = (steps: Step[]) => {
  const positionList = steps
    .filter((s) => !s.scratchedAt)
    .map((item) => item?.positionInContext);
  return (max(positionList) ?? -1) + 1;
};

export const realPosition = (position: number) => (endPosition: number) =>
  position < 0 ? endPosition + (position + 1) : position;

const compare = (endPosition: number) => (a: any, b: any) => {
  // smallest position first
  const realPositionA = realPosition(a.positionInContext)(endPosition);
  const realPositionB = realPosition(b.positionInContext)(endPosition);

  if (realPositionA !== realPositionB) {
    return realPositionA - realPositionB;
  }

  if (a.__typename === 'EditCommand') {
    return -1;
  }

  if (b.__typename === 'EditCommand') {
    return 1;
  }

  // New command placeholder should appear before other kinds of items
  if (a.__typename === 'NewCommand') {
    return -1;
  }

  if (b.__typename === 'NewCommand') {
    return 1;
  }
  // oldest first
  if (a.createdAt < b.createdAt) {
    return -1;
  }
  if (a.createdAt > b.createdAt) {
    return 1;
  }
  return 0;
};

export function computeItems({
  steps,
  commands,
  newItems,
  debugMode
}: {
  steps: any[];
  commands: any[];
  newItems: any[];
  debugMode: boolean;
}): IRunStepItem[] {
  const stepsAndCommands = [...steps, ...commands];
  const endPosition = calcEndPosition(stepsAndCommands);

  const commandsById = Object.fromEntries(
    commands.map((c: any) => [c.id, c]) ?? []
  );

  const editSteps = newItems.reduce((acc, item) => {
    if (item.__typename === 'EditCommand') {
      acc[item.positionInContext] = item;
    }
    return acc;
  }, {});

  const mergedSteps =
    steps.map((step: any) => {
      const command = commandsById[step.commandId];
      if (!debugMode) {
        delete commandsById[step.commandId];
      }
      return { ...step, command };
    }) ?? [];

  const items = [...mergedSteps, ...Object.values(commandsById), ...newItems]
    .filter((item) => item.__typename !== 'EditCommand')
    .map((step) => editSteps[step.positionInContext] || step) // replace step with edit commands
    .sort(compare(endPosition));

  return items;
}

export function computeItemsV2({
  steps,
  newItems
}: {
  steps: Step[] | null;
  newItems: IRunStepItem[];
}): IRunStepItem[] {
  if (steps?.length) {
    const items = steps.map((step) => {
      return {
        type: RunStepItemType.READ,
        step
      };
    });
    const endPosition = calcEndPosition(steps);
    return [...items, ...newItems].sort(compare(endPosition));
  }

  return newItems;
}

export function getTerminalValue(answer: any): null | number | string {
  switch (typeof answer) {
    case 'number':
    case 'string': {
      return answer;
    }
    case 'object': {
      if (answer != null && answer.concept && answer.concept.value) {
        const decoded = FormattingUtil.decodeBrainValue(answer.concept.value);
        return getTerminalValue(decoded);
      }
      break;
    }
    default:
      AppUtil.logError('Unsupported value', answer);
  }
  return null;
}

export function removeTextExtraIndentation(text: string) {
  const textList: string[] = AppUtil.safeParseJSON(text);
  let stepsToEdit = textList;

  if (textList.length > 0) {
    const firstLine = textList[0];
    const diff = firstLine.length - firstLine.trimStart().length;
    if (diff > 0) {
      stepsToEdit = textList.map((text) => text.slice(diff));
    }
  }

  return stepsToEdit.join('\n');
}

export const getExpandedContexts = (expandedNodes: string[]) =>
  uniq(
    expandedNodes.map((node) => {
      const [context] = node.split(':');
      return context;
    })
  );
