import React, { useRef } from 'react';
import { CellType } from '@/components/example/types';
import { Collapse, Spin } from 'antd';
import {
  isPython,
  isMarkdown,
  isCode,
  isLoading
} from '@/components/example/cellHelper';
import classNames from 'classnames';
import Editor, { EditorRef } from '@/components/editor';
import MarkdownRenderer from '../MarkdownRenderer';

const { Panel } = Collapse;

type Props = {
  cell: CellType;
};

const embedAttachments = (
  source: string,
  attachments: CellType['attachments'] = {}
) => {
  Object.entries(attachments).forEach(([name, mimes]) => {
    const mime = [...Object.keys(mimes)][0];
    if (mime == null) {
      return;
    }
    const data = `data:${mime};base64,${mimes[mime]}`;
    const re = new RegExp(`attachment:${name}`, 'g');
    source = source.replace(re, data);
  });
  return source;
};

export default function Cell({ cell }: Props) {
  const theEditor = useRef<EditorRef | null>(null);

  const title = cell.metadata.procedure;
  const outputEmoji = cell.outputs?.reduce(
    (acc, out) => acc.concat(out?.data?.['text/emoji'] ?? ''),
    ''
  );

  let source = '';
  if (cell.input) {
    source = cell.input.join('');
  } else if (cell.source) {
    source = cell.source.join('');
  }

  return (
    <Spin spinning={isLoading(cell)}>
      <div className="cell rendered">
        <div className="input-bak">
          {/* <div className="prompt input_prompt">
          {cell.cell_type === 'code' ? (
            <>
              In [
              {cell.execution_count || cell.prompt_number || cell.auto_number}
              ]:
            </>
          ) : null}
        </div> */}
          <div
            className="inner_cell"
            style={isPython(cell) ? { padding: '0px' } : {}}
          >
            {(() => {
              if (isMarkdown(cell)) {
                return (
                  <div className="text_cell_render border-box-sizing rendered_html">
                    <MarkdownRenderer>
                      {embedAttachments(source, cell.attachments)}
                    </MarkdownRenderer>
                  </div>
                );
              }

              if (isPython(cell)) {
                // we would like collapse python code by default
                return (
                  <Collapse ghost>
                    <Panel
                      header={`${cell.metadata.procedure} ${outputEmoji}`}
                      key="1"
                    >
                      <Editor
                        ref={theEditor}
                        value={source}
                        language={cell?.metadata?.language}
                        onChange={() => {}}
                      />
                    </Panel>
                  </Collapse>
                );
              }

              if (isCode(cell)) {
                return (
                  <div
                    className={classNames(
                      'text_cell_render',
                      cell.source?.some((s) => s.includes('learn'))
                        ? 'cell_code_knowledge'
                        : 'cell_code_procedure'
                    )}
                  >
                    {title && (
                      <h2 data-cy="code-title">
                        {title} {outputEmoji}
                      </h2>
                    )}
                    <Editor
                      ref={theEditor}
                      value={title ? source : `${source} ${outputEmoji}`}
                      language={cell?.metadata?.language}
                      onChange={() => {}}
                    />
                  </div>
                );
              }

              return null;
            })()}
          </div>
        </div>

        {!cell.outputs?.length ? null : (
          <div className="output_wrapper">
            <div className="output">
              {cell.outputs.map((output, j) => (
                <div className="output_area" key={j}>
                  {/* <div className="prompt output_prompt">
                  {output.execution_count && (
                    <>Out [{output.execution_count}]:</>
                  )}
                </div> */}
                  {(() => {
                    if (output.data == null) {
                      if (output.png) {
                        return (
                          <div className="output_png output_subarea">
                            <img
                              alt="output"
                              src={`data:image/png;base64,${output.png}`}
                            />
                          </div>
                        );
                      }
                      if (output.jpeg) {
                        return (
                          <div className="output_jpeg output_subarea">
                            <img
                              alt="output"
                              src={`data:image/jpeg;base64,${output.jpeg}`}
                            />
                          </div>
                        );
                      }
                      if (output.gif) {
                        return (
                          <div className="output_gif output_subarea">
                            <img
                              alt="output"
                              src={`data:image/gif;base64,${output.gif}`}
                            />
                          </div>
                        );
                      }
                      if (output.text) {
                        return (
                          <div className="output_subarea output_stdout output_text">
                            <pre>{output.text.join('')}</pre>
                          </div>
                        );
                      }
                      return null;
                    }
                    if (output.data['text/latex']) {
                      return (
                        <div className="output_latex output_subarea output_execute_result">
                          <MarkdownRenderer>
                            {output.data['text/latex'].join('')}
                          </MarkdownRenderer>
                        </div>
                      );
                    }
                    if (output.data['text/html']) {
                      return (
                        <div
                          className="output_html rendered_html output_subarea"
                          // eslint-disable-next-line react/no-danger
                          dangerouslySetInnerHTML={{
                            __html: output.data['text/html'].join('')
                          }}
                        />
                      );
                    }
                    if (output.data['image/png']) {
                      return (
                        <div className="output_png output_subarea">
                          <img
                            alt="output"
                            src={`data:image/png;base64,${output.data['image/png']}`}
                          />
                        </div>
                      );
                    }
                    if (output.data['image/jpeg']) {
                      return (
                        <div className="output_jpeg output_subarea">
                          <img
                            alt="output"
                            src={`data:image/jpeg;base64,${output.data['image/jpeg']}`}
                          />
                        </div>
                      );
                    }
                    if (output.data['image/gif']) {
                      return (
                        <div className="output_gif output_subarea">
                          <img
                            alt="output gif"
                            src={`data:image/gif;base64,${output.data['image/gif']}`}
                          />
                        </div>
                      );
                    }
                    if (output.data['text/plain']) {
                      return (
                        <div className="output_text output_subarea output_execute_result">
                          <pre>{output.data['text/plain']}</pre>
                        </div>
                      );
                    }
                    return null;
                  })()}
                </div>
              ))}
            </div>
          </div>
        )}
      </div>
    </Spin>
  );
}
