//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Authentication configuration and init for Amplify
//

import { Auth } from '@aws-amplify/auth';
// import MemoryStorage from 'memorystorage';
// import { v4 as uuidv4 } from 'uuid';
import awsconfig from './generated/aws-exports';

const config = {
  Auth: {
    region: awsconfig.aws_cognito_region,
    userPoolId: awsconfig.aws_user_pools_id,
    userPoolWebClientId: awsconfig.aws_user_pools_web_client_id,
    authenticationFlowType: 'CUSTOM_AUTH'
    // This can be removed once we have cookie-based auth; until then we may enable this again at some point for security reasons.
    // storage: new MemoryStorage(uuidv4()) // used instead of the default (local storage) for improved resistance against XSS vectors
  }
};

Auth.configure(config);
