import { Modal, Button } from 'antd';
import React from 'react';
import DepartmentCollaborators from '../components/department/DepartmentCollaborators';
import { Department } from '../generated/API';

interface IProps {
  department: Department;
  isSupportModeEnabled: boolean;
  onClose: () => void;
}

function DepartmentCollaboratorsPopup(props: IProps) {
  const { department, isSupportModeEnabled, onClose } = props;

  return (
    <Modal
      open
      title={`Share '${department.name}' department`}
      onCancel={onClose}
      width={600}
      keyboard={false}
      maskClosable={false}
      footer={[
        <Button
          key="back"
          onClick={onClose}
          data-cy="department-collaborators-close"
        >
          Close
        </Button>
      ]}
      className="department-collaborators-popup"
    >
      <DepartmentCollaborators
        department={department}
        isSupportModeEnabled={isSupportModeEnabled}
        source="popup"
      />
    </Modal>
  );
}

export default DepartmentCollaboratorsPopup;
