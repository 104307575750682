import React from 'react';
import { RequireAuth } from '@app/provider/auth';
import ErrorBoundary from '@app/components/ErrorBoundary';
import LayoutPage from '@pages/AppPage';

export default function AuthApp() {
  return (
    <ErrorBoundary>
      <RequireAuth>
        <LayoutPage />
      </RequireAuth>
    </ErrorBoundary>
  );
}
