import React from 'react';

import { ApolloError } from '@apollo/client';
import { Button, Result } from 'antd';

import AppUtil from '@/utils/AppUtil';
import { useNavigate } from 'react-router-dom';
import { useRefresh } from '@/provider/refresh';
import BrainIcon from '../components/icons/BrainIcon';
import Container from '../components/Container';

export default function ErrorPage({
  error,
  errorInfo,
  title
}: {
  error: ApolloError | Error | string;
  errorInfo?: any;
  title?: string;
}) {
  AppUtil.logError(error);
  const navigate = useNavigate();
  const { refresh } = useRefresh();

  return (
    <Container>
      <Result
        title={title || <div>Please try refreshing the page</div>}
        icon={
          <div
            style={{
              width: 100,
              margin: '0 auto'
            }}
          >
            <BrainIcon style={{ color: '#1a90ff', fontSize: '60px' }} />
          </div>
        }
        subTitle={error?.toString() || 'Error'}
        extra={
          <>
            <Button
              onClick={() => {
                navigate(-1);
              }}
              type="primary"
            >
              Back
            </Button>
            <Button
              onClick={() => {
                refresh();
              }}
              type="primary"
            >
              Refresh
            </Button>
            <details style={{ whiteSpace: 'pre-wrap', marginTop: 12 }}>
              {error?.toString()}
              <br />
              {errorInfo?.componentStack}
            </details>
          </>
        }
      />
    </Container>
  );
}
