import lazyWithRetry from '@/utils/lazyWithRetry';

export const AuthApp = lazyWithRetry(() => import('@/AuthRoute'));
export const Login = lazyWithRetry(() => import('@pages/Login'));
export const VerifyLogin = lazyWithRetry(() => import('@/pages/VerifyLogin'));
export const ActionBar = lazyWithRetry(() => import('@/pages/ActionBar'));
export const DashboardPage = lazyWithRetry(() => import('@/pages/Dashboard'));
export const ProceduresPage = lazyWithRetry(
  () => import('@/pages/ProceduresPage')
);
export const DepartmentsPage = lazyWithRetry(
  () => import('@pages/DepartmentsPage')
);
export const DepartmentLayout = lazyWithRetry(
  () => import('@/pages/department/DepartmentLayout')
);
export const DepartmentBooks = lazyWithRetry(
  () => import('@/pages/department/DepartmentBooks')
);
export const DepartmentLearnings = lazyWithRetry(
  () => import('@/pages/department/DepartmentLearnings')
);
export const DepartmentHistory = lazyWithRetry(
  () => import('@/pages/department/DepartmentHistory')
);
export const DepartmentCollaborators = lazyWithRetry(
  () => import('@/pages/department/DepartmentCollaborators')
);
export const AllRunsPage = lazyWithRetry(() => import('@/pages/AllRunsPage'));
export const ProcedurePage = lazyWithRetry(
  () => import('@/pages/ProcedurePage')
);
export const FactDebugPage = lazyWithRetry(
  () => import('@pages/FactDebugPage')
);
export const InvalidRoute = lazyWithRetry(() => import('@/pages/InvalidRoute'));
export const PlaygroundV2 = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PlaygroundV2" */ '@/pages/PlaygroundV2/Playgroundv2'
    )
);

export const PlaygroundV3 = lazyWithRetry(
  () =>
    import(
      /* webpackChunkName: "PlaygroundV3" */ '@/pages/PlaygroundV3/PlaygroundV3'
    )
);

export const PlaygroundHistory = lazyWithRetry(
  () => import('@pages/PlaygroundHistory')
);
export const RunViewLayout = lazyWithRetry(
  () => import('@/pages/RunViewLayout')
);
export const AdminPage = lazyWithRetry(() => import('@pages/AdminPage'));
export const QuestionPage = lazyWithRetry(() => import('@pages/QuestionPage'));
export const RequestPage = lazyWithRetry(() => import('@pages/RequestPage'));
export const SchedulesPage = lazyWithRetry(() => import('@/pages/Schedules'));
export const ProcedureLearningsPage = lazyWithRetry(
  () => import('@/pages/ProcedureLearnings')
);
export const RunView = lazyWithRetry(() => import('@/pages/RunView'));
export const ProcedureShare = lazyWithRetry(
  () => import('@/pages/ProcedureShare')
);
export const FactTreeViewer = lazyWithRetry(
  () => import('@/pages/FactTreeViewer')
);
export const NotificationsPage = lazyWithRetry(
  () => import('@/pages/Notifications')
);
