/* eslint-disable react/no-unused-prop-types */
import React from 'react';
import { useLazyQuery } from '@apollo/client';
import { Tooltip } from 'antd';
import { showPopup } from '@/stores/slices/appPopup';
import { GetAnswerQuery, Question, Worker } from '../../../generated/API';
import Queries from '../../../graphql/Queries';
import { useAppDispatch } from '../../../stores/hooks';
import FormattingUtil from '../../../utils/FormattingUtil';
import './RunStepQuestions.less';
import AppConstants from '../../../utils/AppConstants';
import QuestionFilledIcon from '../../../components/icons/QuestionFilledIcon';
import CheckFilledIcon from '../../../components/icons/CheckFilledIcon';
import useQuestionChoices from '../../../hooks/useQuestionChoices';
import InlineFacts from '../../../components/facts/InlineFacts';

interface RunStepQuestionProps {
  question: Question;
  worker: Worker;
}

function RunStepQuestionAnswered(props: RunStepQuestionProps) {
  const { question, worker } = props;

  const [getAnswer, { data, loading }] = useLazyQuery<GetAnswerQuery>(
    Queries.Answer(),
    {
      variables: {
        id: question.answerId
      }
    }
  );

  const answer = data?.getAnswer;

  const processed = FormattingUtil.processQuestion(question);

  const questionChoices = useQuestionChoices({
    question,
    knowledgeId: worker?.knowledgeId!
  });

  const getAnswerText = () => {
    if (question.children?.length > 0) {
      const totalQuestions = question.children.length;
      const answeredQuestions = question.children.filter(
        (child) => child.isAnswered
      ).length;
      return `${answeredQuestions}/${totalQuestions} answered`;
    }
    if (loading) {
      return 'Loading...';
    }

    let answerText = '...';
    if (answer) {
      answerText = FormattingUtil.parseBrainValue(answer.text!);
      if (answer?.type === 'choice' && questionChoices.choices) {
        answerText = questionChoices.choices[Number(answer.text)];
      }
    }

    return answerText;
  };

  const getQuestionText = () => {
    return (
      <InlineFacts
        knowledgeId={worker.knowledgeId!}
        text={processed?.text || ''}
      />
    );
  };

  return (
    <Tooltip
      title={
        <>
          {getQuestionText()}: {getAnswerText()}
        </>
      }
      onVisibleChange={(visible) => {
        if (visible) {
          getAnswer();
        }
      }}
    >
      <span data-cy="question-icon-answered" className="question-icon-answered">
        <CheckFilledIcon />
      </span>
    </Tooltip>
  );
}

function RunStepQuestionPending(props: RunStepQuestionProps) {
  const { question, worker } = props;

  const processed = FormattingUtil.processQuestion(question);

  const getTitle = () => {
    if (question.children?.length > 0) {
      const totalQuestions = question.children.length;
      const answeredQuestions = question.children.filter(
        (child) => child.isAnswered
      ).length;
      return `${answeredQuestions}/${totalQuestions} answered`;
    }

    return (
      <InlineFacts knowledgeId={worker.knowledgeId!} text={processed?.text!} />
    );
  };

  return (
    <Tooltip title={getTitle()}>
      <span data-cy="question-icon-pending" className="question-icon-pending">
        <QuestionFilledIcon />
      </span>
    </Tooltip>
  );
}

interface IProps {
  questions: Question[];
  worker: Worker;
}

function RunStepQuestions(props: IProps) {
  const { questions, worker } = props;
  const dispatch = useAppDispatch();

  const renderQuestionIcon = (question: Question) => {
    const { isAnswered } = question;

    if (isAnswered) {
      return <RunStepQuestionAnswered question={question} worker={worker} />;
    }
    if (!isAnswered) {
      return <RunStepQuestionPending question={question} worker={worker} />;
    }
    return null;
  };

  return (
    <div className="run-step-questions">
      {questions.map((question) => (
        <div
          key={question.id}
          className="run-step-question"
          onClick={(e) => {
            e.stopPropagation();
            dispatch(
              showPopup({
                popupId: AppConstants.POPUPS.VIEW_QUESTION,
                popupParams: {
                  question,
                  worker
                }
              })
            );
          }}
        >
          {renderQuestionIcon(question)}
        </div>
      ))}
    </div>
  );
}

export default RunStepQuestions;
