import React from 'react';

export const useDocumentTitle = (title: string) => {
  const prevPageTitleRef = React.useRef<string>(document.title);

  React.useEffect(() => {
    prevPageTitleRef.current = document.title;
  }, []);

  React.useEffect(() => {
    document.title = title;

    return () => {
      document.title = prevPageTitleRef.current;
    };
  }, [title]);
};
