/* eslint-disable no-underscore-dangle */
import {
  IQuestionValues,
  QuestionInputType
} from '../components/request/interface';
import {
  AnswerAction,
  CreateAnswerActionInput,
  CreateRequestAnswerInput,
  LearnedAnswer,
  Request,
  RequestAnswer,
  RequestQuestion,
  UseConceptAction,
  UseLiteralValueAction,
  UseTechniqueAction
} from '../generated/API';
import FormattingUtil from './FormattingUtil';

export function verifyQuestionValues(values: IQuestionValues) {
  let valid = false;

  switch (values.inputType) {
    case QuestionInputType.VALUE:
      valid = !!values.value;
      break;
    case QuestionInputType.VALUE_UPLOAD:
      valid = !!values.upload;
      break;
    case QuestionInputType.VALUE_CHOICE:
      valid = values.choice !== undefined;
      break;
    case QuestionInputType.TECHNIQUE:
      valid = !!values.technique;
      break;
    case QuestionInputType.NO_VALUE:
    case QuestionInputType.SKIP:
    case QuestionInputType.SKIP_RUN_END:
    case QuestionInputType.RETRY:
    case QuestionInputType.APPROVE_REVIEW:
      valid = true;
      break;
    default:
      break;
  }

  return valid;
}

export function prepareAnswerActionInput(
  values: IQuestionValues
): CreateAnswerActionInput {
  const response: CreateAnswerActionInput = {};

  switch (values.inputType) {
    case QuestionInputType.VALUE: {
      response.useLiteralValueAction = {
        literalValue: FormattingUtil.encodeBrainValue(
          values.value || ''
        ) as string
      };
      break;
    }

    case QuestionInputType.VALUE_UPLOAD: {
      const upload = values.upload!;
      let value;

      if (upload.length > 1) {
        value = upload.map(
          (uploadResponse) =>
            FormattingUtil.encodeBrainValue(uploadResponse.s3Url) as string
        );
      } else {
        value = FormattingUtil.encodeBrainValue(upload[0].s3Url) as string;
      }
      response.useLiteralValueAction = {
        literalValue: value as any
      };
      break;
    }

    case QuestionInputType.VALUE_CHOICE: {
      response.useLiteralValueAction = {
        literalValue: JSON.stringify(values.choice)
      };
      break;
    }

    case QuestionInputType.NO_VALUE: {
      response.useNoValueAction = {};
      break;
    }

    case QuestionInputType.TECHNIQUE: {
      response.useTechniqueAction = {
        technique: FormattingUtil.encodeBrainValue(values.technique) as string
      };
      break;
    }

    case QuestionInputType.SKIP: {
      response.skipStepAction = {};
      break;
    }

    case QuestionInputType.SKIP_RUN_END: {
      response.skipToEndOfRunAction = {};
      break;
    }

    case QuestionInputType.RETRY: {
      response.retryStepAction = {
        minimumDelay: 0
      };
      break;
    }

    case QuestionInputType.APPROVE_REVIEW: {
      response.approveReviewAction = {};
      break;
    }

    default: {
      break;
    }
  }

  return response;
}

export function prepareAnswerInput(
  request: Request,
  question: RequestQuestion,
  values: IQuestionValues
): CreateRequestAnswerInput {
  const response: CreateRequestAnswerInput = {
    requestId: request.id,
    questionId: question.id,
    action: prepareAnswerActionInput(values)
  };

  return response;
}

export function getQuestionInputTypeFromAnswerAction(
  action: AnswerAction
): QuestionInputType {
  const ACTION_QUESTION_INPUT_TYPE_MAP: Record<string, QuestionInputType> = {
    UseLiteralValueAction: QuestionInputType.VALUE,
    UseTechniqueAction: QuestionInputType.TECHNIQUE,
    ApproveReviewAction: QuestionInputType.APPROVE_REVIEW,
    RetryStepAction: QuestionInputType.RETRY,
    SkipStepAction: QuestionInputType.SKIP,
    SkipToEndOfRunAction: QuestionInputType.SKIP_RUN_END,
    UseConceptAction: QuestionInputType.CONCEPT,
    UseNoValueAction: QuestionInputType.NO_VALUE
  };

  return (
    ACTION_QUESTION_INPUT_TYPE_MAP[action?.__typename] || QuestionInputType.NONE
  );
}

export function getQuestionInputTypeFromAnswer(
  answer: RequestAnswer
): QuestionInputType {
  let inputType: QuestionInputType = QuestionInputType.NONE;

  if (answer?.action) {
    inputType = getQuestionInputTypeFromAnswerAction(answer.action);
  }

  return inputType;
}

export function getQuestionInputValueFromAnswer(
  answer: RequestAnswer | LearnedAnswer,
  placeholder: boolean = false
) {
  let value = '';

  if (answer) {
    const action = answer.action as any;
    const actionType = action?.__typename;

    switch (actionType) {
      case 'UseLiteralValueAction': {
        const literalValueAction = action as UseLiteralValueAction;

        if (literalValueAction.choices && literalValueAction.literalValue) {
          const { choices } = JSON.parse(literalValueAction.choices[0]) || {};
          return choices[literalValueAction.literalValue];
        }

        value = FormattingUtil.parseBrainValue(
          literalValueAction.literalValue || ''
        );
        break;
      }
      case 'UseTechniqueAction': {
        value = FormattingUtil.parseBrainValue(
          (action as UseTechniqueAction).technique || ''
        );
        break;
      }
      case 'UseConceptAction': {
        value = (action as UseConceptAction).conceptId || '';
        break;
      }
      case 'ApproveReviewAction': {
        if (placeholder) {
          value = '<approve>';
        }
        break;
      }
      case 'RetryStepAction': {
        if (placeholder) {
          value = '<retry>';
        }
        break;
      }
      case 'SkipStepAction': {
        if (placeholder) {
          value = '<skip>';
        }
        break;
      }
      case 'SkipToEndOfRunAction': {
        if (placeholder) {
          value = '<stop>';
        }
        break;
      }
      case 'UseNoValueAction': {
        if (placeholder) {
          value = '<empty>';
        }
        break;
      }
      default: {
        break;
      }
    }
  }

  return value;
}

export function isQuestionAnswered(
  question: RequestQuestion,
  answers: RequestAnswer[]
) {
  return answers.some((answer) => answer.questionId === question.id);
}

export function getQuestionAnswer(
  question: RequestQuestion,
  answers: RequestAnswer[]
) {
  return answers.find((answer) => answer.questionId === question.id);
}

export function getPendingQuestions(requests: Request[]) {
  const questions = requests.reduce<RequestQuestion[]>(
    (acc, cur) => acc.concat(cur.questions!),
    []
  );
  const answers = requests.reduce<RequestAnswer[]>(
    (acc, cur) => acc.concat(cur.answers!),
    []
  );
  return questions.filter((question) => {
    return !isQuestionAnswered(question, answers);
  });
}

export function isRequestHandled(request: Request) {
  return request.state === 'handled';
}

export function areAllRequestsHandled(requests: Request[]) {
  return requests.every(isRequestHandled);
}
