/* eslint-disable prefer-destructuring */
/* eslint-disable no-underscore-dangle */
import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { GetFactsQuery, RequestQuestion } from '../generated/API';
import AppConstants from '../utils/AppConstants';
import AppUtil from '../utils/AppUtil';
import FormattingUtil from '../utils/FormattingUtil';

interface IRequestQuestionChoicesProps {
  question: RequestQuestion;
  knowledgeId: string;
}

interface IRequestQuestionChoicesResponse {
  choices: string[];
  loading: boolean;
  choicesRawValuesMap: Record<number, any>; // Record<index of choice, choice raw value>
}

function useRequestQuestionChoices(
  props: IRequestQuestionChoicesProps
): IRequestQuestionChoicesResponse {
  const { question, knowledgeId } = props;

  const { id } = question;

  const questionChoices = (question as any).choices || [];

  const [choices, setChoices] = useState<string[]>([]);
  const [factIds, setFactIds] = useState<string[]>([]);

  const [choicesRawValuesMap, setChoicesRawValuesMap] = useState({});

  // TODO: Use useFact
  const [getFacts, { loading }] = useLazyQuery<GetFactsQuery>(
    AppConstants.APIS.GET_ENTITY.facts()
  );

  useEffect(() => {
    if (questionChoices?.[0]) {
      const { choices: initialChoices } = JSON.parse(questionChoices[0]) || {};

      if (
        initialChoices.length === 2 &&
        initialChoices.includes(true) &&
        initialChoices.includes(false)
      ) {
        setChoices(['Yes', 'No']);
      } else {
        const factIdsFromChoices = initialChoices.reduce(
          (acc: string[], cur: any) => {
            if (typeof cur === 'object' && '__concept__' in cur) {
              acc.push(cur.__concept__.id);
            }
            return acc;
          },
          []
        );
        setFactIds(factIdsFromChoices);

        const newChoices = initialChoices.map((choice: any) => {
          if (typeof choice !== 'object') {
            return choice;
          }
          if ('__concept__' in choice) {
            return choice.__concept__.id;
          }
          if ('value' in choice) {
            return choice.value;
          }
          return choice;
        });
        setChoices(newChoices);
      }
    }
  }, [id]);

  useEffect(() => {
    if (factIds.length > 0 && knowledgeId) {
      // console.log('getting facts', factIds, knowledgeId);

      getFacts({
        variables: {
          factIds: Array.from(factIds),
          knowledgeId
        }
      }).then((resp) => {
        if (resp.data?.getFacts) {
          const facts = resp.data.getFacts;

          const parsedFacts = facts.map((fact) => {
            const resp = {
              id: fact?.id,
              value: '',
              rawValue: null
            };
            const parsedValue = FormattingUtil.parseBrainValue(
              fact?.value || ''
            );

            if (parsedValue) {
              const type = typeof parsedValue;
              resp.rawValue = parsedValue;

              if (
                ['string', 'number', 'boolean'].includes(type) ||
                !parsedValue
              ) {
                resp.value = parsedValue;
              } else if (Array.isArray(parsedValue)) {
                resp.value = parsedValue[0];
              } else if (type === 'object') {
                const name = AppUtil.getObjectName(parsedValue);
                const id = AppUtil.getObjectName(parsedValue);
                resp.value =
                  name || id || AppUtil.getFirstKeyFromObject(parsedValue);
              }
            } else {
              resp.value = fact?.names?.[0] || '';
            }
            return resp;
          });

          const updatedChoices = choices.map((choice, index) => {
            const resp = {
              value: '',
              index,
              rawValue: null
            };

            const choiceInFacts = parsedFacts.find(
              (fact) => fact.id === choice
            );
            if (choiceInFacts) {
              resp.value = choiceInFacts.value;
              resp.rawValue = choiceInFacts.rawValue;
            }

            return resp;
          });

          const updatedChoicesRawValuesMap = updatedChoices.reduce<any>(
            (acc, cur) => {
              acc[cur.index] = cur.rawValue;
              return acc;
            },
            {}
          );

          setChoicesRawValuesMap(updatedChoicesRawValuesMap);
          setChoices(updatedChoices.map((choice) => choice.value));
        }
      });
    }
  }, [factIds, knowledgeId]);

  return {
    choices,
    loading,
    choicesRawValuesMap
  };
}

export default useRequestQuestionChoices;
