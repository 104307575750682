export const listUserDepartmentsQuery = /* GraphQL */ `
  query ListDepartments($limit: Int = 1000, $nextToken: String) {
    listDepartments(limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        organizationId
        name
        draftKnowledgeId
        publishedKnowledgeId
        workerId
        local
        state
        errors
        createdAt
        isDeleted
        brainVersion
        features
        organization {
          id
          name
        }
        draftKnowledge {
          id
          brainVersion
        }
      }
      nextToken
    }
  }
`;

export const listAllDepartmentsQuery = /* GraphQL */ `
  query ListAllDepartments($limit: Int = 1000, $nextToken: String) {
    listAllDepartments(limit: $limit, nextToken: $nextToken) {
      items {
        id
        owner
        name
        local
        createdAt
        brainVersion
        features
        organization {
          id
          name
        }
      }
      nextToken
    }
  }
`;

export const getDepartmentQuery = /* GraphQL */ `
  query GetDepartment($id: ID!) {
    getDepartment(id: $id) {
      id
      owner
      organizationId
      name
      draftKnowledgeId
      publishedKnowledgeId
      workerId
      local
      state
      errors
      createdAt
      isDeleted
      brainVersion
      features
      organization {
        id
        name
      }
      draftKnowledge {
        id
        brainVersion
      }
    }
  }
`;
