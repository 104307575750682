const awsmobile = {
  aws_appsync_authenticationType: 'AMAZON_COGNITO_USER_POOLS',
  aws_appsync_graphqlEndpoint:
    'https://5yjfgpouonhyhmsaxr2haiakym.appsync-api.us-west-2.amazonaws.com/graphql',
  aws_appsync_region: 'us-west-2',
  aws_cognito_identity_pool_id:
    'us-west-2:be6189d9-40b7-4c84-ad46-4886574d21b4',
  aws_cognito_region: 'us-west-2',
  aws_project_region: 'us-west-2',
  aws_user_pools_id: 'us-west-2_PLtDLja0n',
  aws_user_pools_web_client_id: '206ttjnigfs30uuttdf42qotmf',
  environment: 'main',
  graphql_endpoint: 'https://api.main.kognitos-test.net/graphql',
  graphql_subscription_appsync_endpoint:
    'wss://5yjfgpouonhyhmsaxr2haiakym.appsync-realtime-api.us-west-2.amazonaws.com/graphql',
  kognitos_login_url:
    'https://m4kzsca7xp5n3gdtju4x3ns6ly0wxody.lambda-url.us-west-2.on.aws/login/initiate'
};
export default awsmobile;
