export enum QuestionInputType {
  VALUE = 'value',
  VALUE_UPLOAD = 'value-upload',
  VALUE_CHOICE = 'value-choice',
  CONCEPT = 'concept',
  TECHNIQUE = 'technique',
  NO_VALUE = 'no-value',
  SKIP = 'skip',
  SKIP_RUN_END = 'skip-run-end',
  RETRY = 'retry',
  APPROVE_REVIEW = 'approve-review',
  NONE = ''
}

export interface IQuestionValues {
  inputType: QuestionInputType;
  value?: string;
  upload?: { s3Url: string }[];
  choice?: string;
  technique?: string;
  remember?: boolean;
  secret?: boolean;
}
