import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  Learning,
  ListLearningsByDepartmentQuery,
  ListLearningsByDepartmentQueryVariables
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';

export interface GetDepartmentLearningsParams
  extends ListLearningsByDepartmentQueryVariables {}

export const getDepartmentLearnings = createAsyncThunk<
  FetchResult<ListLearningsByDepartmentQuery>,
  GetDepartmentLearningsParams,
  AsyncThunkConfig
>('departmentLearnings/fetchAll', async ({ departmentId }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.query({
    query: Queries.LearningsByDepartment(),
    variables: {
      departmentId
    }
  })
);

const DepartmentLearningsAdapter = createEntityAdapter<Learning>({
  selectId: (learning) => learning.id!
});

interface IState {
  loading: boolean;
  error: any;
}

export const departmentLearningsSlice = createSlice({
  name: 'departmentLearnings',
  initialState: DepartmentLearningsAdapter.getInitialState<IState>({
    loading: false,
    error: undefined
  }),
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(getDepartmentLearnings.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(getDepartmentLearnings.fulfilled, (state, { payload }) => {
      if (payload?.data?.listLearningsByDepartment) {
        const { items = [] } = payload.data.listLearningsByDepartment;
        const learnings = items as Learning[];
        DepartmentLearningsAdapter.setAll(state, learnings);
      }
      state.loading = false;
    });
    builder.addCase(getDepartmentLearnings.rejected, (state, { payload }) => {
      state.error = payload as any;
      state.loading = false;
    });
  }
});

export const departmentLearningsSelector =
  DepartmentLearningsAdapter.getSelectors<RootState>(
    (state) => state.departmentLearnings
  );

export const departmentLearningsQuerySelector = (state: RootState) => ({
  loading: state.departmentLearnings.loading,
  learnings: departmentLearningsSelector.selectAll(state),
  error: state.departmentLearnings.error
});

export default departmentLearningsSlice.reducer;
