import React, { useEffect, useRef, useState } from 'react';
import { pdfjs } from 'react-pdf';
import { getWindowCache, setWindowCache } from '../utils/windowCache';
import ImageArea from './ImageArea';

pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

interface IPDFAreaProps {
  pdfUrl: string;
  pageNumber: number;
  boundingBox: {
    width: number;
    height: number;
    x: number;
    y: number;
  };
  destination?: {
    width?: number;
    height?: number;
  };
  onClick?: (e: any) => void;
}

function PDFArea(props: IPDFAreaProps) {
  const { pdfUrl, pageNumber, boundingBox, destination, onClick } = props;

  const canvasRef = useRef<HTMLCanvasElement | null>(null);
  const [imageURL, setImageURL] = useState<string>('');

  const [loading, toggleLoading] = useState(false);

  const SCALE = 4;

  const getPDFDocument = async (url: string) => {
    const cacheData = getWindowCache('pdfDocumentCache')(url);
    if (cacheData) {
      return cacheData;
    }
    const document = await pdfjs.getDocument(pdfUrl).promise;
    setWindowCache('pdfDocumentCache')(url, document);
    return document;
  };

  useEffect(() => {
    toggleLoading(true);

    getPDFDocument(pdfUrl).then((doc) => {
      doc.getPage(pageNumber).then((page: any) => {
        const viewport = page.getViewport({
          scale: SCALE
        });

        const canvas = canvasRef.current!;
        const context = canvas.getContext('2d') as CanvasRenderingContext2D;

        canvas.height = viewport.height;
        canvas.width = viewport.width;

        /*
          Rendering a PDF canvas is an expensive task, and we ideally don't want to repeat it for different locations of the same document. This particular task consumes considerable amount of CPU while rendering document locations and we want to avoid it. Here we're caching rendered output by PDF's url and page number.
        */
        const cacheData = getWindowCache('pdfRenderCache')(
          `${pdfUrl}-${pageNumber}`
        );
        if (cacheData) {
          setImageURL(cacheData);
          toggleLoading(false);
        } else {
          page.render({ canvasContext: context, viewport }).promise.then(() => {
            const dataUrl = canvas.toDataURL('image/jpeg');
            setWindowCache('pdfRenderCache')(
              `${pdfUrl}-${pageNumber}`,
              dataUrl
            );
            setImageURL(dataUrl);
            toggleLoading(false);
          });
        }
      });
    });
  }, []);

  return (
    <div>
      <canvas
        ref={canvasRef}
        style={{
          width: 0,
          height: 0,
          display: 'none'
        }}
      />
      <ImageArea
        imgURL={imageURL}
        boundingBox={boundingBox}
        loading={loading}
        onClick={onClick}
        destination={destination}
      />
    </div>
  );
}

export default PDFArea;
