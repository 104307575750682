/* eslint-disable jsx-a11y/anchor-has-content */
import classNames from 'classnames';
import React from 'react';
import ReactMarkdown from 'react-markdown';
import remarkGfm from 'remark-gfm';
import './MarkdownRenderer.less';

interface IMarkdownRendererProps {
  children: any;
  disableLineWhiteSpace?: boolean;
}

function MarkdownRenderer(props: IMarkdownRendererProps) {
  const { children, disableLineWhiteSpace } = props;

  const renderLinkComponent = ({ node: _node, ...props }: any) => {
    // eslint-disable-next-line react/prop-types
    if (props?.href?.startsWith('mailto:')) {
      return <a {...props} />;
    }
    return (
      <a {...props} target="_blank" rel="noreferrer noopener">
        Link
      </a>
    );
  };

  return (
    <ReactMarkdown
      remarkPlugins={[remarkGfm]}
      className={classNames('markdown-renderer', {
        'disable-line-white-space': disableLineWhiteSpace
      })}
      components={{
        a: renderLinkComponent
      }}
    >
      {children}
    </ReactMarkdown>
  );
}

export default MarkdownRenderer;
