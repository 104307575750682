import React from 'react';
import { Drawer } from 'antd';
import { useCustomCompareMemo } from 'use-custom-compare';
import { isEqual } from 'lodash/fp';
import { useSelector } from 'react-redux';
import AutoTable from '../components/AutoTable';
import { runSelector } from '../stores/slices/run';

interface IProps {
  id: string;
  title: string;
  knowledgeId: string;
  data: any[];
  onClose: () => void;
}

export default function AnswerFactsDrawer(props: IProps) {
  const { id, title, knowledgeId, data, onClose } = props;

  const { stepFactsEdit } = useSelector(runSelector);

  const memoTable = useCustomCompareMemo(
    () => (
      <AutoTable
        id={id}
        key="thetable"
        knowledgeId={knowledgeId}
        data={data}
        title={title}
        step={stepFactsEdit?.step}
        worker={stepFactsEdit?.worker}
      />
    ),
    [data],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  return (
    <Drawer title={title} placement="right" onClose={onClose} width="50%" open>
      <div className="concepts-table">{memoTable}</div>
    </Drawer>
  );
}
