import assert from 'assert';

export default function parseInputText(
  text: string
): { text: string; subText: string }[] {
  const commands: { text: string; subText: string }[] = [];
  const lines = text.split('\n');

  let currentText: string = '';
  let currentSubtextBlock: string;
  const SPACE = ' ';
  lines.forEach((line: string) => {
    // skip if empty
    if (line.trim().length === 0) {
      return;
    }
    assert(line.search(/\S/) % 2 === 0);
    const indentCount = line.search(/\S/) / 2;

    // Every indent_cnt=0 line creates a new command where
    // text = indent_cnt=0 line and
    // subtext = text parsed until we get the next indent_cnt=0 line
    if (indentCount === 0) {
      currentText = line.trim();
      currentSubtextBlock = '';

      commands.push({ text: currentText, subText: currentSubtextBlock });
    } else {
      const currentIndentSpaces = SPACE.repeat((indentCount - 1) * 2);
      const currentSubtext = line.trim();

      if (currentSubtextBlock.length === 0) {
        currentSubtextBlock = currentIndentSpaces.concat(currentSubtext);
      } else {
        currentSubtextBlock = currentSubtextBlock.concat(
          '\n',
          currentIndentSpaces.concat(currentSubtext)
        );
      }
      commands[commands.length - 1] = {
        text: currentText,
        subText: currentSubtextBlock
      };
    }
  });

  return commands;
}
