import { useRunCtxV2 } from '@/provider/runv2';
import FormattingUtil, { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import { Step } from '@/generated/API';
import useFactV2 from '@/hooks/useFactV2';
import React from 'react';
import './ShortAnswer.less';
import RunFactRendererV2 from '@/components/playground2/RunFactRendererV2';
import { getFactType } from '@/utils/facts';
import useStepFactEdit from '@/hooks/useStepFactEdit';
import { nodeId } from './RunContextUtil';

interface IProps {
  step: Step;
}

function ShortAnswer(props: IProps) {
  const { step } = props;

  const answer = step.answer;

  const { worker, openLongAnswerForNode, showLongAnswerForNode } =
    useRunCtxV2();
  if (!worker) {
    return null;
  }

  // Answer can be null or an object
  const parsedAnswer = answer ? FormattingUtil.parseBrainValue(answer) : {};
  // const editableValue = getTerminalValue(parsedAnswer);

  if (!parsedAnswer) {
    return null;
  }

  let cptIds: string[] = [];

  if (parsedAnswer.concept) {
    cptIds = [parsedAnswer.concept.id];
  }
  if (parsedAnswer.concepts) {
    parsedAnswer.concepts.items.forEach((cpt: any) => {
      const ans = cpt ? FormattingUtil.decodeBrainValue(cpt) : {};
      cptIds.push(ans?.concept?.id);
    });
  }

  const handleShowLongAnswer: React.MouseEventHandler<HTMLDivElement> = (e) => {
    e.stopPropagation();
    const id = nodeId(step);
    if (showLongAnswerForNode !== id) {
      openLongAnswerForNode(id);
    } else {
      openLongAnswerForNode('');
    }
  };

  const { getFactById, loading, refetchFacts } = useFactV2({
    ids: [cptIds[0]],
    knowledgeId: worker!.knowledgeId!
  });

  const fact = getFactById(cptIds[0]);
  const factType = getFactType(fact);

  const { updateFact } = useStepFactEdit({ step, worker });

  // TODO: Show Table Icon
  if (
    fact &&
    parsedAnswer.concepts &&
    factType.type === FormattedAnswerTypeV2.TABLES_ROW
  ) {
    return (
      <div
        data-cy="answer"
        className="short-answer view-link"
        onClick={handleShowLongAnswer}
      >
        view {cptIds.length} {parsedAnswer.concepts?.name}
      </div>
    );
  }
  // TODO: Show table Icon
  if (parsedAnswer.concept && factType.type === FormattedAnswerTypeV2.TABLE) {
    return fact ? (
      <RunFactRendererV2
        fact={fact}
        factType={factType}
        knowledgeId={worker?.knowledgeId!}
        onEdit={(data, type): Promise<unknown> => {
          if (!data) {
            return new Promise((_, reject) => {
              reject();
            });
          }
          const id = parsedAnswer.concept.id;
          return (
            type ? updateFact(id, data, type) : updateFact(id, data, type)
          ).then(() => refetchFacts([id]));
        }}
      />
    ) : null;
  }

  // TODO: Open sidebar it has multiple results
  if (parsedAnswer.concepts) {
    return (
      <div
        data-cy="answer"
        className="short-answer view-link"
        onClick={handleShowLongAnswer}
      >
        view {cptIds.length} {parsedAnswer.concepts?.name}
      </div>
    );
  }

  if (loading) {
    return <div className="short-answer">...</div>;
  }
  // TODO: Need to put image icon along with answer OCR
  if (fact?.locations && factType.type !== FormattedAnswerTypeV2.S3) {
    return (
      <div className="short-answer view-link" onClick={handleShowLongAnswer}>
        view answer
      </div>
    );
  }

  // TODO: Need to open Sidebar
  if (
    factType.type === FormattedAnswerTypeV2.LONG ||
    factType.type === FormattedAnswerTypeV2.MARKDOWN ||
    factType.type === FormattedAnswerTypeV2.SRC_CONCEPT ||
    factType.type === FormattedAnswerTypeV2.SENSITIVE
  ) {
    return (
      <div className="short-answer view-link" onClick={handleShowLongAnswer}>
        view answer
      </div>
    );
  }

  return fact ? (
    <RunFactRendererV2
      fact={fact}
      factType={factType}
      knowledgeId={worker?.knowledgeId!}
    />
  ) : null;
}

export default ShortAnswer;
