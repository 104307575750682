import { useLazyQuery } from '@apollo/client';
import { useEffect, useState } from 'react';
import { Fact, GetFactsQuery } from '../generated/API';
import Queries from '../graphql/Queries';
import AppUtil from '../utils/AppUtil';

interface IPagination {
  pageSize: number;
}

interface IOptions {
  ids: string[];
  knowledgeId: string | null;
  pagination?: IPagination;
  refetchDeps?: number;
}

interface IResponse {
  facts: Record<string, Fact>;
  fetchFactsByIds: (factIds: string[]) => void;
  loading: boolean;
  getPageData: (pageNumber: number) => void;
  getFactById: (factId: string | null) => Fact | undefined;
}

function useFact(option: IOptions): IResponse {
  const { ids, knowledgeId, pagination, refetchDeps } = option;

  const [fetchFacts, fetchFactsProps] = useLazyQuery<GetFactsQuery>(
    Queries.Facts(),
    {
      fetchPolicy: 'network-only',
      nextFetchPolicy: 'network-only'
    }
  );
  const [facts, setFacts] = useState<Record<string, Fact>>({});

  const getPaginatedList = (list: any[], pageNumber: number) =>
    AppUtil.getPaginatedList(list, pageNumber, pagination?.pageSize);

  const getFactReferenceIds = (facts: Fact[]): string[] =>
    facts.map((fact) => fact.referenceTo?.[0]!).filter(Boolean);

  const getFactsByIds = (factIds: string[]) => {
    if (factIds.length > 0) {
      fetchFacts({
        variables: {
          factIds,
          knowledgeId
        }
      }).then((resp) => {
        if (resp.data?.getFacts) {
          const responseFacts = (resp.data.getFacts! as Fact[]) || [];
          const factsMap = responseFacts.reduce<Record<string, Fact>>(
            (acc, cur) => {
              if (cur && cur.id) {
                acc[cur.id] = cur;
              }
              return acc;
            },
            {}
          );
          setFacts((prev) => ({
            ...prev,
            ...factsMap
          }));
          const refIds = getFactReferenceIds(responseFacts);
          if (refIds.length > 0) {
            getFactsByIds(refIds);
          }
        }
      });
    }
  };

  const idsToFetch = pagination ? getPaginatedList(ids, 1) : ids;

  useEffect(() => {
    if (idsToFetch.length > 0) {
      getFactsByIds(idsToFetch);
    }
  }, [JSON.stringify(idsToFetch), refetchDeps]);

  const getPageData = (pageNumber: number) => {
    getFactsByIds(getPaginatedList(ids, pageNumber));
  };

  const getFactById = (factId: string | null): Fact | undefined => {
    if (!factId) {
      return undefined;
    }

    let factToShow: Fact | undefined;

    const fact = facts[factId];

    if (fact) {
      if (fact.referenceTo?.[0]) {
        factToShow = getFactById(fact.referenceTo[0]);
      } else {
        factToShow = fact;
      }
    }

    return factToShow;
  };

  return {
    facts,
    fetchFactsByIds: getFactsByIds,
    loading: fetchFactsProps.loading,
    getPageData,
    getFactById
  };
}

export default useFact;
