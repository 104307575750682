/* tslint:disable */
/* eslint-disable */
//  This file was automatically generated and should not be edited.

export type CreateAnswerInput = {
  workerId: string,
  commandId: string,
  questionId: string,
  type: string,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  text?: string | null,
  error?: string | null,
  secret?: boolean | null,
};

export type Answer = {
  __typename: "Answer",
  id: string,
  workerId: string,
  createdAt?: string | null,
  forwardedAt?: Array< string > | null,
  invokedAt?: Array< string > | null,
  receivedAt?: Array< string > | null,
  processingAt?: Array< string > | null,
  processedAt?: Array< string > | null,
  failedAt?: Array< string > | null,
  workStatus?: string | null,
  workErrors?: Array< string > | null,
  commandId?: string | null,
  questionId?: string | null,
  type?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  text?: string | null,
  error?: string | null,
  remember?: boolean | null,
  secret?: boolean | null,
};

export type WorkItem = {
  __typename: "WorkItem",
  id: string,
  workerId: string,
  createdAt?: string | null,
  forwardedAt?: Array< string > | null,
  invokedAt?: Array< string > | null,
  receivedAt?: Array< string > | null,
  processingAt?: Array< string > | null,
  processedAt?: Array< string > | null,
  failedAt?: Array< string > | null,
  workStatus?: string | null,
  workErrors?: Array< string > | null,
};

export type Command = {
  __typename: "Command",
  id: string,
  workerId: string,
  createdAt?: string | null,
  forwardedAt?: Array< string > | null,
  invokedAt?: Array< string > | null,
  receivedAt?: Array< string > | null,
  processingAt?: Array< string > | null,
  processedAt?: Array< string > | null,
  failedAt?: Array< string > | null,
  workStatus?: string | null,
  workErrors?: Array< string > | null,
  text?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  subText?: string | null,
  subTextProperties?: Array< string > | null,
  subTextLanguage?: string | null,
  procedureId?: string | null,
  contextId?: number | null,
  sentenceId?: number | null,
  debug?: boolean | null,
  ast?: string | null,
  state?: string | null,
  output?: string | null,
  source?: string | null,
  sourceMetadata?: string | null,
  positionInContext?: number | null,
  isMultilineText?: boolean | null,
  questions?: QuestionConnection | null,
  answers?: AnswerConnection | null,
  notifications?: NotificationConnection | null,
};

export type QuestionConnection = {
  __typename: "QuestionConnection",
  items:  Array<Question >,
  nextToken?: string | null,
};

export type Question = {
  __typename: "Question",
  id: string,
  commandId: string,
  workerId: string,
  contextId: number,
  sentenceId?: number | null,
  contextPath?:  Array<InstructionLocationById > | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  text?: string | null,
  path?: string | null,
  procedureId?: string | null,
  validationList?: Array< string | null > | null,
  answerId?: string | null,
  isAnswered: boolean,
  delegationChain?:  Array<CommunicationItem > | null,
  createdAt?: string | null,
  rawException?: string | null,
  parentId?: string | null,
  secret?: boolean | null,
  stepPath?: Array< string > | null,
  answerTypeHint?: string | null,
  sourceMetadata?: string | null,
  worker: Worker,
  children:  Array<Question >,
  answer?: Answer | null,
};

export type InstructionLocationById = {
  __typename: "InstructionLocationById",
  ctxId: number,
  sentenceId?: number | null,
};

export type CommunicationItem = {
  __typename: "CommunicationItem",
  method: string,
  recipients: Array< string >,
  parameters: string,
};

export type Worker = {
  __typename: "Worker",
  id: string,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  version?: string | null,
  departmentVersion?: string | null,
  knowledgeId?: string | null,
  departmentId?: string | null,
  procedureId?: string | null,
  entryCommand?: string | null,
  entryCommandId?: string | null,
  originalWorkerId?: string | null,
  createdAt?: string | null,
  state?: string | null,
  stateLastUpdatedAt?: string | null,
  status?: string | null,
  statusLastUpdatedAt?: string | null,
  attachments?: Array< string > | null,
  isArchived?: boolean | null,
  exceptions:  Array<Question >,
  questions:  Array<Question >,
  requests?: RequestConnection | null,
};

export type RequestConnection = {
  __typename: "RequestConnection",
  items:  Array<Request >,
  limit?: number | null,
  nextToken?: string | null,
};

export type Request = {
  __typename: "Request",
  id: string,
  // List of steps that contain this request, starting with the outermost step. Each step is identified by its english text. Similar to breadcrumbs.
  stepPath?: Array< string > | null,
  // The id of the (innermost) context that generated this request.
  contextId?: number | null,
  // The id of the sentence that generated this request.
  sentenceId?: number | null,
  // The time at which this request was created. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
  createdAt?: string | null,
  // The time at which this request was handled. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
  handledAt?: string | null,
  // The state of this request. One of: 'pending', 'handled'.
  state?: string | null,
  // The worker that made the request.
  worker?: Worker | null,
  // List of questions in this request.
  questions?:  Array<RequestQuestion > | null,
  // List of suggested answers for this request.
  suggestedAnswers?:  Array<RequestAnswerSuggestion > | null,
  // List of answers created in response to this request.
  answers?:  Array<RequestAnswer > | null,
  // List of instruction location were the request was created.
  contextPath?:  Array<InstructionLocationById > | null,
};

export type RequestQuestion = {
  __typename: "RequestQuestion",
  id: string,
  text?: string | null,
  // The answers that have been applied to and failed to resolve previous identical questions.
  failedAnswers?:  Array<FailedAnswer > | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
};

export type EnsureFailedQuestion = {
  __typename: "EnsureFailedQuestion",
  id: string,
  text?: string | null,
  // The answers that have been applied to and failed to resolve previous identical questions.
  failedAnswers?:  Array<FailedAnswer > | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
};

export type FailedAnswer = {
  __typename: "FailedAnswer",
  // Answer that was already applied and failed to resolve an earlier question.
  answer: RequestAnswer,
  // Reason that the earlier question failed to resolve.
  message?: string | null,
};

export type RequestAnswer = {
  __typename: "RequestAnswer",
  id: string,
  // The id of the request that this answer belongs to
  requestId: string,
  // The id of the question that this answer is in response to, if any.
  questionId?: string | null,
  // The action to take to resolve the question.
  action?: AnswerAction | null,
  // The learning that was used to generate this answer, if any.
  sourceLearning?: LearnedAnswer | null,
  // The time that this answer was created.
  createdAt?: string | null,
  // The id of the user that created this answer.
  createdBy?: string | null,
};

export type AnswerAction = {
  __typename: "AnswerAction",
  _?: string | null,
};

export type ApproveReviewAction = {
  __typename: "ApproveReviewAction",
  _?: string | null,
};

export type ReplaceStepAction = {
  __typename: "ReplaceStepAction",
  _?: string | null,
  newSteps?: Array< string > | null,
};

export type RetryStepAction = {
  __typename: "RetryStepAction",
  _?: string | null,
  minimumDelay: number,
  timeout?: number | null,
};

export type SkipStepAction = {
  __typename: "SkipStepAction",
  _?: string | null,
};

export type SkipToEndOfRunAction = {
  __typename: "SkipToEndOfRunAction",
  _?: string | null,
};

export type UseConceptAction = {
  __typename: "UseConceptAction",
  _?: string | null,
  conceptId?: string | null,
};

export type UseLiteralValueAction = {
  __typename: "UseLiteralValueAction",
  _?: string | null,
  literalValue?: string | null,
  choices?: Array< string > | null,
};

export type UseNoValueAction = {
  __typename: "UseNoValueAction",
  _?: string | null,
};

export type UseTechniqueAction = {
  __typename: "UseTechniqueAction",
  _?: string | null,
  technique?: string | null,
};

export type LearnedAnswer = {
  __typename: "LearnedAnswer",
  id: string,
  // The filter that determines which questions this learning applies to.
  questionFilter?: RequestQuestionFilter | null,
  // The action to take to resolve the question.
  action?: AnswerAction | null,
  // The time that this answer was created.
  createdAt?: string | null,
  // The id of the user that created this answer.
  createdBy?: string | null,
  // The time that this answer was deleted.
  deletedAt?: string | null,
  // The id of the user that deleted this answer.
  deletedBy?: string | null,
  // The id of the procedure that this learning belongs to.
  procedureId?: string | null,
  // The name of the procedure that this learning belongs to.
  procedureName?: string | null,
  // The procedure that this learning belongs to.
  procedure?: Procedure | null,
};

export type RequestQuestionFilter = {
  __typename: "RequestQuestionFilter",
  stepPath?: Array< string | null > | null,
  // The type of question that this learning applies to. Example 'ValueNotKnownQuestion'.
  questionType?: string | null,
  // The lexical path that this learning applies to.
  lexicalPath?: Array< string | null > | null,
};

export type Procedure = {
  __typename: "Procedure",
  id: string,
  knowledgeId?: string | null,
  departmentId?: string | null,
  text?: string | null,
  name?: string | null,
  owner?: string | null,
  language?: string | null,
  public: boolean,
  bookReference?: string | null,
  schedules?:  Array<Schedule | null > | null,
  email?:  Array<StageEmail > | null,
  title?: string | null,
  version?: string | null,
  requests?: RequestConnection | null,
};

export type Schedule = {
  __typename: "Schedule",
  name?: string | null,
  expression?: string | null,
  enabled?: boolean | null,
};

export type StageEmail = {
  __typename: "StageEmail",
  stage: string,
  email: string,
};

export type NativeCodeErrorQuestion = {
  __typename: "NativeCodeErrorQuestion",
  id: string,
  text?: string | null,
  // The answers that have been applied to and failed to resolve previous identical questions.
  failedAnswers?:  Array<FailedAnswer > | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  errorType?: string | null,
  errorMessage?: string | null,
  errorTraceback?: string | null,
};

export type ParseErrorQuestion = {
  __typename: "ParseErrorQuestion",
  id: string,
  text?: string | null,
  // The answers that have been applied to and failed to resolve previous identical questions.
  failedAnswers?:  Array<FailedAnswer > | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  errorTraceback?: string | null,
};

export type ProcedureNotKnownQuestion = {
  __typename: "ProcedureNotKnownQuestion",
  id: string,
  text?: string | null,
  // The answers that have been applied to and failed to resolve previous identical questions.
  failedAnswers?:  Array<FailedAnswer > | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
};

export type ReviewConceptQuestion = {
  __typename: "ReviewConceptQuestion",
  id: string,
  text?: string | null,
  // The answers that have been applied to and failed to resolve previous identical questions.
  failedAnswers?:  Array<FailedAnswer > | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  // English lexical path to the concept.
  lexicalPath?: Array< string > | null,
  conceptId?: string | null,
};

export type ValueNotKnownQuestion = {
  __typename: "ValueNotKnownQuestion",
  id: string,
  text?: string | null,
  // The answers that have been applied to and failed to resolve previous identical questions.
  failedAnswers?:  Array<FailedAnswer > | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  // English lexical path to the concept.
  lexicalPath?: Array< string > | null,
  choices?: Array< string > | null,
  helpText?: string | null,
};

export type RequestAnswerSuggestion = {
  __typename: "RequestAnswerSuggestion",
  id: string,
  // The id of the request that this answer belongs to
  requestId: string,
  // The id of the question that this answer is in response to, if any.
  questionId?: string | null,
  // The action to take to resolve the question.
  action?: AnswerAction | null,
};

export type AnswerConnection = {
  __typename: "AnswerConnection",
  items?:  Array<Answer | null > | null,
  nextToken?: string | null,
};

export type NotificationConnection = {
  __typename: "NotificationConnection",
  items?:  Array<Notification > | null,
  nextToken?: string | null,
};

export type Notification = {
  __typename: "Notification",
  id: string,
  contextId: number,
  commandId: string,
  workerId: string,
  sentenceId?: number | null,
  info?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  createdAt?: string | null,
};

export type ServiceCommand = {
  __typename: "ServiceCommand",
  id: string,
  workerId: string,
  createdAt?: string | null,
  forwardedAt?: Array< string > | null,
  invokedAt?: Array< string > | null,
  receivedAt?: Array< string > | null,
  processingAt?: Array< string > | null,
  processedAt?: Array< string > | null,
  failedAt?: Array< string > | null,
  workStatus?: string | null,
  workErrors?: Array< string > | null,
  method?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  params?: string | null,
  result?: string | null,
  error?: string | null,
};

export type WakeupCall = {
  __typename: "WakeupCall",
  id: string,
  workerId: string,
  createdAt?: string | null,
  forwardedAt?: Array< string > | null,
  invokedAt?: Array< string > | null,
  receivedAt?: Array< string > | null,
  processingAt?: Array< string > | null,
  processedAt?: Array< string > | null,
  failedAt?: Array< string > | null,
  workStatus?: string | null,
  workErrors?: Array< string > | null,
  scheduledTime?: string | null,
};

export type HandledRequest = {
  __typename: "HandledRequest",
  id: string,
  workerId: string,
  createdAt?: string | null,
  forwardedAt?: Array< string > | null,
  invokedAt?: Array< string > | null,
  receivedAt?: Array< string > | null,
  processingAt?: Array< string > | null,
  processedAt?: Array< string > | null,
  failedAt?: Array< string > | null,
  workStatus?: string | null,
  workErrors?: Array< string > | null,
  requestId?: string | null,
};

export type UpdateAnswerInput = {
  id: string,
  workerId?: string | null,
  commandId?: string | null,
  questionId?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  text?: string | null,
  error?: string | null,
  secret?: boolean | null,
};

export type CreateCommandInput = {
  workerId: string,
  text: string,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  subText?: string | null,
  subTextProperties?: Array< string > | null,
  subTextLanguage?: string | null,
  procedureId?: string | null,
  contextId?: number | null,
  sentenceId?: number | null,
  debug?: boolean | null,
  ast?: string | null,
  output?: string | null,
  source?: string | null,
  sourceMetadata?: string | null,
  isMultilineText?: boolean | null,
};

export type UpdateCommandInput = {
  id: string,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  workerId?: string | null,
  text?: string | null,
  subText?: string | null,
  subTextProperties?: Array< string > | null,
  subTextLanguage?: string | null,
  procedureId?: string | null,
  contextId?: number | null,
  sentenceId?: number | null,
  debug?: boolean | null,
  ast?: string | null,
  state?: string | null,
  output?: string | null,
  source?: string | null,
  sourceMetadata?: string | null,
  receivedAt?: string | null,
  processingAt?: string | null,
};

export type CreateOrganizationInput = {
  name: string,
  owner?: string | null,
};

export type Organization = {
  __typename: "Organization",
  id: string,
  name: string,
  owner: string,
  createdAt: string,
  updatedAt: string,
};

export type DeleteOrganizationInput = {
  id: string,
};

export type CreateDepartmentInput = {
  owner?: string | null,
  readers?: Array< string > | null,
  editors?: Array< string > | null,
  name?: string | null,
  local?: boolean | null,
  brainVersion?: string | null,
  notifyProcedureInvocation?: boolean | null,
  features?: Array< DepartmentFeature > | null,
  featureFlags?: Array< DepartmentFeatureFlag > | null,
};

export enum DepartmentFeature {
  DEPARTMENT_BOOK = "DEPARTMENT_BOOK",
  EXCEPTION_REQUEST = "EXCEPTION_REQUEST",
  KNOWLEDGE_EPOCHS = "KNOWLEDGE_EPOCHS",
  SUGGEST_ANSWERS = "SUGGEST_ANSWERS",
  MOCK_SUGGESTED_ANSWERS = "MOCK_SUGGESTED_ANSWERS",
  EXCEPTION_VECTOR_DB = "EXCEPTION_VECTOR_DB",
}


export type DepartmentFeatureFlag = {
  feature?: DepartmentFeature | null,
  enabled?: boolean | null,
};

export type Department = {
  __typename: "Department",
  id: string,
  owner?: string | null,
  organizationId?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  draftKnowledgeId?: string | null,
  publishedKnowledgeId?: string | null,
  workerId?: string | null,
  local?: boolean | null,
  state?: string | null,
  errors?: Array< string > | null,
  createdAt?: string | null,
  isDeleted: boolean,
  upgradeCommandId?: string | null,
  notifyProcedureInvocation?: boolean | null,
  features?: Array< DepartmentFeature > | null,
  brainVersion?: string | null,
  worker?: Worker | null,
  organization?: Organization | null,
  draftKnowledge?: Knowledge | null,
  publishedKnowledge?: Knowledge | null,
  requests?: RequestConnection | null,
  learnedAnswers?: LearnedAnswerConnection | null,
};

export type Knowledge = {
  __typename: "Knowledge",
  id: string,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  departmentId: string,
  workerId?: string | null,
  parentKnowledgeId?: string | null,
  state?: string | null,
  brainVersion?: string | null,
};

export type LearnedAnswerConnection = {
  __typename: "LearnedAnswerConnection",
  items:  Array<LearnedAnswer >,
  nextToken?: string | null,
};

export type UpdateDepartmentInput = {
  id: string,
  owner?: string | null,
  readers?: Array< string > | null,
  editors?: Array< string > | null,
  name?: string | null,
  local?: boolean | null,
  workerId?: string | null,
  notifyProcedureInvocation?: boolean | null,
  features?: Array< DepartmentFeature > | null,
  featureFlags?: Array< DepartmentFeatureFlag > | null,
};

export type DeleteDepartmentInput = {
  id: string,
};

export type DepartmentRollbackInput = {
  departmentId: string,
  version: string,
};

export type DepartmentRollback = {
  __typename: "DepartmentRollback",
  id: string,
  departmentId: string,
  previousVersion: string,
  currentVersion: string,
  rollbackVersion: string,
};

export type RestoreDepartmentInput = {
  id: string,
};

export enum AccessControlRole {
  member = "member",
  admin = "admin",
  owner = "owner",
  editor = "editor",
  invoker = "invoker",
  viewer = "viewer",
}


export type AccessControlAck = {
  __typename: "AccessControlAck",
  status: string,
};

export type CreateServiceCommandInput = {
  workerId: string,
  method: string,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  params?: string | null,
  result?: string | null,
  error?: string | null,
};

export type UpdateServiceCommandInput = {
  id: string,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  workerId?: string | null,
  method?: string | null,
  params?: string | null,
  result?: string | null,
  error?: string | null,
};

export type CreateWakeupCallInput = {
  workerId: string,
  scheduledTime?: string | null,
};

export type CreateScheduledWakeupInput = {
  workerId: string,
  scheduledTime: string,
};

export type ScheduledWakeup = {
  __typename: "ScheduledWakeup",
  workerId: string,
  scheduledTime: string,
  executionDate: string,
  itemId: string,
};

export type CreateWorkerInput = {
  id?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  state?: string | null,
  version?: string | null,
  knowledgeId?: string | null,
  departmentId?: string | null,
  procedureId?: string | null,
  entryCommand?: string | null,
  attachments?: Array< string > | null,
  departmentVersion?: string | null,
};

export type UpdateWorkerInput = {
  id: string,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  state?: string | null,
  version?: string | null,
  knowledgeId?: string | null,
  departmentId?: string | null,
  procedureId?: string | null,
  entryCommand?: string | null,
  entryCommandId?: string | null,
  attachments?: Array< string > | null,
};

export type DeleteWorkerInput = {
  id: string,
};

export type ArchiveWorkerInput = {
  id: string,
  isArchived: boolean,
};

export type CreateQuestionInput = {
  commandId: string,
  workerId: string,
  contextId: number,
  sentenceId?: number | null,
  contextPath?: Array< InstructionLocationByIdInput > | null,
  id?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  text?: string | null,
  path?: string | null,
  procedureId?: string | null,
  validationList?: Array< string | null > | null,
  answerId?: string | null,
  isAnswered?: boolean | null,
  delegationChain?: Array< CommunicationItemInput > | null,
  rawException?: string | null,
  children?: Array< CreateQuestionInput > | null,
  secret?: boolean | null,
  stepPath?: Array< string > | null,
  answerTypeHint?: string | null,
  sourceMetadata?: string | null,
};

export type InstructionLocationByIdInput = {
  ctxId: number,
  sentenceId?: number | null,
};

export type CommunicationItemInput = {
  method: string,
  recipients: Array< string >,
  parameters: string,
};

export type UpdateQuestionInput = {
  id: string,
  contextId: number,
  sentenceId?: number | null,
  contextPath?: Array< InstructionLocationByIdInput > | null,
  commandId?: string | null,
  workerId?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  text?: string | null,
  path?: string | null,
  procedureId?: string | null,
  validationList?: Array< string | null > | null,
  answerId?: string | null,
  isAnswered?: boolean | null,
  delegationChain?: Array< CommunicationItemInput > | null,
  rawException?: string | null,
  secret?: boolean | null,
  stepPath?: Array< string > | null,
  answerTypeHint?: string | null,
};

export type DeleteQuestionInput = {
  id: string,
  contextId?: number | null,
  sentenceId?: number | null,
  commandId?: string | null,
  workerId?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  type?: string | null,
  text?: string | null,
  path?: string | null,
  validationList?: Array< string | null > | null,
  answerId?: string | null,
  delegationChain?: Array< CommunicationItemInput > | null,
  rawException?: string | null,
  answerTypeHint?: string | null,
};

export type CreateNotificationInput = {
  contextId: number,
  commandId: string,
  workerId: string,
  sentenceId?: number | null,
  id?: string | null,
  info?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
};

export type UpdateNotificationInput = {
  id: string,
  contextId?: number | null,
  sentenceId?: number | null,
  commandId?: string | null,
  workerId?: string | null,
  info?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
};

export type DeleteNotificationInput = {
  id: string,
  contextId?: number | null,
  sentenceId?: number | null,
  commandId?: string | null,
  workerId?: string | null,
  info?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
};

export type CreateLearningInput = {
  procedureId: string,
  departmentId: string,
  id?: string | null,
  type?: string | null,
  inputParams?: string | null,
  questionType?: string | null,
  questionPath?: string | null,
  answerType?: string | null,
  answerText?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  secret?: boolean | null,
};

export type Learning = {
  __typename: "Learning",
  id: string,
  procedureId: string,
  departmentId: string,
  type?: string | null,
  inputParams?: string | null,
  questionType?: string | null,
  questionPath?: string | null,
  answerType?: string | null,
  answerText?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  createdAt?: string | null,
  secret?: boolean | null,
};

export type UpdateLearningInput = {
  id: string,
  procedureId?: string | null,
  departmentId?: string | null,
  type?: string | null,
  inputParams?: string | null,
  questionType?: string | null,
  questionPath?: string | null,
  answerType?: string | null,
  answerText?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  secret?: boolean | null,
};

export type DeleteLearningInput = {
  id: string,
  procedureId?: string | null,
  departmentId?: string | null,
  type?: string | null,
  inputParams?: string | null,
  questionType?: string | null,
  questionPath?: string | null,
  answerType?: string | null,
  answerText?: string | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
};

export type CreateProcedureInput = {
  departmentId: string,
  knowledgeId?: string | null,
  name?: string | null,
  text?: string | null,
  language?: string | null,
  owner?: string | null,
  title?: string | null,
};

export type UpdateProcedureInput = {
  departmentId: string,
  id: string,
  knowledgeId?: string | null,
  name?: string | null,
  text?: string | null,
  language?: string | null,
  owner?: string | null,
  public?: boolean | null,
  scheduleExpression?: string | null,
  scheduleEnabled?: boolean | null,
  title?: string | null,
};

export type UpdateProcedureMetadataInput = {
  id: string,
  departmentId: string,
  knowledgeId?: string | null,
  owner?: string | null,
  public?: boolean | null,
  scheduleExpression?: string | null,
  scheduleEnabled?: boolean | null,
};

export type UpdateProcedureScheduleInput = {
  id: string,
  departmentId: string,
  knowledgeId?: string | null,
  owner?: string | null,
  public?: boolean | null,
  scheduleExpression?: string | null,
  scheduleEnabled?: boolean | null,
};

export type ShareProcedureInput = {
  id: string,
  departmentId: string,
  procedureType: string,
  userId: string,
  role: AccessControlRole,
  message?: string | null,
};

export type DeleteProcedureInput = {
  departmentId: string,
  id: string,
  knowledgeId?: string | null,
  owner?: string | null,
};

export type CommandParamsInput = {
  subTextParams: Array< Subtext >,
  attachments?: Array< string > | null,
  source?: string | null,
  sourceMetadata?: string | null,
};

export type Subtext = {
  name: string,
  value: string,
  helpingVerb: string,
};

export type WorkerStatusInput = {
  id: string,
  mood: string,
  operation: string,
  icons: Array< string >,
  what: string,
};

export type WorkerStatus = {
  __typename: "WorkerStatus",
  id: string,
  mood: string,
  operation: string,
  icons: Array< string >,
  what: string,
};

export type UpdateKnowledgeInput = {
  id: string,
  name?: string | null,
  description?: string | null,
  workerId?: string | null,
};

export type Sandbox = {
  __typename: "Sandbox",
  language: string,
  arn: string,
};

export type WorkerInvocationInput = {
  departmentId: string,
  event: string,
};

export type WorkerInvocation = {
  __typename: "WorkerInvocation",
  departmentId: string,
  event: string,
};

export type NotifyWorkerUpdateInput = {
  id: string,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  name?: string | null,
  description?: string | null,
  state?: string | null,
  version?: string | null,
  knowledgeId?: string | null,
  departmentId?: string | null,
  procedureId?: string | null,
  entryCommand?: string | null,
  attachments?: Array< string > | null,
  departmentVersion?: string | null,
  entryCommandId?: string | null,
  originalWorkerId?: string | null,
  createdAt?: string | null,
  stateLastUpdatedAt?: string | null,
  status?: string | null,
  statusLastUpdatedAt?: string | null,
  isArchived?: boolean | null,
};

export type StepsInput = {
  workerId: string,
  stepList?: Array< StepInput | null > | null,
};

export type StepInput = {
  id: string,
  contextId: string,
  workerId: string,
  number?: number | null,
  commandId?: string | null,
  createdAt?: string | null,
  scratchedAt?: string | null,
  text?: string | null,
  answer?: string | null,
  childContextIdList?: Array< string | null > | null,
  concepts?: string | null,
  questionIds?: Array< string > | null,
  requestIds?: Array< string > | null,
  // List of sentences from the root context to the current step. Each sentence is identified by its contextId and sentenceId.
  sentencePath?: Array< SentenceLocationByIdInput > | null,
  epoch?: number | null,
};

export type SentenceLocationByIdInput = {
  ctxId: number,
  sentenceId: number,
};

export type Steps = {
  __typename: "Steps",
  workerId: string,
  stepList?:  Array<Step | null > | null,
};

export type Step = {
  __typename: "Step",
  id: string,
  contextId: string,
  workerId: string,
  number?: number | null,
  commandId?: string | null,
  createdAt?: string | null,
  scratchedAt?: string | null,
  text?: string | null,
  answer?: string | null,
  childContextIdList?: Array< string | null > | null,
  concepts?: string | null,
  questionIds?: Array< string > | null,
  requestIds?: Array< string > | null,
  positionInContext?: number | null,
  sentencePath?:  Array<SentenceLocationById > | null,
  epoch?: number | null,
};

export type SentenceLocationById = {
  __typename: "SentenceLocationById",
  ctxId: number,
  sentenceId: number,
};

export type SandboxProxyMessage = {
  __typename: "SandboxProxyMessage",
  departmentId: string,
  workerId: string,
  proxyId: string,
  requestId: string,
  request?: string | null,
  response?: string | null,
};

export type Book = {
  __typename: "Book",
  id: string,
  url: string,
  name?: string | null,
  author?: string | null,
  description?: string | null,
  learnedAt?: string | null,
  icon?: string | null,
  commandId?: string | null,
  version?: string | null,
};

export type CreateRequestInput = {
  workerId: string,
  departmentId?: string | null,
  procedureId?: string | null,
  procedureName?: string | null,
  // List of steps that contain this request, starting with the outermost step. Each step is identified by its english text. Similar to breadcrumbs.
  stepPath?: Array< string > | null,
  contextId?: number | null,
  sentenceId?: number | null,
  questions?: Array< CreateRequestQuestionInput > | null,
  suggestedAnswers?: Array< CreateRequestAnswerSuggestionInput > | null,
  // List of instruction location were the request was created.
  contextPath?: Array< InstructionLocationByIdInput > | null,
  sourceMetadata?: string | null,
};

export type CreateRequestQuestionInput = {
  valueNotKnown?: ValueNotKnownInput | null,
  procedureNotKnown?: ProcedureNotKnownInput | null,
  nativeCodeError?: NativeCodeErrorInput | null,
  parseError?: ParseErrorInput | null,
  reviewConcept?: ReviewConceptInput | null,
  ensureFailed?: EnsureFailedInput | null,
};

export type ValueNotKnownInput = {
  text?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  // English lexical path to the concept.
  lexicalPath?: Array< string > | null,
  choices?: Array< string > | null,
  helpText?: string | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerNotKnown?: string | null,
  answerTypeHint?: string | null,
};

export type ProcedureNotKnownInput = {
  text?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
};

export type NativeCodeErrorInput = {
  text?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  errorType?: string | null,
  errorMessage?: string | null,
  errorTraceback?: string | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
};

export type ParseErrorInput = {
  text?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  errorTraceback?: string | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
};

export type ReviewConceptInput = {
  text?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  // English lexical path to the concept.
  lexicalPath?: Array< string > | null,
  conceptId?: string | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
};

export type EnsureFailedInput = {
  text?: string | null,
  failedAnswerId?: string | null,
  failedAnswerReason?: string | null,
  contextId?: number | null,
  sentenceId?: number | null,
  answerTypeHint?: string | null,
};

export type CreateRequestAnswerSuggestionInput = {
  action?: CreateAnswerActionInput | null,
};

export type CreateAnswerActionInput = {
  useLiteralValueAction?: CreateUseLiteralValueActionInput | null,
  useConceptAction?: CreateUseConceptActionInput | null,
  useNoValueAction?: CreateUseNoValueActionInput | null,
  useTechniqueAction?: CreateUseTechniqueActionInput | null,
  skipStepAction?: CreateSkipStepActionInput | null,
  skipToEndOfRunAction?: CreateSkipToEndOfRunActionInput | null,
  retryStepAction?: CreateRetryStepActionInput | null,
  approveReviewAction?: CreateApproveReviewActionInput | null,
  replaceStepAction?: CreateReplaceStepActionInput | null,
};

export type CreateUseLiteralValueActionInput = {
  literalValue?: string | null,
  choices?: Array< string > | null,
};

export type CreateUseConceptActionInput = {
  conceptId?: string | null,
};

export type CreateUseNoValueActionInput = {
  _?: string | null,
};

export type CreateUseTechniqueActionInput = {
  technique?: string | null,
};

export type CreateSkipStepActionInput = {
  _?: string | null,
};

export type CreateSkipToEndOfRunActionInput = {
  _?: string | null,
};

export type CreateRetryStepActionInput = {
  minimumDelay: number,
  timeout?: number | null,
};

export type CreateApproveReviewActionInput = {
  _?: string | null,
};

export type CreateReplaceStepActionInput = {
  newSteps?: Array< string > | null,
};

export type UpdateRequestStateInput = {
  id: string,
};

export type CreateRequestAnswerInput = {
  requestId: string,
  // The id of the question that this answer is in response to.
  questionId?: string | null,
  action?: CreateAnswerActionInput | null,
};

export type DeleteRequestAnswerInput = {
  requestId: string,
  answerId: string,
};

export type CreateLearnedAnswerInput = {
  departmentId?: string | null,
  filter?: CreateRequestQuestionFilterInput | null,
  action?: CreateAnswerActionInput | null,
  // The id of the answer that this learning was created from, if any.
  originalAnswerId?: string | null,
  // The id of the procedure that this learning was created from, if any.
  procedureId?: string | null,
};

export type CreateRequestQuestionFilterInput = {
  stepPath?: Array< string | null > | null,
  // The type of question that this learning applies to. Example 'ValueNotKnownQuestion'.
  questionType?: string | null,
  // The lexical path that this learning applies to.
  lexicalPath?: Array< string | null > | null,
};

export type UpdateLearnedAnswerInput = {
  learnedAnswerId: string,
  // The filter that determines which questions this learning applies to.
  filter?: CreateRequestQuestionFilterInput | null,
  // The action to take to resolve the question.
  action?: CreateAnswerActionInput | null,
  // The id of the procedure that this learning was created from, if any.
  procedureId?: string | null,
};

export type DeleteLearnedAnswerInput = {
  learnedAnswerId: string,
};

export type CreateDepartmentFactInput = {
  departmentId: string,
  name: string,
  values: Array< string >,
};

export type DepartmentFact = {
  __typename: "DepartmentFact",
  id: string,
  departmentId: string,
  name: string,
  values: Array< string >,
  version?: string | null,
};

export type UpdateDepartmentFactInput = {
  id: string,
  departmentId: string,
  name: string,
  values: Array< string >,
};

export type DeleteDepartmentFactInput = {
  id: string,
  departmentId: string,
};

export type CreateSecretValueInput = {
  // The value to be stored as a secret.
  content: string,
  // The content type of the value
  type: string,
  // The department to store the secret in
  departmentId: string,
  // The worker to store the secret for
  workerId?: string | null,
  // The filename of the value. Can be null for non-file values.
  filename?: string | null,
  // The confidence of the value. 0.0 is least confident, 1.0 is most confident.
  confidence?: number | null,
  // Whether the value is a secret or not
  secret?: boolean | null,
};

export type UploadValueInput = {
  // The content type of the value
  type: string,
  // The department to store the secret in
  departmentId: string,
  // The worker to store the secret for
  workerId?: string | null,
  // The filename of the value. Can be null for non-file values.
  filename?: string | null,
  // The confidence of the value. 0.0 is least confident, 1.0 is most confident.
  confidence?: number | null,
  // Whether the value is a secret or not
  secret?: boolean | null,
};

export type ValueUpload = {
  __typename: "ValueUpload",
  // The serialized form of the value. Can be provided to the brain after the upload is complete.
  value: string,
  // The URL to upload the value to.
  url: string,
  // Additional fields that must be included in form data of the upload.
  fields:  Array<ValueUploadField >,
};

export type ValueUploadField = {
  __typename: "ValueUploadField",
  name: string,
  value: string,
};

export type CreateSharedEntityInput = {
  departmentId: string,
  entityId: string,
  shareName: string,
  departmentVersion?: string | null,
};

export type SharedEntity = {
  __typename: "SharedEntity",
  id: string,
  entityId: string,
  departmentId: string,
  shareName: string,
  fullShareId?: string | null,
  departmentVersion?: string | null,
};

export type UpdateSharedEntityInput = {
  id: string,
  entityId?: string | null,
  shareName?: string | null,
  departmentVersion?: string | null,
};

export type DepartmentImportInput = {
  organizationId?: string | null,
  owner?: string | null,
  name?: string | null,
  importData?: DepartmentImport | null,
};

export type DepartmentImport = {
  formatVersion: number,
  departmentID: string,
  departmentVersion?: string | null,
  checksum?: string | null,
  features?: Array< DepartmentFeature | null > | null,
  procedures: Array< ProcedureImport | null >,
  facts: Array< FactImport | null >,
  learnedBooks?: LearnedBookImport | null,
};

export type ProcedureImport = {
  name?: string | null,
  text?: string | null,
  language?: string | null,
};

export type FactImport = {
  name: string,
  values: Array< string >,
};

export type LearnedBookImport = {
  ids: Array< string | null >,
};

export type CreateChatMessageInput = {
  departmentId: string,
  workerId: string,
  message: string,
  user?: string | null,
};

export type ChatMessageConnection = {
  __typename: "ChatMessageConnection",
  items:  Array<ChatMessage >,
  nextToken?: string | null,
};

export type ChatMessage = {
  __typename: "ChatMessage",
  id: string,
  departmentId: string,
  workerId: string,
  message: string,
  timestamp: string,
  displayName?: string | null,
  role?: string | null,
};

export type NotifyChatMessageUpdateInput = {
  workerId: string,
  departmentId: string,
  id?: string | null,
  message?: string | null,
  timestamp?: string | null,
  displayName?: string | null,
  role?: string | null,
};

export type BookConnection = {
  __typename: "BookConnection",
  items?:  Array<Book > | null,
  nextToken?: string | null,
};

export type CommandConnection = {
  __typename: "CommandConnection",
  items?:  Array<Command | null > | null,
  nextToken?: string | null,
};

export type ContextConnection = {
  __typename: "ContextConnection",
  items?:  Array<Context | null > | null,
};

export type Context = {
  __typename: "Context",
  id: string,
  workerId: string,
  createdAt?: string | null,
  depth?: number | null,
  stepList?:  Array<Step | null > | null,
  commandId?: string | null,
  concepts?: string | null,
  commands?:  Array<Command | null > | null,
};

export enum DepartmentBookEntity {
  PROCEDURE = "PROCEDURE",
  FACT = "FACT",
  LEARNED_BOOK = "LEARNED_BOOK",
  ROLLBACK = "ROLLBACK",
}


export type DepartmentHistoryConnection = {
  __typename: "DepartmentHistoryConnection",
  items?:  Array<DepartmentHistoryEvent | null > | null,
  nextToken?: string | null,
};

export type DepartmentHistoryEvent = {
  __typename: "DepartmentHistoryEvent",
  entityId: string,
  user: string,
  operation: string,
  entityName: string,
  timestamp: string,
  name?: string | null,
  rollbackVersion?: string | null,
  sharedEntities?:  Array<SharedEntity | null > | null,
};

export type DepartmentConnection = {
  __typename: "DepartmentConnection",
  items?:  Array<Department | null > | null,
  nextToken?: string | null,
};

export type DepartmentUserConnection = {
  __typename: "DepartmentUserConnection",
  items?:  Array<DepartmentUser | null > | null,
  nextToken?: string | null,
};

export type DepartmentUser = {
  __typename: "DepartmentUser",
  id: string,
  userId: string,
  role: AccessControlRole,
};

export type Fact = {
  __typename: "Fact",
  id: string,
  knowledgeId?: string | null,
  knowledge?: Knowledge | null,
  names?: Array< string | null > | null,
  parentFact?: Fact | null,
  parentFactId?: string | null,
  locations?: Array< string > | null,
  relations?:  Array<Relation | null > | null,
  value?: string | null,
  descriptions?:  Array<Description | null > | null,
  owner?: string | null,
  readers?: Array< string | null > | null,
  editors?: Array< string | null > | null,
  referenceTo?: Array< string | null > | null,
  viewUrl?: string | null,
  childProperties?: string | null,
  isDeleted?: boolean | null,
};

export type Relation = {
  __typename: "Relation",
  factId?: string | null,
  fact?: Fact | null,
  name?: string | null,
  direction?: string | null,
};

export type Description = {
  __typename: "Description",
  name?: string | null,
  factId?: string | null,
};

export type HistoricalFactID = {
  id: string,
  epoch: number,
};

export type LearningConnection = {
  __typename: "LearningConnection",
  items?:  Array<Learning | null > | null,
  nextToken?: string | null,
};

export type ModelProcedureConnection = {
  __typename: "ModelProcedureConnection",
  items?:  Array<Procedure | null > | null,
  nextToken?: string | null,
};

export type QuestionsMetrics = {
  __typename: "QuestionsMetrics",
  procedureId: string,
  procedureName: string,
  unhandled: number,
  handled: number,
  total: number,
  totalRuns: number,
  procedureTitle?: string | null,
  unhandledRequests?: number | null,
  handledRequests?: number | null,
  totalRequests?: number | null,
  knowledgeId?: string | null,
  departmentId?: string | null,
};

export enum RequestState {
  PENDING = "PENDING",
  HANDLED = "HANDLED",
}


export type QuestionFilter = {
  id?: string | null,
  ids?: Array< string > | null,
  commandId?: string | null,
  type?: string | null,
  isAnswered?: boolean | null,
  procedureId?: string | null,
  path?: string | null,
  owner?: string | null,
  limit?: number | null,
  createdAt?: string | null,
  nextToken?: string | null,
};

export type ServiceCommandConnection = {
  __typename: "ServiceCommandConnection",
  items?:  Array<ServiceCommand | null > | null,
  nextToken?: string | null,
};

export type WorkerOutput = {
  __typename: "WorkerOutput",
  name: string,
  facts:  Array<Fact | null >,
};

export type DepartmentWorkerFactConnection = {
  __typename: "DepartmentWorkerFactConnection",
  items?:  Array<Fact | null > | null,
  nextToken?: string | null,
};

export type ProcedureOutputTable = {
  __typename: "ProcedureOutputTable",
  columns?: Array< string > | null,
  data?: string | null,
  nextToken?: string | null,
};

export type WorkerConnection = {
  __typename: "WorkerConnection",
  items?:  Array<Worker | null > | null,
  nextToken?: string | null,
};

export type ProcedureFactTable = {
  __typename: "ProcedureFactTable",
  columns?: Array< string > | null,
  data?: string | null,
};

export type StepPathFactTable = {
  __typename: "StepPathFactTable",
  columns?: Array< string > | null,
  data?: string | null,
};

export type AlangParser = {
  __typename: "AlangParser",
  ast?: string | null,
  text?: string | null,
  error?: string | null,
};

export enum S3FileScope {
  worker = "worker",
  department = "department",
}


export type S3PresignedPost = {
  __typename: "S3PresignedPost",
  url: string,
  s3Url: string,
  fields: string,
};

export type S3PresignedUrl = {
  __typename: "S3PresignedUrl",
  url: string,
  s3Url: string,
};

export type DepartmentFactConnection = {
  __typename: "DepartmentFactConnection",
  items?:  Array<DepartmentFact | null > | null,
  nextToken?: string | null,
};

export type SharedEntityConnection = {
  __typename: "SharedEntityConnection",
  items?:  Array<SharedEntity | null > | null,
  nextToken?: string | null,
};

export type DepartmentExport = {
  __typename: "DepartmentExport",
  formatVersion: number,
  departmentID: string,
  departmentVersion?: string | null,
  checksum?: string | null,
  features?: Array< DepartmentFeature | null > | null,
  procedures:  Array<ProcedureExport | null >,
  facts:  Array<FactExport | null >,
  learnedBooks?: LearnedBookExport | null,
};

export type ProcedureExport = {
  __typename: "ProcedureExport",
  name?: string | null,
  text?: string | null,
  language?: string | null,
};

export type FactExport = {
  __typename: "FactExport",
  name: string,
  values: Array< string >,
};

export type LearnedBookExport = {
  __typename: "LearnedBookExport",
  ids: Array< string | null >,
};

export type CreateAnswerMutationVariables = {
  input: CreateAnswerInput,
};

export type CreateAnswerMutation = {
  createAnswer?:  {
    __typename: "Answer",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    commandId?: string | null,
    questionId?: string | null,
    type?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    text?: string | null,
    error?: string | null,
    remember?: boolean | null,
    secret?: boolean | null,
  } | null,
};

export type CreateAnswersMutationVariables = {
  inputs: Array< CreateAnswerInput >,
};

export type CreateAnswersMutation = {
  createAnswers:  Array< {
    __typename: "Answer",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    commandId?: string | null,
    questionId?: string | null,
    type?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    text?: string | null,
    error?: string | null,
    remember?: boolean | null,
    secret?: boolean | null,
  } | null >,
};

export type UpdateAnswerMutationVariables = {
  input: UpdateAnswerInput,
};

export type UpdateAnswerMutation = {
  updateAnswer?:  {
    __typename: "Answer",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    commandId?: string | null,
    questionId?: string | null,
    type?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    text?: string | null,
    error?: string | null,
    remember?: boolean | null,
    secret?: boolean | null,
  } | null,
};

export type CreateCommandMutationVariables = {
  input: CreateCommandInput,
  locked?: boolean | null,
};

export type CreateCommandMutation = {
  createCommand?:  {
    __typename: "Command",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    text?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    subText?: string | null,
    subTextProperties?: Array< string > | null,
    subTextLanguage?: string | null,
    procedureId?: string | null,
    contextId?: number | null,
    sentenceId?: number | null,
    debug?: boolean | null,
    ast?: string | null,
    state?: string | null,
    output?: string | null,
    source?: string | null,
    sourceMetadata?: string | null,
    positionInContext?: number | null,
    isMultilineText?: boolean | null,
    questions?:  {
      __typename: "QuestionConnection",
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "AnswerConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "NotificationConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCommandMutationVariables = {
  input: UpdateCommandInput,
};

export type UpdateCommandMutation = {
  updateCommand?:  {
    __typename: "Command",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    text?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    subText?: string | null,
    subTextProperties?: Array< string > | null,
    subTextLanguage?: string | null,
    procedureId?: string | null,
    contextId?: number | null,
    sentenceId?: number | null,
    debug?: boolean | null,
    ast?: string | null,
    state?: string | null,
    output?: string | null,
    source?: string | null,
    sourceMetadata?: string | null,
    positionInContext?: number | null,
    isMultilineText?: boolean | null,
    questions?:  {
      __typename: "QuestionConnection",
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "AnswerConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "NotificationConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateCommandStateMutationVariables = {
  id: string,
  state: string,
  output?: string | null,
};

export type UpdateCommandStateMutation = {
  updateCommandState?:  {
    __typename: "Command",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    text?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    subText?: string | null,
    subTextProperties?: Array< string > | null,
    subTextLanguage?: string | null,
    procedureId?: string | null,
    contextId?: number | null,
    sentenceId?: number | null,
    debug?: boolean | null,
    ast?: string | null,
    state?: string | null,
    output?: string | null,
    source?: string | null,
    sourceMetadata?: string | null,
    positionInContext?: number | null,
    isMultilineText?: boolean | null,
    questions?:  {
      __typename: "QuestionConnection",
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "AnswerConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "NotificationConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateOrganizationMutationVariables = {
  input: CreateOrganizationInput,
};

export type CreateOrganizationMutation = {
  createOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type DeleteOrganizationMutationVariables = {
  input: DeleteOrganizationInput,
};

export type DeleteOrganizationMutation = {
  deleteOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type CreateDepartmentMutationVariables = {
  input: CreateDepartmentInput,
};

export type CreateDepartmentMutation = {
  createDepartment?:  {
    __typename: "Department",
    id: string,
    owner?: string | null,
    organizationId?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    draftKnowledgeId?: string | null,
    publishedKnowledgeId?: string | null,
    workerId?: string | null,
    local?: boolean | null,
    state?: string | null,
    errors?: Array< string > | null,
    createdAt?: string | null,
    isDeleted: boolean,
    upgradeCommandId?: string | null,
    notifyProcedureInvocation?: boolean | null,
    features?: Array< DepartmentFeature > | null,
    brainVersion?: string | null,
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    draftKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    publishedKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
    learnedAnswers?:  {
      __typename: "LearnedAnswerConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateDepartmentMutationVariables = {
  input: UpdateDepartmentInput,
};

export type UpdateDepartmentMutation = {
  updateDepartment?:  {
    __typename: "Department",
    id: string,
    owner?: string | null,
    organizationId?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    draftKnowledgeId?: string | null,
    publishedKnowledgeId?: string | null,
    workerId?: string | null,
    local?: boolean | null,
    state?: string | null,
    errors?: Array< string > | null,
    createdAt?: string | null,
    isDeleted: boolean,
    upgradeCommandId?: string | null,
    notifyProcedureInvocation?: boolean | null,
    features?: Array< DepartmentFeature > | null,
    brainVersion?: string | null,
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    draftKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    publishedKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
    learnedAnswers?:  {
      __typename: "LearnedAnswerConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteDepartmentMutationVariables = {
  input: DeleteDepartmentInput,
};

export type DeleteDepartmentMutation = {
  deleteDepartment?:  {
    __typename: "Department",
    id: string,
    owner?: string | null,
    organizationId?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    draftKnowledgeId?: string | null,
    publishedKnowledgeId?: string | null,
    workerId?: string | null,
    local?: boolean | null,
    state?: string | null,
    errors?: Array< string > | null,
    createdAt?: string | null,
    isDeleted: boolean,
    upgradeCommandId?: string | null,
    notifyProcedureInvocation?: boolean | null,
    features?: Array< DepartmentFeature > | null,
    brainVersion?: string | null,
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    draftKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    publishedKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
    learnedAnswers?:  {
      __typename: "LearnedAnswerConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type RollbackDepartmentVersionMutationVariables = {
  input: DepartmentRollbackInput,
};

export type RollbackDepartmentVersionMutation = {
  rollbackDepartmentVersion?:  {
    __typename: "DepartmentRollback",
    id: string,
    departmentId: string,
    previousVersion: string,
    currentVersion: string,
    rollbackVersion: string,
  } | null,
};

export type RestoreDepartmentMutationVariables = {
  input: RestoreDepartmentInput,
};

export type RestoreDepartmentMutation = {
  restoreDepartment?:  {
    __typename: "Department",
    id: string,
    owner?: string | null,
    organizationId?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    draftKnowledgeId?: string | null,
    publishedKnowledgeId?: string | null,
    workerId?: string | null,
    local?: boolean | null,
    state?: string | null,
    errors?: Array< string > | null,
    createdAt?: string | null,
    isDeleted: boolean,
    upgradeCommandId?: string | null,
    notifyProcedureInvocation?: boolean | null,
    features?: Array< DepartmentFeature > | null,
    brainVersion?: string | null,
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    draftKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    publishedKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
    learnedAnswers?:  {
      __typename: "LearnedAnswerConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpsertUserToDepartmentMutationVariables = {
  departmentId: string,
  userId: string,
  role: AccessControlRole,
};

export type UpsertUserToDepartmentMutation = {
  upsertUserToDepartment?:  {
    __typename: "AccessControlAck",
    status: string,
  } | null,
};

export type RemoveUserFromDepartmentMutationVariables = {
  departmentId: string,
  userId: string,
};

export type RemoveUserFromDepartmentMutation = {
  removeUserFromDepartment?:  {
    __typename: "AccessControlAck",
    status: string,
  } | null,
};

export type CreateServiceCommandMutationVariables = {
  input: CreateServiceCommandInput,
};

export type CreateServiceCommandMutation = {
  createServiceCommand?:  {
    __typename: "ServiceCommand",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    method?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    params?: string | null,
    result?: string | null,
    error?: string | null,
  } | null,
};

export type UpdateServiceCommandMutationVariables = {
  input: UpdateServiceCommandInput,
};

export type UpdateServiceCommandMutation = {
  updateServiceCommand?:  {
    __typename: "ServiceCommand",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    method?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    params?: string | null,
    result?: string | null,
    error?: string | null,
  } | null,
};

export type CreateWakeupCallMutationVariables = {
  input: CreateWakeupCallInput,
};

export type CreateWakeupCallMutation = {
  createWakeupCall?:  {
    __typename: "WakeupCall",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    scheduledTime?: string | null,
  } | null,
};

export type CreateScheduledWakeupCallMutationVariables = {
  input: CreateScheduledWakeupInput,
};

export type CreateScheduledWakeupCallMutation = {
  // Mutation to schedule brain workload at a later point in time
  createScheduledWakeupCall?:  {
    __typename: "ScheduledWakeup",
    workerId: string,
    scheduledTime: string,
    executionDate: string,
    itemId: string,
  } | null,
};

export type CreateWorkerMutationVariables = {
  input: CreateWorkerInput,
  local?: boolean | null,
};

export type CreateWorkerMutation = {
  createWorker?:  {
    __typename: "Worker",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    version?: string | null,
    departmentVersion?: string | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
    procedureId?: string | null,
    entryCommand?: string | null,
    entryCommandId?: string | null,
    originalWorkerId?: string | null,
    createdAt?: string | null,
    state?: string | null,
    stateLastUpdatedAt?: string | null,
    status?: string | null,
    statusLastUpdatedAt?: string | null,
    attachments?: Array< string > | null,
    isArchived?: boolean | null,
    exceptions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    questions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateWorkerMutationVariables = {
  input: UpdateWorkerInput,
};

export type UpdateWorkerMutation = {
  updateWorker?:  {
    __typename: "Worker",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    version?: string | null,
    departmentVersion?: string | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
    procedureId?: string | null,
    entryCommand?: string | null,
    entryCommandId?: string | null,
    originalWorkerId?: string | null,
    createdAt?: string | null,
    state?: string | null,
    stateLastUpdatedAt?: string | null,
    status?: string | null,
    statusLastUpdatedAt?: string | null,
    attachments?: Array< string > | null,
    isArchived?: boolean | null,
    exceptions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    questions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateWorkerInternalStateMutationVariables = {
  id: string,
  state: string,
  message?: string | null,
};

export type UpdateWorkerInternalStateMutation = {
  updateWorkerInternalState?:  {
    __typename: "Worker",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    version?: string | null,
    departmentVersion?: string | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
    procedureId?: string | null,
    entryCommand?: string | null,
    entryCommandId?: string | null,
    originalWorkerId?: string | null,
    createdAt?: string | null,
    state?: string | null,
    stateLastUpdatedAt?: string | null,
    status?: string | null,
    statusLastUpdatedAt?: string | null,
    attachments?: Array< string > | null,
    isArchived?: boolean | null,
    exceptions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    questions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type DeleteWorkerMutationVariables = {
  input: DeleteWorkerInput,
};

export type DeleteWorkerMutation = {
  deleteWorker?:  {
    __typename: "Worker",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    version?: string | null,
    departmentVersion?: string | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
    procedureId?: string | null,
    entryCommand?: string | null,
    entryCommandId?: string | null,
    originalWorkerId?: string | null,
    createdAt?: string | null,
    state?: string | null,
    stateLastUpdatedAt?: string | null,
    status?: string | null,
    statusLastUpdatedAt?: string | null,
    attachments?: Array< string > | null,
    isArchived?: boolean | null,
    exceptions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    questions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type SetWorkerArchivedMutationVariables = {
  input: ArchiveWorkerInput,
};

export type SetWorkerArchivedMutation = {
  setWorkerArchived?:  {
    __typename: "Worker",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    version?: string | null,
    departmentVersion?: string | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
    procedureId?: string | null,
    entryCommand?: string | null,
    entryCommandId?: string | null,
    originalWorkerId?: string | null,
    createdAt?: string | null,
    state?: string | null,
    stateLastUpdatedAt?: string | null,
    status?: string | null,
    statusLastUpdatedAt?: string | null,
    attachments?: Array< string > | null,
    isArchived?: boolean | null,
    exceptions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    questions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateQuestionMutationVariables = {
  input: CreateQuestionInput,
};

export type CreateQuestionMutation = {
  createQuestion?:  {
    __typename: "Question",
    id: string,
    commandId: string,
    workerId: string,
    contextId: number,
    sentenceId?: number | null,
    contextPath?:  Array< {
      __typename: "InstructionLocationById",
      ctxId: number,
      sentenceId?: number | null,
    } > | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    text?: string | null,
    path?: string | null,
    procedureId?: string | null,
    validationList?: Array< string | null > | null,
    answerId?: string | null,
    isAnswered: boolean,
    delegationChain?:  Array< {
      __typename: "CommunicationItem",
      method: string,
      recipients: Array< string >,
      parameters: string,
    } > | null,
    createdAt?: string | null,
    rawException?: string | null,
    parentId?: string | null,
    secret?: boolean | null,
    stepPath?: Array< string > | null,
    answerTypeHint?: string | null,
    sourceMetadata?: string | null,
    worker:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    },
    children:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    answer?:  {
      __typename: "Answer",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      commandId?: string | null,
      questionId?: string | null,
      type?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      text?: string | null,
      error?: string | null,
      remember?: boolean | null,
      secret?: boolean | null,
    } | null,
  } | null,
};

export type UpdateQuestionMutationVariables = {
  input: UpdateQuestionInput,
};

export type UpdateQuestionMutation = {
  updateQuestion?:  {
    __typename: "Question",
    id: string,
    commandId: string,
    workerId: string,
    contextId: number,
    sentenceId?: number | null,
    contextPath?:  Array< {
      __typename: "InstructionLocationById",
      ctxId: number,
      sentenceId?: number | null,
    } > | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    text?: string | null,
    path?: string | null,
    procedureId?: string | null,
    validationList?: Array< string | null > | null,
    answerId?: string | null,
    isAnswered: boolean,
    delegationChain?:  Array< {
      __typename: "CommunicationItem",
      method: string,
      recipients: Array< string >,
      parameters: string,
    } > | null,
    createdAt?: string | null,
    rawException?: string | null,
    parentId?: string | null,
    secret?: boolean | null,
    stepPath?: Array< string > | null,
    answerTypeHint?: string | null,
    sourceMetadata?: string | null,
    worker:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    },
    children:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    answer?:  {
      __typename: "Answer",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      commandId?: string | null,
      questionId?: string | null,
      type?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      text?: string | null,
      error?: string | null,
      remember?: boolean | null,
      secret?: boolean | null,
    } | null,
  } | null,
};

export type DeleteQuestionMutationVariables = {
  input: DeleteQuestionInput,
};

export type DeleteQuestionMutation = {
  deleteQuestion?:  {
    __typename: "Question",
    id: string,
    commandId: string,
    workerId: string,
    contextId: number,
    sentenceId?: number | null,
    contextPath?:  Array< {
      __typename: "InstructionLocationById",
      ctxId: number,
      sentenceId?: number | null,
    } > | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    text?: string | null,
    path?: string | null,
    procedureId?: string | null,
    validationList?: Array< string | null > | null,
    answerId?: string | null,
    isAnswered: boolean,
    delegationChain?:  Array< {
      __typename: "CommunicationItem",
      method: string,
      recipients: Array< string >,
      parameters: string,
    } > | null,
    createdAt?: string | null,
    rawException?: string | null,
    parentId?: string | null,
    secret?: boolean | null,
    stepPath?: Array< string > | null,
    answerTypeHint?: string | null,
    sourceMetadata?: string | null,
    worker:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    },
    children:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    answer?:  {
      __typename: "Answer",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      commandId?: string | null,
      questionId?: string | null,
      type?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      text?: string | null,
      error?: string | null,
      remember?: boolean | null,
      secret?: boolean | null,
    } | null,
  } | null,
};

export type CreateNotificationMutationVariables = {
  input: CreateNotificationInput,
};

export type CreateNotificationMutation = {
  createNotification?:  {
    __typename: "Notification",
    id: string,
    contextId: number,
    commandId: string,
    workerId: string,
    sentenceId?: number | null,
    info?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    createdAt?: string | null,
  } | null,
};

export type UpdateNotificationMutationVariables = {
  input: UpdateNotificationInput,
};

export type UpdateNotificationMutation = {
  updateNotification?:  {
    __typename: "Notification",
    id: string,
    contextId: number,
    commandId: string,
    workerId: string,
    sentenceId?: number | null,
    info?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    createdAt?: string | null,
  } | null,
};

export type DeleteNotificationMutationVariables = {
  input: DeleteNotificationInput,
};

export type DeleteNotificationMutation = {
  deleteNotification?:  {
    __typename: "Notification",
    id: string,
    contextId: number,
    commandId: string,
    workerId: string,
    sentenceId?: number | null,
    info?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    createdAt?: string | null,
  } | null,
};

export type CreateLearningMutationVariables = {
  input: CreateLearningInput,
};

export type CreateLearningMutation = {
  createLearning?:  {
    __typename: "Learning",
    id: string,
    procedureId: string,
    departmentId: string,
    type?: string | null,
    inputParams?: string | null,
    questionType?: string | null,
    questionPath?: string | null,
    answerType?: string | null,
    answerText?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    createdAt?: string | null,
    secret?: boolean | null,
  } | null,
};

export type UpdateLearningMutationVariables = {
  input: UpdateLearningInput,
};

export type UpdateLearningMutation = {
  updateLearning?:  {
    __typename: "Learning",
    id: string,
    procedureId: string,
    departmentId: string,
    type?: string | null,
    inputParams?: string | null,
    questionType?: string | null,
    questionPath?: string | null,
    answerType?: string | null,
    answerText?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    createdAt?: string | null,
    secret?: boolean | null,
  } | null,
};

export type DeleteLearningMutationVariables = {
  input: DeleteLearningInput,
};

export type DeleteLearningMutation = {
  deleteLearning?:  {
    __typename: "Learning",
    id: string,
    procedureId: string,
    departmentId: string,
    type?: string | null,
    inputParams?: string | null,
    questionType?: string | null,
    questionPath?: string | null,
    answerType?: string | null,
    answerText?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    createdAt?: string | null,
    secret?: boolean | null,
  } | null,
};

export type CreateProcedureMutationVariables = {
  input: CreateProcedureInput,
};

export type CreateProcedureMutation = {
  createProcedure?:  {
    __typename: "Procedure",
    id: string,
    knowledgeId?: string | null,
    departmentId?: string | null,
    text?: string | null,
    name?: string | null,
    owner?: string | null,
    language?: string | null,
    public: boolean,
    bookReference?: string | null,
    schedules?:  Array< {
      __typename: "Schedule",
      name?: string | null,
      expression?: string | null,
      enabled?: boolean | null,
    } | null > | null,
    email?:  Array< {
      __typename: "StageEmail",
      stage: string,
      email: string,
    } > | null,
    title?: string | null,
    version?: string | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateProcedureMutationVariables = {
  input: UpdateProcedureInput,
};

export type UpdateProcedureMutation = {
  updateProcedure?:  {
    __typename: "Procedure",
    id: string,
    knowledgeId?: string | null,
    departmentId?: string | null,
    text?: string | null,
    name?: string | null,
    owner?: string | null,
    language?: string | null,
    public: boolean,
    bookReference?: string | null,
    schedules?:  Array< {
      __typename: "Schedule",
      name?: string | null,
      expression?: string | null,
      enabled?: boolean | null,
    } | null > | null,
    email?:  Array< {
      __typename: "StageEmail",
      stage: string,
      email: string,
    } > | null,
    title?: string | null,
    version?: string | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateProcedureMetadataMutationVariables = {
  input: UpdateProcedureMetadataInput,
};

export type UpdateProcedureMetadataMutation = {
  updateProcedureMetadata?:  {
    __typename: "Procedure",
    id: string,
    knowledgeId?: string | null,
    departmentId?: string | null,
    text?: string | null,
    name?: string | null,
    owner?: string | null,
    language?: string | null,
    public: boolean,
    bookReference?: string | null,
    schedules?:  Array< {
      __typename: "Schedule",
      name?: string | null,
      expression?: string | null,
      enabled?: boolean | null,
    } | null > | null,
    email?:  Array< {
      __typename: "StageEmail",
      stage: string,
      email: string,
    } > | null,
    title?: string | null,
    version?: string | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpdateProcedureScheduleMutationVariables = {
  input: UpdateProcedureScheduleInput,
};

export type UpdateProcedureScheduleMutation = {
  updateProcedureSchedule?:  {
    __typename: "Procedure",
    id: string,
    knowledgeId?: string | null,
    departmentId?: string | null,
    text?: string | null,
    name?: string | null,
    owner?: string | null,
    language?: string | null,
    public: boolean,
    bookReference?: string | null,
    schedules?:  Array< {
      __typename: "Schedule",
      name?: string | null,
      expression?: string | null,
      enabled?: boolean | null,
    } | null > | null,
    email?:  Array< {
      __typename: "StageEmail",
      stage: string,
      email: string,
    } > | null,
    title?: string | null,
    version?: string | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ShareProcedureMutationVariables = {
  input: ShareProcedureInput,
};

export type ShareProcedureMutation = {
  shareProcedure?:  {
    __typename: "AccessControlAck",
    status: string,
  } | null,
};

export type DeleteProcedureMutationVariables = {
  input: DeleteProcedureInput,
};

export type DeleteProcedureMutation = {
  deleteProcedure?:  {
    __typename: "Procedure",
    id: string,
    knowledgeId?: string | null,
    departmentId?: string | null,
    text?: string | null,
    name?: string | null,
    owner?: string | null,
    language?: string | null,
    public: boolean,
    bookReference?: string | null,
    schedules?:  Array< {
      __typename: "Schedule",
      name?: string | null,
      expression?: string | null,
      enabled?: boolean | null,
    } | null > | null,
    email?:  Array< {
      __typename: "StageEmail",
      stage: string,
      email: string,
    } > | null,
    title?: string | null,
    version?: string | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type MarkWorkItemReceivedMutationVariables = {
  id: string,
};

export type MarkWorkItemReceivedMutation = {
  markWorkItemReceived?: string | null,
};

export type MarkWorkItemProcessingMutationVariables = {
  id: string,
};

export type MarkWorkItemProcessingMutation = {
  markWorkItemProcessing?: string | null,
};

export type MarkWorkItemProcessedMutationVariables = {
  id: string,
};

export type MarkWorkItemProcessedMutation = {
  markWorkItemProcessed?: string | null,
};

export type MarkWorkItemFailedMutationVariables = {
  id: string,
  errors?: Array< string > | null,
};

export type MarkWorkItemFailedMutation = {
  markWorkItemFailed?: string | null,
};

export type InvokeProcedureMutationVariables = {
  departmentId: string,
  procedureId: string,
  knowledgeId?: string | null,
  owner?: string | null,
  name?: string | null,
  description?: string | null,
  debug?: boolean | null,
  local?: boolean | null,
  commandParamsInput?: CommandParamsInput | null,
  version?: string | null,
};

export type InvokeProcedureMutation = {
  invokeProcedure:  {
    __typename: "Command",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    text?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    subText?: string | null,
    subTextProperties?: Array< string > | null,
    subTextLanguage?: string | null,
    procedureId?: string | null,
    contextId?: number | null,
    sentenceId?: number | null,
    debug?: boolean | null,
    ast?: string | null,
    state?: string | null,
    output?: string | null,
    source?: string | null,
    sourceMetadata?: string | null,
    positionInContext?: number | null,
    isMultilineText?: boolean | null,
    questions?:  {
      __typename: "QuestionConnection",
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "AnswerConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "NotificationConnection",
      nextToken?: string | null,
    } | null,
  },
};

export type DuplicateWorkerMutationVariables = {
  departmentId: string,
  workerId: string,
  owner?: string | null,
};

export type DuplicateWorkerMutation = {
  duplicateWorker:  {
    __typename: "Worker",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    version?: string | null,
    departmentVersion?: string | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
    procedureId?: string | null,
    entryCommand?: string | null,
    entryCommandId?: string | null,
    originalWorkerId?: string | null,
    createdAt?: string | null,
    state?: string | null,
    stateLastUpdatedAt?: string | null,
    status?: string | null,
    statusLastUpdatedAt?: string | null,
    attachments?: Array< string > | null,
    isArchived?: boolean | null,
    exceptions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    questions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  },
};

export type WorkerStatusMutationVariables = {
  input: WorkerStatusInput,
};

export type WorkerStatusMutation = {
  workerStatus:  {
    __typename: "WorkerStatus",
    id: string,
    mood: string,
    operation: string,
    icons: Array< string >,
    what: string,
  },
};

export type CreateKnowledgeSnapshotMutationVariables = {
  workerId: string,
};

export type CreateKnowledgeSnapshotMutation = {
  createKnowledgeSnapshot?:  {
    __typename: "Knowledge",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    departmentId: string,
    workerId?: string | null,
    parentKnowledgeId?: string | null,
    state?: string | null,
    brainVersion?: string | null,
  } | null,
};

export type CreateMutableKnowledgeMutationVariables = {
  knowledgeId?: string | null,
  departmentId?: string | null,
};

export type CreateMutableKnowledgeMutation = {
  createMutableKnowledge?:  {
    __typename: "Knowledge",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    departmentId: string,
    workerId?: string | null,
    parentKnowledgeId?: string | null,
    state?: string | null,
    brainVersion?: string | null,
  } | null,
};

export type UpdateKnowledgeMutationVariables = {
  input: UpdateKnowledgeInput,
};

export type UpdateKnowledgeMutation = {
  updateKnowledge?:  {
    __typename: "Knowledge",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    departmentId: string,
    workerId?: string | null,
    parentKnowledgeId?: string | null,
    state?: string | null,
    brainVersion?: string | null,
  } | null,
};

export type PublishToDepartmentMutationVariables = {
  departmentId: string,
  knowledgeId: string,
};

export type PublishToDepartmentMutation = {
  publishToDepartment?:  {
    __typename: "Department",
    id: string,
    owner?: string | null,
    organizationId?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    draftKnowledgeId?: string | null,
    publishedKnowledgeId?: string | null,
    workerId?: string | null,
    local?: boolean | null,
    state?: string | null,
    errors?: Array< string > | null,
    createdAt?: string | null,
    isDeleted: boolean,
    upgradeCommandId?: string | null,
    notifyProcedureInvocation?: boolean | null,
    features?: Array< DepartmentFeature > | null,
    brainVersion?: string | null,
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    draftKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    publishedKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
    learnedAnswers?:  {
      __typename: "LearnedAnswerConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type UpgradeDepartmentMutationVariables = {
  departmentId: string,
  brainVersion: string,
};

export type UpgradeDepartmentMutation = {
  upgradeDepartment?:  {
    __typename: "Department",
    id: string,
    owner?: string | null,
    organizationId?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    draftKnowledgeId?: string | null,
    publishedKnowledgeId?: string | null,
    workerId?: string | null,
    local?: boolean | null,
    state?: string | null,
    errors?: Array< string > | null,
    createdAt?: string | null,
    isDeleted: boolean,
    upgradeCommandId?: string | null,
    notifyProcedureInvocation?: boolean | null,
    features?: Array< DepartmentFeature > | null,
    brainVersion?: string | null,
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    draftKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    publishedKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
    learnedAnswers?:  {
      __typename: "LearnedAnswerConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type RollbackDepartmentMutationVariables = {
  departmentId: string,
};

export type RollbackDepartmentMutation = {
  rollbackDepartment?:  {
    __typename: "Department",
    id: string,
    owner?: string | null,
    organizationId?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    draftKnowledgeId?: string | null,
    publishedKnowledgeId?: string | null,
    workerId?: string | null,
    local?: boolean | null,
    state?: string | null,
    errors?: Array< string > | null,
    createdAt?: string | null,
    isDeleted: boolean,
    upgradeCommandId?: string | null,
    notifyProcedureInvocation?: boolean | null,
    features?: Array< DepartmentFeature > | null,
    brainVersion?: string | null,
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    draftKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    publishedKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
    learnedAnswers?:  {
      __typename: "LearnedAnswerConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type RequestSandboxesMutationVariables = {
  departmentId: string,
  brainVersion: string,
};

export type RequestSandboxesMutation = {
  requestSandboxes:  Array< {
    __typename: "Sandbox",
    language: string,
    arn: string,
  } >,
};

export type InvokeLocalWorkerMutationVariables = {
  input: WorkerInvocationInput,
};

export type InvokeLocalWorkerMutation = {
  invokeLocalWorker:  {
    __typename: "WorkerInvocation",
    departmentId: string,
    event: string,
  },
};

export type NotifyWorkerUpdateMutationVariables = {
  input: NotifyWorkerUpdateInput,
};

export type NotifyWorkerUpdateMutation = {
  notifyWorkerUpdate:  {
    __typename: "Worker",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    version?: string | null,
    departmentVersion?: string | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
    procedureId?: string | null,
    entryCommand?: string | null,
    entryCommandId?: string | null,
    originalWorkerId?: string | null,
    createdAt?: string | null,
    state?: string | null,
    stateLastUpdatedAt?: string | null,
    status?: string | null,
    statusLastUpdatedAt?: string | null,
    attachments?: Array< string > | null,
    isArchived?: boolean | null,
    exceptions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    questions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  },
};

export type UpdateStepsMutationVariables = {
  input: StepsInput,
};

export type UpdateStepsMutation = {
  updateSteps:  {
    __typename: "Steps",
    workerId: string,
    stepList?:  Array< {
      __typename: "Step",
      id: string,
      contextId: string,
      workerId: string,
      number?: number | null,
      commandId?: string | null,
      createdAt?: string | null,
      scratchedAt?: string | null,
      text?: string | null,
      answer?: string | null,
      childContextIdList?: Array< string | null > | null,
      concepts?: string | null,
      questionIds?: Array< string > | null,
      requestIds?: Array< string > | null,
      positionInContext?: number | null,
      epoch?: number | null,
    } | null > | null,
  },
};

export type CreateSandboxProxyRequestMutationVariables = {
  departmentId: string,
  proxyId: string,
  workerId: string,
  request: string,
};

export type CreateSandboxProxyRequestMutation = {
  createSandboxProxyRequest?:  {
    __typename: "SandboxProxyMessage",
    departmentId: string,
    workerId: string,
    proxyId: string,
    requestId: string,
    request?: string | null,
    response?: string | null,
  } | null,
};

export type CreateSandboxProxyResponseMutationVariables = {
  departmentId: string,
  proxyId: string,
  workerId: string,
  requestId: string,
  response: string,
};

export type CreateSandboxProxyResponseMutation = {
  createSandboxProxyResponse?:  {
    __typename: "SandboxProxyMessage",
    departmentId: string,
    workerId: string,
    proxyId: string,
    requestId: string,
    request?: string | null,
    response?: string | null,
  } | null,
};

export type LearnBooksMutationVariables = {
  ids: Array< string >,
  departmentId: string,
};

export type LearnBooksMutation = {
  learnBooks:  Array< {
    __typename: "Book",
    id: string,
    url: string,
    name?: string | null,
    author?: string | null,
    description?: string | null,
    learnedAt?: string | null,
    icon?: string | null,
    commandId?: string | null,
    version?: string | null,
  } >,
};

export type ForgetBooksMutationVariables = {
  ids: Array< string >,
  departmentId: string,
};

export type ForgetBooksMutation = {
  forgetBooks:  Array< {
    __typename: "Book",
    id: string,
    url: string,
    name?: string | null,
    author?: string | null,
    description?: string | null,
    learnedAt?: string | null,
    icon?: string | null,
    commandId?: string | null,
    version?: string | null,
  } >,
};

export type CreateRequestMutationVariables = {
  input: CreateRequestInput,
};

export type CreateRequestMutation = {
  // Create a request. Only a worker can create a request.
  createRequest?:  {
    __typename: "Request",
    id: string,
    // List of steps that contain this request, starting with the outermost step. Each step is identified by its english text. Similar to breadcrumbs.
    stepPath?: Array< string > | null,
    // The id of the (innermost) context that generated this request.
    contextId?: number | null,
    // The id of the sentence that generated this request.
    sentenceId?: number | null,
    // The time at which this request was created. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
    createdAt?: string | null,
    // The time at which this request was handled. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
    handledAt?: string | null,
    // The state of this request. One of: 'pending', 'handled'.
    state?: string | null,
    // The worker that made the request.
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    // List of questions in this request.
    questions:  Array<( {
        __typename: "EnsureFailedQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
      } | {
        __typename: "NativeCodeErrorQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        errorType?: string | null,
        errorMessage?: string | null,
        errorTraceback?: string | null,
      } | {
        __typename: "ParseErrorQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        errorTraceback?: string | null,
      } | {
        __typename: "ProcedureNotKnownQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
      } | {
        __typename: "ReviewConceptQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        // English lexical path to the concept.
        lexicalPath?: Array< string > | null,
        conceptId?: string | null,
      } | {
        __typename: "ValueNotKnownQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        // English lexical path to the concept.
        lexicalPath?: Array< string > | null,
        choices?: Array< string > | null,
        helpText?: string | null,
      }
    ) > | null,
    // List of suggested answers for this request.
    suggestedAnswers?:  Array< {
      __typename: "RequestAnswerSuggestion",
      id: string,
      // The id of the request that this answer belongs to
      requestId: string,
      // The id of the question that this answer is in response to, if any.
      questionId?: string | null,
    } > | null,
    // List of answers created in response to this request.
    answers?:  Array< {
      __typename: "RequestAnswer",
      id: string,
      // The id of the request that this answer belongs to
      requestId: string,
      // The id of the question that this answer is in response to, if any.
      questionId?: string | null,
      // The time that this answer was created.
      createdAt?: string | null,
      // The id of the user that created this answer.
      createdBy?: string | null,
    } > | null,
    // List of instruction location were the request was created.
    contextPath?:  Array< {
      __typename: "InstructionLocationById",
      ctxId: number,
      sentenceId?: number | null,
    } > | null,
  } | null,
};

export type UpdateRequestStateMutationVariables = {
  input: UpdateRequestStateInput,
};

export type UpdateRequestStateMutation = {
  updateRequestState?:  {
    __typename: "Request",
    id: string,
    // List of steps that contain this request, starting with the outermost step. Each step is identified by its english text. Similar to breadcrumbs.
    stepPath?: Array< string > | null,
    // The id of the (innermost) context that generated this request.
    contextId?: number | null,
    // The id of the sentence that generated this request.
    sentenceId?: number | null,
    // The time at which this request was created. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
    createdAt?: string | null,
    // The time at which this request was handled. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
    handledAt?: string | null,
    // The state of this request. One of: 'pending', 'handled'.
    state?: string | null,
    // The worker that made the request.
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    // List of questions in this request.
    questions:  Array<( {
        __typename: "EnsureFailedQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
      } | {
        __typename: "NativeCodeErrorQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        errorType?: string | null,
        errorMessage?: string | null,
        errorTraceback?: string | null,
      } | {
        __typename: "ParseErrorQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        errorTraceback?: string | null,
      } | {
        __typename: "ProcedureNotKnownQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
      } | {
        __typename: "ReviewConceptQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        // English lexical path to the concept.
        lexicalPath?: Array< string > | null,
        conceptId?: string | null,
      } | {
        __typename: "ValueNotKnownQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        // English lexical path to the concept.
        lexicalPath?: Array< string > | null,
        choices?: Array< string > | null,
        helpText?: string | null,
      }
    ) > | null,
    // List of suggested answers for this request.
    suggestedAnswers?:  Array< {
      __typename: "RequestAnswerSuggestion",
      id: string,
      // The id of the request that this answer belongs to
      requestId: string,
      // The id of the question that this answer is in response to, if any.
      questionId?: string | null,
    } > | null,
    // List of answers created in response to this request.
    answers?:  Array< {
      __typename: "RequestAnswer",
      id: string,
      // The id of the request that this answer belongs to
      requestId: string,
      // The id of the question that this answer is in response to, if any.
      questionId?: string | null,
      // The time that this answer was created.
      createdAt?: string | null,
      // The id of the user that created this answer.
      createdBy?: string | null,
    } > | null,
    // List of instruction location were the request was created.
    contextPath?:  Array< {
      __typename: "InstructionLocationById",
      ctxId: number,
      sentenceId?: number | null,
    } > | null,
  } | null,
};

export type CreateRequestAnswerMutationVariables = {
  input: CreateRequestAnswerInput,
};

export type CreateRequestAnswerMutation = {
  createRequestAnswer?:  {
    __typename: "RequestAnswer",
    id: string,
    // The id of the request that this answer belongs to
    requestId: string,
    // The id of the question that this answer is in response to, if any.
    questionId?: string | null,
    // The action to take to resolve the question.
    action: ( {
        __typename: "ApproveReviewAction",
        _?: string | null,
      } | {
        __typename: "ReplaceStepAction",
        _?: string | null,
        newSteps?: Array< string > | null,
      } | {
        __typename: "RetryStepAction",
        _?: string | null,
        minimumDelay: number,
        timeout?: number | null,
      } | {
        __typename: "SkipStepAction",
        _?: string | null,
      } | {
        __typename: "SkipToEndOfRunAction",
        _?: string | null,
      } | {
        __typename: "UseConceptAction",
        _?: string | null,
        conceptId?: string | null,
      } | {
        __typename: "UseLiteralValueAction",
        _?: string | null,
        literalValue?: string | null,
        choices?: Array< string > | null,
      } | {
        __typename: "UseNoValueAction",
        _?: string | null,
      } | {
        __typename: "UseTechniqueAction",
        _?: string | null,
        technique?: string | null,
      }
    ) | null,
    // The learning that was used to generate this answer, if any.
    sourceLearning?:  {
      __typename: "LearnedAnswer",
      id: string,
      // The time that this answer was created.
      createdAt?: string | null,
      // The id of the user that created this answer.
      createdBy?: string | null,
      // The time that this answer was deleted.
      deletedAt?: string | null,
      // The id of the user that deleted this answer.
      deletedBy?: string | null,
      // The id of the procedure that this learning belongs to.
      procedureId?: string | null,
      // The name of the procedure that this learning belongs to.
      procedureName?: string | null,
    } | null,
    // The time that this answer was created.
    createdAt?: string | null,
    // The id of the user that created this answer.
    createdBy?: string | null,
  } | null,
};

export type CreateBulkRequestAnswerMutationVariables = {
  inputs: Array< CreateRequestAnswerInput >,
};

export type CreateBulkRequestAnswerMutation = {
  createBulkRequestAnswer:  Array< {
    __typename: "RequestAnswer",
    id: string,
    // The id of the request that this answer belongs to
    requestId: string,
    // The id of the question that this answer is in response to, if any.
    questionId?: string | null,
    // The action to take to resolve the question.
    action: ( {
        __typename: "ApproveReviewAction",
        _?: string | null,
      } | {
        __typename: "ReplaceStepAction",
        _?: string | null,
        newSteps?: Array< string > | null,
      } | {
        __typename: "RetryStepAction",
        _?: string | null,
        minimumDelay: number,
        timeout?: number | null,
      } | {
        __typename: "SkipStepAction",
        _?: string | null,
      } | {
        __typename: "SkipToEndOfRunAction",
        _?: string | null,
      } | {
        __typename: "UseConceptAction",
        _?: string | null,
        conceptId?: string | null,
      } | {
        __typename: "UseLiteralValueAction",
        _?: string | null,
        literalValue?: string | null,
        choices?: Array< string > | null,
      } | {
        __typename: "UseNoValueAction",
        _?: string | null,
      } | {
        __typename: "UseTechniqueAction",
        _?: string | null,
        technique?: string | null,
      }
    ) | null,
    // The learning that was used to generate this answer, if any.
    sourceLearning?:  {
      __typename: "LearnedAnswer",
      id: string,
      // The time that this answer was created.
      createdAt?: string | null,
      // The id of the user that created this answer.
      createdBy?: string | null,
      // The time that this answer was deleted.
      deletedAt?: string | null,
      // The id of the user that deleted this answer.
      deletedBy?: string | null,
      // The id of the procedure that this learning belongs to.
      procedureId?: string | null,
      // The name of the procedure that this learning belongs to.
      procedureName?: string | null,
    } | null,
    // The time that this answer was created.
    createdAt?: string | null,
    // The id of the user that created this answer.
    createdBy?: string | null,
  } | null >,
};

export type DeleteRequestAnswerMutationVariables = {
  input: DeleteRequestAnswerInput,
};

export type DeleteRequestAnswerMutation = {
  deleteRequestAnswer?:  {
    __typename: "Request",
    id: string,
    // List of steps that contain this request, starting with the outermost step. Each step is identified by its english text. Similar to breadcrumbs.
    stepPath?: Array< string > | null,
    // The id of the (innermost) context that generated this request.
    contextId?: number | null,
    // The id of the sentence that generated this request.
    sentenceId?: number | null,
    // The time at which this request was created. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
    createdAt?: string | null,
    // The time at which this request was handled. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
    handledAt?: string | null,
    // The state of this request. One of: 'pending', 'handled'.
    state?: string | null,
    // The worker that made the request.
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    // List of questions in this request.
    questions:  Array<( {
        __typename: "EnsureFailedQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
      } | {
        __typename: "NativeCodeErrorQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        errorType?: string | null,
        errorMessage?: string | null,
        errorTraceback?: string | null,
      } | {
        __typename: "ParseErrorQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        errorTraceback?: string | null,
      } | {
        __typename: "ProcedureNotKnownQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
      } | {
        __typename: "ReviewConceptQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        // English lexical path to the concept.
        lexicalPath?: Array< string > | null,
        conceptId?: string | null,
      } | {
        __typename: "ValueNotKnownQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        // English lexical path to the concept.
        lexicalPath?: Array< string > | null,
        choices?: Array< string > | null,
        helpText?: string | null,
      }
    ) > | null,
    // List of suggested answers for this request.
    suggestedAnswers?:  Array< {
      __typename: "RequestAnswerSuggestion",
      id: string,
      // The id of the request that this answer belongs to
      requestId: string,
      // The id of the question that this answer is in response to, if any.
      questionId?: string | null,
    } > | null,
    // List of answers created in response to this request.
    answers?:  Array< {
      __typename: "RequestAnswer",
      id: string,
      // The id of the request that this answer belongs to
      requestId: string,
      // The id of the question that this answer is in response to, if any.
      questionId?: string | null,
      // The time that this answer was created.
      createdAt?: string | null,
      // The id of the user that created this answer.
      createdBy?: string | null,
    } > | null,
    // List of instruction location were the request was created.
    contextPath?:  Array< {
      __typename: "InstructionLocationById",
      ctxId: number,
      sentenceId?: number | null,
    } > | null,
  } | null,
};

export type CreateLearnedAnswerMutationVariables = {
  input: CreateLearnedAnswerInput,
};

export type CreateLearnedAnswerMutation = {
  createLearnedAnswer?:  {
    __typename: "LearnedAnswer",
    id: string,
    // The filter that determines which questions this learning applies to.
    questionFilter?:  {
      __typename: "RequestQuestionFilter",
      stepPath?: Array< string | null > | null,
      // The type of question that this learning applies to. Example 'ValueNotKnownQuestion'.
      questionType?: string | null,
      // The lexical path that this learning applies to.
      lexicalPath?: Array< string | null > | null,
    } | null,
    // The action to take to resolve the question.
    action: ( {
        __typename: "ApproveReviewAction",
        _?: string | null,
      } | {
        __typename: "ReplaceStepAction",
        _?: string | null,
        newSteps?: Array< string > | null,
      } | {
        __typename: "RetryStepAction",
        _?: string | null,
        minimumDelay: number,
        timeout?: number | null,
      } | {
        __typename: "SkipStepAction",
        _?: string | null,
      } | {
        __typename: "SkipToEndOfRunAction",
        _?: string | null,
      } | {
        __typename: "UseConceptAction",
        _?: string | null,
        conceptId?: string | null,
      } | {
        __typename: "UseLiteralValueAction",
        _?: string | null,
        literalValue?: string | null,
        choices?: Array< string > | null,
      } | {
        __typename: "UseNoValueAction",
        _?: string | null,
      } | {
        __typename: "UseTechniqueAction",
        _?: string | null,
        technique?: string | null,
      }
    ) | null,
    // The time that this answer was created.
    createdAt?: string | null,
    // The id of the user that created this answer.
    createdBy?: string | null,
    // The time that this answer was deleted.
    deletedAt?: string | null,
    // The id of the user that deleted this answer.
    deletedBy?: string | null,
    // The id of the procedure that this learning belongs to.
    procedureId?: string | null,
    // The name of the procedure that this learning belongs to.
    procedureName?: string | null,
    // The procedure that this learning belongs to.
    procedure?:  {
      __typename: "Procedure",
      id: string,
      knowledgeId?: string | null,
      departmentId?: string | null,
      text?: string | null,
      name?: string | null,
      owner?: string | null,
      language?: string | null,
      public: boolean,
      bookReference?: string | null,
      title?: string | null,
      version?: string | null,
    } | null,
  } | null,
};

export type UpdateLearnedAnswerMutationVariables = {
  input: UpdateLearnedAnswerInput,
};

export type UpdateLearnedAnswerMutation = {
  updateLearnedAnswer?:  {
    __typename: "LearnedAnswer",
    id: string,
    // The filter that determines which questions this learning applies to.
    questionFilter?:  {
      __typename: "RequestQuestionFilter",
      stepPath?: Array< string | null > | null,
      // The type of question that this learning applies to. Example 'ValueNotKnownQuestion'.
      questionType?: string | null,
      // The lexical path that this learning applies to.
      lexicalPath?: Array< string | null > | null,
    } | null,
    // The action to take to resolve the question.
    action: ( {
        __typename: "ApproveReviewAction",
        _?: string | null,
      } | {
        __typename: "ReplaceStepAction",
        _?: string | null,
        newSteps?: Array< string > | null,
      } | {
        __typename: "RetryStepAction",
        _?: string | null,
        minimumDelay: number,
        timeout?: number | null,
      } | {
        __typename: "SkipStepAction",
        _?: string | null,
      } | {
        __typename: "SkipToEndOfRunAction",
        _?: string | null,
      } | {
        __typename: "UseConceptAction",
        _?: string | null,
        conceptId?: string | null,
      } | {
        __typename: "UseLiteralValueAction",
        _?: string | null,
        literalValue?: string | null,
        choices?: Array< string > | null,
      } | {
        __typename: "UseNoValueAction",
        _?: string | null,
      } | {
        __typename: "UseTechniqueAction",
        _?: string | null,
        technique?: string | null,
      }
    ) | null,
    // The time that this answer was created.
    createdAt?: string | null,
    // The id of the user that created this answer.
    createdBy?: string | null,
    // The time that this answer was deleted.
    deletedAt?: string | null,
    // The id of the user that deleted this answer.
    deletedBy?: string | null,
    // The id of the procedure that this learning belongs to.
    procedureId?: string | null,
    // The name of the procedure that this learning belongs to.
    procedureName?: string | null,
    // The procedure that this learning belongs to.
    procedure?:  {
      __typename: "Procedure",
      id: string,
      knowledgeId?: string | null,
      departmentId?: string | null,
      text?: string | null,
      name?: string | null,
      owner?: string | null,
      language?: string | null,
      public: boolean,
      bookReference?: string | null,
      title?: string | null,
      version?: string | null,
    } | null,
  } | null,
};

export type DeleteLearnedAnswerMutationVariables = {
  input: DeleteLearnedAnswerInput,
};

export type DeleteLearnedAnswerMutation = {
  deleteLearnedAnswer?:  {
    __typename: "LearnedAnswer",
    id: string,
    // The filter that determines which questions this learning applies to.
    questionFilter?:  {
      __typename: "RequestQuestionFilter",
      stepPath?: Array< string | null > | null,
      // The type of question that this learning applies to. Example 'ValueNotKnownQuestion'.
      questionType?: string | null,
      // The lexical path that this learning applies to.
      lexicalPath?: Array< string | null > | null,
    } | null,
    // The action to take to resolve the question.
    action: ( {
        __typename: "ApproveReviewAction",
        _?: string | null,
      } | {
        __typename: "ReplaceStepAction",
        _?: string | null,
        newSteps?: Array< string > | null,
      } | {
        __typename: "RetryStepAction",
        _?: string | null,
        minimumDelay: number,
        timeout?: number | null,
      } | {
        __typename: "SkipStepAction",
        _?: string | null,
      } | {
        __typename: "SkipToEndOfRunAction",
        _?: string | null,
      } | {
        __typename: "UseConceptAction",
        _?: string | null,
        conceptId?: string | null,
      } | {
        __typename: "UseLiteralValueAction",
        _?: string | null,
        literalValue?: string | null,
        choices?: Array< string > | null,
      } | {
        __typename: "UseNoValueAction",
        _?: string | null,
      } | {
        __typename: "UseTechniqueAction",
        _?: string | null,
        technique?: string | null,
      }
    ) | null,
    // The time that this answer was created.
    createdAt?: string | null,
    // The id of the user that created this answer.
    createdBy?: string | null,
    // The time that this answer was deleted.
    deletedAt?: string | null,
    // The id of the user that deleted this answer.
    deletedBy?: string | null,
    // The id of the procedure that this learning belongs to.
    procedureId?: string | null,
    // The name of the procedure that this learning belongs to.
    procedureName?: string | null,
    // The procedure that this learning belongs to.
    procedure?:  {
      __typename: "Procedure",
      id: string,
      knowledgeId?: string | null,
      departmentId?: string | null,
      text?: string | null,
      name?: string | null,
      owner?: string | null,
      language?: string | null,
      public: boolean,
      bookReference?: string | null,
      title?: string | null,
      version?: string | null,
    } | null,
  } | null,
};

export type CreateDepartmentFactMutationVariables = {
  input: CreateDepartmentFactInput,
};

export type CreateDepartmentFactMutation = {
  createDepartmentFact?:  {
    __typename: "DepartmentFact",
    id: string,
    departmentId: string,
    name: string,
    values: Array< string >,
    version?: string | null,
  } | null,
};

export type UpdateDepartmentFactMutationVariables = {
  input: UpdateDepartmentFactInput,
};

export type UpdateDepartmentFactMutation = {
  updateDepartmentFact?:  {
    __typename: "DepartmentFact",
    id: string,
    departmentId: string,
    name: string,
    values: Array< string >,
    version?: string | null,
  } | null,
};

export type DeleteDepartmentFactMutationVariables = {
  input: DeleteDepartmentFactInput,
};

export type DeleteDepartmentFactMutation = {
  deleteDepartmentFact?:  {
    __typename: "DepartmentFact",
    id: string,
    departmentId: string,
    name: string,
    values: Array< string >,
    version?: string | null,
  } | null,
};

export type CreateSecretValueMutationVariables = {
  input: CreateSecretValueInput,
};

export type CreateSecretValueMutation = {
  // Stores the given value as a secret. Returns an equivalent value with the content hidden.
  createSecretValue?: string | null,
};

export type UploadValueMutationVariables = {
  input: UploadValueInput,
};

export type UploadValueMutation = {
  // Creates a new value whose content is set by uploading a file via a POST request.
  uploadValue?:  {
    __typename: "ValueUpload",
    // The serialized form of the value. Can be provided to the brain after the upload is complete.
    value: string,
    // The URL to upload the value to.
    url: string,
    // Additional fields that must be included in form data of the upload.
    fields:  Array< {
      __typename: "ValueUploadField",
      name: string,
      value: string,
    } >,
  } | null,
};

export type CreateSharedEntityMutationVariables = {
  input: CreateSharedEntityInput,
};

export type CreateSharedEntityMutation = {
  createSharedEntity?:  {
    __typename: "SharedEntity",
    id: string,
    entityId: string,
    departmentId: string,
    shareName: string,
    fullShareId?: string | null,
    departmentVersion?: string | null,
  } | null,
};

export type UpdateSharedEntityMutationVariables = {
  input: UpdateSharedEntityInput,
};

export type UpdateSharedEntityMutation = {
  updateSharedEntity?:  {
    __typename: "SharedEntity",
    id: string,
    entityId: string,
    departmentId: string,
    shareName: string,
    fullShareId?: string | null,
    departmentVersion?: string | null,
  } | null,
};

export type DeleteSharedEntityMutationVariables = {
  id: string,
};

export type DeleteSharedEntityMutation = {
  deleteSharedEntity?:  {
    __typename: "SharedEntity",
    id: string,
    entityId: string,
    departmentId: string,
    shareName: string,
    fullShareId?: string | null,
    departmentVersion?: string | null,
  } | null,
};

export type ImportDepartmentMutationVariables = {
  input: DepartmentImportInput,
};

export type ImportDepartmentMutation = {
  importDepartment?:  {
    __typename: "Department",
    id: string,
    owner?: string | null,
    organizationId?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    draftKnowledgeId?: string | null,
    publishedKnowledgeId?: string | null,
    workerId?: string | null,
    local?: boolean | null,
    state?: string | null,
    errors?: Array< string > | null,
    createdAt?: string | null,
    isDeleted: boolean,
    upgradeCommandId?: string | null,
    notifyProcedureInvocation?: boolean | null,
    features?: Array< DepartmentFeature > | null,
    brainVersion?: string | null,
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    draftKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    publishedKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
    learnedAnswers?:  {
      __typename: "LearnedAnswerConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type CreateKChatMessageMutationVariables = {
  input: CreateChatMessageInput,
};

export type CreateKChatMessageMutation = {
  createKChatMessage:  {
    __typename: "ChatMessageConnection",
    items:  Array< {
      __typename: "ChatMessage",
      id: string,
      departmentId: string,
      workerId: string,
      message: string,
      timestamp: string,
      displayName?: string | null,
      role?: string | null,
    } >,
    nextToken?: string | null,
  },
};

export type NotifyKChatMessageUpdateMutationVariables = {
  input: NotifyChatMessageUpdateInput,
};

export type NotifyKChatMessageUpdateMutation = {
  notifyKChatMessageUpdate:  {
    __typename: "ChatMessage",
    id: string,
    departmentId: string,
    workerId: string,
    message: string,
    timestamp: string,
    displayName?: string | null,
    role?: string | null,
  },
};

export type GetAnswerQueryVariables = {
  id: string,
};

export type GetAnswerQuery = {
  getAnswer?:  {
    __typename: "Answer",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    commandId?: string | null,
    questionId?: string | null,
    type?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    text?: string | null,
    error?: string | null,
    remember?: boolean | null,
    secret?: boolean | null,
  } | null,
};

export type ListAnswersByCommandQueryVariables = {
  commandId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAnswersByCommandQuery = {
  listAnswersByCommand?:  {
    __typename: "AnswerConnection",
    items?:  Array< {
      __typename: "Answer",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      commandId?: string | null,
      questionId?: string | null,
      type?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      text?: string | null,
      error?: string | null,
      remember?: boolean | null,
      secret?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListBooksByKnowledgeQueryVariables = {
  knowledgeId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBooksByKnowledgeQuery = {
  listBooksByKnowledge?:  {
    __typename: "BookConnection",
    items?:  Array< {
      __typename: "Book",
      id: string,
      url: string,
      name?: string | null,
      author?: string | null,
      description?: string | null,
      learnedAt?: string | null,
      icon?: string | null,
      commandId?: string | null,
      version?: string | null,
    } > | null,
    nextToken?: string | null,
  } | null,
};

export type ListBooksByDepartmentQueryVariables = {
  departmentId: string,
  version?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListBooksByDepartmentQuery = {
  listBooksByDepartment?:  {
    __typename: "BookConnection",
    items?:  Array< {
      __typename: "Book",
      id: string,
      url: string,
      name?: string | null,
      author?: string | null,
      description?: string | null,
      learnedAt?: string | null,
      icon?: string | null,
      commandId?: string | null,
      version?: string | null,
    } > | null,
    nextToken?: string | null,
  } | null,
};

export type GetCommandQueryVariables = {
  id: string,
};

export type GetCommandQuery = {
  getCommand?:  {
    __typename: "Command",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    text?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    subText?: string | null,
    subTextProperties?: Array< string > | null,
    subTextLanguage?: string | null,
    procedureId?: string | null,
    contextId?: number | null,
    sentenceId?: number | null,
    debug?: boolean | null,
    ast?: string | null,
    state?: string | null,
    output?: string | null,
    source?: string | null,
    sourceMetadata?: string | null,
    positionInContext?: number | null,
    isMultilineText?: boolean | null,
    questions?:  {
      __typename: "QuestionConnection",
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "AnswerConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "NotificationConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListCommandsByWorkerQueryVariables = {
  workerId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListCommandsByWorkerQuery = {
  listCommandsByWorker?:  {
    __typename: "CommandConnection",
    items?:  Array< {
      __typename: "Command",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      text?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      subText?: string | null,
      subTextProperties?: Array< string > | null,
      subTextLanguage?: string | null,
      procedureId?: string | null,
      contextId?: number | null,
      sentenceId?: number | null,
      debug?: boolean | null,
      ast?: string | null,
      state?: string | null,
      output?: string | null,
      source?: string | null,
      sourceMetadata?: string | null,
      positionInContext?: number | null,
      isMultilineText?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetContextsForStepQueryVariables = {
  workerId: string,
  contextId?: string | null,
  stepId?: string | null,
};

export type GetContextsForStepQuery = {
  getContextsForStep?:  {
    __typename: "ContextConnection",
    items?:  Array< {
      __typename: "Context",
      id: string,
      workerId: string,
      createdAt?: string | null,
      depth?: number | null,
      commandId?: string | null,
      concepts?: string | null,
    } | null > | null,
  } | null,
};

export type ExportCodeForContextQueryVariables = {
  workerId: string,
  contextId?: string | null,
};

export type ExportCodeForContextQuery = {
  exportCodeForContext?: Array< string | null > | null,
};

export type GetOrganizationQueryVariables = {
  id: string,
};

export type GetOrganizationQuery = {
  getOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetOwnerOrganizationQueryVariables = {
  owner?: string | null,
};

export type GetOwnerOrganizationQuery = {
  getOwnerOrganization?:  {
    __typename: "Organization",
    id: string,
    name: string,
    owner: string,
    createdAt: string,
    updatedAt: string,
  } | null,
};

export type GetDepartmentQueryVariables = {
  id: string,
};

export type GetDepartmentQuery = {
  getDepartment?:  {
    __typename: "Department",
    id: string,
    owner?: string | null,
    organizationId?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    draftKnowledgeId?: string | null,
    publishedKnowledgeId?: string | null,
    workerId?: string | null,
    local?: boolean | null,
    state?: string | null,
    errors?: Array< string > | null,
    createdAt?: string | null,
    isDeleted: boolean,
    upgradeCommandId?: string | null,
    notifyProcedureInvocation?: boolean | null,
    features?: Array< DepartmentFeature > | null,
    brainVersion?: string | null,
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    organization?:  {
      __typename: "Organization",
      id: string,
      name: string,
      owner: string,
      createdAt: string,
      updatedAt: string,
    } | null,
    draftKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    publishedKnowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
    learnedAnswers?:  {
      __typename: "LearnedAnswerConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetDepartmentHistoryQueryVariables = {
  departmentId: string,
  entityName?: DepartmentBookEntity | null,
  entityIDs?: Array< string > | null,
  start?: string | null,
  end?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type GetDepartmentHistoryQuery = {
  getDepartmentHistory?:  {
    __typename: "DepartmentHistoryConnection",
    items?:  Array< {
      __typename: "DepartmentHistoryEvent",
      entityId: string,
      user: string,
      operation: string,
      entityName: string,
      timestamp: string,
      name?: string | null,
      rollbackVersion?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListAllDepartmentsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListAllDepartmentsQuery = {
  listAllDepartments?:  {
    __typename: "DepartmentConnection",
    items?:  Array< {
      __typename: "Department",
      id: string,
      owner?: string | null,
      organizationId?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      draftKnowledgeId?: string | null,
      publishedKnowledgeId?: string | null,
      workerId?: string | null,
      local?: boolean | null,
      state?: string | null,
      errors?: Array< string > | null,
      createdAt?: string | null,
      isDeleted: boolean,
      upgradeCommandId?: string | null,
      notifyProcedureInvocation?: boolean | null,
      features?: Array< DepartmentFeature > | null,
      brainVersion?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDepartmentsQueryVariables = {
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentsQuery = {
  listDepartments?:  {
    __typename: "DepartmentConnection",
    items?:  Array< {
      __typename: "Department",
      id: string,
      owner?: string | null,
      organizationId?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      draftKnowledgeId?: string | null,
      publishedKnowledgeId?: string | null,
      workerId?: string | null,
      local?: boolean | null,
      state?: string | null,
      errors?: Array< string > | null,
      createdAt?: string | null,
      isDeleted: boolean,
      upgradeCommandId?: string | null,
      notifyProcedureInvocation?: boolean | null,
      features?: Array< DepartmentFeature > | null,
      brainVersion?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListDepartmentCollaboratorsQueryVariables = {
  departmentId: string,
  role?: AccessControlRole | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentCollaboratorsQuery = {
  listDepartmentCollaborators?:  {
    __typename: "DepartmentUserConnection",
    items?:  Array< {
      __typename: "DepartmentUser",
      id: string,
      userId: string,
      role: AccessControlRole,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetFactQueryVariables = {
  knowledgeId: string,
  factId: string,
  epoch: number,
};

export type GetFactQuery = {
  getFact?:  {
    __typename: "Fact",
    id: string,
    knowledgeId?: string | null,
    knowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    names?: Array< string | null > | null,
    parentFact?:  {
      __typename: "Fact",
      id: string,
      knowledgeId?: string | null,
      names?: Array< string | null > | null,
      parentFactId?: string | null,
      locations?: Array< string > | null,
      value?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      referenceTo?: Array< string | null > | null,
      viewUrl?: string | null,
      childProperties?: string | null,
      isDeleted?: boolean | null,
    } | null,
    parentFactId?: string | null,
    locations?: Array< string > | null,
    relations?:  Array< {
      __typename: "Relation",
      factId?: string | null,
      name?: string | null,
      direction?: string | null,
    } | null > | null,
    value?: string | null,
    descriptions?:  Array< {
      __typename: "Description",
      name?: string | null,
      factId?: string | null,
    } | null > | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    referenceTo?: Array< string | null > | null,
    viewUrl?: string | null,
    childProperties?: string | null,
    isDeleted?: boolean | null,
  } | null,
};

export type GetFactsQueryVariables = {
  knowledgeId: string,
  factIds: Array< string >,
};

export type GetFactsQuery = {
  getFacts:  Array< {
    __typename: "Fact",
    id: string,
    knowledgeId?: string | null,
    knowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    names?: Array< string | null > | null,
    parentFact?:  {
      __typename: "Fact",
      id: string,
      knowledgeId?: string | null,
      names?: Array< string | null > | null,
      parentFactId?: string | null,
      locations?: Array< string > | null,
      value?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      referenceTo?: Array< string | null > | null,
      viewUrl?: string | null,
      childProperties?: string | null,
      isDeleted?: boolean | null,
    } | null,
    parentFactId?: string | null,
    locations?: Array< string > | null,
    relations?:  Array< {
      __typename: "Relation",
      factId?: string | null,
      name?: string | null,
      direction?: string | null,
    } | null > | null,
    value?: string | null,
    descriptions?:  Array< {
      __typename: "Description",
      name?: string | null,
      factId?: string | null,
    } | null > | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    referenceTo?: Array< string | null > | null,
    viewUrl?: string | null,
    childProperties?: string | null,
    isDeleted?: boolean | null,
  } | null >,
};

export type GetHistoricalFactsQueryVariables = {
  knowledgeId: string,
  factIds: Array< HistoricalFactID >,
};

export type GetHistoricalFactsQuery = {
  getHistoricalFacts:  Array< {
    __typename: "Fact",
    id: string,
    knowledgeId?: string | null,
    knowledge?:  {
      __typename: "Knowledge",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      departmentId: string,
      workerId?: string | null,
      parentKnowledgeId?: string | null,
      state?: string | null,
      brainVersion?: string | null,
    } | null,
    names?: Array< string | null > | null,
    parentFact?:  {
      __typename: "Fact",
      id: string,
      knowledgeId?: string | null,
      names?: Array< string | null > | null,
      parentFactId?: string | null,
      locations?: Array< string > | null,
      value?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      referenceTo?: Array< string | null > | null,
      viewUrl?: string | null,
      childProperties?: string | null,
      isDeleted?: boolean | null,
    } | null,
    parentFactId?: string | null,
    locations?: Array< string > | null,
    relations?:  Array< {
      __typename: "Relation",
      factId?: string | null,
      name?: string | null,
      direction?: string | null,
    } | null > | null,
    value?: string | null,
    descriptions?:  Array< {
      __typename: "Description",
      name?: string | null,
      factId?: string | null,
    } | null > | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    referenceTo?: Array< string | null > | null,
    viewUrl?: string | null,
    childProperties?: string | null,
    isDeleted?: boolean | null,
  } | null >,
};

export type GetKnowledgeQueryVariables = {
  id: string,
};

export type GetKnowledgeQuery = {
  getKnowledge?:  {
    __typename: "Knowledge",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    departmentId: string,
    workerId?: string | null,
    parentKnowledgeId?: string | null,
    state?: string | null,
    brainVersion?: string | null,
  } | null,
};

export type GetLearningQueryVariables = {
  id: string,
};

export type GetLearningQuery = {
  getLearning?:  {
    __typename: "Learning",
    id: string,
    procedureId: string,
    departmentId: string,
    type?: string | null,
    inputParams?: string | null,
    questionType?: string | null,
    questionPath?: string | null,
    answerType?: string | null,
    answerText?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    createdAt?: string | null,
    secret?: boolean | null,
  } | null,
};

export type ListLearningsByProcedureQueryVariables = {
  procedureId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLearningsByProcedureQuery = {
  listLearningsByProcedure?:  {
    __typename: "LearningConnection",
    items?:  Array< {
      __typename: "Learning",
      id: string,
      procedureId: string,
      departmentId: string,
      type?: string | null,
      inputParams?: string | null,
      questionType?: string | null,
      questionPath?: string | null,
      answerType?: string | null,
      answerText?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      createdAt?: string | null,
      secret?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListLearningsByDepartmentQueryVariables = {
  departmentId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListLearningsByDepartmentQuery = {
  listLearningsByDepartment?:  {
    __typename: "LearningConnection",
    items?:  Array< {
      __typename: "Learning",
      id: string,
      procedureId: string,
      departmentId: string,
      type?: string | null,
      inputParams?: string | null,
      questionType?: string | null,
      questionPath?: string | null,
      answerType?: string | null,
      answerText?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      createdAt?: string | null,
      secret?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetNotificationQueryVariables = {
  id: string,
};

export type GetNotificationQuery = {
  getNotification?:  {
    __typename: "Notification",
    id: string,
    contextId: number,
    commandId: string,
    workerId: string,
    sentenceId?: number | null,
    info?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    createdAt?: string | null,
  } | null,
};

export type ListNotificationsByCommandQueryVariables = {
  commandId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListNotificationsByCommandQuery = {
  listNotificationsByCommand?:  {
    __typename: "NotificationConnection",
    items?:  Array< {
      __typename: "Notification",
      id: string,
      contextId: number,
      commandId: string,
      workerId: string,
      sentenceId?: number | null,
      info?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      createdAt?: string | null,
    } > | null,
    nextToken?: string | null,
  } | null,
};

export type GetProcedureQueryVariables = {
  procedureId: string,
  knowledgeId?: string | null,
  departmentId?: string | null,
  version?: string | null,
};

export type GetProcedureQuery = {
  getProcedure?:  {
    __typename: "Procedure",
    id: string,
    knowledgeId?: string | null,
    departmentId?: string | null,
    text?: string | null,
    name?: string | null,
    owner?: string | null,
    language?: string | null,
    public: boolean,
    bookReference?: string | null,
    schedules?:  Array< {
      __typename: "Schedule",
      name?: string | null,
      expression?: string | null,
      enabled?: boolean | null,
    } | null > | null,
    email?:  Array< {
      __typename: "StageEmail",
      stage: string,
      email: string,
    } > | null,
    title?: string | null,
    version?: string | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetProcedureByNameQueryVariables = {
  departmentId: string,
  procedureName: string,
  departmentVersion: string,
};

export type GetProcedureByNameQuery = {
  getProcedureByName?:  {
    __typename: "Procedure",
    id: string,
    knowledgeId?: string | null,
    departmentId?: string | null,
    text?: string | null,
    name?: string | null,
    owner?: string | null,
    language?: string | null,
    public: boolean,
    bookReference?: string | null,
    schedules?:  Array< {
      __typename: "Schedule",
      name?: string | null,
      expression?: string | null,
      enabled?: boolean | null,
    } | null > | null,
    email?:  Array< {
      __typename: "StageEmail",
      stage: string,
      email: string,
    } > | null,
    title?: string | null,
    version?: string | null,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type ListProceduresByKnowledgeQueryVariables = {
  knowledgeId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListProceduresByKnowledgeQuery = {
  listProceduresByKnowledge?:  {
    __typename: "ModelProcedureConnection",
    items?:  Array< {
      __typename: "Procedure",
      id: string,
      knowledgeId?: string | null,
      departmentId?: string | null,
      text?: string | null,
      name?: string | null,
      owner?: string | null,
      language?: string | null,
      public: boolean,
      bookReference?: string | null,
      title?: string | null,
      version?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListProceduresByDepartmentQueryVariables = {
  departmentId: string,
  limit?: number | null,
  version?: string | null,
  nextToken?: string | null,
};

export type ListProceduresByDepartmentQuery = {
  listProceduresByDepartment?:  {
    __typename: "ModelProcedureConnection",
    items?:  Array< {
      __typename: "Procedure",
      id: string,
      knowledgeId?: string | null,
      departmentId?: string | null,
      text?: string | null,
      name?: string | null,
      owner?: string | null,
      language?: string | null,
      public: boolean,
      bookReference?: string | null,
      title?: string | null,
      version?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type QuestionsMetricsByDepartmentQueryVariables = {
  departmentId: string,
  language?: string | null,
  duration?: number | null,
  limit?: number | null,
  nextToken?: string | null,
  requestsMetrics?: boolean | null,
};

export type QuestionsMetricsByDepartmentQuery = {
  questionsMetricsByDepartment?:  Array< {
    __typename: "QuestionsMetrics",
    procedureId: string,
    procedureName: string,
    unhandled: number,
    handled: number,
    total: number,
    totalRuns: number,
    procedureTitle?: string | null,
    unhandledRequests?: number | null,
    handledRequests?: number | null,
    totalRequests?: number | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
  } > | null,
};

export type GetQuestionQueryVariables = {
  id: string,
};

export type GetQuestionQuery = {
  getQuestion?:  {
    __typename: "Question",
    id: string,
    commandId: string,
    workerId: string,
    contextId: number,
    sentenceId?: number | null,
    contextPath?:  Array< {
      __typename: "InstructionLocationById",
      ctxId: number,
      sentenceId?: number | null,
    } > | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    type?: string | null,
    text?: string | null,
    path?: string | null,
    procedureId?: string | null,
    validationList?: Array< string | null > | null,
    answerId?: string | null,
    isAnswered: boolean,
    delegationChain?:  Array< {
      __typename: "CommunicationItem",
      method: string,
      recipients: Array< string >,
      parameters: string,
    } > | null,
    createdAt?: string | null,
    rawException?: string | null,
    parentId?: string | null,
    secret?: boolean | null,
    stepPath?: Array< string > | null,
    answerTypeHint?: string | null,
    sourceMetadata?: string | null,
    worker:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    },
    children:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    answer?:  {
      __typename: "Answer",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      commandId?: string | null,
      questionId?: string | null,
      type?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      text?: string | null,
      error?: string | null,
      remember?: boolean | null,
      secret?: boolean | null,
    } | null,
  } | null,
};

export type ListQuestionsByCommandQueryVariables = {
  commandId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListQuestionsByCommandQuery = {
  listQuestionsByCommand?:  {
    __typename: "QuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListQuestionsByProcedureQueryVariables = {
  procedureId: string,
  limit?: number | null,
  nextToken?: string | null,
  isAnsweredFilter?: boolean | null,
};

export type ListQuestionsByProcedureQuery = {
  listQuestionsByProcedure?:  {
    __typename: "QuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListRequestsByProcedureQueryVariables = {
  procedureId: string,
  departmentId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
  state?: RequestState | null,
};

export type ListRequestsByProcedureQuery = {
  listRequestsByProcedure?:  {
    __typename: "RequestConnection",
    items:  Array< {
      __typename: "Request",
      id: string,
      // List of steps that contain this request, starting with the outermost step. Each step is identified by its english text. Similar to breadcrumbs.
      stepPath?: Array< string > | null,
      // The id of the (innermost) context that generated this request.
      contextId?: number | null,
      // The id of the sentence that generated this request.
      sentenceId?: number | null,
      // The time at which this request was created. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
      createdAt?: string | null,
      // The time at which this request was handled. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
      handledAt?: string | null,
      // The state of this request. One of: 'pending', 'handled'.
      state?: string | null,
    } >,
    limit?: number | null,
    nextToken?: string | null,
  } | null,
};

export type QuestionsQueryVariables = {
  filter: QuestionFilter,
};

export type QuestionsQuery = {
  questions?:  {
    __typename: "QuestionConnection",
    items:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type GetServiceCommandQueryVariables = {
  id: string,
};

export type GetServiceCommandQuery = {
  getServiceCommand?:  {
    __typename: "ServiceCommand",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    method?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    params?: string | null,
    result?: string | null,
    error?: string | null,
  } | null,
};

export type ListServiceCommandsByWorkerQueryVariables = {
  workerId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListServiceCommandsByWorkerQuery = {
  listServiceCommandsByWorker?:  {
    __typename: "ServiceCommandConnection",
    items?:  Array< {
      __typename: "ServiceCommand",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      method?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      params?: string | null,
      result?: string | null,
      error?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetWorkItemQueryVariables = {
  id: string,
};

export type GetWorkItemQuery = {
  getWorkItem: ( {
      __typename: "Answer",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      commandId?: string | null,
      questionId?: string | null,
      type?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      text?: string | null,
      error?: string | null,
      remember?: boolean | null,
      secret?: boolean | null,
    } | {
      __typename: "Command",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      text?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      subText?: string | null,
      subTextProperties?: Array< string > | null,
      subTextLanguage?: string | null,
      procedureId?: string | null,
      contextId?: number | null,
      sentenceId?: number | null,
      debug?: boolean | null,
      ast?: string | null,
      state?: string | null,
      output?: string | null,
      source?: string | null,
      sourceMetadata?: string | null,
      positionInContext?: number | null,
      isMultilineText?: boolean | null,
      questions?:  {
        __typename: string,
        nextToken?: string | null,
      } | null,
      answers?:  {
        __typename: string,
        nextToken?: string | null,
      } | null,
      notifications?:  {
        __typename: string,
        nextToken?: string | null,
      } | null,
    } | {
      __typename: "ServiceCommand",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      method?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      params?: string | null,
      result?: string | null,
      error?: string | null,
    } | {
      __typename: "WakeupCall",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      scheduledTime?: string | null,
    } | {
      __typename: "HandledRequest",
      id: string,
      workerId: string,
      createdAt?: string | null,
      forwardedAt?: Array< string > | null,
      invokedAt?: Array< string > | null,
      receivedAt?: Array< string > | null,
      processingAt?: Array< string > | null,
      processedAt?: Array< string > | null,
      failedAt?: Array< string > | null,
      workStatus?: string | null,
      workErrors?: Array< string > | null,
      requestId?: string | null,
    }
  ) | null,
};

export type GetWorkerQueryVariables = {
  id: string,
};

export type GetWorkerQuery = {
  getWorker?:  {
    __typename: "Worker",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    version?: string | null,
    departmentVersion?: string | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
    procedureId?: string | null,
    entryCommand?: string | null,
    entryCommandId?: string | null,
    originalWorkerId?: string | null,
    createdAt?: string | null,
    state?: string | null,
    stateLastUpdatedAt?: string | null,
    status?: string | null,
    statusLastUpdatedAt?: string | null,
    attachments?: Array< string > | null,
    isArchived?: boolean | null,
    exceptions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    questions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type GetWorkerOutputFactsQueryVariables = {
  id: string,
};

export type GetWorkerOutputFactsQuery = {
  getWorkerOutputFacts?:  Array< {
    __typename: "WorkerOutput",
    name: string,
    facts:  Array< {
      __typename: "Fact",
      id: string,
      knowledgeId?: string | null,
      names?: Array< string | null > | null,
      parentFactId?: string | null,
      locations?: Array< string > | null,
      value?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      referenceTo?: Array< string | null > | null,
      viewUrl?: string | null,
      childProperties?: string | null,
      isDeleted?: boolean | null,
    } | null >,
  } | null > | null,
};

export type ListDepartmentWorkerFactsQueryVariables = {
  knowledgeId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentWorkerFactsQuery = {
  listDepartmentWorkerFacts?:  {
    __typename: "DepartmentWorkerFactConnection",
    items?:  Array< {
      __typename: "Fact",
      id: string,
      knowledgeId?: string | null,
      names?: Array< string | null > | null,
      parentFactId?: string | null,
      locations?: Array< string > | null,
      value?: string | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      referenceTo?: Array< string | null > | null,
      viewUrl?: string | null,
      childProperties?: string | null,
      isDeleted?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListWorkerOutputsByProcedureQueryVariables = {
  procedureId: string,
  knowledgeId?: string | null,
  page?: number | null,
  pageSize?: number | null,
  decodeConcepts?: boolean | null,
  departmentId?: string | null,
  fromDate?: string | null,
  toDate?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListWorkerOutputsByProcedureQuery = {
  listWorkerOutputsByProcedure?:  {
    __typename: "ProcedureOutputTable",
    columns?: Array< string > | null,
    data?: string | null,
    nextToken?: string | null,
  } | null,
};

export type ListWorkersByProcedureQueryVariables = {
  procedureId?: string | null,
  limit?: number | null,
  nextToken?: string | null,
  stateFilter?: string | null,
  fromCreationDate?: string | null,
  toCreationDate?: string | null,
};

export type ListWorkersByProcedureQuery = {
  listWorkersByProcedure?:  {
    __typename: "WorkerConnection",
    items?:  Array< {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ListFactsByProcedureQueryVariables = {
  workerIds: Array< string >,
  contextId: string,
  stepId: string,
};

export type ListFactsByProcedureQuery = {
  listFactsByProcedure?:  {
    __typename: "ProcedureFactTable",
    columns?: Array< string > | null,
    data?: string | null,
  } | null,
};

export type GetStepPathForRunQueryVariables = {
  workerId: string,
  contextId: string,
  stepId: string,
};

export type GetStepPathForRunQuery = {
  getStepPathForRun: Array< string >,
};

export type GetFactsAtStepPathQueryVariables = {
  workerId: string,
  stepPath: Array< string >,
};

export type GetFactsAtStepPathQuery = {
  getFactsAtStepPath:  {
    __typename: "StepPathFactTable",
    columns?: Array< string > | null,
    data?: string | null,
  },
};

export type ListWorkersByUserQueryVariables = {
  userId: string,
  limit?: number | null,
  nextToken?: string | null,
  fromCreationDate?: string | null,
  toCreationDate?: string | null,
};

export type ListWorkersByUserQuery = {
  listWorkersByUser?:  {
    __typename: "WorkerConnection",
    items?:  Array< {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type ParseAlangTextQueryVariables = {
  statement: string,
  scopeCode: string,
  language: string,
  departmentId: string,
  version?: string | null,
};

export type ParseAlangTextQuery = {
  parseAlangText?:  {
    __typename: "AlangParser",
    ast?: string | null,
    text?: string | null,
    error?: string | null,
  } | null,
};

export type GetUploadToS3UrlQueryVariables = {
  scope: S3FileScope,
  scopeId: string,
  filename: string,
  fields?: string | null,
  expiresIn?: number | null,
};

export type GetUploadToS3UrlQuery = {
  getUploadToS3Url:  {
    __typename: "S3PresignedPost",
    url: string,
    s3Url: string,
    fields: string,
  },
};

export type GetDownloadFromS3UrlQueryVariables = {
  objectKey: string,
  expiresIn?: number | null,
};

export type GetDownloadFromS3UrlQuery = {
  getDownloadFromS3Url:  {
    __typename: "S3PresignedUrl",
    url: string,
    s3Url: string,
  },
};

export type AutoCompleteCommandQueryVariables = {
  text: string,
};

export type AutoCompleteCommandQuery = {
  autoCompleteCommand: Array< string >,
};

export type GenerateOpenAICompletionsQueryVariables = {
  prompt: string,
};

export type GenerateOpenAICompletionsQuery = {
  generateOpenAICompletions: Array< string >,
};

export type GetRequestQueryVariables = {
  id: string,
};

export type GetRequestQuery = {
  getRequest?:  {
    __typename: "Request",
    id: string,
    // List of steps that contain this request, starting with the outermost step. Each step is identified by its english text. Similar to breadcrumbs.
    stepPath?: Array< string > | null,
    // The id of the (innermost) context that generated this request.
    contextId?: number | null,
    // The id of the sentence that generated this request.
    sentenceId?: number | null,
    // The time at which this request was created. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
    createdAt?: string | null,
    // The time at which this request was handled. In ISO format (ex 2022-11-21T22:12:39.692815+00:00).
    handledAt?: string | null,
    // The state of this request. One of: 'pending', 'handled'.
    state?: string | null,
    // The worker that made the request.
    worker?:  {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null,
    // List of questions in this request.
    questions:  Array<( {
        __typename: "EnsureFailedQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
      } | {
        __typename: "NativeCodeErrorQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        errorType?: string | null,
        errorMessage?: string | null,
        errorTraceback?: string | null,
      } | {
        __typename: "ParseErrorQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        errorTraceback?: string | null,
      } | {
        __typename: "ProcedureNotKnownQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
      } | {
        __typename: "ReviewConceptQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        // English lexical path to the concept.
        lexicalPath?: Array< string > | null,
        conceptId?: string | null,
      } | {
        __typename: "ValueNotKnownQuestion",
        id: string,
        text?: string | null,
        contextId?: number | null,
        sentenceId?: number | null,
        answerTypeHint?: string | null,
        failedAnswerId?: string | null,
        failedAnswerReason?: string | null,
        // English lexical path to the concept.
        lexicalPath?: Array< string > | null,
        choices?: Array< string > | null,
        helpText?: string | null,
      }
    ) > | null,
    // List of suggested answers for this request.
    suggestedAnswers?:  Array< {
      __typename: "RequestAnswerSuggestion",
      id: string,
      // The id of the request that this answer belongs to
      requestId: string,
      // The id of the question that this answer is in response to, if any.
      questionId?: string | null,
    } > | null,
    // List of answers created in response to this request.
    answers?:  Array< {
      __typename: "RequestAnswer",
      id: string,
      // The id of the request that this answer belongs to
      requestId: string,
      // The id of the question that this answer is in response to, if any.
      questionId?: string | null,
      // The time that this answer was created.
      createdAt?: string | null,
      // The id of the user that created this answer.
      createdBy?: string | null,
    } > | null,
    // List of instruction location were the request was created.
    contextPath?:  Array< {
      __typename: "InstructionLocationById",
      ctxId: number,
      sentenceId?: number | null,
    } > | null,
  } | null,
};

export type GetRequestQuestionQueryVariables = {
  id: string,
};

export type GetRequestQuestionQuery = {
  getRequestQuestion: ( {
      __typename: "EnsureFailedQuestion",
      id: string,
      text?: string | null,
      // The answers that have been applied to and failed to resolve previous identical questions.
      failedAnswers?:  Array< {
        __typename: "FailedAnswer",
        // Reason that the earlier question failed to resolve.
        message?: string | null,
      } > | null,
      contextId?: number | null,
      sentenceId?: number | null,
      answerTypeHint?: string | null,
      failedAnswerId?: string | null,
      failedAnswerReason?: string | null,
    } | {
      __typename: "NativeCodeErrorQuestion",
      id: string,
      text?: string | null,
      // The answers that have been applied to and failed to resolve previous identical questions.
      failedAnswers?:  Array< {
        __typename: string,
        // Reason that the earlier question failed to resolve.
        message?: string | null,
      } > | null,
      contextId?: number | null,
      sentenceId?: number | null,
      answerTypeHint?: string | null,
      failedAnswerId?: string | null,
      failedAnswerReason?: string | null,
      errorType?: string | null,
      errorMessage?: string | null,
      errorTraceback?: string | null,
    } | {
      __typename: "ParseErrorQuestion",
      id: string,
      text?: string | null,
      // The answers that have been applied to and failed to resolve previous identical questions.
      failedAnswers?:  Array< {
        __typename: string,
        // Reason that the earlier question failed to resolve.
        message?: string | null,
      } > | null,
      contextId?: number | null,
      sentenceId?: number | null,
      answerTypeHint?: string | null,
      failedAnswerId?: string | null,
      failedAnswerReason?: string | null,
      errorTraceback?: string | null,
    } | {
      __typename: "ProcedureNotKnownQuestion",
      id: string,
      text?: string | null,
      // The answers that have been applied to and failed to resolve previous identical questions.
      failedAnswers?:  Array< {
        __typename: "FailedAnswer",
        // Reason that the earlier question failed to resolve.
        message?: string | null,
      } > | null,
      contextId?: number | null,
      sentenceId?: number | null,
      answerTypeHint?: string | null,
      failedAnswerId?: string | null,
      failedAnswerReason?: string | null,
    } | {
      __typename: "ReviewConceptQuestion",
      id: string,
      text?: string | null,
      // The answers that have been applied to and failed to resolve previous identical questions.
      failedAnswers?:  Array< {
        __typename: string,
        // Reason that the earlier question failed to resolve.
        message?: string | null,
      } > | null,
      contextId?: number | null,
      sentenceId?: number | null,
      answerTypeHint?: string | null,
      failedAnswerId?: string | null,
      failedAnswerReason?: string | null,
      // English lexical path to the concept.
      lexicalPath?: Array< string > | null,
      conceptId?: string | null,
    } | {
      __typename: "ValueNotKnownQuestion",
      id: string,
      text?: string | null,
      // The answers that have been applied to and failed to resolve previous identical questions.
      failedAnswers?:  Array< {
        __typename: string,
        // Reason that the earlier question failed to resolve.
        message?: string | null,
      } > | null,
      contextId?: number | null,
      sentenceId?: number | null,
      answerTypeHint?: string | null,
      failedAnswerId?: string | null,
      failedAnswerReason?: string | null,
      // English lexical path to the concept.
      lexicalPath?: Array< string > | null,
      choices?: Array< string > | null,
      helpText?: string | null,
    }
  ) | null,
};

export type GetRequestAnswerQueryVariables = {
  id: string,
};

export type GetRequestAnswerQuery = {
  getRequestAnswer?:  {
    __typename: "RequestAnswer",
    id: string,
    // The id of the request that this answer belongs to
    requestId: string,
    // The id of the question that this answer is in response to, if any.
    questionId?: string | null,
    // The action to take to resolve the question.
    action: ( {
        __typename: "ApproveReviewAction",
        _?: string | null,
      } | {
        __typename: "ReplaceStepAction",
        _?: string | null,
        newSteps?: Array< string > | null,
      } | {
        __typename: "RetryStepAction",
        _?: string | null,
        minimumDelay: number,
        timeout?: number | null,
      } | {
        __typename: "SkipStepAction",
        _?: string | null,
      } | {
        __typename: "SkipToEndOfRunAction",
        _?: string | null,
      } | {
        __typename: "UseConceptAction",
        _?: string | null,
        conceptId?: string | null,
      } | {
        __typename: "UseLiteralValueAction",
        _?: string | null,
        literalValue?: string | null,
        choices?: Array< string > | null,
      } | {
        __typename: "UseNoValueAction",
        _?: string | null,
      } | {
        __typename: "UseTechniqueAction",
        _?: string | null,
        technique?: string | null,
      }
    ) | null,
    // The learning that was used to generate this answer, if any.
    sourceLearning?:  {
      __typename: "LearnedAnswer",
      id: string,
      // The time that this answer was created.
      createdAt?: string | null,
      // The id of the user that created this answer.
      createdBy?: string | null,
      // The time that this answer was deleted.
      deletedAt?: string | null,
      // The id of the user that deleted this answer.
      deletedBy?: string | null,
      // The id of the procedure that this learning belongs to.
      procedureId?: string | null,
      // The name of the procedure that this learning belongs to.
      procedureName?: string | null,
    } | null,
    // The time that this answer was created.
    createdAt?: string | null,
    // The id of the user that created this answer.
    createdBy?: string | null,
  } | null,
};

export type GetLearnedAnswerQueryVariables = {
  id: string,
};

export type GetLearnedAnswerQuery = {
  getLearnedAnswer?:  {
    __typename: "LearnedAnswer",
    id: string,
    // The filter that determines which questions this learning applies to.
    questionFilter?:  {
      __typename: "RequestQuestionFilter",
      stepPath?: Array< string | null > | null,
      // The type of question that this learning applies to. Example 'ValueNotKnownQuestion'.
      questionType?: string | null,
      // The lexical path that this learning applies to.
      lexicalPath?: Array< string | null > | null,
    } | null,
    // The action to take to resolve the question.
    action: ( {
        __typename: "ApproveReviewAction",
        _?: string | null,
      } | {
        __typename: "ReplaceStepAction",
        _?: string | null,
        newSteps?: Array< string > | null,
      } | {
        __typename: "RetryStepAction",
        _?: string | null,
        minimumDelay: number,
        timeout?: number | null,
      } | {
        __typename: "SkipStepAction",
        _?: string | null,
      } | {
        __typename: "SkipToEndOfRunAction",
        _?: string | null,
      } | {
        __typename: "UseConceptAction",
        _?: string | null,
        conceptId?: string | null,
      } | {
        __typename: "UseLiteralValueAction",
        _?: string | null,
        literalValue?: string | null,
        choices?: Array< string > | null,
      } | {
        __typename: "UseNoValueAction",
        _?: string | null,
      } | {
        __typename: "UseTechniqueAction",
        _?: string | null,
        technique?: string | null,
      }
    ) | null,
    // The time that this answer was created.
    createdAt?: string | null,
    // The id of the user that created this answer.
    createdBy?: string | null,
    // The time that this answer was deleted.
    deletedAt?: string | null,
    // The id of the user that deleted this answer.
    deletedBy?: string | null,
    // The id of the procedure that this learning belongs to.
    procedureId?: string | null,
    // The name of the procedure that this learning belongs to.
    procedureName?: string | null,
    // The procedure that this learning belongs to.
    procedure?:  {
      __typename: "Procedure",
      id: string,
      knowledgeId?: string | null,
      departmentId?: string | null,
      text?: string | null,
      name?: string | null,
      owner?: string | null,
      language?: string | null,
      public: boolean,
      bookReference?: string | null,
      title?: string | null,
      version?: string | null,
    } | null,
  } | null,
};

export type GetLearnedAnswerByRequestAnswerIdQueryVariables = {
  requestAnswerId: string,
};

export type GetLearnedAnswerByRequestAnswerIdQuery = {
  getLearnedAnswerByRequestAnswerId?:  {
    __typename: "LearnedAnswer",
    id: string,
    // The filter that determines which questions this learning applies to.
    questionFilter?:  {
      __typename: "RequestQuestionFilter",
      stepPath?: Array< string | null > | null,
      // The type of question that this learning applies to. Example 'ValueNotKnownQuestion'.
      questionType?: string | null,
      // The lexical path that this learning applies to.
      lexicalPath?: Array< string | null > | null,
    } | null,
    // The action to take to resolve the question.
    action: ( {
        __typename: "ApproveReviewAction",
        _?: string | null,
      } | {
        __typename: "ReplaceStepAction",
        _?: string | null,
        newSteps?: Array< string > | null,
      } | {
        __typename: "RetryStepAction",
        _?: string | null,
        minimumDelay: number,
        timeout?: number | null,
      } | {
        __typename: "SkipStepAction",
        _?: string | null,
      } | {
        __typename: "SkipToEndOfRunAction",
        _?: string | null,
      } | {
        __typename: "UseConceptAction",
        _?: string | null,
        conceptId?: string | null,
      } | {
        __typename: "UseLiteralValueAction",
        _?: string | null,
        literalValue?: string | null,
        choices?: Array< string > | null,
      } | {
        __typename: "UseNoValueAction",
        _?: string | null,
      } | {
        __typename: "UseTechniqueAction",
        _?: string | null,
        technique?: string | null,
      }
    ) | null,
    // The time that this answer was created.
    createdAt?: string | null,
    // The id of the user that created this answer.
    createdBy?: string | null,
    // The time that this answer was deleted.
    deletedAt?: string | null,
    // The id of the user that deleted this answer.
    deletedBy?: string | null,
    // The id of the procedure that this learning belongs to.
    procedureId?: string | null,
    // The name of the procedure that this learning belongs to.
    procedureName?: string | null,
    // The procedure that this learning belongs to.
    procedure?:  {
      __typename: "Procedure",
      id: string,
      knowledgeId?: string | null,
      departmentId?: string | null,
      text?: string | null,
      name?: string | null,
      owner?: string | null,
      language?: string | null,
      public: boolean,
      bookReference?: string | null,
      title?: string | null,
      version?: string | null,
    } | null,
  } | null,
};

export type ListDepartmentFactsQueryVariables = {
  departmentId: string,
  version?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListDepartmentFactsQuery = {
  listDepartmentFacts?:  {
    __typename: "DepartmentFactConnection",
    items?:  Array< {
      __typename: "DepartmentFact",
      id: string,
      departmentId: string,
      name: string,
      values: Array< string >,
      version?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetDepartmentFactQueryVariables = {
  id: string,
  departmentId: string,
  version?: string | null,
};

export type GetDepartmentFactQuery = {
  getDepartmentFact?:  {
    __typename: "DepartmentFact",
    id: string,
    departmentId: string,
    name: string,
    values: Array< string >,
    version?: string | null,
  } | null,
};

export type GetValueDownloadUrlQueryVariables = {
  value: string,
  departmentId: string,
  workerId?: string | null,
  secret: boolean,
  disposition?: string | null,
};

export type GetValueDownloadUrlQuery = {
  // 
  // Returns a URL that can be used to download a value from the system.
  // The URL is valid for 5 minutes.
  // 
  // value: The serialized value as a json string
  // departmentId: The id of the department that the value belongs to
  // workerId: The id of the worker that the value belongs to
  // secret: If the value is a secret value
  // disposition: Sets the Content-Disposition header of the response, can be "inline" or "attachment"
  // 
  getValueDownloadUrl?: string | null,
};

export type RevealSecretValueQueryVariables = {
  value: string,
  departmentId: string,
  workerId?: string | null,
};

export type RevealSecretValueQuery = {
  revealSecretValue?: string | null,
};

export type ListSharedEntityByProcedureQueryVariables = {
  departmentId: string,
  procedureId: string,
  version?: string | null,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListSharedEntityByProcedureQuery = {
  listSharedEntityByProcedure?:  {
    __typename: "SharedEntityConnection",
    items?:  Array< {
      __typename: "SharedEntity",
      id: string,
      entityId: string,
      departmentId: string,
      shareName: string,
      fullShareId?: string | null,
      departmentVersion?: string | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type GetSharedEntityQueryVariables = {
  id: string,
};

export type GetSharedEntityQuery = {
  getSharedEntity?:  {
    __typename: "SharedEntity",
    id: string,
    entityId: string,
    departmentId: string,
    shareName: string,
    fullShareId?: string | null,
    departmentVersion?: string | null,
  } | null,
};

export type ExportDepartmentQueryVariables = {
  departmentId: string,
  version?: string | null,
};

export type ExportDepartmentQuery = {
  exportDepartment?:  {
    __typename: "DepartmentExport",
    formatVersion: number,
    departmentID: string,
    departmentVersion?: string | null,
    checksum?: string | null,
    features?: Array< DepartmentFeature | null > | null,
    procedures:  Array< {
      __typename: "ProcedureExport",
      name?: string | null,
      text?: string | null,
      language?: string | null,
    } | null >,
    facts:  Array< {
      __typename: "FactExport",
      name: string,
      values: Array< string >,
    } | null >,
    learnedBooks?:  {
      __typename: "LearnedBookExport",
      ids: Array< string | null >,
    } | null,
  } | null,
};

export type ListKChatMessagesByWorkerQueryVariables = {
  workerId: string,
  limit?: number | null,
  nextToken?: string | null,
};

export type ListKChatMessagesByWorkerQuery = {
  listKChatMessagesByWorker?:  {
    __typename: "ChatMessageConnection",
    items:  Array< {
      __typename: "ChatMessage",
      id: string,
      departmentId: string,
      workerId: string,
      message: string,
      timestamp: string,
      displayName?: string | null,
      role?: string | null,
    } >,
    nextToken?: string | null,
  } | null,
};

export type ListWorkersByDepartmentQueryVariables = {
  departmentId: string,
  limit?: number | null,
  nextToken?: string | null,
  fromCreationDate?: string | null,
  toCreationDate?: string | null,
};

export type ListWorkersByDepartmentQuery = {
  listWorkersByDepartment?:  {
    __typename: "WorkerConnection",
    items?:  Array< {
      __typename: "Worker",
      id: string,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      version?: string | null,
      departmentVersion?: string | null,
      knowledgeId?: string | null,
      departmentId?: string | null,
      procedureId?: string | null,
      entryCommand?: string | null,
      entryCommandId?: string | null,
      originalWorkerId?: string | null,
      createdAt?: string | null,
      state?: string | null,
      stateLastUpdatedAt?: string | null,
      status?: string | null,
      statusLastUpdatedAt?: string | null,
      attachments?: Array< string > | null,
      isArchived?: boolean | null,
    } | null > | null,
    nextToken?: string | null,
  } | null,
};

export type OnCreateCommandByWorkerIdSubscriptionVariables = {
  workerId: string,
};

export type OnCreateCommandByWorkerIdSubscription = {
  onCreateCommandByWorkerId?:  {
    __typename: "Command",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    text?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    subText?: string | null,
    subTextProperties?: Array< string > | null,
    subTextLanguage?: string | null,
    procedureId?: string | null,
    contextId?: number | null,
    sentenceId?: number | null,
    debug?: boolean | null,
    ast?: string | null,
    state?: string | null,
    output?: string | null,
    source?: string | null,
    sourceMetadata?: string | null,
    positionInContext?: number | null,
    isMultilineText?: boolean | null,
    questions?:  {
      __typename: "QuestionConnection",
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "AnswerConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "NotificationConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnUpdateCommandByWorkerIdSubscriptionVariables = {
  workerId: string,
};

export type OnUpdateCommandByWorkerIdSubscription = {
  onUpdateCommandByWorkerId?:  {
    __typename: "Command",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    text?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    subText?: string | null,
    subTextProperties?: Array< string > | null,
    subTextLanguage?: string | null,
    procedureId?: string | null,
    contextId?: number | null,
    sentenceId?: number | null,
    debug?: boolean | null,
    ast?: string | null,
    state?: string | null,
    output?: string | null,
    source?: string | null,
    sourceMetadata?: string | null,
    positionInContext?: number | null,
    isMultilineText?: boolean | null,
    questions?:  {
      __typename: "QuestionConnection",
      nextToken?: string | null,
    } | null,
    answers?:  {
      __typename: "AnswerConnection",
      nextToken?: string | null,
    } | null,
    notifications?:  {
      __typename: "NotificationConnection",
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnCreateNotificationByWorkerIdSubscriptionVariables = {
  workerId: string,
};

export type OnCreateNotificationByWorkerIdSubscription = {
  onCreateNotificationByWorkerId?:  {
    __typename: "Notification",
    id: string,
    contextId: number,
    commandId: string,
    workerId: string,
    sentenceId?: number | null,
    info?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    createdAt?: string | null,
  } | null,
};

export type OnCreateAnswerByWorkerIdSubscriptionVariables = {
  workerId: string,
};

export type OnCreateAnswerByWorkerIdSubscription = {
  onCreateAnswerByWorkerId?:  {
    __typename: "Answer",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    commandId?: string | null,
    questionId?: string | null,
    type?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    text?: string | null,
    error?: string | null,
    remember?: boolean | null,
    secret?: boolean | null,
  } | null,
};

export type OnCreateServiceCommandByWorkerSubscriptionVariables = {
  workerId: string,
};

export type OnCreateServiceCommandByWorkerSubscription = {
  onCreateServiceCommandByWorker?:  {
    __typename: "ServiceCommand",
    id: string,
    workerId: string,
    createdAt?: string | null,
    forwardedAt?: Array< string > | null,
    invokedAt?: Array< string > | null,
    receivedAt?: Array< string > | null,
    processingAt?: Array< string > | null,
    processedAt?: Array< string > | null,
    failedAt?: Array< string > | null,
    workStatus?: string | null,
    workErrors?: Array< string > | null,
    method?: string | null,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    params?: string | null,
    result?: string | null,
    error?: string | null,
  } | null,
};

export type OnWorkerStatusSubscriptionVariables = {
  id: string,
};

export type OnWorkerStatusSubscription = {
  onWorkerStatus?:  {
    __typename: "WorkerStatus",
    id: string,
    mood: string,
    operation: string,
    icons: Array< string >,
    what: string,
  } | null,
};

export type OnInvokeLocalWorkerSubscriptionVariables = {
  departmentId: string,
};

export type OnInvokeLocalWorkerSubscription = {
  onInvokeLocalWorker?:  {
    __typename: "WorkerInvocation",
    departmentId: string,
    event: string,
  } | null,
};

export type OnNotifyWorkerUpdateSubscriptionVariables = {
  id: string,
};

export type OnNotifyWorkerUpdateSubscription = {
  // Notify on completion of worker's knowledge creation
  onNotifyWorkerUpdate?:  {
    __typename: "Worker",
    id: string,
    owner?: string | null,
    readers?: Array< string | null > | null,
    editors?: Array< string | null > | null,
    name?: string | null,
    description?: string | null,
    version?: string | null,
    departmentVersion?: string | null,
    knowledgeId?: string | null,
    departmentId?: string | null,
    procedureId?: string | null,
    entryCommand?: string | null,
    entryCommandId?: string | null,
    originalWorkerId?: string | null,
    createdAt?: string | null,
    state?: string | null,
    stateLastUpdatedAt?: string | null,
    status?: string | null,
    statusLastUpdatedAt?: string | null,
    attachments?: Array< string > | null,
    isArchived?: boolean | null,
    exceptions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    questions:  Array< {
      __typename: "Question",
      id: string,
      commandId: string,
      workerId: string,
      contextId: number,
      sentenceId?: number | null,
      owner?: string | null,
      readers?: Array< string | null > | null,
      editors?: Array< string | null > | null,
      name?: string | null,
      description?: string | null,
      type?: string | null,
      text?: string | null,
      path?: string | null,
      procedureId?: string | null,
      validationList?: Array< string | null > | null,
      answerId?: string | null,
      isAnswered: boolean,
      createdAt?: string | null,
      rawException?: string | null,
      parentId?: string | null,
      secret?: boolean | null,
      stepPath?: Array< string > | null,
      answerTypeHint?: string | null,
      sourceMetadata?: string | null,
    } >,
    requests?:  {
      __typename: "RequestConnection",
      limit?: number | null,
      nextToken?: string | null,
    } | null,
  } | null,
};

export type OnStepsUpdatedSubscriptionVariables = {
  workerId: string,
};

export type OnStepsUpdatedSubscription = {
  onStepsUpdated?:  {
    __typename: "Steps",
    workerId: string,
    stepList?:  Array< {
      __typename: "Step",
      id: string,
      contextId: string,
      workerId: string,
      number?: number | null,
      commandId?: string | null,
      createdAt?: string | null,
      scratchedAt?: string | null,
      text?: string | null,
      answer?: string | null,
      childContextIdList?: Array< string | null > | null,
      concepts?: string | null,
      questionIds?: Array< string > | null,
      requestIds?: Array< string > | null,
      positionInContext?: number | null,
      epoch?: number | null,
    } | null > | null,
  } | null,
};

export type OnCreateSandboxProxyRequestSubscriptionVariables = {
  departmentId: string,
  proxyId: string,
};

export type OnCreateSandboxProxyRequestSubscription = {
  onCreateSandboxProxyRequest?:  {
    __typename: "SandboxProxyMessage",
    departmentId: string,
    workerId: string,
    proxyId: string,
    requestId: string,
    request?: string | null,
    response?: string | null,
  } | null,
};

export type OnCreateSandboxProxyResponseSubscriptionVariables = {
  departmentId: string,
  workerId: string,
};

export type OnCreateSandboxProxyResponseSubscription = {
  onCreateSandboxProxyResponse?:  {
    __typename: "SandboxProxyMessage",
    departmentId: string,
    workerId: string,
    proxyId: string,
    requestId: string,
    request?: string | null,
    response?: string | null,
  } | null,
};

export type OnNotifyKChatMessageUpdateSubscriptionVariables = {
  workerId: string,
  departmentId: string,
};

export type OnNotifyKChatMessageUpdateSubscription = {
  onNotifyKChatMessageUpdate?:  {
    __typename: "ChatMessage",
    id: string,
    departmentId: string,
    workerId: string,
    message: string,
    timestamp: string,
    displayName?: string | null,
    role?: string | null,
  } | null,
};
