import { graphql } from 'msw';

let timeoutCount = 0;

export const handlers = [
  graphql.query('ListProceduresByKnowledge', (req, res, ctx) =>
    // eslint-disable-next-line global-require
    res(ctx.data(require('./data/procedures.json')))
  ),
  graphql.mutation('CreateProcedure', (req, res, ctx) => {
    const { input } = req.variables;
    // console.log(input);
    if (input.text === 'timeout' && timeoutCount < 3) {
      timeoutCount += 1;
      return res(
        ctx.errors([
          {
            path: ['createProcedure'],
            data: null,
            errorType: 'ExecutionTimeout',
            errorInfo: null,
            locations: [
              {
                line: 2,
                column: 3,
                sourceName: null
              }
            ],
            message: 'Execution timed out.'
          }
        ])
      );
    }
    timeoutCount = 0;
    return res(
      ctx.data({
        createProcedure: null
      })
    );
  })
];
