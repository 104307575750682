import { useState } from 'react';

const useForceRerender = () => {
  const [count, _setCount] = useState(0);
  // useEffect(() => {
  //   const interval = setInterval(() => {
  //     setCount((prevCount) => {
  //       const newCount = prevCount + 1;
  //       return newCount;
  //     });
  //   }, 5000);

  //   return () => clearInterval(interval);
  // }, []);

  return { refetchDeps: count };
};

export default useForceRerender;
