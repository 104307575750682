import { hideDrawer } from '@/stores/slices/appDrawer';
import { useLazyQuery, useMutation } from '@apollo/client';
import { message } from 'antd';
import { useAppDispatch } from '@/stores/hooks';
import Queries from '@/graphql/Queries';
import {
  CreateServiceCommandMutation,
  CreateServiceCommandMutationVariables,
  GetServiceCommandQuery,
  Step,
  Worker
} from '../generated/API';
import AppConstants from '../utils/AppConstants';

interface IProps {
  step?: Step;
  worker?: Worker;
}

function useStepFactEdit(props: IProps) {
  const { step, worker } = props;

  const dispatch = useAppDispatch();

  const [createDebugCommand] = useMutation<CreateServiceCommandMutation>(
    AppConstants.APIS.SERVICE_COMMANDS.CREATE()
  );

  const [getServiceCommand] = useLazyQuery<GetServiceCommandQuery>(
    Queries.ServiceCommand()
  );

  const makeDebugRequest = (
    method: string,
    params: any,
    worker: Worker,
    onSuccess: (response: any) => void,
    onError: (error: any) => void
  ) => {
    const variables: CreateServiceCommandMutationVariables = {
      input: {
        owner: worker.owner,
        workerId: worker.id || '',
        method,
        params: JSON.stringify(params)
      }
    };

    createDebugCommand({
      variables
    })
      .then(async (response) => {
        const id = response.data?.createServiceCommand?.id;

        let timer: any = null;

        await Promise.race([
          new Promise((resolve) => {
            timer = setInterval(async () => {
              const command = await getServiceCommand({
                variables: {
                  id
                }
              });
              if (command.data?.getServiceCommand?.result) {
                clearInterval(timer);
                resolve(command);
              }
            }, 2000);
          }),
          new Promise((_, reject) => {
            setTimeout(() => {
              clearInterval(timer);
              message.error('some error occurred while updating fact');
              reject(
                new Error(
                  'Command not received by brain, May be you are using old department'
                )
              );
            }, AppConstants.APIS.SERVICE_COMMANDS.STOP_SERVICE_COMMAND_POLLING_DURATION);
          })
        ]);

        onSuccess(response);
      })
      .catch((e) => {
        onError(e);
      });
  };

  const createFact = (name: string, values: string[]) => {
    const isSingleValue = values.length === 1;
    return new Promise((resolve, reject) => {
      if (step && worker) {
        let params: any = {
          location_dict: {
            ctx_id: Number(step.contextId),
            sentence_index: Number(step.positionInContext)
          },
          fact_name: ['the', name],
          fact_type: 'string'
        };
        if (isSingleValue) {
          params = { ...params, fact_value: values[0] };
        } else {
          params = { ...params, fact_values: values };
        }

        const serviceCommand = isSingleValue
          ? 'create_fact'
          : 'create_fact_with_multiple_values';

        makeDebugRequest(
          serviceCommand,
          params,
          worker,
          (resp) => {
            message.success(`fact will be created soon`);
            resolve(resp);
          },
          reject
        );
      } else {
        reject();
      }
    });
  };

  const insertFactValue = (factId: string, index: number, value: string) => {
    return new Promise((resolve, reject) => {
      if (step && worker) {
        const params: any = {
          location_dict: {
            ctx_id: Number(step.contextId),
            sentence_index: Number(step.positionInContext)
          },
          fact_id: factId,
          insert_index: index,
          new_value: value,
          new_type: 'string'
        };

        makeDebugRequest(
          'insert_fact_with_multiple_values_at',
          params,
          worker,
          (resp) => {
            message.success(`fact will be added soon`);
            resolve(resp);
          },
          reject
        );
      } else {
        reject();
      }
    });
  };

  const deleteFactWithMultipleValue = (factId: string, index: number) => {
    return new Promise((resolve, reject) => {
      if (step && worker) {
        const params: any = {
          location_dict: {
            ctx_id: Number(step.contextId),
            sentence_index: Number(step.positionInContext)
          },
          fact_id: factId,
          delete_index: index
        };

        makeDebugRequest(
          'delete_fact_with_multiple_values_at',
          params,
          worker,
          (resp) => {
            message.success(`fact will be deleted soon`);
            resolve(resp);
          },
          reject
        );
      } else {
        reject();
      }
    });
  };

  const deleteFact = (factId: string) => {
    return new Promise((resolve, reject) => {
      if (step && worker) {
        const params: any = {
          fact_id: factId
        };

        makeDebugRequest(
          'delete_fact_by_id',
          params,
          worker,
          (resp) => {
            message.success(`fact will be deleted soon`);
            dispatch(hideDrawer());
            resolve(resp);
          },
          reject
        );
      } else {
        reject();
      }
    });
  };

  const updateFactWithMultipleValues = (
    factId: string,
    index: number,
    value: string
  ) => {
    return new Promise((resolve, reject) => {
      if (step && worker) {
        const params: any = {
          location_dict: {
            ctx_id: Number(step.contextId),
            sentence_index: Number(step.positionInContext)
          },
          fact_id: factId,
          update_index: index,
          new_value: value,
          new_type: 'string'
        };

        makeDebugRequest(
          'update_fact_with_multiple_values_at',
          params,
          worker,
          (resp) => {
            message.success(`fact will be updated soon`);
            resolve(resp);
          },
          reject
        );
      } else {
        reject();
      }
    });
  };

  const updateFact = (factId: string, value: string, type?: string) => {
    return new Promise((resolve, reject) => {
      if (step && worker) {
        const params: any = {
          fact_id: factId,
          new_value: value,
          new_type: type || 'string'
        };

        makeDebugRequest(
          'update_fact_by_id',
          params,
          worker,
          (resp) => {
            message.success(`fact will be updated soon`);
            resolve(resp);
          },
          reject
        );
      } else {
        reject();
      }
    });
  };

  return {
    createFact,
    insertFactValue,
    deleteFactWithMultipleValue,
    deleteFact,
    updateFact,
    updateFactWithMultipleValues
  };
}

export default useStepFactEdit;
