import { message, Divider, Layout } from 'antd';
import React from 'react';
import { nodeId } from '@/details/runs/items/RunContextUtil';
import Request from '../../../components/request/Request';
import {
  Question,
  Request as RequestInterface,
  Step
} from '../../../generated/API';
import usePlaygroundServiceCommands from '../../../hooks/usePlaygroundServiceCommands';
import { useRunCtxV2 } from '../../../provider/runv2';
import { useAppDispatch, useAppSelector } from '../../../stores/hooks';
import { departmentQuerySelector } from '../../../stores/slices/department';
import { getSteps } from '../../../stores/slices/steps';
import { isRequestHandled } from '../../../utils/request';
import './RunStepInfo.less';
import QuestionForm from '../QuestionForm';

interface IProps {
  step: Step;
  stepRequests: RequestInterface[];
  stepQuestions: Question[];
}

function RunStepInfo(props: IProps) {
  const { step, stepRequests, stepQuestions } = props;

  const { worker, updateExpandedNodes, expandedNodes, procedure } =
    useRunCtxV2();
  const stepNodeId = nodeId(step);
  const { department } = useAppSelector(departmentQuerySelector);
  const dispatch = useAppDispatch();
  const { scratchAllSteps, sendSteps } = usePlaygroundServiceCommands();

  const pendingRequests = stepRequests.filter(
    (request) => !isRequestHandled(request)
  );
  const pendingQuestions = stepQuestions.filter(
    (question) => !question.isAnswered
  );
  const hasExceptions =
    pendingRequests.length > 0 || pendingQuestions.length > 0;

  const hide = !hasExceptions;

  if (hide) {
    return null;
  }

  const handleSuggestionClick = (suggestion: string[]) => {
    const hideMessage = message.loading('Applying suggestions...', 0);

    const runAsyncCommands = async () => {
      try {
        await scratchAllSteps();
        await sendSteps(suggestion.join('\n'));
        hideMessage();
      } catch (e: any) {
        console.error('Error while applying suggestions', e);
        message.error(e.message || 'Error while applying suggestions');
      }
    };

    runAsyncCommands();
  };

  const renderPendingQuestions = () => {
    if (!pendingQuestions.length) {
      return null;
    }

    return (
      <div className="run-questions">
        {pendingQuestions.map((q: any) => {
          let displayQuestions = [q];
          let isParent = false;
          if (['all_answers', 'any_answer'].includes(q.type)) {
            isParent = true;
            displayQuestions = q.children;
          }
          return (
            <div className="question-group" key={q.id}>
              <Layout className="question">
                {isParent && (
                  <>
                    {q.text}
                    <Divider style={{ marginBottom: 0 }} />
                  </>
                )}
                <Layout.Content>
                  <QuestionForm
                    key={q.id}
                    knowledgeId={worker?.knowledgeId!}
                    questions={displayQuestions}
                    isAnswered={q.isAnswered}
                    hideAnswered
                    allowContextDebugging
                    worker={worker}
                  />
                </Layout.Content>
              </Layout>
            </div>
          );
        })}
      </div>
    );
  };

  const renderPendingRequests = () => {
    return pendingRequests.map((request) => (
      <Request
        key={request.id}
        request={request}
        worker={worker}
        department={department}
        procedure={procedure || undefined}
        hideAnsweredQuestion
        questionConfig={{
          answerMode: 'single',
          allowContextDebugging: false // TODO: Enable once KOG-2053 & KOG-2057 are resolved.
        }}
        dispatchContextPath={false}
        onSuccess={() => {
          updateExpandedNodes(
            expandedNodes.filter((id) => id !== stepNodeId),
            true
          );
          dispatch(
            getSteps({
              workerId: worker.id,
              contextId: step.contextId
            })
          );
        }}
        suggestions={{
          handleClick: handleSuggestionClick
        }}
      />
    ));
  };

  return (
    <div className="run-step-info">
      {renderPendingQuestions()}
      {renderPendingRequests()}
    </div>
  );
}

export default RunStepInfo;
