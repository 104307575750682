import React, { useMemo } from 'react';
import { Fact } from '@/generated/API';
import reactStringReplace from 'react-string-replace';
import FormattingUtil, { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import AppUtil from '@/utils/AppUtil';
import useFactV2 from '@/hooks/useFactV2';
import { range } from 'lodash/fp';
import { useAppDispatch } from '@/stores/hooks';
import './FactCitation.less';
import { showPopup } from '@/stores/slices/appPopup';
import AppConstants from '@/utils/AppConstants';

interface IProps {
  fact: Fact;
  factType: {
    type: FormattedAnswerTypeV2;
    answer: any;
  };
  knowledgeId: string | null;
}

function FactCitation(props: IProps) {
  const { fact, factType, knowledgeId } = props;
  const dispatch = useAppDispatch();

  const cptIds: string[] = [];
  reactStringReplace(fact.value || '', FormattingUtil.srcRegex, (match) => {
    const matchJson = AppUtil.safeParseJSON(
      AppUtil.safeParseJSON(`"{${match}}"`)
    );
    cptIds.push(matchJson.id);
    return '';
  });

  const { getFactById, loading } = useFactV2({
    ids: cptIds,
    knowledgeId
  });

  const text = reactStringReplace(
    factType.answer,
    FormattingUtil.srcRegex,
    (match) => {
      const matchJson = AppUtil.safeParseJSON(`{${match}}`);
      const fact = getFactById(matchJson.id);
      const memoList = useMemo(() => {
        if (!fact?.locations?.[0]) {
          return null;
        }

        return fact.locations!.map((factLocation: string) => {
          const [pageConfig, locationConfig] =
            AppUtil.safeParseJSON(factLocation);

          if (!pageConfig || !locationConfig) {
            return null;
          }

          const { page } = pageConfig;

          // `page` in the updated location schema has an object. The following logic is for backward compatibility.
          const locationPages =
            typeof page === 'object'
              ? [...range(page.start)(page.end), page.end]
              : [page];

          const { location } = locationConfig;
          const pages = locationPages.map((p) => p + 1);
          const parentFact = fact?.parentFactId
            ? getFactById(fact.parentFactId)
            : null;

          let s3Parts: any;
          if (parentFact?.value) {
            const parsedValue = FormattingUtil.parseBrainValue(
              parentFact.value
            );

            if (parsedValue !== null) {
              s3Parts = AppUtil.getS3URIParts(parsedValue)!;
            }
          }

          return (
            <span
              className="fact-citation"
              onClick={() => {
                dispatch(
                  showPopup({
                    popupId: AppConstants.POPUPS.VIEW_S3_FILE,
                    popupParams: {
                      title: s3Parts.filename,
                      s3Object: {
                        bucket: s3Parts.bucket,
                        key: s3Parts.key
                      },
                      pdfConfig: {
                        pageNumbers: pages,
                        highlight: {
                          x: location.left * 100,
                          y: location.top * 100,
                          height: (location.bottom - location.top) * 100,
                          width: (location.right - location.left) * 100
                        },
                        allowViewTypeChange: false
                      }
                    }
                  })
                );
              }}
            >{`[${matchJson.value}]`}</span>
          );
        });
      }, [fact?.locations]);
      return loading ? '...' : <span>{memoList}</span>;
    }
  );
  return <div className="src-concept-container">{text}</div>;
}

export default FactCitation;
