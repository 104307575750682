import { EyeInvisibleOutlined, EyeOutlined } from '@ant-design/icons';
import { Tooltip } from 'antd';
import classNames from 'classnames';
import React, { useState } from 'react';
import './SensitiveText.less';

interface IProps {
  isSensitive: boolean;
  children: React.ReactNode;
}

function SensitiveText(props: IProps) {
  const { isSensitive: defaultSensitive, children } = props;
  const [isSensitive, setIsSensitive] = useState(defaultSensitive);

  return (
    <span
      className={classNames('sensitive-text', {
        active: isSensitive
      })}
    >
      {isSensitive ? '*****' : children}

      {defaultSensitive ? (
        <Tooltip title={isSensitive ? 'Reveal' : 'Hide'} destroyTooltipOnHide>
          <span
            onClick={() => setIsSensitive(!isSensitive)}
            className="toggle-icon"
          >
            {isSensitive ? <EyeOutlined /> : <EyeInvisibleOutlined />}
          </span>
        </Tooltip>
      ) : null}
    </span>
  );
}

export default SensitiveText;
