import {
  createAction,
  createAsyncThunk,
  createEntityAdapter,
  createSlice,
  nanoid
} from '@reduxjs/toolkit';
import { gql, FetchResult } from '@apollo/client';
import { RootState, AsyncThunkConfig } from '@/stores/AppStore';
import { IExample, CellType } from '@/components/example/types';
import {
  CreateCommandMutation,
  CreateCommandMutationVariables,
  CreateProcedureMutation,
  DepartmentFeature,
  InvokeProcedureMutation,
  InvokeProcedureMutationVariables,
  Procedure
} from '@/generated/API';
import Mutations from '@/graphql/Mutations';
import { learnBooks } from '@/stores/slices/books';
import {
  createDepartment,
  createOrganization,
  departmentQuerySelector
} from '@/stores/slices/department';
import {
  createProcedure,
  getProceduresByDepartment,
  getProceduresByKnowledge,
  updateProcedureSchedule
} from '@/stores/slices/procedure';
import { userSelector } from '@/stores/slices/user';
import { invokeProcedure } from '@/generated/graphql/mutations';
import { getWorker, listWorkerByProcedureId } from '@/stores/slices/worker';
import debug from 'debug';
import AppUtil from '../../utils/AppUtil';

const log = debug('example');

export const getExample = createAsyncThunk<IExample, string, AsyncThunkConfig>(
  'example/fetch',
  async (fileName) =>
    import(`@/components/example/db/${fileName}.json`).then(
      (res) => res.default
    ),
  {
    condition: (fileName, { getState }) => {
      const state = getState();
      const example = state.example.entities[fileName];
      if (example) {
        return false;
      }
      return true;
    }
  }
);

export const createCommand = createAsyncThunk<
  FetchResult<CreateCommandMutation>,
  CreateCommandMutationVariables,
  AsyncThunkConfig
>('procedure/create', async (variables, thunkAPI) =>
  thunkAPI.extra.appSyncClient.mutate({
    mutation: Mutations.CreateCommand(),
    variables
  })
);

export const runMultipleCommands = createAsyncThunk<
  any,
  { text: string; workerId: string },
  AsyncThunkConfig
>('procedure/multicreate', async ({ text, workerId }, { dispatch }) => {
  const commandsList = text
    .split('\n')
    .filter(Boolean)
    .map((i) => i.trim());

  const result = commandsList.reduce<any>(async (prev, text) => {
    await prev;
    return dispatch(
      createCommand({
        input: {
          name: 'debug',
          description: 'debug',
          workerId,
          text,
          subText: '',
          subTextLanguage: '',
          contextId: 0
          // sentenceId: endPosition + i
        }
      })
    );
  }, Promise.resolve());

  return result;
});

const runCell = createAsyncThunk<
  any,
  { cell: CellType; example: string },
  AsyncThunkConfig
>('cell/run', async ({ cell }, thunkApi) => {
  const { dispatch, getState } = thunkApi;
  const state = getState();
  const { department } = departmentQuerySelector(state);
  const { workerId } = department;

  const { username: owner } = userSelector(state);

  const source = cell.source?.join() || '';

  const match = /^learn "(.*)"$/.exec(source);
  if (match) {
    const [_, bookId] = match;
    const variables = {
      ids: [bookId],
      departmentId: department.id
    };
    log('learnBook', variables);
    return dispatch(learnBooks(variables));
  }

  if (cell.metadata.language === 'english' && !cell.metadata.procedure) {
    const variables = {
      input: {
        name: 'debug',
        description: 'debug',
        workerId: workerId!,
        text: cell.source?.join('')!,
        subText: '',
        subTextLanguage: '',
        contextId: 0,
        sentenceId: 0
      }
    };
    log('createCommand', variables);
    return dispatch(createCommand(variables));
  }

  if (cell.metadata.language === 'python') {
    const variables = {
      input: {
        departmentId: department.id,
        knowledgeId: department.draftKnowledgeId!,
        name: cell?.metadata.procedure,
        text: cell.source?.join(''),
        language: cell.metadata.language,
        title: cell.metadata.title,
        owner
      }
    };
    log('createProcedure', variables);
    return dispatch(createProcedure(variables));
  }

  if (cell.metadata.language === 'english' && cell.metadata.procedure) {
    const variables = {
      input: {
        departmentId: department.id,
        knowledgeId: department.draftKnowledgeId!,
        name: cell?.metadata.procedure,
        text: cell.source?.join(''),
        language: cell.metadata.language,
        title: cell.metadata.title,
        owner
      }
    };

    if (cell.metadata.schedule) {
      log('createProcedure', variables);
      const resp = await dispatch(createProcedure(variables));
      const procedure = (resp?.payload as FetchResult<CreateProcedureMutation>)
        ?.data?.createProcedure!;

      const scheduleVar = {
        input: {
          id: procedure?.id,
          departmentId: department.id,
          knowledgeId: procedure.knowledgeId,
          scheduleEnabled: true,
          scheduleExpression: cell.metadata.schedule,
          owner: procedure.owner,
          public: procedure.public
        }
      };
      log('updateProcedureSchedule', scheduleVar, procedure);
      await dispatch(updateProcedureSchedule(scheduleVar));
      return resp;
    }

    log('createProcedure', variables);
    return dispatch(createProcedure(variables));
  }

  return Promise.resolve();
});

export const learnExample = createAsyncThunk<any, string, AsyncThunkConfig>(
  'example/learn',
  async (fileName: string, { dispatch, getState }) => {
    const state = getState();
    const example = state.example.entities[fileName]!;
    if (!example) {
      return Promise.reject(new Error(`Example not found: ${example}`));
    }
    const codeCells = example.cells.filter(
      (cell) =>
        cell.cell_type === 'code' &&
        cell.outputs?.[0]?.data?.['text/emoji'] !== '✅'
    );
    const seqProm = codeCells.reduce<any>(async (prev, cell) => {
      await prev;
      return dispatch(runCell({ example: fileName, cell })).unwrap();
    }, Promise.resolve());

    return seqProm;
  }
);

export const runProcedure = createAsyncThunk<
  FetchResult<InvokeProcedureMutation>,
  InvokeProcedureMutationVariables,
  AsyncThunkConfig
>('procedure/create', async (variables, thunkAPI) =>
  thunkAPI.extra.appSyncClient.mutate({
    mutation: gql(invokeProcedure),
    variables
  })
);

export enum AutomationStatus {
  Idle = 'Idle',
  Fetching = 'Fetching',
  Learning = 'Learning',
  Running = 'Running',
  Redirecting = 'Redirecting',
  Error = 'Error'
}

export const automationStatus =
  createAction<AutomationStatus>('automation/status');

export const runAutomation = createAsyncThunk<
  {
    departmentId: string | null;
    knowledgeId: string | null; // TODO: DEP-BOOK: REMOVE
    workerId: string;
    procedureId: string;
  },
  string,
  AsyncThunkConfig
>('automation/run', async (fileName, { dispatch, getState }) => {
  dispatch(automationStatus(AutomationStatus.Fetching));
  try {
    await dispatch(getExample(fileName));
    // eslint-disable-next-line no-empty
  } catch (e) {
    log('getExample', e);
  }

  const { department } = departmentQuerySelector(getState());

  if (!department) {
    await dispatch(
      createOrganization({
        input: {
          name: 'Automation'
        }
      })
    );
    await dispatch(
      createDepartment({
        input: {
          name: 'default',
          features: [
            DepartmentFeature.EXCEPTION_REQUEST,
            DepartmentFeature.DEPARTMENT_BOOK
          ]
        }
      })
    );
  } else {
    let procedures;

    if (AppUtil.isDepartmentBookSupported(department)) {
      procedures = (
        await dispatch(
          getProceduresByDepartment({ departmentId: department.id })
        ).unwrap()
      ).data?.listProceduresByDepartment?.items;
    } else {
      procedures = (
        await dispatch(
          getProceduresByKnowledge({
            knowledgeId: department.draftKnowledgeId!
          })
        ).unwrap()
      ).data?.listProceduresByKnowledge?.items;
    }

    const state = getState();
    const example = state.example.entities[fileName];
    const title = (example?.cells ?? []).filter(
      (cell) => cell?.metadata?.title
    )[0]?.metadata.title;
    const procedure = procedures?.find((p) => p?.title === title);
    if (procedure) {
      dispatch(automationStatus(AutomationStatus.Redirecting));
      const workers = await dispatch(
        listWorkerByProcedureId({ procedureId: procedure.id })
      ).unwrap();
      const worker = workers.data?.listWorkersByProcedure?.items?.[0];
      return {
        departmentId: procedure?.departmentId || null,
        knowledgeId: procedure?.knowledgeId || null,
        workerId: worker?.id!,
        procedureId: procedure?.id
      };
    }
  }

  dispatch(automationStatus(AutomationStatus.Learning));
  log('learnExample', fileName);
  const data = await dispatch(learnExample(fileName)).unwrap();
  const procedure = data?.payload?.data?.createProcedure;

  dispatch(automationStatus(AutomationStatus.Running));
  const run = await dispatch(
    runProcedure({
      procedureId: procedure?.id,
      knowledgeId: procedure?.knowledgeId,
      departmentId: procedure?.departmentId
    })
  ).unwrap();
  const invokedProcedure = run.data?.invokeProcedure;
  const workerId = invokedProcedure?.workerId!;

  dispatch(automationStatus(AutomationStatus.Redirecting));
  const worker = (await dispatch(getWorker({ id: workerId })).unwrap()).data
    ?.getWorker;

  return {
    departmentId: worker?.departmentId || null,
    knowledgeId: worker?.knowledgeId || null,
    workerId,
    procedureId: worker?.procedureId!
  };
});

const ExampleAdapter = createEntityAdapter<IExample>({
  selectId: (example) => example.metadata.id!
});

enum ExampleStatus {
  Idle = 'Idle',
  Loading = 'Loading',
  Learning = 'Learning',
  Complete = 'Complete',
  Error = 'Error'
}

const initialState = ExampleAdapter.getInitialState<{
  status: ExampleStatus;
  error?: string;
  result?: Procedure;
  automationStatus?: AutomationStatus;
}>({
  status: ExampleStatus.Idle,
  automationStatus: AutomationStatus.Idle
});

type IExampleSlice = typeof initialState;

const updateCell = (
  state: IExampleSlice,
  { example, cell }: { example: string; cell: CellType },
  updaterFn?: (c: CellType) => CellType
) => {
  state.entities[example]?.cells.map((c: CellType) => {
    if (c.metadata.id === cell.metadata.id) {
      return updaterFn?.(c) || cell;
    }
    return c;
  });
};

export const exampleSlice = createSlice({
  name: 'example',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(automationStatus, (state, action) => {
      state.automationStatus = action.payload;
    });
    builder.addCase(getExample.pending, (state) => {
      state.status = ExampleStatus.Loading;
    });
    builder.addCase(getExample.fulfilled, (state, { meta, payload }) => {
      state.status = ExampleStatus.Idle;
      payload.cells = payload.cells.map((cell) => ({
        ...cell,
        metadata: {
          ...cell.metadata,
          id: nanoid()
        }
      }));
      payload.metadata.id = meta.arg; // filename
      ExampleAdapter.addOne(state, payload);
    });
    builder.addCase(learnExample.pending, (state) => {
      state.status = ExampleStatus.Learning;
      state.error = '';
    });
    builder.addCase(learnExample.fulfilled, (state, action) => {
      state.status = ExampleStatus.Complete;
      // I assume last code cell of the examples will be a procedure
      state.result = action.payload?.payload?.data?.createProcedure;
    });
    builder.addCase(learnExample.rejected, (state, action) => {
      state.status = ExampleStatus.Error;
      state.error = action.error as string;
    });
    builder.addCase(runCell.pending, (state, action) => {
      updateCell(state, action.meta.arg, (c) => {
        c.metadata.loading = true;
        c.execution_count = (c.execution_count || 0) + 1;
        return c;
      });
    });
    builder.addCase(runCell.fulfilled, (state, action) => {
      updateCell(state, action.meta.arg, (c) => {
        c.metadata.loading = false;
        c.outputs = [
          {
            output_type: 'text/plain',
            execution_count: 1,
            response: action.payload,
            data: {
              'text/emoji': '✅'
            }
          }
        ];
        return c;
      });
    });
    builder.addCase(runCell.rejected, (state, action) => {
      updateCell(state, action.meta.arg, (c) => {
        c.metadata.loading = false;
        c.outputs = [
          {
            output_type: 'text/plain',
            execution_count: 1,
            response: action.payload,
            data: {
              'text/emoji': '❌',
              'text/json': JSON.stringify(action.error)
            }
          }
        ];
        return c;
      });
    });
  }
});

export const exampleSelector = ExampleAdapter.getSelectors<RootState>(
  (state) => state.example
);

export const exampleQuerySelector = (name: string) => (state: RootState) => ({
  example: exampleSelector.selectById(state, name),
  isLoading: state.example.status === ExampleStatus.Loading,
  isLearning: state.example.status === ExampleStatus.Learning,
  isComplete: state.example.status === ExampleStatus.Complete,
  isError: state.example.status === ExampleStatus.Error,
  result: state.example.result
});

export const automationStatusSelector = (state: RootState) =>
  state.example.automationStatus;

export default exampleSlice.reducer;
