import {
  createSlice,
  createAsyncThunk,
  createEntityAdapter
} from '@reduxjs/toolkit';
import {
  Book,
  ForgetBooksMutation,
  ForgetBooksMutationVariables,
  LearnBooksMutation,
  LearnBooksMutationVariables,
  ListBooksByDepartmentQuery,
  ListBooksByDepartmentQueryVariables,
  ListBooksByKnowledgeQuery,
  ListBooksByKnowledgeQueryVariables
} from '@/generated/API';
import { AsyncThunkConfig, RootState } from '@/stores/AppStore';
import Queries from '@/graphql/Queries';
import { FetchResult } from '@apollo/client';
import Mutations from '@/graphql/Mutations';
import { delay } from '@/utils/promise';
import { departmentQuerySelector } from './department';
import AppUtil from '../../utils/AppUtil';

export interface GetBooksByKnowledgeParams
  extends ListBooksByKnowledgeQueryVariables {
  page?: number;
  background?: boolean;
}

// TODO: DEP-BOOK: REMOVE
export const getBooksByKnowledge = createAsyncThunk<
  FetchResult<ListBooksByKnowledgeQuery>,
  GetBooksByKnowledgeParams,
  AsyncThunkConfig
>(
  'books/knowledge/fetchAll',
  async ({ knowledgeId, limit, nextToken }, thunkAPI) =>
    thunkAPI.extra.appSyncClient.query({
      query: Queries.ListBooksByKnowledge(),
      variables: {
        knowledgeId,
        limit,
        nextToken
      }
    })
);

export interface GetBooksByDepartmentParams
  extends ListBooksByDepartmentQueryVariables {
  page?: number;
  background?: boolean;
}

export const getBooksByDepartment = createAsyncThunk<
  FetchResult<ListBooksByDepartmentQuery>,
  GetBooksByDepartmentParams,
  AsyncThunkConfig
>(
  'books/department/fetchAll',
  async ({ departmentId, version, limit, nextToken }, thunkAPI) =>
    thunkAPI.extra.appSyncClient.query({
      query: Queries.ListBooksByDepartment(),
      variables: {
        departmentId,
        version,
        limit,
        nextToken
      }
    })
);

// TODO: DEP-BOOK: REMOVE
const isAllLearned = (data: Book[], ids: string[]) =>
  data
    .filter((book) => ids.indexOf(book.id) !== -1)
    .filter((book) => book.learnedAt === null).length === 0;

export const learnBooks = createAsyncThunk<
  FetchResult<LearnBooksMutation>,
  LearnBooksMutationVariables,
  AsyncThunkConfig
>('book/learn', async ({ ids, departmentId }, thunkAPI) => {
  const resp = await thunkAPI.extra.appSyncClient.mutate({
    mutation: Mutations.LearnBooks(),
    variables: {
      ids,
      departmentId
    }
  });

  let books = resp.data?.learnBooks as Book[];
  const { department } = departmentQuerySelector(thunkAPI.getState());

  if (!AppUtil.isDepartmentBookSupported(department)) {
    let timeout = false;
    setTimeout(() => {
      timeout = true;
    }, 5 * 60 * 1000);

    while (!isAllLearned(books, ids) && !timeout) {
      // eslint-disable-next-line no-await-in-loop
      const res = await thunkAPI
        .dispatch(
          getBooksByKnowledge({
            knowledgeId: department.draftKnowledgeId!,
            background: true
          })
        )
        .unwrap();
      books = res?.data?.listBooksByKnowledge?.items as Book[];
      // eslint-disable-next-line no-await-in-loop
      await delay(1000);
    }

    if (timeout) {
      throw new Error('Book Learning Timeout');
    }
  }

  return resp;
});

export const forgetBooks = createAsyncThunk<
  FetchResult<ForgetBooksMutation>,
  ForgetBooksMutationVariables,
  AsyncThunkConfig
>('book/forget', async ({ ids, departmentId }, thunkAPI) =>
  thunkAPI.extra.appSyncClient.mutate({
    mutation: Mutations.ForgetBooks(),
    variables: {
      ids,
      departmentId
    }
  })
);

const BooksAdapter = createEntityAdapter<Book>({
  selectId: (book) => book.id!
});

interface IState {
  loading: boolean;
  error: any;
  nextToken: string | undefined;
  pageDataMap: Record<number, Book[]>;
}

export const booksSlice = createSlice({
  name: 'books',
  initialState: BooksAdapter.getInitialState<IState>({
    loading: false,
    error: undefined,
    nextToken: undefined,
    pageDataMap: {}
  }),
  reducers: {
    updateBook: BooksAdapter.updateOne
  },
  extraReducers: (builder) => {
    [getBooksByKnowledge.pending, getBooksByDepartment.pending].forEach(
      (thunkCase) => {
        builder.addCase(thunkCase, (state, { meta }) => {
          if (!meta.arg.background) {
            state.loading = true;
          }
        });
      }
    );

    [getBooksByKnowledge.fulfilled, getBooksByDepartment.fulfilled].forEach(
      (thunkCase) => {
        builder.addCase(thunkCase, (state, { payload, meta }) => {
          if (payload?.data) {
            const data: any = payload.data;
            const { items = [], nextToken } =
              data.listBooksByKnowledge || data.listBooksByDepartment;

            const books = items as Book[];

            if (!meta.arg.nextToken) {
              BooksAdapter.setAll(state, books);
            } else {
              BooksAdapter.addMany(state, books);
            }

            if (meta.arg.page && books.length > 0) {
              state.pageDataMap[meta.arg.page] = books;
            }

            state.nextToken = nextToken || undefined;
          }

          if (!meta.arg.background) {
            state.loading = false;
          }
        });
      }
    );

    [getBooksByKnowledge.rejected, getBooksByDepartment.rejected].forEach(
      (thunkCase) => {
        builder.addCase(thunkCase, (state, { payload, meta }) => {
          state.error = payload as any;
          if (!meta.arg.background) {
            state.loading = false;
          }
        });
      }
    );

    // this block is not need because we are fetch all books after learning
    // builder.addCase(learnBook.fulfilled, (state, action) => {
    //   state.loading = false;
    //   const books = (action.payload.data?.learnBooks ?? []).map(
    //     ({ name, ...rest }) => ({ name, ...rest })
    //   );
    //   BooksAdapter.setMany(state, books);
    // });
  }
});

export const { updateBook } = booksSlice.actions;

export const booksSelectors = BooksAdapter.getSelectors<RootState>(
  (state) => state.books
);
export const booksQuerySelector = (state: RootState) => ({
  loading: state.books.loading,
  books: booksSelectors.selectAll(state),
  error: state.books.error,
  nextToken: state.books.nextToken,
  pageDataMap: state.books.pageDataMap
});

export default booksSlice.reducer;
