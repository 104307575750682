export const listDepartmentLearnedAnswers = /* GraphQL */ `
  query listDepartmentLearnedAnswers($id: ID!) {
    getDepartment(id: $id) {
      learnedAnswers {
        nextToken
        items {
          id
          questionFilter {
            stepPath
            questionType
            lexicalPath
          }
          action {
            _
            ... on RetryStepAction {
              minimumDelay
              timeout
            }
            ... on UseConceptAction {
              conceptId
            }
            ... on UseLiteralValueAction {
              literalValue
              choices
            }
            ... on UseTechniqueAction {
              technique
            }
          }
          createdAt
          createdBy
          deletedAt
          deletedBy
          procedureId
          procedureName
          procedure {
            id
            name
            knowledgeId
          }
        }
      }
    }
  }
`;
