import { Fact } from '@/generated/API';
import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import React from 'react';
import { CheckCircleFilled } from '@ant-design/icons';
import { Empty, Typography } from 'antd';
import AnswerS3FileViewer from '../AnswerS3FileViewer';
import FactDocumentSummaryList from '../facts/FactDocumentSummary';
import AnswerMarkdownViewer from '../AnswerMarkdownViewer';
import SensitiveText from '../SensitiveText';
import FactCitation from './FactCitation';
import AnswerCSVViewer from '../AnswerCSVViewer';
import AnswerConceptsTable from './AnswerConceptsTable';
import EditableStringFact from './EditableStringFact';
import EditableS3Fact from './EditableS3Fact';

// TODO: Edit types
// String
// S3
// md -> string/md editor (talk to faiyaz)
// Table -> csv string editor
// table's row -> insert, update, delete
// result -> result object from string

interface IProps {
  fact: Fact;
  factType: {
    type: FormattedAnswerTypeV2;
    answer: any;
  };
  knowledgeId: string | null;
  noTextMassage?: boolean;
  // This is to get step answer for table's row kind of fact
  stepAnswer?: string;
  // This is to get the step concepts for tables's row kind of fact
  tableRowCpt?: any;
  showEmpty?: boolean;
  onEdit?: (data: string, type?: string) => Promise<unknown>;
  onDelete?: () => Promise<unknown>;
}

function RunFactRendererV2(props: IProps) {
  const {
    fact,
    factType,
    knowledgeId,
    noTextMassage,
    stepAnswer,
    tableRowCpt,
    showEmpty,
    onEdit,
    onDelete
  } = props;

  if (
    (tableRowCpt || stepAnswer) &&
    factType.type === FormattedAnswerTypeV2.TABLES_ROW
  ) {
    return (
      <div data-cy="answer" className="short-answer view-link">
        <AnswerConceptsTable answer={stepAnswer!} tableRowCpt={tableRowCpt} />
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.TABLE) {
    return (
      <div
        data-cy="answer"
        className="short-answer view-link"
        onClick={(e) => e.stopPropagation()}
      >
        <AnswerCSVViewer
          onEdit={onEdit}
          title="View table"
          data={factType.answer}
        />
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.SRC_CONCEPT) {
    return (
      <EditableStringFact
        onEdit={onEdit}
        onDelete={onDelete}
        factType={factType}
      >
        <div>
          <FactCitation
            fact={fact}
            factType={factType}
            knowledgeId={knowledgeId}
          />
        </div>
      </EditableStringFact>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.LONG) {
    return (
      <EditableStringFact
        onEdit={onEdit}
        onDelete={onDelete}
        factType={factType}
      >
        <div className="long-fact-table-answer">
          {factType.answer}
          <FactDocumentSummaryList
            fact={fact}
            showInPopup
            knowledgeId={knowledgeId}
          />
        </div>
      </EditableStringFact>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.MARKDOWN) {
    return (
      <div>
        <AnswerMarkdownViewer answer={factType.answer} />
      </div>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.SENSITIVE) {
    return (
      <EditableStringFact
        onEdit={onEdit}
        onDelete={onDelete}
        factType={factType}
      >
        <div>
          <SensitiveText isSensitive>{factType.answer}</SensitiveText>
        </div>
      </EditableStringFact>
    );
  }

  if (factType.type === FormattedAnswerTypeV2.S3) {
    return (
      <EditableS3Fact onEdit={onEdit} onDelete={onDelete} factType={factType}>
        <div data-cy="answer" className="short-answer view-link">
          <AnswerS3FileViewer answer={factType.answer!} />
        </div>
      </EditableS3Fact>
    );
  }

  // TODO: Need to show the result as text
  if (
    factType.type === FormattedAnswerTypeV2.RESULT &&
    (factType.answer.toLowerCase() === 'done' ||
      factType.answer.toLowerCase() === 'ok')
  ) {
    return (
      <span data-cy="answer" className="short-answer dim">
        {noTextMassage ? (
          factType.answer
        ) : (
          <CheckCircleFilled data-cy="answer-ok-icon" />
        )}
      </span>
    );
  }

  if (fact.locations) {
    return (
      <EditableStringFact
        onEdit={onEdit}
        onDelete={onDelete}
        factType={factType}
      >
        <div className="answer-files">
          <Typography.Text className="short-answer dim fact-summary-text">
            {factType.answer}
          </Typography.Text>
          <FactDocumentSummaryList
            fact={fact}
            showInPopup
            knowledgeId={knowledgeId}
          />
        </div>
      </EditableStringFact>
    );
  }

  return (
    <EditableStringFact onEdit={onEdit} onDelete={onDelete} factType={factType}>
      <Typography.Text
        data-cy="answer"
        className="short-answer dim fact-summary-text"
      >
        {factType.answer || (showEmpty && <Empty />)}
      </Typography.Text>
    </EditableStringFact>
  );
}

export default RunFactRendererV2;
