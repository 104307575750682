export const getWorker = /* GraphQL */ `
  query GetWorker($id: ID!) {
    getWorker(id: $id) {
      id
      owner
      readers
      editors
      name
      description
      version
      departmentVersion
      knowledgeId
      departmentId
      procedureId
      entryCommand
      entryCommandId
      originalWorkerId
      createdAt
      state
      stateLastUpdatedAt
      status
      statusLastUpdatedAt
      attachments
      isArchived
    }
  }
`;

export const getWorkerRequestsQuery = /* GraphQL */ `
  query GetWorkerRequests($id: ID!) {
    getWorker(id: $id) {
      id
      requests {
        limit
        nextToken
        items {
          id
          stepPath
          contextId
          sentenceId
          createdAt
          handledAt
          state
          worker {
            id
          }
          questions {
            id
            text
            contextId
            sentenceId
            answerTypeHint
            ... on NativeCodeErrorQuestion {
              errorType
              errorMessage
              errorTraceback
            }
            ... on ReviewConceptQuestion {
              lexicalPath
              conceptId
            }
            ... on ValueNotKnownQuestion {
              lexicalPath
              choices
              helpText
            }
          }
          answers {
            id
            requestId
            questionId
          }
          contextPath {
            ctxId
            sentenceId
          }
        }
      }
    }
  }
`;

export const getWorkerDependencies = /* GraphQL */ `
  query getWorkerDependencies($workerId: ID!, $contextId: ID) {
    getContextsForStep(workerId: $workerId, contextId: $contextId) {
      items {
        id
        createdAt
        depth
        stepList {
          __typename
          id
          number
          createdAt
          scratchedAt
          text
          answer
          childContextIdList
          contextId
          positionInContext
          workerId
          concepts
          commandId
        }
        commands {
          __typename
          contextId
          positionInContext
          id
          state
          text
          createdAt
          forwardedAt
          invokedAt
          receivedAt
          processingAt
          processedAt
          failedAt
          workErrors
          workStatus
        }
        workerId
      }
    }
    listCommandsByWorker(workerId: $workerId) {
      items {
        id
        owner
        readers
        editors
        name
        description
        workerId
        text
        subText
        subTextLanguage
        procedureId
        createdAt
        ast
        state
        output
        source
        sourceMetadata
        answers {
          items {
            questionId
          }
        }
        debug
        receivedAt
        questions {
          items {
            id
            createdAt
            commandId
            workerId
            text
            answerId
            isAnswered
            path
            type
            validationList
            description
            delegationChain {
              method
              recipients
            }
            rawException
            contextPath {
              ctxId
              sentenceId
            }
            contextId
            children {
              id
              createdAt
              commandId
              workerId
              text
              answerId
              isAnswered
              path
              type
              validationList
              delegationChain {
                method
                recipients
              }
              rawException
              contextPath {
                ctxId
                sentenceId
              }
              contextId
              parentId
              description
            }
          }
        }
        notifications {
          items {
            id
            contextId
            commandId
            workerId
            sentenceId
            info
            owner
            readers
            editors
            name
            description
            createdAt
          }
        }
      }
      nextToken
    }
    getWorker(id: $workerId) {
      requests {
        limit
        nextToken
        items {
          id
          stepPath
          contextId
          sentenceId
          createdAt
          handledAt
          state
          worker {
            id
          }
          questions {
            id
            text
            contextId
            sentenceId
            answerTypeHint
            ... on NativeCodeErrorQuestion {
              errorType
              errorMessage
              errorTraceback
            }
            ... on ReviewConceptQuestion {
              lexicalPath
              conceptId
            }
            ... on ValueNotKnownQuestion {
              lexicalPath
              choices
              helpText
            }
          }
          answers {
            id
            requestId
            questionId
          }
          contextPath {
            ctxId
            sentenceId
          }
        }
      }
    }
  }
`;

export const getWorkerDependenciesV2 = /* GraphQL */ `
  query getWorkerDependencies(
    $workerId: ID!
    $requestExceptionSupported: Boolean!
  ) {
    getWorker(id: $workerId) @include(if: $requestExceptionSupported) {
      requests {
        limit
        nextToken
        items {
          id
          stepPath
          contextId
          sentenceId
          createdAt
          handledAt
          state
          worker {
            id
          }
          questions {
            id
            text
            contextId
            sentenceId
            answerTypeHint
            ... on NativeCodeErrorQuestion {
              errorType
              errorMessage
              errorTraceback
            }
            ... on ReviewConceptQuestion {
              lexicalPath
              conceptId
            }
            ... on ValueNotKnownQuestion {
              lexicalPath
              choices
              helpText
            }
          }
          answers {
            id
            requestId
            questionId
          }
          suggestedAnswers {
            id
            requestId
            questionId
            action {
              ... on ReplaceStepAction {
                _
                newSteps
              }
            }
          }
          contextPath {
            ctxId
            sentenceId
          }
        }
      }
    }
    listCommandsByWorker(workerId: $workerId)
      @skip(if: $requestExceptionSupported) {
      items {
        id
        questions {
          items {
            id
            createdAt
            commandId
            workerId
            text
            answerId
            isAnswered
            path
            type
            validationList
            description
            delegationChain {
              method
              recipients
            }
            rawException
            contextPath {
              ctxId
              sentenceId
            }
            contextId
            children {
              id
              createdAt
              commandId
              workerId
              text
              answerId
              isAnswered
              path
              type
              validationList
              delegationChain {
                method
                recipients
              }
              rawException
              contextPath {
                ctxId
                sentenceId
              }
              contextId
              parentId
              description
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const getWorkerKnowledgeId = /* GraphQL */ `
  query GetWorkerKnowledgeId($id: ID!) {
    getWorker(id: $id) {
      id
      knowledgeId
    }
  }
`;

// Naming convention for Graphql queries is as per Apollo documention articles: 'SCREAMIGN_SNAKE_CASE'
export const LIST_WORKERS_BY_DEPARTMENT = /* GraphQL */ `
  query listWorkersByDepartment(
    $departmentId: ID!
    $limit: Int
    $nextToken: String
  ) {
    listWorkersByDepartment(
      departmentId: $departmentId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        owner
        name
        version
        departmentVersion
        departmentId
        createdAt
        isArchived
        knowledgeId
      }
      nextToken
    }
  }
`;
