export const listProcedureRequestsQuery = /* GraphQL */ `
  query ListRequestsByProcedure(
    $procedureId: ID!
    $departmentId: ID!
    $limit: Int = 100
    $nextToken: String
    $state: RequestState
  ) {
    listRequestsByProcedure(
      procedureId: $procedureId
      departmentId: $departmentId
      limit: $limit
      nextToken: $nextToken
      state: $state
    ) {
      items {
        id
        stepPath
        contextId
        sentenceId
        createdAt
        handledAt
        state
        worker {
          id
          knowledgeId
        }
        questions {
          id
          text
          contextId
          sentenceId
          ... on NativeCodeErrorQuestion {
            errorType
            errorMessage
            errorTraceback
          }
          ... on ReviewConceptQuestion {
            lexicalPath
            conceptId
          }
          ... on ValueNotKnownQuestion {
            lexicalPath
            choices
            helpText
          }
        }
        answers {
          id
          requestId
          questionId
          action {
            _
            ... on RetryStepAction {
              minimumDelay
              timeout
            }
            ... on UseConceptAction {
              conceptId
            }
            ... on UseLiteralValueAction {
              literalValue
            }
            ... on UseTechniqueAction {
              technique
            }
          }
          createdAt
          createdBy
        }
        contextPath {
          ctxId
          sentenceId
        }
      }
      limit
      nextToken
    }
  }
`;

export const getRequest = /* GraphQL */ `
  query GetRequest($id: ID!) {
    getRequest(id: $id) {
      id
      stepPath
      contextId
      sentenceId
      createdAt
      handledAt
      state
      worker {
        id
        knowledgeId
      }
      questions {
        id
        text
        contextId
        sentenceId
        ... on NativeCodeErrorQuestion {
          errorType
          errorMessage
          errorTraceback
        }
        ... on ReviewConceptQuestion {
          lexicalPath
          conceptId
        }
        ... on ValueNotKnownQuestion {
          lexicalPath
          choices
          helpText
        }
      }
      answers {
        id
        requestId
        questionId
        action {
          _
          ... on RetryStepAction {
            minimumDelay
            timeout
          }
          ... on UseConceptAction {
            conceptId
          }
          ... on UseLiteralValueAction {
            literalValue
          }
          ... on UseTechniqueAction {
            technique
          }
        }
        createdAt
        createdBy
      }
      contextPath {
        ctxId
        sentenceId
      }
    }
  }
`;
