import { Divider, Layout, Modal, Tooltip } from 'antd';
import React from 'react';
import CheckFilledIcon from '../components/icons/CheckFilledIcon';
import QuestionFilledIcon from '../components/icons/QuestionFilledIcon';
import QuestionForm from '../details/runs/QuestionForm';
import { Question, Worker } from '../generated/API';
import './ViewQuestionPopup.less';

interface IProps {
  question: Question;
  worker: Worker;
  onClose: () => void;
}

function ViewQuestionPopup(props: IProps) {
  const { question, worker, onClose } = props;

  let displayQuestions = [question];
  let isParent = false;
  if (question.type && ['all_answers', 'any_answer'].includes(question.type)) {
    isParent = true;
    displayQuestions = question.children;
  }

  return (
    <Modal
      className="view-question-popup"
      title={
        <div className="header">
          View Question{' '}
          {question.isAnswered ? (
            <Tooltip title="Answered">
              <span>
                <CheckFilledIcon />
              </span>
            </Tooltip>
          ) : (
            <Tooltip title="Pending">
              <span>
                <QuestionFilledIcon />
              </span>
            </Tooltip>
          )}
        </div>
      }
      centered
      open
      footer={null}
      onCancel={onClose}
    >
      <div className="question-group" key={question.id}>
        <Layout className="question">
          {isParent && <Divider>{question.text}</Divider>}
          <Layout.Content>
            <QuestionForm
              key={question.id}
              knowledgeId={worker?.knowledgeId!}
              questions={displayQuestions}
              isAnswered={question.isAnswered}
              onSuccess={() => {
                onClose();
              }}
              hideAnswered={false}
              // TODO: Enable once supported in brain
              // allowUpdate={question.isAnswered}
              dispatchContextPath={false}
              allowContextDebugging
              worker={worker}
            />
          </Layout.Content>
        </Layout>
      </div>
    </Modal>
  );
}

export default ViewQuestionPopup;
