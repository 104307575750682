export const listBooksByKnowledge = /* GraphQL */ `
  query ListBooksByKnowledge(
    $knowledgeId: ID!
    $limit: Int = 100
    $nextToken: String
  ) {
    listBooksByKnowledge(
      knowledgeId: $knowledgeId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        name
        author
        description
        learnedAt
        commandId
        version
      }
      nextToken
    }
  }
`;

export const listBooksByDepartment = /* GraphQL */ `
  query ListBooksByDepartment(
    $departmentId: ID!
    $version: String
    $limit: Int = 100
    $nextToken: String
  ) {
    listBooksByDepartment(
      departmentId: $departmentId
      version: $version
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        url
        name
        author
        description
        learnedAt
        commandId
        version
      }
      nextToken
    }
  }
`;
