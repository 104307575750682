//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// AppPage is a component that is responsible for rendering the current page
//

// 3rd party libraries
import React, { useEffect } from 'react';
import { Outlet, useLocation } from 'react-router-dom';
import { Layout, message } from 'antd';

// Local Includes
import AppHeader from '@views/AppHeader';
import DetailsPage from '@details/DetailsPage';
import CurrentPopup from '@popups/CurrentPopup';

// Component CSS
import './AppPage.less';
import classNames from 'classnames';
// import DepartmentNotFound from '../components/DepartmentNotFound';
import WithSuspense from '../components/WithSuspense';
import { useAppDispatch, useAppSelector } from '../stores/hooks';
import {
  departmentQuerySelector,
  getDepartment,
  getDepartments,
  selectDepartment
} from '../stores/slices/department';
import Loader, { ILoaderType } from '../components/Loader';
import routes from '../utils/routes';
import DepartmentNotFound from '../components/DepartmentNotFound';
import DocsViewer from './DocsViewer';
import CurrentDrawer from '../popups/CurrentDrawer';
import ErrorBoundary from '../components/ErrorBoundary';
import { getSelectedDepartmentId } from '../utils/department';
import { Department } from '../generated/API';

const { Content } = Layout;

/**
 * This is layout page. `<Outlet/>` is the placeholder for dynamic components
 * @returns
 */
function AppPage() {
  // TODO: each page should control its layout

  const { department, loading } = useAppSelector(departmentQuerySelector);
  const dispatch = useAppDispatch();
  const location = useLocation();

  // TODO: Remove this after 2 weeks post prod deployment.
  useEffect(() => {
    localStorage.removeItem('persist:root');
  }, []);

  const selectedDepartmentId = getSelectedDepartmentId();

  const fetchSelectedDepartment = () => {
    dispatch(
      getDepartment({
        id: selectedDepartmentId!
      })
    )
      .unwrap()
      .then((res) => {
        const fetchedDepartment = res.data?.getDepartment as Department | null;
        if (fetchedDepartment) {
          dispatch(selectDepartment(fetchedDepartment));
        }
      })
      .catch((err) => {
        console.log('getDepartment:error', err);
        message.error(err.message || 'Failed to fetch department');
      });
  };

  const fetchLatestDepartment = () => {
    dispatch(
      getDepartments({
        limit: 1
      })
    )
      .unwrap()
      .then((response) => {
        const latestDepartment = response.data?.listDepartments?.items?.[0];
        if (latestDepartment) {
          dispatch(selectDepartment(latestDepartment));
        }
      })
      .catch((err) => {
        console.log('listDepartments:error', err);
        message.error(err.message || 'Failed to fetch department');
      });
  };

  useEffect(() => {
    if (selectedDepartmentId) {
      fetchSelectedDepartment();
    } else {
      fetchLatestDepartment();
    }
  }, []);

  const renderContent = () => {
    if (location.pathname.includes(routes.department.getDepartmentsPageUrl())) {
      return <Outlet />;
    }
    if (loading && !department) {
      return <Loader type={ILoaderType.FULL_PAGE} />;
    }
    if (location.pathname.includes('/automation')) {
      return <Outlet />;
    }
    if (!department) {
      return <DepartmentNotFound />;
    }
    return <Outlet />;
  };

  return (
    <Layout className="app-page">
      <div>
        <AppHeader />
        <Content>
          <div className={classNames('page-content')}>
            <ErrorBoundary>
              <WithSuspense element={<>{renderContent()}</>} />
            </ErrorBoundary>
          </div>
        </Content>
      </div>
      <DetailsPage />
      <CurrentPopup />
      <CurrentDrawer />
      <DocsViewer />
    </Layout>
  );
}

export default AppPage;
