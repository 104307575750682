import React from 'react';
import { showPopup } from '@/stores/slices/appPopup';
import { Tooltip } from 'antd';
import { useAppDispatch } from '../stores/hooks';
import AppConstants from '../utils/AppConstants';
import './AnswerObjectViewer.less';
import AppUtil from '../utils/AppUtil';

interface IProps {
  object: Record<string, any>;
}

function AnswerObjectViewer(props: IProps) {
  const { object = {} } = props;

  const dispatch = useAppDispatch();

  const name = AppUtil.getObjectName(object);
  const id = AppUtil.getObjectId(object);

  let label = <span>View details</span>;

  if (name) {
    const nameWithTooltip = (
      <Tooltip title={id}>
        <span>{name}</span>
      </Tooltip>
    );
    label = nameWithTooltip;
  }

  return (
    <div className="answer-object-viewer">
      <div
        className="view-object-link"
        onClick={() => {
          dispatch(
            showPopup({
              popupId: AppConstants.POPUPS.VIEW_OBJECT,
              popupParams: {
                data: object
              }
            })
          );
        }}
      >
        {label}
      </div>
    </div>
  );
}

export default AnswerObjectViewer;
