import { Modal } from 'antd';
import React from 'react';
import S3DocumentViewer from '../components/S3DocumentViewer';
import { IS3ViewDetails } from '../stores/slices/run';
import './S3FileViewerPopup.less';

interface IProps extends IS3ViewDetails {
  onClose: () => void;
}

function S3FileViewerPopup(props: IProps) {
  const { onClose, s3Object, title, pdfConfig } = props;

  const handleClose = () => {
    onClose();
  };

  return (
    <Modal
      open
      onCancel={handleClose}
      centered
      className="s3-file-viewer-popup"
      width="80%"
      closable={false}
      footer={null}
    >
      <div>
        <S3DocumentViewer
          key={`${title}:${s3Object.key}`}
          title={title}
          s3Object={s3Object}
          onClose={handleClose}
          pdfConfig={{
            highlight: pdfConfig?.highlight,
            pageNumbers: pdfConfig?.pageNumbers,
            allowViewTypeChange: pdfConfig?.allowViewTypeChange
          }}
        />
      </div>
    </Modal>
  );
}

export default S3FileViewerPopup;
