import { useEffect, useRef, useState } from 'react';

export enum PageVisibilityType {
  VISIBLE = 'visible',
  HIDDEN = 'hidden'
}

const PAGE_HIDDEN_DELAY = 1 * 60 * 1000;

interface IOption {
  waitForDelay?: boolean;
}

function usePageVisibility(options?: IOption) {
  const { waitForDelay = true } = options || {};

  const [visibilityState, setVisibilityState] =
    useState<PageVisibilityType | null>(PageVisibilityType.VISIBLE);

  const timerRef = useRef<any>(null);

  const handleVisibilityChange = () => {
    const state = document.visibilityState;

    if (!waitForDelay) {
      setVisibilityState(
        state === 'hidden'
          ? PageVisibilityType.HIDDEN
          : PageVisibilityType.VISIBLE
      );
    } else {
      if (state === 'hidden') {
        timerRef.current = setTimeout(() => {
          setVisibilityState(PageVisibilityType.HIDDEN);
        }, PAGE_HIDDEN_DELAY);
      } else if (state === 'visible') {
        if (timerRef.current) {
          setVisibilityState(PageVisibilityType.VISIBLE);
          clearTimeout(timerRef.current);
        }
      }
    }
  };

  useEffect(() => {
    if (document.visibilityState) {
      document.addEventListener('visibilitychange', handleVisibilityChange);
    }

    return () => {
      if (document.visibilityState) {
        document.removeEventListener(
          'visibilitychange',
          handleVisibilityChange
        );
      }
    };
  }, []);

  return {
    visibilityState,
    pageHidden: visibilityState === PageVisibilityType.HIDDEN
  };
}

export default usePageVisibility;
