import { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import {
  CheckOutlined,
  CloseOutlined,
  DeleteOutlined,
  EditOutlined,
  LoadingOutlined
} from '@ant-design/icons';
import { Button, Input, Tooltip } from 'antd';
import React, { useEffect, useState } from 'react';
import styles from './EditableStringFact.module.less';

interface IEditableStringFactProps {
  children: React.ReactNode;
  factType: {
    type: FormattedAnswerTypeV2;
    answer: any;
  };
  onEdit?: (data: string) => Promise<unknown>;
  onDelete?: () => Promise<unknown>;
}

const { TextArea } = Input;

function EditableStringFact(props: IEditableStringFactProps) {
  const { children, factType, onEdit, onDelete } = props;
  const [isDeleteClicked, setIsDeleteClicked] = useState(false);
  const [isEditClicked, setIsEditClicked] = useState(false);
  const [editValue, setEditValue] = useState(factType.answer);

  const [editLoading, setEditLoading] = useState(false);
  const [deleteLoading, setDeleteLoading] = useState(false);

  useEffect(() => {
    setDeleteLoading(false);
    setIsDeleteClicked(false);
  }, [factType.answer]);

  const isInReadMode = !isEditClicked && !isDeleteClicked;
  const hasEditFunctions = onEdit || onDelete;

  const fact = (
    <div className={styles['editable-container']}>
      {isEditClicked ? (
        <>
          <TextArea
            rows={editValue.length / 40 + 1}
            style={{ width: '80%' }}
            value={editValue}
            disabled={editLoading}
            onChange={(e: { target: { value: any } }) =>
              setEditValue(e.target.value)
            }
          />
          <span className={styles['edit-actions-container']}>
            {editLoading ? (
              <LoadingOutlined />
            ) : (
              <>
                {editValue && (
                  <Tooltip title="confirm">
                    <Button
                      danger
                      type="primary"
                      shape="circle"
                      size="small"
                      icon={<CheckOutlined />}
                      onClick={() => {
                        if (onEdit) {
                          setEditLoading(true);
                          onEdit(editValue).finally(() => {
                            setEditLoading(false);
                            setIsEditClicked(false);
                          });
                        }
                      }}
                    />
                  </Tooltip>
                )}
                <Tooltip title="cancel">
                  <Button
                    size="small"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => setIsEditClicked(false)}
                  />
                </Tooltip>
              </>
            )}
          </span>
        </>
      ) : (
        children
      )}
      {isInReadMode && hasEditFunctions && (
        <span className={styles['actions-container']}>
          {onEdit && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => {
                setIsEditClicked(true);
                setEditValue(factType.answer);
              }}
            >
              <Tooltip title="Edit">
                <EditOutlined />
              </Tooltip>
            </span>
          )}
          {onDelete && (
            <span
              style={{ cursor: 'pointer' }}
              onClick={() => setIsDeleteClicked(true)}
            >
              <Tooltip title="Delete">
                <DeleteOutlined />
              </Tooltip>
            </span>
          )}
        </span>
      )}
    </div>
  );

  return (
    <span>
      {isDeleteClicked ? (
        <div className={{ ...styles.dim, ...styles['delete-container'] }}>
          <span className={styles['delete-fact-container']}>{fact}</span>
          <span className={styles['delete-actions-container']}>
            Delete?
            {deleteLoading ? (
              <LoadingOutlined />
            ) : (
              <span className={styles['delete-buttons-container']}>
                <Tooltip title="confirm">
                  <Button
                    danger
                    type="primary"
                    shape="circle"
                    size="small"
                    icon={<CheckOutlined />}
                    onClick={() => {
                      if (onDelete) {
                        setDeleteLoading(true);
                        onDelete().finally(() => {
                          setIsDeleteClicked(false);
                          setDeleteLoading(false);
                        });
                      }
                    }}
                  />
                </Tooltip>
                <Tooltip title="cancel">
                  <Button
                    size="small"
                    shape="circle"
                    icon={<CloseOutlined />}
                    onClick={() => setIsDeleteClicked(false)}
                  />
                </Tooltip>
              </span>
            )}
          </span>
        </div>
      ) : (
        fact
      )}
    </span>
  );
}

export default EditableStringFact;
