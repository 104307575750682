import { Question } from '@/generated/API';
import { RootState } from '@/stores/AppStore';
import {
  getWorkerDependencies,
  getWorkerDependenciesV2
} from '@/stores/slices/worker';
import { createEntityAdapter, createSlice } from '@reduxjs/toolkit';

export const QuestionAdapter = createEntityAdapter<Question>();

export const questionSlice = createSlice({
  name: 'question',
  initialState: QuestionAdapter.getInitialState({
    loading: false,
    error: undefined,
    nextToken: undefined
  }),
  reducers: {
    updateQuestion: QuestionAdapter.updateOne
  },
  extraReducers: (builder) => {
    [
      getWorkerDependencies.fulfilled,
      getWorkerDependenciesV2.fulfilled
    ].forEach((action) => {
      builder.addCase(action, (state, action) => {
        if (action.payload.data?.listCommandsByWorker?.items) {
          const questions =
            action.payload.data.listCommandsByWorker.items.reduce(
              (acc, cur) => {
                if (!cur?.debug) {
                  return acc.concat((cur as any).questions.items);
                }
                return acc;
              },
              []
            );
          QuestionAdapter.upsertMany(state, questions);
        }
      });
    });
  }
});

export const { updateQuestion } = questionSlice.actions;
export const questionSelectors = QuestionAdapter.getSelectors<RootState>(
  (state) => state.question
);

export default questionSlice.reducer;
