/* eslint-disable react/style-prop-object */
/* eslint-disable react/no-unused-prop-types */
/* eslint-disable no-shadow */
//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Loader is a component that shows a loader
//

// 3rd party libraries
import React, { useRef, useState, useEffect } from 'react';
import AppConstants from '@utils/AppConstants';
import ComponentFactory from '@utils/ComponentFactory';
import './Loader.less';
import { Skeleton, Spin } from 'antd';
import LoadingMessages from './loading_messages.js';

interface ILoaderDefaultProps {
  message: string;
}

function LoaderDefault(props: ILoaderDefaultProps) {
  const { message } = props;

  return (
    <div className="loader default">
      <div className="icon">
        {ComponentFactory.getIcon(AppConstants.ICONS.LOADING)}
      </div>
      <div className="msg">{message}</div>
    </div>
  );
}

function LoaderFullPage() {
  return (
    <div className="loader full">
      <Spin size="large" />
    </div>
  );
}

interface IBrainyLoaderProps {
  message: string;
}
function BrainyLoader(props: IBrainyLoaderProps) {
  // Poor mans typewriter effect.

  const messageDiv = useRef<HTMLDivElement>(null);
  const [hasAnimated, setHasAnimated] = useState(false);
  const [animationIdx, setAnimationIdx] = useState(0);

  useEffect(() => {
    if (messageDiv.current && props.message.length) {
      const msgDiv = messageDiv.current as HTMLDivElement;
      // Silly heurisitc - we should pass speed in as a prop
      const speedMillis = props.message.length * 10;
      if (!hasAnimated) {
        msgDiv.innerText = props.message.slice(0, animationIdx);
        if (animationIdx < props.message.length) {
          setTimeout(() => setAnimationIdx(animationIdx + 1), speedMillis);
        } else {
          setHasAnimated(true);
        }
      } else {
        msgDiv.innerText = `${props.message}`;
      }
    }
  }, [props.message, animationIdx]);

  return (
    <div className="loader brainy">
      <div className="-icon">
        {ComponentFactory.getIcon(AppConstants.ICONS.BRAIN_LOGO)}
      </div>
      <div className="-message" ref={messageDiv} />
    </div>
  );
}

export enum ILoaderType {
  DEFAULT = 'DEFAULT',
  FULL_PAGE = 'FULL_PAGE',
  SKELETON = 'SKELETON',
  BRAINY = 'BRAINY'
}

interface IProps {
  type?: ILoaderType;
  message?: string;
  skeletonConfig?: {
    rows: number;
  };
  noMessage?: boolean;
}

function Loader(props: IProps) {
  const { type, message, skeletonConfig, noMessage = false } = props;

  if (type === ILoaderType.FULL_PAGE) {
    return <LoaderFullPage />;
  }

  if (type === ILoaderType.SKELETON) {
    return (
      <Skeleton
        active
        paragraph={{
          rows: skeletonConfig?.rows
        }}
        style={{
          padding: 16
        }}
      />
    );
  }

  if (type === ILoaderType.BRAINY) {
    const messageIdx =
      Math.round(Math.random() * 100000) % LoadingMessages.length;
    const message = LoadingMessages[messageIdx] || 'Loading...';
    return <BrainyLoader message={message} />;
  }

  return <LoaderDefault message={noMessage ? '' : message || 'Loading...'} />;
}

export default Loader;
