import { isEqual } from 'lodash/fp';
import { useRef, useState } from 'react';
import { useSelector } from 'react-redux';
import { useCustomCompareLayoutEffect } from 'use-custom-compare';
import { Department, Request } from '../generated/API';
import { stepsQuerySelector } from '../stores/slices/steps';
import AppUtil from '../utils/AppUtil';
import { RunContextType } from '../provider/runv2';

const isStepNodeHidden = (node: string): boolean => {
  const nodeElement = document.getElementById(`run-item-${node}`);
  return nodeElement === null;
};

export type StepRequestsMap = Record<string, Request[]>;

interface IProps {
  requests: Request[];
  updateExpandedNodes: RunContextType['updateExpandedNodes'];
  department: Department | undefined;
}

function useStepRequests(props: IProps): {
  stepRequestsMap: StepRequestsMap;
} {
  const { requests, department, updateExpandedNodes } = props;

  const { steps } = useSelector(stepsQuerySelector);
  const [stepRequestsMap, setStepRequestsMap] = useState<StepRequestsMap>({});

  const timerRef = useRef<any>(null);

  const prepareStepRequestsMap = () => {
    if (requests.length > 0) {
      const allStepNodes = requests.map((question) => ({
        node: AppUtil.getStepNodeFromQuestion(
          question.contextPath,
          question.contextId
        ),
        questionId: question.id
      }));

      const newStepRequestsMap = allStepNodes.reduce<any>((acc, cur) => {
        if (cur.node) {
          let key: string = cur.node!;

          const questionsList = [
            ...(acc[key] || []),
            requests.find((q) => q.id === cur.questionId)
          ];

          const isNodeHidden = isStepNodeHidden(cur.node!);

          if (isNodeHidden) {
            // console.log('-----------');
            // console.log(key, questionsList);

            const firstQuestion = questionsList[0];
            const contextList: any[] = firstQuestion.contextPath;

            const [currentContext] = cur.node!.split(':');

            const filteredContextList = contextList
              .filter((context) => context.ctxId !== Number(currentContext))
              .reverse();

            const expandNodesList = filteredContextList.map(
              (context) => `${context.ctxId}:${context.sentenceId}`
            );
            updateExpandedNodes(expandNodesList, false);
            const visibleParentNode = filteredContextList[0];

            if (visibleParentNode) {
              key = `${visibleParentNode.ctxId}:${visibleParentNode.sentenceId}`;
            }
          }

          acc[key] = [
            ...(acc[key] || []),
            requests.find((q) => q.id === cur.questionId)
          ];
        }
        return acc;
      }, {});

      setStepRequestsMap(newStepRequestsMap);
    }
  };

  useCustomCompareLayoutEffect(
    () => {
      const requestExceptionSupported =
        AppUtil.isRequestExceptionSupported(department);

      if (department && requestExceptionSupported) {
        timerRef.current = setTimeout(() => {
          prepareStepRequestsMap();
        }, 2000);
      }

      return () => {
        setStepRequestsMap({});
        if (timerRef.current) {
          clearTimeout(timerRef.current);
        }
      };
    },
    // TODO:
    [requests, steps],
    (prevDeps, nextDeps) => isEqual(prevDeps, nextDeps)
  );

  return { stepRequestsMap };
}

export default useStepRequests;
