import RunFactRendererV2 from '@/components/playground2/RunFactRendererV2';
import { Step } from '@/generated/API';
import useFactV2 from '@/hooks/useFactV2';
import { useRunCtxV2 } from '@/provider/runv2';
import FormattingUtil, { FormattedAnswerTypeV2 } from '@/utils/FormattingUtil';
import React from 'react';
import './LongAnswer.less';
import { Divider, Pagination } from 'antd';
import { getFactType } from '@/utils/facts';
import usePaginationState from '@/hooks/usePaginationState';
import { LoadingOutlined } from '@ant-design/icons';

interface IProps {
  step: Step;
}

const PAGE_SIZE = 10;

function LongAnswer(props: IProps) {
  const { step } = props;
  const answer = step.answer;

  const { worker } = useRunCtxV2();
  if (!worker) {
    return null;
  }

  // Answer can be null or an object
  const parsedAnswer = answer ? FormattingUtil.parseBrainValue(answer) : {};
  // const editableValue = getTerminalValue(parsedAnswer);

  if (!parsedAnswer) {
    return null;
  }

  let cptIds: string[] = [];
  if (parsedAnswer.concept) {
    cptIds = [parsedAnswer.concept.id];
  }
  if (parsedAnswer.concepts) {
    parsedAnswer.concepts.items.forEach((cpt: any) => {
      const ans = cpt ? FormattingUtil.decodeBrainValue(cpt) : {};
      cptIds.push(ans?.concept?.id);
    });
  }

  const { pageNumber, updatePageNumber, pageSize, total } = usePaginationState({
    totalCount: cptIds.length,
    pageSize: PAGE_SIZE
  });

  const {
    getFactById,
    loading,
    ids: currentCpts
  } = useFactV2({
    ids: cptIds,
    knowledgeId: worker!.knowledgeId!,
    paginationState: {
      pgNumber: pageNumber,
      pgSize: pageSize
    }
  });

  if (parsedAnswer.concept) {
    if (loading) {
      return (
        <div className="long-answer">
          <LoadingOutlined />
        </div>
      );
    }
    const fact = getFactById(cptIds[0]);
    const factType = getFactType(fact);
    return fact ? (
      <div className="long-answer">
        <RunFactRendererV2
          fact={fact}
          noTextMassage={factType.type === FormattedAnswerTypeV2.RESULT}
          factType={factType}
          knowledgeId={worker!.knowledgeId || ''}
        />
      </div>
    ) : null;
  }

  const fact = getFactById(currentCpts[0]);
  const factType = getFactType(fact);
  if (fact && factType.type === FormattedAnswerTypeV2.TABLES_ROW) {
    return (
      <div className="long-answer">
        <RunFactRendererV2
          fact={fact}
          factType={factType}
          knowledgeId={worker?.knowledgeId!}
          stepAnswer={step.answer!}
        />
      </div>
    );
  }

  return (
    <div className="long-answer">
      {total > pageSize && (
        <div className="pagination-container">
          <Pagination
            pageSize={pageSize}
            showSizeChanger={false}
            size="small"
            defaultCurrent={pageNumber}
            total={total}
            onChange={(pgNumber) => updatePageNumber(pgNumber)}
          />
        </div>
      )}
      {loading ? (
        <div>
          <LoadingOutlined />
        </div>
      ) : (
        <ul className="list-style">
          {currentCpts.map((cpt, idx) => {
            const fact = getFactById(cpt);
            const factType = getFactType(fact);
            return (
              <li>
                {fact ? (
                  <RunFactRendererV2
                    fact={fact}
                    noTextMassage={
                      factType.type === FormattedAnswerTypeV2.RESULT
                    }
                    factType={factType}
                    knowledgeId={worker!.knowledgeId || ''}
                  />
                ) : null}
                {idx !== currentCpts.length - 1 && (
                  <Divider className="divider" />
                )}
              </li>
            );
          })}
        </ul>
      )}
    </div>
  );
}

export default LongAnswer;
