import { gql } from '@apollo/client';
import { onNotifyKChatMessageUpdate } from '../generated/graphql/subscriptions';

const onStepsUpdated = /* GraphQL */ `
  subscription OnStepsUpdated($workerId: ID!) {
    onStepsUpdated(workerId: $workerId) {
      workerId
      stepList {
        id
        contextId
        workerId
        number
        commandId
        createdAt
        scratchedAt
        text
        answer
        childContextIdList
        concepts
        questionIds
        positionInContext
        sentencePath {
          ctxId
          sentenceId
        }
      }
    }
  }
`;

const onWorkerKnowledgeIdUpdate = /* GraphQL */ `
  subscription OnNotifyWorkerUpdate($id: ID!) {
    onNotifyWorkerUpdate(id: $id) {
      id
      knowledgeId
    }
  }
`;

const onWorkerCreationFromEmail = /* GraphQL */ `
  subscription OnNotifyWorkerCreationFromEmail($procedureId: ID!) {
    onNotifyWorkerCreationFromEmail(procedureId: $procedureId) {
      id
      name
      createdAt
      originalWorkerId
      state
    }
  }
`;

const Subscriptions = {
  OnStepsUpdated() {
    return gql(onStepsUpdated);
  },

  onWorkerKnowledgeIdUpdate() {
    return gql(onWorkerKnowledgeIdUpdate);
  },

  onWorkerCreationFromEmail() {
    return gql(onWorkerCreationFromEmail);
  },

  OnNotifyKChatMessageUpdate() {
    return gql(onNotifyKChatMessageUpdate);
  }
};

export default Subscriptions;
