//
// Copyright (C) - Kognitos, Inc. All rights reserved
//
// Application wide utility functions
//

import { createAuthLink } from 'aws-appsync-auth-link';
import { createSubscriptionHandshakeLink } from 'aws-appsync-subscription-link';
import {
  split,
  ApolloClient,
  ApolloLink,
  createHttpLink,
  InMemoryCache
} from '@apollo/client';
import { getMainDefinition } from '@apollo/client/utilities';
import { GraphQLWsLink } from '@apollo/client/link/subscriptions';
import * as Sentry from '@sentry/react';
import { SentryLink } from 'apollo-link-sentry';
import { onError } from '@apollo/client/link/error';
import { createClient } from 'graphql-ws';
import apolloLogger from 'apollo-link-logger';
import _cloneDeep from 'lodash/fp/cloneDeep';
import _get from 'lodash/fp/get';
import moment from 'moment';
import emailProviders from 'email-providers/all.json';
import tldExtract from 'tld-extract';
import Fuse from 'fuse.js';
import { delayExponentialBackoff } from '@/utils/promise';
import AppConstants from '@utils/AppConstants';
import awsconfig from '@/generated/aws-exports';
import ENV_CONFIG from '@/EnvConstants';
import dayjs from 'dayjs';
import { removeKogLineBreak } from '@/components/editor/helper';
import { DepartmentFeature } from '@/generated/API';
import FormattingUtil from './FormattingUtil';

// TODO: use logging library.
const logLevelError = 0;
const logLevelDebug = 1;
const currentLogLevel = logLevelError;

const errorLink = onError(({ graphQLErrors, networkError, operation }) => {
  if (graphQLErrors) {
    Sentry.withScope((scope) => {
      graphQLErrors.forEach((gError) => {
        const { message, locations, path, errorType } = gError;

        if (errorType === 'UnauthorizedException') {
          // This reload is acceptable since the end goal is to force
          // login.
          window.location.reload();
        }

        const locationz = JSON.stringify(locations);
        scope.setTags({
          operation,
          path,
          message
        });
        scope.setExtra('graphqlError', {
          message,
          locations: locationz,
          path,
          errorType
        });
        Sentry.captureMessage(message);
        console.log(
          `[GraphQL error]: Message: ${message}, Location: ${locationz}, Path: ${path}, ErrorType: ${errorType}`
        );
      });
    });
  }
  if (networkError) console.log(`[Network error]: ${networkError}`);
});

const AppUtil = {
  logDebug(...msg) {
    if (currentLogLevel >= logLevelDebug) {
      // eslint-disable-next-line no-console
      console.log(...msg);
    }
  },

  logError(...args) {
    if (currentLogLevel >= logLevelError) {
      // eslint-disable-next-line no-console
      console.error(...args);
    }
  },

  // Validate an email string
  validateEmail(email) {
    const re =
      // eslint-disable-next-line no-useless-escape
      /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
    return re.test(email);
  },

  isPersonalEmail(email) {
    const broken = email.split('@');
    const address = `http://${broken[broken.length - 1]}`;
    const { domain } = tldExtract(address);
    return emailProviders.includes(domain);
  },

  // Return a random string of length size_bytes
  getRandomString(sizeBytes) {
    const randomValues = new Uint8Array(sizeBytes);
    window.crypto.getRandomValues(randomValues);
    return Array.from(randomValues)
      .map((nr) => nr.toString(16).padStart(2, '0'))
      .join('');
  },

  // Returns the type of a user
  getUserType(_user) {
    // Return hard-coded user type for now; eventually, get this based on groups.
    return AppConstants.USER_TYPES.SME;
  },

  // Application wide Code Mirror options
  getCodeMirrorOptions(procedureType) {
    return {
      theme: 'eclipse', // if you change this, update index.js as well
      lineNumbers: true,
      lineWrapping: true,
      gutters: ['CodeMirror-linenumbers'],
      mode: AppConstants.PROCEDURE_TYPE_MAP[procedureType].codeMode
    };
  },

  // Returns a production apollo client linked to the backend
  getProdApolloClient(getJwtToken) {
    const url = awsconfig.aws_appsync_graphqlEndpoint;
    const region = awsconfig.aws_appsync_region;
    const auth = {
      type: awsconfig.aws_appsync_authenticationType,
      jwtToken: getJwtToken
    };

    const httpLink = createHttpLink({
      uri: ({ operationName }) => `${url}?op=${operationName}`
    });

    const handlers = [
      new SentryLink(/* See options */),
      errorLink,
      createAuthLink({
        url,
        region,
        auth
      }),
      createSubscriptionHandshakeLink({ url, region, auth }, httpLink)
    ];

    if (currentLogLevel >= logLevelDebug) {
      handlers.unshift(apolloLogger);
    }

    const appsyncLink = ApolloLink.from(handlers);

    const defaultOptions = {
      watchQuery: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none'
      },
      query: {
        fetchPolicy: 'no-cache',
        errorPolicy: 'none'
      }
    };

    const cache = new InMemoryCache({
      typePolicies: {}
    });

    const wsLink = new GraphQLWsLink(
      createClient({
        url: awsconfig.graphql_ws_url,
        shouldRetry: () => true,
        retryAttempts: 5,
        retryWait: delayExponentialBackoff
      })
    );

    window.wsLink = wsLink;

    // apollo-link-ws
    // const wsLink = new WebSocketLink({
    //   uri: url,
    //   options: {
    //     reconnect: true
    //   }
    // });

    const splitLink = split(
      ({ query }) => {
        const definition = getMainDefinition(query);
        return (
          definition.kind === 'OperationDefinition' &&
          definition.operation === 'subscription'
        );
      },
      wsLink,
      appsyncLink
    );

    return new ApolloClient({
      link: awsconfig.graphql_ws_url ? splitLink : appsyncLink,
      connectToDevTools: true,
      cache,
      defaultOptions
    });
  },

  // Returns a development apollo client linked to the mockserver
  getDevApolloClient() {
    const handlers = [createHttpLink({ uri: 'http://localhost:3010/graphql' })];
    if (currentLogLevel >= logLevelDebug) {
      handlers.unshift(apolloLogger);
    }
    const link = ApolloLink.from(handlers);

    return new ApolloClient({
      link,
      connectToDevTools: true,
      cache: new InMemoryCache({
        addTypename: false
      })
    });
  },

  getFromLocalStorage(key) {
    const prefixedKey = `${AppConstants.STORAGE_KEYS.PREFIX}.${key}`;
    return localStorage.getItem(prefixedKey);
  },

  setInLocalStorage(key, value) {
    const prefixedKey = `${AppConstants.STORAGE_KEYS.PREFIX}.${key}`;
    return localStorage.setItem(prefixedKey, value);
  },

  removeFromLocalStorage(key) {
    const prefixedKey = `${AppConstants.STORAGE_KEYS.PREFIX}.${key}`;
    return localStorage.removeItem(prefixedKey);
  },

  // Safe parse a json string
  // (Do not call JSON.parse directly - hard to debug failures)
  safeParseJSON(jsonString, returnOriginal = false) {
    if (jsonString) {
      try {
        return JSON.parse(jsonString);
      } catch (ex) {
        AppUtil.logError(`Invalid JSON: ${jsonString}`);
      }
    }
    return returnOriginal ? jsonString : {};
  },

  hasPermissionError(error) {
    if (!error?.graphQLErrors?.length) {
      return false;
    }
    return error.graphQLErrors.some(
      (gErr) => gErr?.errorType === 'PermissionError'
    );
  },

  // Basic clone of an object
  simpleClone(jsObject) {
    return AppUtil.safeParseJSON(JSON.stringify(jsObject));
  },

  // CSV converter
  convert2CSV(entities, displayColumns) {
    const ret = entities.map((entity) => {
      const csvLine = [];
      displayColumns.forEach((col) => {
        let csvValue = _get(col.dataIndex, entity);
        // Column level exporter.
        if (col.export) {
          csvValue = col.export(csvValue);
        }

        csvLine.push(`"${csvValue || '-'}"`);
      });
      return csvLine.join(',');
    });
    return ret.join('\n');
  },

  // Key handler - checks for enter key
  onEnter(event, func) {
    const code = event.keyCode ? event.keyCode : event.which;
    if (code === 13) {
      func();
    }
    return false;
  },

  // Update a questions' answer
  updateAnswer(question, answer) {
    if (question.questionType === AppConstants.QUESTION_TYPES.MULTIPLE_SELECT) {
      // eslint-disable-next-line no-param-reassign
      question.answers = answer;
    } else {
      // eslint-disable-next-line no-param-reassign
      question.answers = [answer];
    }
  },

  // Get tree node path
  treeNodePath(treeNode) {
    if (treeNode) {
      const pNodes = treeNode.getPath().splice(1); // remove root node
      return pNodes.map((np) => np.model.id).join('/');
    }
    return '';
  },

  // Is given tree node a leaf
  isLeafTreeNode(treeNode) {
    return treeNode?.children?.length === 0;
  },

  // Tree Model - Get node by id under a portion of the tree
  findNodeById(fromNode, nodeId) {
    let ret;
    AppUtil.TreeWalk(fromNode, (n) => {
      if (n.id === nodeId) {
        ret = n;
      }
    });
    return ret;
  },

  // Render a tree node's value
  renderTreeNodeValue(nodeData) {
    if (nodeData && nodeData.value) {
      if (nodeData.value.join) {
        return nodeData.value.join(', ');
      }
      if (nodeData.type === 'date') {
        return new Date(Number(nodeData.value)).toLocaleDateString();
      }
      return nodeData.value;
    }
    return null;
  },

  // Simple Tree walker
  TreeWalk(node, visitFunc) {
    visitFunc(node);
    if (node.children) {
      node.children.forEach((c) => AppUtil.TreeWalk(c, visitFunc));
    }
  },

  // Insert a tree node after the given treeNode
  insertTreeNode(treeData, treeNode, newNode) {
    // eslint-disable-next-line no-param-reassign
    newNode.parentId = treeNode.parentId;
    const parentTreeNode = AppUtil.findNodeById(treeData, treeNode.parentId);
    const newChildren = [];
    parentTreeNode.children.forEach((c) => {
      newChildren.push(c);
      if (c.id === treeNode.id) {
        newChildren.push(newNode);
      }
    });
    parentTreeNode.children = newChildren;
  },

  // Update a tree node (type/name)
  // if type changes, validations are removed
  updateTreeNode(treeNode, newName, newType) {
    // Successful - so update local one
    const hasTypeChanged = treeNode.type !== newType;
    // eslint-disable-next-line no-param-reassign
    treeNode.name = newName;
    // eslint-disable-next-line no-param-reassign
    treeNode.type = newType;
    if (hasTypeChanged) {
      // eslint-disable-next-line no-param-reassign
      treeNode.validationList = [];
    }
    treeNode.data.forEach((d) => {
      d.value = '';
    });
  },

  // Insert a tree node after the given treeNode
  deleteTreeNode(treeData, treeNode) {
    const parentTreeNode = AppUtil.findNodeById(treeData, treeNode.parentId);
    const newChildren = [];
    parentTreeNode.children.forEach((c) => {
      if (c.id !== treeNode.id) {
        newChildren.push(c);
      }
    });
    parentTreeNode.children = newChildren;
  },

  // Get contextInfo by ID
  // @return { data, mimetype, url }
  contextInfo(contexts, contextId) {
    const context = contexts.find((c) => c.id === contextId);
    if (context) {
      const ret = {
        data: context.data,
        mimeType: context.mimeType,
        url: ''
      };
      if (context.mimeType.startsWith('image/') && context.data) {
        ret.url = `data:${context.mimeType};base64,${context.data}`;
      } else if (context.url) {
        ret.url = context.url;
      }
      return ret;
    }

    return null;
  },

  millisFromDateString(dateString) {
    return new Date(Date.parse(dateString)).getTime();
  },

  getS3URIParts(value) {
    const matches = `${value}`.match(AppConstants.PATTERNS.S3_URI);
    if (matches) {
      const parts = matches[1].split('/');
      const bucket = parts[0];
      const key = parts.slice(1).join('/');
      const [filename] = parts.slice(-1);

      return {
        bucket,
        key,
        filename
      };
    }

    return null;
  },

  // Get Poll interval for queries in millis
  getQueryPollInterval() {
    return 2000;
  },

  // Helper to programmatically update the current user's columns
  updateUserColumns(gridId, defaultColumns, columnId, bAdd) {
    const localStorageKey = `${AppConstants.STORAGE_KEYS.GRID_CONFIG}.${gridId}`;
    let userConfig = AppUtil.safeParseJSON(
      AppUtil.getFromLocalStorage(localStorageKey)
    );
    if (!userConfig.columns) {
      userConfig = {
        columns: defaultColumns,
        pageSize: AppConstants.GRID_PAGE_SIZES[0]
      };
    }
    if (bAdd) {
      // Add the new column to the end
      userConfig.columns.push(columnId);
      AppUtil.setInLocalStorage(localStorageKey, JSON.stringify(userConfig));
    } else {
      // Remove only if user had non-default
      const idx = userConfig.columns.indexOf(columnId);
      if (idx >= 0) {
        userConfig.columns.splice(idx, 1);
        AppUtil.setInLocalStorage(localStorageKey, JSON.stringify(userConfig));
      }
    }
  },

  // Helper to disable grid actions
  updateGridActions(actions, selectionInfo, disabledKeys) {
    const actionsCopy = _cloneDeep(actions);
    actionsCopy.forEach((a) => {
      switch (a.scope) {
        case 'single':
          // eslint-disable-next-line no-param-reassign
          a.disabled = selectionInfo?.keys.length !== 1 ?? true;
          break;
        case 'batch':
          // eslint-disable-next-line no-param-reassign
          a.disabled = selectionInfo?.keys.length === 0 ?? true;
          break;
        default:
          break;
      }

      if (disabledKeys.includes(a.id)) {
        // eslint-disable-next-line no-param-reassign
        a.disabled = true;
      }
    });
    return actionsCopy;
  },

  generateUniqueID() {
    const four = () => Math.round(Math.random() * 10000);
    return Number(`${four()}${four()}`);
  },

  /* *** HACK for demo: remove *** */
  removeToFromName(name, search = '') {
    if (!name) {
      return '';
    }
    if (
      name.toLowerCase().startsWith('to ') &&
      !search.toLowerCase().startsWith('to')
    ) {
      return name.slice(3);
    }
    return name;
  },

  isDepartmentValid(department) {
    return [
      department?.draftKnowledgeId,
      department?.publishedKnowledgeId
    ].some((id) => id !== null);
  },

  filterPrivateValue(item) {
    return !item?.startsWith('_');
  },

  listFilterPrivateValue(list) {
    return list.filter(AppUtil.filterPrivateValue);
  },

  isOrganizationAdmin(userId, organization) {
    return organization !== null && organization?.owner === userId;
  },

  canCreateDepartment(userId, organization) {
    const isOrgAdmin = AppUtil.isOrganizationAdmin(userId, organization);
    if (!organization) {
      return true;
    }
    if (organization && isOrgAdmin) {
      return true;
    }
    return false;
  },

  getSelectionText() {
    let text = '';
    if (window.getSelection) {
      text = window.getSelection().toString();
    } else if (document.selection && document.selection.type !== 'Control') {
      text = document.selection.createRange().text;
    }
    return text;
  },

  isProdEnv() {
    return awsconfig.environment === 'production';
  },

  isSandboxEnv() {
    return awsconfig.environment === 'sandbox';
  },

  isDevEnv() {
    return !(AppUtil.isProdEnv() || AppUtil.isSandboxEnv());
  },

  isTestEnv() {
    return !(
      this.isProdEnv() ||
      this.isSandboxEnv() ||
      awsconfig.environment === 'main'
    );
  },

  isProcedurePromptEnabled() {
    const localValue = AppUtil.getFromLocalStorage('procedure-prompt-enabled');
    if (localValue) {
      return JSON.parse(localValue) === true;
    }
    return false;
  },

  allowDepLocalUpdate() {
    // We deliberately want to disable showing/updating a department's local state on prod and sandbox. https://linear.app/kognitos/issue/KOG-1384/prevent-set-local-on-production-customer-departments
    return AppUtil.isDevEnv();
  },

  // TODO: DEP-BOOK: REMOVE - find all the references of this method and remove the else condition from there
  isDepartmentBookSupported(department) {
    if (!department?.features) {
      return false;
    }
    return department.features.includes(DepartmentFeature.DEPARTMENT_BOOK);
  },

  isRequestExceptionSupported(department) {
    if (!department?.features) {
      return false;
    }
    return department.features.includes(DepartmentFeature.EXCEPTION_REQUEST);
  },

  // TODO: Remove
  areQuestionFormValuesValid(values) {
    let valid = false;

    switch (values.inputType) {
      case 'value':
        valid = !!values.value;
        break;
      case 'technique':
        valid = !!values.technique;
        break;
      case 'choice':
        valid = values.choice !== undefined;
        break;
      case 'delegate':
        valid = !!(values.delegate_email && values.delegate_message);
        break;
      case 'upload':
        valid = !!values.upload;
        break;
      case 'book':
        valid = !!values.book_option;
        break;
      case 'stop':
      case 'skip':
      case 'retry':
        valid = true;
        break;
      default:
        break;
    }

    return valid;
  },

  prepareAnswerInputVariables({ question, values, owner }) {
    let text = null;
    let type = 'value'; // Allows submitting empty value
    let params = null;

    switch (values.inputType) {
      case 'value':
        text = FormattingUtil.encodeBrainValue(values.value || '');
        type = values.remember ? 'value_learning' : 'value';
        break;
      case 'technique':
        text = FormattingUtil.encodeBrainValue(values.technique);
        type = values.remember ? 'path_learning' : 'path';
        break;
      case 'choice':
        text = JSON.stringify(values.choice);
        type = values.remember ? 'choice_learning' : 'choice';
        break;
      case 'delegate':
        params = {
          method: 'email',
          recipients: [values.delegate_email],
          parameters: JSON.stringify({ body: values.delegate_message })
        };
        text = JSON.stringify(params);
        type = 'delegate';
        break;
      case 'upload':
        if (values.upload.length > 1) {
          text = values.upload.map((uploadResponse) =>
            FormattingUtil.encodeBrainValue(uploadResponse.s3Url)
          );
        } else {
          text = FormattingUtil.encodeBrainValue(values.upload[0].s3Url);
        }
        type = values.remember ? 'value_learning' : 'value';
        break;
      case 'book':
        text = FormattingUtil.encodeBrainValue(values.book_option);
        type = 'book';
        break;
      case 'stop':
        type = 'stop';
        break;
      case 'skip':
        type = 'skip';
        break;
      case 'retry':
        type = 'retry';
        break;
      default:
        break;
    }

    return {
      commandId: question.commandId,
      workerId: question.workerId,
      questionId: question.id,
      owner,
      secret: values.secret,
      text,
      type
    };
  },

  getPlural(word, count) {
    if (count === 1) {
      return word;
    }
    return `${word}s`;
  },

  getTimeSpanDelta(span) {
    const deltas = {
      day: AppConstants.MILLIS_PER_DAY,
      week: AppConstants.MILLIS_PER_WEEK,
      month: AppConstants.MILLIS_PER_MONTH
    };
    return deltas[span];
  },

  // Generate a time range covering a span
  // Days start at 12:00AM and end at 11:59PM
  generateTimeRange(span, endAt) {
    const m = moment(endAt);
    m.hours(0);
    m.minutes(0);
    m.seconds(0);

    const delta = AppUtil.getTimeSpanDelta(span);
    const start = m.valueOf() - delta;
    const end = m.add(1, 'd').subtract(1, 'm').valueOf();

    return {
      span,
      outerStart: start,
      outerEnd: end,
      innerStart: start,
      innerEnd: end
    };
  },

  // checks if the user had filled up a schedule
  hasFilledSchedule(schedule) {
    let ret;
    const hasEveryFewMins = !!(
      schedule.byEveryFewMinutes && schedule.byEveryFewMinutes.length > 0
    );
    const hasMinutes = !!(schedule.byminute && schedule.byminute.length > 0);
    const hasHours = !!(schedule.byhour && schedule.byhour.length > 0);
    const hasWeekday = !!(schedule.byweekday && schedule.byweekday.length > 0);
    const hasMonthDay = !!(
      schedule.bymonthday && schedule.bymonthday.length > 0
    );

    switch (schedule.freq) {
      case AppConstants.SCHEDULE_TYPES.FEW_MINUTES:
        ret = hasEveryFewMins;
        break;
      case AppConstants.SCHEDULE_TYPES.HOURLY:
        ret = hasMinutes;
        break;
      case AppConstants.SCHEDULE_TYPES.DAILY:
        ret = hasMinutes && hasHours;
        break;
      case AppConstants.SCHEDULE_TYPES.WEEKLY:
        ret = hasMinutes && hasHours && hasWeekday;
        break;
      case AppConstants.SCHEDULE_TYPES.MONTHLY:
        ret = hasMinutes && hasHours && hasMonthDay;
        break;
      default:
        ret = false;
        break;
    }
    return ret;
  },

  // Convert a schedule to/from UTF and the current timezone
  convertSchedule(schedule, bToUTC) {
    const covertedRule = { ...schedule };

    // Set inital timezone
    const mmt = moment();
    if (bToUTC) {
      mmt.local();
    } else {
      mmt.utc();
    }

    // set the target timezone
    const setTargetTz = () => {
      if (bToUTC) {
        mmt.utc();
      } else {
        mmt.local();
      }
    };

    switch (schedule.freq) {
      case AppConstants.SCHEDULE_TYPES.HOURLY:
        mmt.minutes(schedule.byminute ? schedule.byminute[0] : 0);
        setTargetTz();
        covertedRule.byminute = [mmt.minutes()];
        break;
      case AppConstants.SCHEDULE_TYPES.DAILY:
        mmt.hours(schedule.byhour ? schedule.byhour[0] : 0);
        mmt.minutes(schedule.byminute ? schedule.byminute[0] : 0);
        setTargetTz();
        covertedRule.byhour = [mmt.hours()];
        covertedRule.byminute = [mmt.minutes()];
        break;
      case AppConstants.SCHEDULE_TYPES.WEEKLY:
        mmt.days(schedule.byweekday ? schedule.byweekday[0] : 1);
        mmt.hours(schedule.byhour ? schedule.byhour[0] : 0);
        mmt.minutes(schedule.byminute ? schedule.byminute[0] : 0);
        setTargetTz();
        covertedRule.byweekday = [mmt.day()];
        covertedRule.byhour = [mmt.hours()];
        covertedRule.byminute = [mmt.minutes()];
        break;
      case AppConstants.SCHEDULE_TYPES.MONTHLY:
        mmt.date(schedule.bymonthday ? schedule.bymonthday[0] : 1);
        mmt.hours(schedule.byhour ? schedule.byhour[0] : 0);
        mmt.minutes(schedule.byminute ? schedule.byminute[0] : 0);
        setTargetTz();
        covertedRule.bymonthday = [mmt.date()];
        covertedRule.byhour = [mmt.hours()];
        covertedRule.byminute = [mmt.minutes()];
        break;
      default:
        break;
    }

    return covertedRule;
  },

  // https://github.com/kognitos/brain/blob/main/kognitos/appsync/types.py#L199-L207
  checkSameQuestions(questions) {
    if (questions.length === 0) {
      return false;
    }

    if (questions.length === 1) {
      return true;
    }

    const firstQuestion = questions[0];
    return questions.every(
      (question) =>
        question.type === firstQuestion.type &&
        question.text === firstQuestion.text &&
        question.path === firstQuestion.path &&
        question.rawException === firstQuestion.rawException
    );
  },

  isKognitosEmail(email) {
    return email?.endsWith('@kognitos.com');
  },

  allowProcedureEdit(procedure, department, _userEmail) {
    return procedure.knowledgeId === department?.draftKnowledgeId;
  },

  shouldShowQuestionChoices(type) {
    return type && ['which', 'review', 'review relations'].includes(type);
  },

  getObjectName(object) {
    return AppConstants.VALUE_ROW_KEYS.NAME.map((key) => object[key]).filter(
      Boolean
    )[0];
  },

  getObjectId(object) {
    return AppConstants.VALUE_ROW_KEYS.ID.map((key) => object[key]).filter(
      Boolean
    )[0];
  },

  getFirstKeyFromObject(object) {
    return object[Object.keys(object)[0]];
  },

  getStepNodeFromQuestion(contextPath, contextId) {
    let stepToHighlight = null;

    if (contextPath?.length) {
      if (contextId) {
        const lastContextPath = contextPath.find(
          (path) => path.ctxId === contextId
        );
        if (lastContextPath) {
          stepToHighlight = `${lastContextPath.ctxId}:${lastContextPath.sentenceId}`;
        }
      } else {
        const maxContextId = Math.max(...contextPath.map((o) => o.ctxId));
        const listWithMaxContextId = contextPath.filter(
          (path) => path.ctxId === maxContextId
        );
        if (listWithMaxContextId.length > 1) {
          const maxSentenceId = Math.max(
            ...listWithMaxContextId.map((o) => o.sentenceId)
          );
          const objectWithMaxSentenceId = listWithMaxContextId.find(
            (path) => path.sentenceId === maxSentenceId
          );
          stepToHighlight = `${objectWithMaxSentenceId.ctxId}:${objectWithMaxSentenceId.sentenceId}`;
        } else {
          stepToHighlight = `${listWithMaxContextId[0].ctxId}:${listWithMaxContextId[0].sentenceId}`;
        }
      }
    }

    return stepToHighlight;
  },

  preparePageTitle(title) {
    return `${title} | ${ENV_CONFIG.app.name}`;
  },

  listFuzzySearch(list, keys, searchValue) {
    const result = new Fuse(list, {
      keys: Array.isArray(keys) ? keys : [keys],
      includeScore: true,
      minMatchCharLength: 1
    }).search(searchValue);

    return (
      result
        // .filter((resultItem) => resultItem.score! <= 0.5)
        .map((resultItem) => resultItem.item)
    );
  },

  listDateSearch(list, key, date) {
    return list.filter((item) => dayjs(item[key]).isSame(date, 'day'));
  },

  // TODO: Ideally API should support these filters, Table should just replace the incoming data from API
  handleTableExternalFilterChange({
    list,
    key: { search: searchKeys, date: dateKey },
    searchValue,
    dateValue
  }) {
    let updatedData = list;

    // filter for data
    if (dateValue) {
      updatedData = AppUtil.listDateSearch(updatedData, dateKey, dateValue);
    }

    // filter for search
    if (searchValue.trim().length) {
      updatedData = AppUtil.listFuzzySearch(
        updatedData,
        searchKeys,
        searchValue
      );
    }

    return updatedData;
  },

  shouldFetchPageData(pageDataMap, page) {
    if (page === 0) {
      return false;
    }

    const pageData = pageDataMap[page];
    if (pageData === undefined) {
      return true;
    }
    return false;
  },

  // are we on a mobile UI ?
  isMobileUI() {
    const query = '(max-device-width: 1024px)';
    const ret = window.matchMedia(query);
    return ret.matches;
  },

  // Add the application's mobile class if on mobile
  mobileClassName(clzName) {
    if (AppUtil.isMobileUI()) {
      return `${clzName} mobile`;
    }
    return clzName;
  },

  prepareFilesUploadCommand(uploadedResponse, oldValue = '') {
    const filesString = uploadedResponse.map((r) => `"${r.s3Url}"`).join(', ');

    const defaultCommand =
      uploadedResponse.length > 1
        ? `the files are ${filesString}`
        : `the file is ${filesString}`;

    let command = defaultCommand;

    if (oldValue) {
      if (oldValue.includes('the file is')) {
        const replacedOldValue = oldValue
          .trim()
          .replace('the file is', 'the files are');
        command = `${replacedOldValue}, ${filesString}`;
      } else if (!oldValue.match(AppConstants.PATTERNS.S3_URI)) {
        command = `${oldValue.trim()}\n${command}`;
      } else {
        command = `${oldValue.trim()}, ${filesString}`;
      }
    }

    return command;
  },

  formatProcedureText(text) {
    const procedureText = removeKogLineBreak(text);
    // return encodeMarkdownText(procedureText).trim();
    return procedureText;
  },

  hasMarkdownText(text) {
    if (!text) {
      return false;
    }
    // return AppConstants.PATTERNS.MARKDOWN_TEXT.test(text);
    return `${text}`.startsWith('"""');
  },

  getPaginatedList(list, pageNumber, pageSize) {
    return [...list].slice(pageSize * (pageNumber - 1), pageSize * pageNumber);
  },

  isSupportUser(username) {
    return !!username.match(AppConstants.PATTERNS.SUPPORT_USER);
  },

  // Util to format time
  formatTime(timeStamp) {
    return moment(timeStamp).format('MMM DD, YYYY h:mm a');
  },

  upperFirstChar(str) {
    return str.charAt(0).toUpperCase() + str.slice(1);
  },

  isPlaygroundV3(pathname) {
    return pathname.includes('playground2');
  }
};

export default AppUtil;
