//
// Copyright (C) - Kognitos. All rights reserved
//
// NewFlow is the popup for creating a new flow or cloning an existing one
//

// 3rd party libraries
import React, { useEffect, useRef, useState } from 'react';
import {
  Form,
  Input,
  InputRef,
  message,
  Modal,
  Select,
  Space,
  Spin,
  Typography
} from 'antd';
import { gql, useMutation } from '@apollo/client';
import _debounce from 'lodash/fp/debounce';
import i18n from '@utils/i18n';
import AppConstants from '@utils/AppConstants';
import AppUtil from '@utils/AppUtil';
import { ParseFeedback, ParseResult } from '@/components/ParseFeedback';
import { useAppDispatch } from '@/stores/hooks';
import { createProcedure } from '@/stores/slices/procedure';
import { track } from '@amplitude/analytics-browser';
import { updateProcedure as updateProcedureGQL } from '@/generated/graphql/mutations';
import Editor from '@/components/editor';
import './NewFlow.less';
import { useSelector } from 'react-redux';
import { userSelector } from '../stores/slices/user';
import {
  CreateProcedureMutationVariables,
  Department,
  UpdateProcedureMutationVariables
} from '../generated/API';

// Component i18n
const i18nt = (s: string, d?: string) => i18n.t('NewFlow', s, d);

function template(language: string) {
  if (language === 'python') {
    return `from kognitos.bci import procedure

@procedure
def handler(brain):
  # TODO implement
  return
`;
  }

  if (language === 'nodejs') {
    return `// TODO implement
return
`;
  }

  return '';
}

// Component Prop Types
interface IProps {
  /**
   * Success Callback -- called back w/ new flow
   */
  onSuccess: (data: any) => void;
  /**
   * Close Callback
   */
  onClose: () => void;

  department: Department;
  knowledgeId: string;

  /**
   * Procedure to be cloned
   */
  procedureToClone: {
    id?: string;
    name: string;
    title?: string;
    description: string;
    language: string;
    text: string;
  };

  procedureBody?: string;
  suffix?: string;

  title?: string;
  update: boolean;
}

const getCloneName = (
  procedureName: string,
  suffix: string | undefined
): string => {
  if (suffix) {
    return `${procedureName.trim()}${suffix}`;
  }
  const match = procedureName.match(AppConstants.PATTERNS.CLONE_SUFFIX);
  if (!match) {
    return `${procedureName.trim()} - clone`;
  }
  const cloneSuffix = procedureName.split('-')[1].trim();
  const matchDigit = cloneSuffix.match(AppConstants.PATTERNS.NUMBERS);
  const foundNumber = matchDigit?.[0];
  const normalisedName = procedureName
    .replace(AppConstants.PATTERNS.CLONE_SUFFIX, '')
    .trim();
  if (!foundNumber) {
    return `${normalisedName} - 1 clone`;
  }
  return `${normalisedName} - ${Number(foundNumber) + 1} clone`;
};

// Component implementation
function NewFlow(props: IProps) {
  const {
    onSuccess,
    onClose,
    department,
    knowledgeId,
    procedureToClone,
    update,
    procedureBody,
    title = 'New Process',
    suffix
  } = props;

  // The new form instance
  const [theForm] = Form.useForm();
  const dispatch = useAppDispatch();
  // Is the popup visible
  const [visible, setVisible] = useState(true);
  // The form's field values
  const [formState, setFormState] = useState({
    name: procedureToClone ? getCloneName(procedureToClone.name, suffix) : '',
    description: procedureToClone?.description ?? '',
    title: procedureToClone ? getCloneName(procedureToClone.name, suffix) : '',
    procedureType:
      procedureToClone?.language ??
      AppConstants.PROCEDURE_TYPE_MAP.english.type,
    procedure: procedureToClone?.text ?? procedureBody ?? template('english')
  });

  // TODO: Move - added as a temp fix
  const [loading, toggleLoading] = useState(false);

  const iRef = useRef<InputRef>(null);

  useEffect(() => {
    iRef.current?.focus();
  }, []);

  const { username } = useSelector(userSelector);

  const [parseResult, setParseResult] = useState<ParseResult>(ParseResult.FAIL);

  const [updateProcedure] = useMutation(gql(updateProcedureGQL));

  const OkUpdateProcedure = () => {
    theForm
      .validateFields(['name', 'procedure'])
      .then(() => {
        const variables: UpdateProcedureMutationVariables = {
          input: {
            departmentId: department.id,
            id: procedureToClone.id!,
            name: formState.name.trim(),
            text: formState.procedure.trim(),
            language: formState.procedureType.trim(),
            owner: username
          }
        };

        if (!AppUtil.isDepartmentBookSupported(department)) {
          variables.input.knowledgeId = knowledgeId;
        }

        toggleLoading(true);
        updateProcedure({ variables })
          .then(
            (value) => {
              if (!value.errors) {
                track('ProcedureUpdated', value?.data?.updateProcedure);
                onSuccess?.(value?.data?.updateProcedure);
              }
            },
            (error) => {
              AppUtil.logError(error);
            }
          )
          .finally(() => {
            onClose();
            toggleLoading(false);
          });
      })
      .catch((errorInfo) => {
        AppUtil.logDebug(errorInfo);
      });
  };

  const onOK = () => {
    theForm
      .validateFields(['name', 'procedure'])
      .then(() => {
        const variables: CreateProcedureMutationVariables = {
          input: {
            departmentId: department.id,
            name: formState.name.trim(),
            title: formState.title.trim(),
            text: AppUtil.formatProcedureText(formState.procedure),
            language: formState.procedureType.trim(),
            owner: username
          }
        };

        if (!AppUtil.isDepartmentBookSupported(department)) {
          variables.input.knowledgeId = knowledgeId;
        }

        toggleLoading(true);

        dispatch(createProcedure(variables))
          .unwrap()
          .then((value) => {
            if (value.errors) {
              console.log(
                'createProcedure error',
                JSON.stringify(value.errors)
              );
            }
            if (value.data) {
              const procedure = value.data.createProcedure;
              if (procedure) {
                track('ProcedureCreated', procedure);
              }
              onSuccess?.(value.data);
            }
            onClose();
          })
          .catch((e) => {
            console.log('createProcedure error', e);
            message.error(e.message || 'Failed to create a new process', 5);
          })
          .finally(() => {
            toggleLoading(false);
          });
      })
      .catch((errorInfo) => {
        AppUtil.logDebug(errorInfo);
      });
  };

  const onCancel = () => {
    setVisible(false);
    onClose();
  };

  const onChange = (key: string, value: any) => {
    const newState: any = { ...formState };
    newState[key] = value;
    if (newState.procedure === template(formState.procedureType)) {
      newState.procedure = template(newState.procedureType);
    }
    setFormState(newState);
  };

  const allowSubmit = [
    parseResult === ParseResult.PASS,
    !!formState.name.trim(),
    !!formState.procedure.trim()
  ].every(Boolean);

  return (
    <Modal
      className="new-flow-modal"
      title={
        <Space>
          <Typography.Title level={5} className="title">
            {title}
          </Typography.Title>
          <ParseFeedback
            statement={formState.name.trim()}
            scopeCode={formState.procedure.trim() || 'do nothing'}
            language={formState.procedureType.trim()}
            departmentId={department?.id}
            parseCallback={setParseResult}
          />
        </Space>
      }
      centered
      open={visible}
      onOk={update ? OkUpdateProcedure : onOK}
      okButtonProps={{
        // @ts-ignore
        'data-cy': 'ok-btn',
        disabled: !allowSubmit
      }}
      onCancel={onCancel}
      width={800}
      confirmLoading={loading}
      keyboard={false}
      maskClosable={false}
    >
      <Form
        form={theForm}
        layout="vertical"
        requiredMark={false}
        validateTrigger={['onBlur', 'onChange']}
      >
        <Form.Item
          name="name"
          data-cy="procedure-name-for-cloning"
          label={i18nt('name', 'Name')}
          rules={[
            {
              validator: () =>
                new Promise<void>((resolve, reject) => {
                  const pName = formState.name.trim().toLocaleLowerCase();
                  if (
                    pName.startsWith('to ') ||
                    pName.endsWith(' is') ||
                    pName.endsWith(' if') ||
                    pName.endsWith(' are')
                  ) {
                    resolve();
                  } else {
                    reject();
                  }
                }),
              message: i18nt(
                'invalid_procedure_name',
                'Enter a name that starts with "to" or ends with "is", "if", or "are"'
              )
            }
          ]}
        >
          <Input
            ref={iRef}
            data-cy="procedure-name"
            className="new-procedure-name"
            placeholder="to do something"
            autoComplete="off"
            disabled={loading}
            onChange={(e) => onChange('name', e.target.value)}
            value={formState.name}
          />
        </Form.Item>

        <Form.Item
          name="procedure"
          label={
            <div className="procedure-type-wrapper">
              {i18nt('procedure', 'Process')}

              <div data-cy="procedure-type">
                <Select
                  className="procedure-type"
                  value={formState.procedureType}
                  style={{ width: 120 }}
                  onChange={(val) => {
                    onChange('procedureType', val);
                  }}
                >
                  {Object.values(AppConstants.PROCEDURE_TYPE_MAP).map((k) => (
                    <Select.Option
                      key={k.type}
                      value={k.type}
                      data-cy={k.type}
                      disabled={loading}
                    >
                      {i18nt(`procedure_type_${k.type}`, k.label)}
                    </Select.Option>
                  ))}
                </Select>
              </div>
            </div>
          }
        >
          <div className="procedure">
            <Spin spinning={loading}>
              <Editor
                value={`${formState.procedure}`}
                language={formState.procedureType}
                onChange={(val) => {
                  if (!loading) {
                    onChange('procedure', val);
                  }
                }}
                markdown={{
                  onSave(updatedValue) {
                    onChange('procedure', updatedValue);
                  }
                }}
              />
            </Spin>
          </div>
        </Form.Item>
      </Form>
    </Modal>
  );
}

export default NewFlow;
