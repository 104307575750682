/* eslint-disable react/no-array-index-key */
import React, { useCallback, useEffect } from 'react';
import { useParams, useNavigate, Link } from 'react-router-dom';
import { Button, Card, Collapse, Space } from 'antd';
import { useAppDispatch, useAppSelector } from '@/stores/hooks';
import Cell from '@/components/example/Cell';
import routes from '@/utils/routes';
import {
  getExample,
  learnExample,
  exampleQuerySelector
} from '@/stores/slices/example';
import Loader, { ILoaderType } from '@/components/Loader';
import { useAmplitudeTrack } from '@/analytics/hooks';
import { useDocumentTitle } from '@/hooks/useDocumentTitle';
import { ampTrackEvent } from '@/analytics';
import data from './data/examplesList';
import './Example.less';
import MarkdownRenderer from '../components/MarkdownRenderer';
import AppUtil from '../utils/AppUtil';
import Walkthrough from '../components/walkthrough/Walkthrough';
import { WalkthroughType } from '../components/walkthrough/interface';

const { Panel } = Collapse;

function CardTitle({ text, icons }: { text: string; icons: string[] }) {
  return (
    <Space align="center">
      <div className="example-card-icon">
        <Space align="center">
          {icons?.map((icon, index) => (
            <img alt="icon" key={index} src={icon} width={30} height={30} />
          ))}
        </Space>
      </div>{' '}
      {text}
    </Space>
  );
}

export function ExampleList() {
  useDocumentTitle(AppUtil.preparePageTitle(`Examples list`));
  useAmplitudeTrack('ExamplesListPage');

  return (
    <div className="examples container">
      <h2>Examples</h2>
      {data.map((item, index) => (
        <Card
          style={{ marginTop: 16 }}
          title={<CardTitle text={item.title} icons={item.icons} />}
          extra={
            <Button
              type="primary"
              onClick={() => {
                ampTrackEvent('ClickLaunchExample', { example: item.url });
              }}
            >
              <Link data-cy={item.url} to={`/automation/${item.url}`}>
                Launch
              </Link>
            </Button>
          }
          key={index}
        >
          <MarkdownRenderer>{item.description}</MarkdownRenderer>
          {item?.requirements && 'Requirements:'}
          {item?.requirements?.map((requirement, index) => (
            <div key={index}>{requirement}</div>
          ))}
          <br />
          {item?.procedure && (
            <Collapse bordered={false} expandIconPosition="end">
              {['Procedure'].map((p, index) => (
                <Panel header={p} key={index}>
                  <div className="card-container">
                    <i>
                      <MarkdownRenderer>{item?.procedure}</MarkdownRenderer>
                    </i>
                  </div>
                </Panel>
              ))}
            </Collapse>
          )}
        </Card>
      ))}
    </div>
  );
}

export default function Example() {
  const { name } = useParams();
  const navigate = useNavigate();
  const dispatch = useAppDispatch();
  const { example, isLearning, isError, isComplete, isLoading, result } =
    useAppSelector(exampleQuerySelector(name!));

  useDocumentTitle(AppUtil.preparePageTitle('Example'));

  useEffect(() => {
    dispatch(getExample(name!));
  }, [name]);

  const cells = example?.cells ?? [];

  const runSolution = useCallback(() => {
    dispatch(learnExample(name!));
  }, [name]);

  if (isLoading) {
    return <Loader type={ILoaderType.FULL_PAGE} />;
  }

  return (
    <div className="container example">
      <Walkthrough type={WalkthroughType.EXAMPLE} />
      <div className="react-ipynb-renderer ipynb-renderer-root">
        {cells.map((cell) => (
          <Cell key={cell.metadata.id} cell={cell} />
        ))}
      </div>
      <Button
        loading={isLearning}
        disabled={isComplete}
        type="primary"
        data-cy="learn-example"
        onClick={runSolution}
        className="learn-example-btn"
      >
        {isError ? 'Retry' : 'Learn'}
      </Button>{' '}
      {isComplete && (
        <Button
          type="primary"
          onClick={() =>
            navigate(
              routes.procedure.getProcedurePageUrl(
                result?.departmentId,
                result?.knowledgeId,
                result?.id!
              )
            )
          }
        >
          Show Process
        </Button>
      )}
    </div>
  );
}
